<template>
  <div style="display: flex; align-items: center;">
    <span>AWS Bedrock</span>
    <CheckIconOnly
      style="width: 13px; margin-left: 10px; margin-top: 1px;"
      :style="{ fill: connected ? 'rgb(67, 160, 71)' : 'grey' }"
    />
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import CheckIconOnly from "@/components/icons/CheckIconOnly.vue";

const connected = ref(JSON.parse(localStorage.getItem('bedrock_connected')) ?? false);

const fetchStatus = async () => {
  try {
    const response = await axios.get('/go/bedrock');
    connected.value = response.data.connected;
    localStorage.setItem('bedrock_connected', JSON.stringify(connected.value));
  } catch (error) {
    console.error('failed to fetch status', error);
    connected.value = false;
  }
};

onMounted(fetchStatus);
</script>