<template>
  <div>
    <div style="width: 100%; height: 80%;">
      <div v-show="!qrImage && !showVerify" class="login-container">
        <form style="margin-top: 15px;" @submit.prevent="handleSubmit">

          <img style="width: 260px;height: 49.5px;" src="images/logo-greyscale.png" alt="Login Logo">

          <div style="margin-left: 5px; margin-top: 20px; text-align: left;">
            <label for="username">Username:</label>
            <input type="text" id="username" v-model="username" autocomplete="username" required>
          </div>
          <div style="margin-left: 5px; text-align: left;">
            <label for="password">Password:</label>
            <input type="password" id="password" v-model="password" autocomplete="current-password" required>
          </div>
          <div style="margin-left: 5px; margin-top: 5px; text-align: left; display: flex; align-items: center;">
            <input style="margin-top: 2px;" type="checkbox" id="rememberMe" v-model="rememberMe">
            <label style="margin-left: 7px;" for="rememberMe">Remember on this device</label>
          </div>

          <button style="margin-top: 20px; margin-bottom: 20px;" type="submit">
            Login
            <span v-if="isLoggingIn" class="loading-spinner"></span>
          </button>
          <div class="error" v-if="errorMessage" style="margin-bottom: 15px; margin-top: -2px;">{{ errorMessage }}</div>

          <span style="margin-top: 5px; margin-bottom: 5px; display: block;">© {{ currentYear }} CloudHound Ltd</span>

        </form>
      </div>

      <!-- Modal or Image display for QR Code -->
      <div v-show="qrImage" class="qr-modal">
        <form @submit.prevent="handleVerify">
          <div style="text-align: left;">
            <p style="margin-bottom:11px;"><b>Multi-Factor Authentication</b></p>
            <p style="margin-bottom:8px;">To enable two-factor authentication for CloudHound:</p>
            <p style="margin-bottom:8px;">1. Scan the QR code or copy the secret into an authenticator app.</p>
            <p style="margin-bottom:20px;">2. Input the code shown in the app and click Activate.</p>
          </div>
          <img :src="qrImage" alt="TOTP QR Code" style="width: 125px; height: 125px; margin-bottom: 15px;">

          <div style="display: flex; align-items: center; justify-content: center; margin-bottom: 15px;">
            <div style="margin-right: 5px;">{{ secret }}</div>
            <CopyIcon style="cursor: pointer;" @click="handleCopy"></CopyIcon>
            <div v-if="showCopiedMessage" class="copied-message">Copied</div>
          </div>

          <div style="margin-left: 5px; text-align: left; margin-bottom: 10px;">
            <label for="verification">Verification code:</label>
            <input type="text" id="verificationQR" v-model="verificationQR" required>
          </div>

          <button class="activate" style="margin-bottom: 13px;" type="submit">Activate</button>
          <button class="cancel" @click="qrImage = ''; errorMessage = ''; verificationQR = ''">Cancel</button>

          <div class="error" v-if="errorMessage" style="margin-top: 15px; margin-bottom: -5px;">{{ errorMessage }}</div>

        </form>
      </div>

      <!-- modal to get verification code -->
      <div v-show="showVerify" class="qr-modal">
        <form @submit.prevent="handleVerify">

          <img style="width: 260px; height: 49.5px; margin-top: 15px; margin-bottom: 10px;" src="images/logo-greyscale.png" alt="Login Logo">

          <div style="margin-left: 5px; text-align: left; margin-top: 10px;">
            <p style="margin-bottom: 10px;">Multi-Factor Authentication</p>
          </div>

          <div style="margin-left: 5px; text-align: left; margin-bottom: 5px;">
            <label for="verification">Use your authenticator app to enter an OTP token:</label>
            <input class="verification" type="text" id="verification" v-model="verification" autocomplete="off" required>
          </div>

          <div style="margin-left: 5px; margin-top: 5px; text-align: left; display: flex; align-items: center; margin-bottom: 20px;">
            <input style="margin-top: 2px;" type="checkbox" id="rememberMeV" v-model="rememberMe">
            <label style="margin-left: 7px;" for="rememberMeV">Remember on this device</label>
          </div>

          <button class="activate" style="margin-bottom: 13px;" type="submit">Verify</button>
          <button class="cancel" @click="errorMessage = ''; showVerify = false; verification = '';">Cancel</button>

          <div class="error" v-if="errorMessage" style="margin-bottom: -5px; margin-top: 15px;">{{ errorMessage }}</div>

          <div style="margin-top: 20px;">© {{ currentYear }} CloudHound Ltd</div>

        </form>
      </div>

    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useCookies } from 'vue3-cookies';
import { useRouter } from 'vue-router';
import localForage from "localforage";
import CopyIcon from "@/components/icons/CopyIcon.vue";
import {usePortfolioStore} from "@/stores/DataStore";

const currentYear = ref('');
const { cookies } = useCookies();
const router = useRouter();
const username = ref('');
const password = ref('');
const verificationQR = ref('');
const verification = ref('');
const errorMessage = ref('');
const rememberMe = ref(true);
const qrImage = ref('');
const secret = ref('');
const showCopiedMessage = ref(false);
const showVerify = ref(false);
const isLoggingIn = ref(false);

onMounted(() => {
  currentYear.value = new Date().getFullYear();
  clearDB();
})

const clearDB = async () => {
  const chartDB = localForage.createInstance({name: 'CloudHound', storeName: 'charts'});
  const costingDB = localForage.createInstance({name: 'CloudHound', storeName: 'costing'});
  const portfolioDB = localForage.createInstance({name: 'CloudHound', storeName: 'portfolio'});
  const discoveryDB = localForage.createInstance({name: 'CloudHound', storeName: 'discovery'});
  const serverDB = localForage.createInstance({name: 'CloudHound', storeName: 'servers'});
  const counterDB = localForage.createInstance({name: 'CloudHound', storeName: 'livecounter'});
  const mappingDB = localForage.createInstance({name: 'CloudHound', storeName: 'mapping'});

  await chartDB.clear();
  await costingDB.clear();
  await portfolioDB.clear();
  await discoveryDB.clear();
  await serverDB.clear();
  await counterDB.clear();
  await mappingDB.clear();
};

const handleCopy = () => {
  navigator.clipboard.writeText(secret.value);
  showCopiedMessage.value = true;
  setTimeout(() => {
    showCopiedMessage.value = false;
  }, 2000);
};

const handleVerify = async () => {
  try {
    isLoggingIn.value = true;

    let otp = verificationQR.value;
    if (!otp) otp = verification.value;

    const response = await axios.post(`/go/login`, {
      username: username.value,
      password: password.value,
      totp: otp
    });

    if (response.status === 200) {
      errorMessage.value = "";

      if (response.data.error === "Token invalid") {
        isLoggingIn.value = false;
        errorMessage.value = "Token invalid";
        return;
      }

      // successfully logged in
      if (response.data.session_id) {
        login(response.data.session_id);
      }

    }
  } catch (error) {
    isLoggingIn.value = false;

    errorMessage.value = "Unable to connect"
    if (error.response) {
      console.log("error.response: " + error.response.data.error);
      errorMessage.value = error.response.data.error; // http response error
    }
  }
}

const handleSubmit = async () => {
  isLoggingIn.value = true;
  try {
    const response = await axios.post(`/go/login`, {
      username: username.value,
      password: password.value
    });

    if (response.status === 200) {
      errorMessage.value = "";

      if (response.data.error === "Token invalid") {
        isLoggingIn.value = false;
        errorMessage.value = "";
        showVerify.value = true;
        return;
      }

      if (response.data.qr_image) {
        isLoggingIn.value = false;
        qrImage.value = 'data:image/png;base64,' + response.data.qr_image;
        secret.value = response.data.secret;
      }

      if (response.data.session_id) {
        login(response.data.session_id);
      }
    }
  } catch (error) {
    isLoggingIn.value = false;

    errorMessage.value = "Unable to connect"
    if (error.response) {
      if (error.response.data.error === "Token invalid") {
        errorMessage.value = "";
        showVerify.value = true;
        return;
      }
      errorMessage.value = error.response.data.error; // http response error
    }
  }
};

const login = async (session_id) => {
  let expireDays = rememberMe.value ? "14d" : "0";
  cookies.set('session_id', session_id, expireDays, '/', undefined, true, 'Strict');
  new Promise(resolve => setTimeout(resolve, 0));  // complete event loop so cookie will be set

  // get portfolio data before changing page for super smooth transition
  await getPorfolioData();

  // send through the latest counter number so there's no flash on login
  router.push({ path: '/' });
}

// synchronously get and save portfolio data before login completes
const getPorfolioData = async () => {

  try {
    const response = await axios.get('/go/portfolios', {
      headers: {
        'Content-Type': 'application/json'
      },
    });
    const store = usePortfolioStore();

    store.save(response.data.portfolios);
    store.saveApiKey(response.data.api_key);
    store.setCurPortfolio(response.data.cur_portfolio);
  } catch (error) {
      console.error('Error fetching data:', error);
  }
  return 0;
};

</script>

<style scoped>
.login-container {
  margin: 100px auto auto;
  max-width: 300px;
  padding: 20px;
  background-color: #0D1117;
  border: 1px solid #2F353D;
  border-radius: 5px;
}

.qr-modal {
  margin: 100px auto auto;
  max-width: 300px;
  padding: 20px;
  background-color: #0D1117;
  border: 1px solid #2F353D;
  border-radius: 5px;
}

input[type="text"], input[type="password"] {
  width: 290px;
  padding: 10px;
  margin: 10px 0;
  box-sizing: border-box;
  font-size: 13px;
  background-color: #000000 !important;
  border: 1px solid #2f353d;
  border-radius: 4px;
  color: #eaedf0 !important;
}

input[type="text"]:-webkit-autofill::first-line { font-size: 13px; }
input[type="password"]:-webkit-autofill::first-line { font-size: 13px; }

input[type="text"]:-webkit-autofill,
input[type="password"]:-webkit-autofill {
  color: #eaedf0 !important;
  -webkit-text-fill-color: #eaedf0;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

input[type="text"].verification:focus, input[type="password"].verification:focus, input[type="text"].verification:focus {
  border: 1px solid #000 !important; /* Change color as needed */
}

input[type="text"].verification:focus, input[type="password"].verification:focus, input[type="text"].verification:focus-visible {
  border: 1px solid #000 !important; /* Change color as needed */
}

.verification:active {
  border: 1px solid #000;
}

.verification:focus {
  border: 1px solid #000;
}

.verification:focus-visible {
  border: 1px solid #000;
}

button {
  width: 290px;
  padding: 10px;
  background-color: #43A047;
  color: white;
  border: none;
  cursor: pointer;
  margin: 0;
  position: relative;
}

.cancel {
  background-color: #282f3b;
}

.error {
  color: #ec4545;
}

.copied-message {
  position: absolute;
  left: calc(50% + 78px);
  /* top: 50%; */
  margin-top: 68px;
  transform: translateY(-50%);
  white-space: nowrap;
  margin-left: 10px;
  padding: 2px 6px;
  background-color: #282f3b;
  border: 1px solid #2f353d;
  border-radius: 4px;
  font-size: 11px;
  color: #ffffff;
}

.loading-spinner {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-left: 8px;
  border: 1.5px solid #ffffff;
  border-top: 1.5px solid transparent;
  border-radius: 50%;
  animation: spin 0.6s linear infinite;
  position: absolute;
  right: 11px;
  top: 27%;
  transform: translateY(-50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>