<template>
  <div class="table-container">
    <table class="portfolio-table">
      <tr>
        <th class="header-cell" style="width: 310px">Email</th>
        <th class="header-cell" style="width: 220px">Firstname</th>
        <th class="header-cell" style="width: 220px">Lastname</th>
        <th class="header-cell" style="width: 340px">Last Seen</th>
        <th class="header-cell" style="width: 55px"></th>
      </tr>
      <tr v-for="user in localUsers" @click="selectPortfolio(user.email)" :key="user.email">
        <td>{{ user.email }}</td>
        <td>{{ user.firstname }}</td>
        <td>{{ user.lastname }}</td>
        <td>{{ formatDate(user.last_seen) }}</td>
        <td>
          <div class="dropdown-container">
            <button @click.stop="toggleDropdown(user.email, $event)" class="dropdown-button">&#8942;</button>
            <Teleport to="body">
              <ul v-if="activeDropdown === user.email" class="dropdown" :style="dropdownStyles">
                <li @click="confirmDeletePortfolio(user)">Delete</li>
              </ul>
            </Teleport>
          </div>
        </td>
      </tr>
    </table>
  </div>

  <button class="portfolio-button" @click="openNewPortfolioModal">Add User</button>

  <!-- NewPortfolio Modal -->
  <NewUser
    v-if="showModal"
    :initialData="modalData.initialData"
    @close="showModal = false"
    @submit="handleAddUser"
  />

  <!-- DeletePortfolio Modal -->
  <DeletePortfolio
    v-if="showDeleteModal"
    :portfolioId="deleteData.portfolioId"
    @close="showDeleteModal = false"
    @delete="handleDeletePortfolio"
  />

  <NewPasswordModal
    v-if="showPasswordModal"
    :password="newPassword"
    @close="showPasswordModal = false"
  />
</template>

<script setup>
import { ref, watch, onMounted, onUnmounted } from 'vue';
import axios from 'axios';
import NewUser from "@/settings/users/NewUser.vue";
import NewPasswordModal from "@/settings/users/UserPassword.vue";
import DeletePortfolio from "@/components/portfolio/DeletePortfolio.vue";

const emit = defineEmits(['refresh']);

const props = defineProps({
  users: {
    type: Array,
    default: () => []
  }
});

const showModal = ref(false); // toggle NewPortfolio modal visibility
const showDeleteModal = ref(false); // toggle DeletePortfolio modal visibility
const activeDropdown = ref(null); // track which portfolio dropdown is active
const dropdownStyles = ref({}); // dynamic styles for dropdown positioning

const showPasswordModal = ref(false);
const newPassword = ref('');
const localUsers = ref([...props.users]);

const modalData = ref({
  isEditing: false,
  initialData: { portfolioId: '', customer: '', description: '', api_key: '' }
});

const deleteData = ref({
  portfolioId: ''
});

const formatDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const year = String(d.getFullYear());

    const isUS = navigator.language.startsWith('en-US');
    return isUS ? `${month}/${day}/${year}` : `${day}/${month}/${year}`;
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});

const openNewPortfolioModal = () => {
  modalData.value.isEditing = false;
  modalData.value.initialData = { portfolioId: '', customer: '', description: '' };
  showModal.value = true;
};

watch(() => props.users, (newUsers) => {
  localUsers.value = [...newUsers];
});

const confirmDeletePortfolio = (portfolio) => {
  deleteData.value.portfolioId = portfolio.portfolio;
  showDeleteModal.value = true;
};

const handleAddUser = async (formData) => {

  // add to table
  localUsers.value.push({
    email: formData.email, firstname: formData.firstname, lastname: formData.lastname
  });

  try {
    const response = await axios.post('/go/newuser', formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });

    // show password in modal
    newPassword.value = response.data.password;
    showPasswordModal.value = true;

  } catch (error) {
    console.error('Error adding portfolio:', error);
  }

  // refresh the table via api
  emit('refresh');

  showModal.value = false;
};

const handleDeletePortfolio = async (email) => {
  try {

    const params = new URLSearchParams();
    params.append('email', email);
    await axios.post('/go/deleteuser', params, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    });

    // remove user from localUsers after successful deletion
    localUsers.value = localUsers.value.filter(p => p.email !== email);
  } catch (error) {
    console.error('Error deleting portfolio:', error);
  }
  showDeleteModal.value = false;
};

const toggleDropdown = (apiKey, event) => {
  if (activeDropdown.value === apiKey) {
    activeDropdown.value = null;
    return;
  }

  const buttonRect = event.target.getBoundingClientRect();

  dropdownStyles.value = {
    position: 'absolute',
    top: `${buttonRect.bottom + 7}px`, // position it below the button
    left: `${buttonRect.left - 54}px`,  // align it with the button
    zIndex: 1000,
  };

  activeDropdown.value = apiKey;
};

const handleClickOutside = (event) => {
  event.stopPropagation(); // stop the click event from propagating
  event.stopImmediatePropagation();

  const dropdowns = document.querySelectorAll('.dropdown-container');
  let isClickInside = false;

  dropdowns.forEach(dropdown => {
    if (dropdown.contains(event.target)) {
      isClickInside = true;
    }
  });

  if (!isClickInside) {
    activeDropdown.value = null;
  }
};
</script>

<style scoped>
.portfolio-table {
  margin-bottom: 0;
}

.portfolio-button {
  height: 22px;
  margin: 15px 0;
  padding: 2px 10px;
  background: #d05c00;
}

.dropdown-container {
  position: relative;
}

.dropdown {
  background-color: #1d2532;
  box-shadow: 0 0 20px #000000;
  border: 1px solid #4a556d;
  border-radius: 3px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100px;
  z-index: 1000;
  position: absolute;
}

.dropdown li {
  padding: 8px 13px;
  cursor: pointer;
  color: #cccccc;
}

.dropdown li:hover {
  background-color: #384459;
}

.dropdown-button {
  margin: 0;
  background: none;
  border: none;
}

.table-container {
  width: 1195px;
  overflow-x: auto;
  box-shadow: 3px 3px 16px -8px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  border: 1px solid #2f353d;
}

</style>