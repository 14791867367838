<template>
  <div class="register">
    <div class="main-content placeholder-header" style="flex-direction: column;">

      <div style="margin-top: 20px;">

        <img style="width: 210px;" src="https://web.cloudhound.io/images/listing.png" alt="CloudHound Listing Image" />

        <div>Welcome to CloudHound</div>

        <p style="max-width: 350px; margin-top: 20px;">Enter an initial email for your organisation. When you click continue, a registration email will be
          sent.</p>

        <div class="form-container" style="display: flex; flex-direction: column; gap: 10px; margin-top: 10px; width: 250px; margin-left: 50px;">
          <input type="email" v-model="email1" placeholder="Enter first email" />
          <input type="email" v-model="email2" placeholder="Enter second email" />
        </div>

        <button @click="sendRegistrationEmail" style="margin-top: 15px;">Continue</button>
        <div v-if="validationError" style="color: red; margin-top: 10px;">{{ validationError }}</div>
        <div v-if="emailSent" style="color: green; margin-top: 10px;">Email sent</div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref, onMounted} from 'vue';
import axios from 'axios';

export default {
  setup() {
    const params = ref({
      message: '',
      customer_id: '',
      aws_account_id: '',
      product_code: '',
      subscription: '',
      auth_id: ''
    });

    const email1 = ref('');
    const email2 = ref('');
    const validationError = ref('');
    const emailSent = ref(false);

    const sendRegistrationEmail = async () => {
      validationError.value = ''; // Reset error message
      emailSent.value = false; // Reset email sent status
      if (!email1.value || !email2.value) {
        validationError.value = 'Both email fields must be filled out.';
        return;
      }
      if (email1.value !== email2.value) {
        validationError.value = 'Emails do not match. Please try again.';
        return;
      }
      try {
        await axios.post('https://web.cloudhound.ai/register_org', {
          email: email1.value,
          auth_id: params.value.auth_id
        });
        emailSent.value = true; // Set email sent status
      } catch (error) {
        console.error('Error sending registration email:', error);
        alert('Failed to send registration email');
      }
    };

    onMounted(() => {
      const queryParams = new URLSearchParams(window.location.search);
      params.value.message = queryParams.get('message');
      params.value.customer_id = queryParams.get('customer_id');
      params.value.aws_account_id = queryParams.get('aws_account_id');
      params.value.product_code = queryParams.get('product_code');
      params.value.subscription = queryParams.get('subscription') === 'true';
      params.value.auth_id = queryParams.get('auth_id');
    });

    return {params, email1, email2, validationError, emailSent, sendRegistrationEmail};
  }
};
</script>

<style>
.register {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  color: #000;
  z-index: 9999;
}
</style>
