import axios from 'axios';
import {useServerDataStore} from '@/stores/DataStore';

const BATCH_SIZE = 15; // constant for the number of items fetched in each batch

let isFetchingData = false; // global flag to prevent multiple instances

const fetchData = async (uuids, cancel = {}) => {
  const { signal } = cancel;

  if (isFetchingData) {
    // console.log('Fetch operation is already in progress.');
    return;
  }

  isFetchingData = true;

  const store = useServerDataStore();

  const cookieExists = () => document.cookie.includes('session_id');

  try {
    for (let i = 0; i < uuids.length; i += BATCH_SIZE) {
      if (signal?.aborted || !cookieExists()) {
        // console.log('Prefetch exit');
        break;
      }

      const batch = uuids.slice(i, i + BATCH_SIZE);

      // console.log("Fetch " + BATCH_SIZE);

      // filter stale UUIDs
      const staleUUIDs = batch.filter(uuid => store.isDataStale(uuid));

      if (staleUUIDs.length === 0) {
        continue;
      }

      // make a request to the server list endpoint
      const response = await axios.post(
        `/go/server/prefetch`,
        { uuids: staleUUIDs }, // send UUIDs in the request body
        {
          headers: {'Content-Type': 'application/json',},
          signal, // cancel the request if going away from discovery page
        }
      );

      // process the response
      if (response.data && Array.isArray(response.data)) {

        // empty array returned if portfolio has changed -> exit
        if (response.data.length === 0) {
            console.log("Prefetch returned empty");
            isFetchingData = false;
            return;
        }

        // otherwise save
        response.data.forEach(data => {
          if (data.uuid) {
            store.saveServer(data.uuid, data);
          }
        });
      }
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      // cancelled on portfolio change, halts the pre-fetch
      // console.log('Request canceled:', error.message);
    } else {
      console.log('Request error:', error.message);
    }
  } finally {
    isFetchingData = false;
  }

  // console.log("Complete Fetch");
};

export default fetchData;

