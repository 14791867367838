<template>
  <div  v-if="getMissing !== ''" class="outer-container">
    <div style="font-weight: 600; margin-bottom: 8px;">{{ getMissing }}</div>
    <div>Not Found</div>
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue';

const props = defineProps({
  costings: {
    type: Array,
    required: true
  }
});

const getMissing = computed(() => {
  if (!props.costings) {
    return "";
  }

  const counts = {
    "IBM Db2": props.costings.filter((item) => ['DB2'].includes(item.pricing.rds_database)).length,
    "Oracle DB": props.costings.filter((item) => ['Oracle'].includes(item.pricing.rds_database)).length,
    "RedHat Enterprise Linux": props.costings.filter((item) => item.platform === 'redhat').length,
    "SQL Server": props.costings.filter((item) => {
      if (item.target.startsWith("rds")) {
        return ['SQL Server'].includes(item.pricing.rds_database);
      }
      return false;
    }).length,
    "SUSE Enterprise Linux": props.costings.filter((item) => item.platform === 'sles').length,
    "Windows Server": props.costings.filter((item) => item.os === 'windows').length
  };

  // get the names of items with zero count
  const zeroCounts = Object.keys(counts).filter((key) => counts[key] === 0);

  return zeroCounts.join(", ");
});

</script>

<style scoped>
.outer-container {
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: rgb(165,165,165) transparent;
  -ms-overflow-style: none; /* Edge, Internet Explorer */
}

/* Style the scrollbar for Webkit browsers (Chrome, Safari) */
.outer-container::-webkit-scrollbar {
  width: 6px;
}

.outer-container::-webkit-scrollbar-track {
  background: transparent;
}

.outer-container::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.header {
  font-weight: 700;
  margin-bottom: 14px;
  font-size: 10pt;
  margin-top: 10px;
  text-align: left;
}

.monitoring-table-container {
  width: 1195px;
  border: 1px solid #2f353d;
  border-radius: 2px;
  overflow: hidden;
}

.monitoring-table {
  border-collapse: collapse;
  width: 100%;
}

.monitoring-table th, .monitoring-table td {
  padding: 2px 5px 3px 5px;

  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-cell > * {
  margin: auto;
}

.pagination-controls {
  margin-top: 15px;
}

.value-center {
  text-align: center !important;
}
</style>
