<template>
  <div class="page">
    <MonitoringComponent :monitoring="discoveryData" :selectedHost="selectedHost"/>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from 'vue';
import axios from 'axios';
import MonitoringComponent from "@/components/discovery/DiscoveryTable.vue";
import { useDiscoveryStore, usePortfolioStore } from "@/stores/DataStore";
import { useRoute } from 'vue-router';
import { useCancelFetchStore } from "@/stores/CancelFetch";

const discoveryData = ref([]);
const fetchInterval = ref(null);

const discoveryStore = useDiscoveryStore();
const portfolioStore = usePortfolioStore();

const route = useRoute();

const cancelFetchStore = useCancelFetchStore();
const isFetching = ref(false);
let abortController = null;
const selectedHost = ref(route.query.host || null);

// function to check if any labels have got the special character '…'
const needsFrequentRefresh = () => {
  return discoveryData.value.some(item => item.label === '…');
};

// refresh monitoring screen
async function fetchDiscoveryData() {
  if (cancelFetchStore.cancelFetch) {
    console.log("Fetch operation is canceled.");
    if (abortController) abortController.abort();
    return;
  }

  isFetching.value = true;
  abortController = new AbortController();
  const signal = abortController.signal;

  try {
    const response = await axios.get('/go/discovery', {
      headers: { 'Content-Type': 'application/json' },
      signal
    });

    if (response.data?.discovery) {
      discoveryData.value = response.data.discovery;
      discoveryStore.save(response.data.portfolio, response.data.discovery);

      // adjust refresh interval based on label content
      updateInterval();
    }
  } catch (error) {
    if (!axios.isCancel(error)) {
      console.error("Error fetching monitoring data:", error);
    }
  } finally {
    isFetching.value = false;
  }
}

// update fetch interval dynamically
const updateInterval = () => {
  const newInterval = needsFrequentRefresh() ? 1000 : 4000;

  if (fetchInterval.value) clearInterval(fetchInterval.value);

  fetchInterval.value = setInterval(() => {
    if (!isFetching.value) fetchDiscoveryData();
  }, newInterval);
};

onMounted(async () => {
  // load from memory/IndexedDB if available
  discoveryData.value = await discoveryStore.get(portfolioStore.getCurPortfolio);

  fetchDiscoveryData();
  updateInterval();
});

onUnmounted(() => {
  if (fetchInterval.value) clearInterval(fetchInterval.value);
});
</script>

<style scoped>
.page {
  text-align: left;
  font-size: 14px;
}

</style>
