<template>
  <div class="portfolio-screen">
    <PortfolioComponent :portfolios="portfolios" :selectedPortfolio="selectedApiKey"
                        @portfolioSwitched="handlePortfolioSwitch" @prePortfolioSwitched="handlePrePortfolioSwitch"
                        @newPortfolio="handleNewPortfolio" @agentStatus="handleAgentStatusChange"/>
    <div style="height: 10px"/>

    <div class="seperator"></div>

    <div class="individual-header">{{ selectedCustomerName }} - {{ selectedDescription }}</div>
    <div class="horizontal-container">
      <CounterView :selectedPortfolio="selectedPortfName" :agentStatus="selectedAgentStatus" :preassessment="selectedPreassessment"/>
      <SummaryStats :selectedPortfolio="selectedPortfName"/>
    </div>

    <div style="height: 20px"/>

   <AgentInfo :apikey="selectedPreassessment ? '' : selectedApiKey" />

  </div>
</template>

<script setup>
import PortfolioComponent from "@/components/portfolio/PortfolioTable.vue";
import {onMounted, ref} from 'vue';
import axios from 'axios';
import {usePortfolioStore} from "@/stores/DataStore";
import AgentInfo from "@/components/portfolio/AgentInfo.vue";
import globalOnLoadPrefetchData from "@/prefetch/PreDataFetchGlobal";
import CounterView from "@/components/portfolio/CounterView.vue";
import SummaryStats from "@/components/portfolio/SummaryStats.vue";

const portfolios = ref([]);
const selectedApiKey = ref(null);
const selectedPortfName = ref(null);
const selectedCustomerName = ref(null);
const selectedDescription = ref(null);
const selectedAgentStatus = ref(null);
const selectedPreassessment = ref(false);

const store = usePortfolioStore();

let getPortfolioAbortController = null; // cancel portfolio fetch if portfolio is changed

const getPorfolioData = async () => {
  if (getPortfolioAbortController) {
    getPortfolioAbortController.abort();
  }

  getPortfolioAbortController = new AbortController();

  try {
    const response = await axios.get('/go/portfolios', {
      headers: {
        'Content-Type': 'application/json'
      },
      signal: getPortfolioAbortController.signal
    });

    portfolios.value = response.data.portfolios;
    selectedApiKey.value = response.data.api_key;
    selectedPortfName.value = response.data.cur_portfolio

    // get customer name
    const currentPortfolio = response.data.portfolios.find(
      (portfolio) => portfolio.portfolio === response.data.cur_portfolio
    );
    selectedCustomerName.value = currentPortfolio ? currentPortfolio.customer : "No Portfolio Selected";
    selectedDescription.value = currentPortfolio ? currentPortfolio.description : "";
    selectedPreassessment.value = currentPortfolio ? currentPortfolio.preassess : false;
    updateAgentStatus(currentPortfolio);

    store.save(response.data.portfolios);
    store.saveApiKey(response.data.api_key);
    store.setCurPortfolio(response.data.cur_portfolio);

  } catch (error) {
    if (error.name === 'AbortError' || error.code === 'ERR_CANCELED') {
      //console.log("Portfolio fetch was canceled.");
    } else {
      console.error('Error fetching data:', error);
    }
  }
};

onMounted(async () => {

  if (store.get) {
    portfolios.value = store.get;
    selectedApiKey.value = store.getApiKey;
    selectedPortfName.value = store.getCurPortfolio

    // get customer name
    const currentPortfolio = portfolios.value.find(
      (portfolio) => portfolio.portfolio === selectedPortfName.value
    );
    selectedCustomerName.value = currentPortfolio ? currentPortfolio.customer : "No Portfolio Selected";
    selectedDescription.value = currentPortfolio ? currentPortfolio.description : "";
    selectedPreassessment.value = currentPortfolio ? currentPortfolio.preassess : false;
    updateAgentStatus(currentPortfolio);
  }

  getPorfolioData();

  getPrefetch();
});

const handleAgentStatusChange = (paused) => {
  selectedAgentStatus.value = paused ? "Paused" : "Active"
}

const updateAgentStatus = (currentPortfolio) => {
  selectedAgentStatus.value = currentPortfolio ? currentPortfolio.agent_status : "";

  if (selectedAgentStatus.value==="" || selectedAgentStatus.value === "active") {
    selectedAgentStatus.value = "Active"
  }
  if (selectedAgentStatus.value==="paused") {
    selectedAgentStatus.value = "Paused"
  }
  if (selectedAgentStatus.value==="shutdown") {
    selectedAgentStatus.value = "Complete"
  }
}

let prefetchAbortController = null;

// this is called before the API call to portfolio change completes
const handlePrePortfolioSwitch = (apikey) => {

  // if clicked mid portfolio request, cancel portfolio & prefetch request
  if (getPortfolioAbortController) {
    getPortfolioAbortController.abort();
  }
  if (prefetchAbortController) {
    prefetchAbortController.abort();
  }

  // set API key and save in the store
  selectedApiKey.value = apikey;
  store.saveApiKey(selectedApiKey.value)

  // use the API key to match the portfolio name from the list
  const _portfolio = store.get.find(p => p.api_key === apikey);
  if (_portfolio) {
    selectedPortfName.value = _portfolio.portfolio;
    selectedCustomerName.value = _portfolio.customer;
    selectedDescription.value = _portfolio.description;
    selectedPreassessment.value = _portfolio ? _portfolio.preassess : false;
    updateAgentStatus(_portfolio);
    store.setCurPortfolio(_portfolio.portfolio); // portfolio name only
  }
}

// new portfolio
const handleNewPortfolio = () => {
  getPorfolioData();
};

// save portfolio key in storage -> this is done AFTER the call is completed
const handlePortfolioSwitch = () => {
  // prefetch new portfolio (monitoring page + refresh data if required)
  // this uses the server portfolio value which has now been set
  getPrefetch();
};

const getPrefetch = async () => {
  // cancel the previous prefetch if it's still ongoing
  if (prefetchAbortController) {
    prefetchAbortController.abort();
  }

  // create a new abort controller for the current request
  prefetchAbortController = new AbortController();

  try {
    // pass the signal to the fetch call
    await globalOnLoadPrefetchData({signal: prefetchAbortController.signal});
  } catch (error) {
    if (error.name === 'AbortError') {
      console.log("Previous fetch aborted due to new portfolio switch.");
    } else {
      console.error("Error fetching token or data:", error);
    }
  }
};

</script>

<style scoped>
.portfolio-screen {
  text-align: left;
  font-size: 14px;
}

.portfolio-header {
    font-weight: 700;
    margin-bottom: 21px;
    margin-top: 6px;
    font-size: 10pt;
}

.individual-header {
  font-weight: 700;
  margin-top: 0;
  padding-top: 11px;
  padding-left: 40px;
  padding-bottom: 10px;
  font-size: 10pt;
  position: fixed;
  background-color: #0d1117;
  width: 110%;
  margin-left: -30px;
  margin-bottom: 20px;
  border-bottom: 1px solid #2f353d;
}

.horizontal-container {
  display: flex;
  gap: 1rem;
  padding-top: 15px;
  margin-top: 40px;
}

.seperator {
  height: 1px;
  background-color: #52575e;
  width: 110%;
  margin-left: -30px;
}

</style>
