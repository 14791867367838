import fetchData from "@/prefetch/PreDataFetch";
import fetchMetricData from "@/prefetch/PreChartFetch";

import axios from "axios";

import { useDiscoveryStore, useServerDataStore } from "@/stores/DataStore";
import { useChartCacheStore } from '@/stores/ChartStore';
import {useCostingStore} from "@/stores/CostStore";

let prefetchMutex = false

const globalOnLoadPrefetchData = async (cancel = {}) => {
    const { signal } = cancel;

    if (prefetchMutex) {
        return;
    }
    prefetchMutex = true;

    const serverDataStore = useServerDataStore();
    const discoveryStore = useDiscoveryStore();
    const chartDataStore = useChartCacheStore();

    let cur_portfolio = ""

    // get discovery list aka current portfolio list and save to pinia
    try {
        const response = await axios.get('/go/discovery', {
            headers: { 'Content-Type': 'application/json' },
            signal // pass signal to axios request
        });

        if (response.data?.discovery) {
            discoveryStore.save(response.data.portfolio, response.data.discovery);
            cur_portfolio = response.data.portfolio
        } else {
            console.log("Empty discovery list");
        }
    } catch (error) {
        if (!axios.isCancel(error)) {
            console.error("Error fetching discovery data:", error);
        }
        prefetchMutex = false;
        return; // exit early if there was an error or cancel
    }

    // discovery list (list of servers) for selected portfolio
    let discover_list = await discoveryStore.get(cur_portfolio);

    // server data store (where each item is saved)
    if (!discover_list) {
        prefetchMutex = false;
        return;
    }

    // get costing - but only once per portfolio + fetch on change
    if (sessionStorage.getItem('lastCostFetched') !== cur_portfolio) {

        const cost_response = await axios.get('/go/costing', {
            headers: {'Content-Type': 'application/json'}
        });

        if (cost_response.data?.array) {
            useCostingStore().setCostingData(
                cost_response.data.portfolio,
                cost_response.data.array,
                cost_response.data.free_egress,
                cost_response.data.discount,
                cost_response.data.support,
                cost_response.data.overhead,
                cost_response.data.s3_cost,
                cost_response.data.s3_tibs,
            );

            // Set session flag to avoid fetching again
            sessionStorage.setItem('lastCostFetched', cur_portfolio);
        }
    }

    // get any uuid that doesn't exist or is stale (>5 mins old)
    const uuidsToFetch = Array.isArray(discover_list)
        ? discover_list
            .filter(item => !item.imported && serverDataStore.isDataStale(item.uuid))
            .map(item => item.uuid)
        : [];

    if (uuidsToFetch.length > 0) {
        // async, do this first then charts
        await fetchData(uuidsToFetch, { signal });
    }

    // second prefetch (metric charts): look up UUIDs in useChartCacheStore
    let uuidsForMetricsFetch = [];

    // get a list of non-imported disovery objects that are stale
    if (Array.isArray(discover_list)) {
        uuidsForMetricsFetch = (
            await Promise.all(
                discover_list.map(async item => ({
                    uuid: item.uuid,
                    isStale: await chartDataStore.isDataStale(item.uuid),
                    isImported: item.imported
                }))
            )
        )
        .filter(check => check.isStale && !check.isImported)
        .map(check => check.uuid);
    }

    const cookieExists = () => document.cookie.includes('session_id');

    if (uuidsForMetricsFetch.length > 0) {
        // fetch metric data for the UUIDs that are not cached
        for (let i = 0; i < uuidsForMetricsFetch.length; i++) {
            if (signal.aborted || !cookieExists()) {
                break;
            }

            // do sequentially
            await fetchMetricData(uuidsForMetricsFetch[i], { signal });
        }
    }

    prefetchMutex = false;
};

export default globalOnLoadPrefetchData;