import * as vNG from 'v-network-graph';
import { ForceLayout } from "v-network-graph/lib/force-layout"

export const initialConfigs = vNG.defineConfigs({
  view: {
    autoPanAndZoomOnLoad: false,
    panEnabled: false,
    zoomEnabled: false,
    doubleClickZoomEnabled: false,
    mouseWheelZoomEnabled: false,
    layoutHandler: new ForceLayout({
      positionFixedByDrag: true,
      positionFixedByClickWithAltKey: true,
      noAutoRestartSimulation: true,
      createSimulation: (d3, nodes, edges) => {
        const forceLink = d3.forceLink(edges).id(d => d.id);
        return d3
          .forceSimulation(nodes)
          .force("edge", forceLink.distance(140).strength(0.5))
          .force("charge", d3.forceManyBody().strength(-20))
          .alphaMin(0.01);
      }
    })
  },
  node: {
    normal: {
      color: '#d6d4d4',
      radius: 12
    },
    hover: {
      color: '#d6d4d4',
      radius: 13
    },
    label: {
      visible: true,
      fontFamily: undefined,
      fontSize: 11,
      lineHeight: 1.1,
      color: "#d6d4d4",
      margin: 4,
      direction: "south",
      directionAutoAdjustment: true,
      background: {
        visible: true,
        color: "#13213066",
        padding: {
          vertical: 1,
          horizontal: 4,
        },
        borderRadius: 2,
      },
    },
  },
  edge: {
    selectable: true,
    normal: {
      width: 2.0,
      color: '#dd8800',
      dasharray: '0',
      linecap: 'butt',
      animate: false,
      animationSpeed: 50,
    },
    hover: {
      width: 2.3,
      color: '#dd8800',
      dasharray: '0',
      linecap: 'butt',
      animate: false,
      animationSpeed: 50,
    },
    selected: {
      width: 2.6,
      color: '#dd4400',
      dasharray: '0',
      linecap: 'round',
      animate: false,
      animationSpeed: 50,
    },
    gap: 5,
    type: 'straight',
    margin: 6,
    marker: {
      source: {
        type: 'none',
        width: 4,
        height: 4,
        margin: 1,
        offset: 0,
        units: 'strokeWidth',
        color: null,
      },
      target: {
        type: 'arrow',
        width: 4,
        height: 4,
        margin: -1,
        offset: 0,
        units: 'strokeWidth',
        color: null,
      },
    },
  },
});
