/* eslint-disable vue/no-deprecated-fixed, vue/no-unused-components */

<!--suppress ALL -->
<template>
  <div class="layout-container">

    <div class="graph-container">

      <div class="button-container">
        <button
          v-for="(component, index) in components"
          :key="`button-${index}`"
          @click="selectApplication(index)"
          :class="['toggle-button', { active: selectedComponent === index }]"
        >
          Application {{ index + 1 }}
        </button>
      </div>


      <v-network-graph
        v-if="components[selectedComponent]"
        class="graph"
        :nodes="components[selectedComponent].nodes"
        :edges="components[selectedComponent].edges"
        :layouts="components[selectedComponent].layouts"
        :configs="initialConfigs"
        :key="graphKey + selectedComponent"
        @update:layouts="updateLayouts"
      >

        <!-- SVG REPLACEMENT OF EACH NODE -->
        <template #override-node="{ nodeId, scale, config, ...slotProps }">

          <!-- border -->
          <rect
            :width="142" :height="27 + (getTextLength(nodeId)>0?7:0) + (getTextLength(nodeId)*15)" :x="-71" :y="-16" :rx="5" :ry="5"
            fill="#989898" v-bind="slotProps"
          />

          <!-- darker body -->
          <rect
            v-if="getTextLength(nodeId)>0"
            :width="140" :height="32 + (getTextLength(nodeId)*15)" :x="-70" :y="-15" :rx="4" :ry="4"
            fill="#0D1117" v-bind="slotProps"
          />

          <!-- lighter header -->
          <rect
            :width="140" :height="25" :x="-70" :y="-15" :rx="4" :ry="4"
            fill="#384459" v-bind="slotProps"
          />

          <text
              :font-size="12"
              fill="#D9D9D9"
              text-anchor="start"
              ominant-baseline="middle"
              :x="-62" :y="2"
              style="pointer-events: none"
          >
            {{ components[selectedComponent].nodes[nodeId].name }}
          </text>

          <text
            v-for="(text, index) in getText(nodeId)"
            :key="index"
            :font-size="12"
            fill="#D9D9D9"
            text-anchor="start"
            dominant-baseline="middle"
            :x="-62"
            :y="22 + (index * 15)"
            style="pointer-events: none"
          >
            {{ text }}
          </text>

        </template>

        <template #edge-label="{ edge, ...slotProps }">
          <v-edge-label
            :text="`‎ ‎ ‎ ‎ ${edge.source_app} ‎ ‎ ‎ ‎`"
            align="source"
            vertical-align="above"
            style="fill: rgb(204, 204, 204) !important; font-size: 11px"
            v-bind="slotProps"
          />
          <v-edge-label
            :text="`‎ ‎ ‎ ‎ ${edge.target_app} ‎ ‎ ‎ ‎`"
            align="target"
            vertical-align="above"
            style="fill: rgb(204, 204, 204) !important; font-size: 11px"
            v-bind="slotProps"
          />
        </template>
      </v-network-graph>
    </div>

    <div>
      <button
        v-if="components[selectedComponent]"
        @click="clearLayout"
        class="clear-layout-button"
      >
        Clear Layout
      </button>
    </div>

  </div>
</template>

<script setup>

import { defineProps, reactive, watch, ref, onMounted, onBeforeUnmount, nextTick, delay } from 'vue'
import axios from 'axios';
import { settledLayout, newLayout } from './AllConnectionsConfig';
import { useMappingStore } from "@/mapping/pinia";
import { usePortfolioStore } from "@/stores/DataStore";
const portfolioStore = usePortfolioStore();

const props = defineProps({
  components: {
    type: Object,
  }
});

const center_offset_x = 200
const center_offset_y = 50

const graphKey = ref(0);  // key to force re-render of v-network-graph
const mappingStore = useMappingStore(); // pinia store
const components = reactive([]); // store individual graph components
const selectedComponent = ref(0); // track selected component index
const highlightedNodes = reactive({}); // core nodes

const initialConfigs = ref(settledLayout); // default to new layout
let saveTimeout = null; // debounce mechanism

let haveClearedLayout = false;

const textLookup = reactive({});

// get once and save
const getText = (nodeId) => {
  if (!textLookup[selectedComponent.value])           textLookup[selectedComponent.value] = {};
  if (!textLookup[selectedComponent.value][nodeId])   textLookup[selectedComponent.value][nodeId] = getTextBase(nodeId);
  return textLookup[selectedComponent.value][nodeId];
};

const getTextLength = (nodeId) => {
  if (!textLookup[selectedComponent.value])           textLookup[selectedComponent.value] = {};
  if (!textLookup[selectedComponent.value][nodeId])   textLookup[selectedComponent.value][nodeId] = getTextBase(nodeId);
  return textLookup[selectedComponent.value][nodeId].length;
};

const getTextBase = (nodeId) => {
  const c = components[selectedComponent.value];
  if (!c || !c.edges) return [];

  const appList = Object.values(c.edges).flatMap((edge) => {
    const apps = [];
    if (edge.source === nodeId && edge.source_app) {
      //console.log("Adding source_app:", edge.source_app);
      apps.push(removePortAndTrim(edge.source_app));
    }
    if (edge.target === nodeId && edge.target_app) {
      //console.log("Adding target_app:", edge.target_app);
      apps.push(removePortAndTrim(edge.target_app));
    }
    return apps;
  });
  //console.log("App List:", appList);

  // filter out empty strings, deduplicate, and sort alphabetically
  const filteredAppList = [...new Set(appList.filter((app) => app !== ''))].sort();
  return filteredAppList;

  // deduplicate and sort alphabetically
  // return [...new Set(appList)].sort();
}

function removePortAndTrim(input) {
  // Check if the input is only a port (entirely numeric)
  if (/^\d+$/.test(input.trim())) {
    return ''; // return empty string for ports only
  }
  // Remove the trailing port number and trim the result
  const withoutPort = input.replace(/\s+\d+$/, '');
  return withoutPort.trim();
}

// used to check the existence of in memory layouts (ie: already there, stay settled)
const hasObjectsInLayout = obj => {
  return obj?.layouts?.nodes && Object.keys(obj.layouts.nodes).length > 0;
};

// on application selection
const selectApplication = (index) => {
  selectedComponent.value = index;
  initialConfigs.value = hasObjectsInLayout(components[0])  ? settledLayout : newLayout // settled if layouts exist
};

// on mount
onMounted(async () => {
  const currentPortfolio = portfolioStore.getCurPortfolio;

  if (!currentPortfolio) {
    console.error("Current portfolio is not set.");
    return;
  }

  try {
    const storedState = await mappingStore.getComponents(currentPortfolio);

    if (storedState) {
      components.splice(0, components.length, ...storedState);
      setHighlightedNodes();
      initialConfigs.value = { ...settledLayout };
      // graphKey.value++;
    } else {
      console.log(`No stored state found for portfolio ${currentPortfolio}.`);
    }
  } catch (error) {
    console.error(`Error loading state for portfolio ${currentPortfolio}:`, error);
  }
});

// called on network return
watch(() => props.components, (newVal, oldVal) => {
  // if we have cleared the layout, don't overwrite with server side version
  if (haveClearedLayout){
    return
  }

  if (newVal !== oldVal && newVal) {
    // load new nodes into the graph
    loadNodePositions();
    setHighlightedNodes();
  }
});

onBeforeUnmount(() => {
  const currentPortfolio = portfolioStore.getCurPortfolio;

  if (!currentPortfolio) {
    console.error("Current portfolio is not set. Cannot save state.");
    return;
  }

  try {
    mappingStore.setComponents(currentPortfolio, components);
  } catch (error) {
    console.error(`Error saving state for portfolio ${currentPortfolio}:`, error);
  }
});

// called by v-network-graph on every update tick
const updateLayouts = () => {

  /**
  const currentComponent = components[selectedComponent.value];
  if (currentComponent?.layouts?.nodes) {
    Object.entries(currentComponent.layouts.nodes).forEach(([nodeId, node]) => {
      node.x = Math.min(Math.max(node.x, 5), 950); // clamp x/y
      node.y = Math.min(Math.max(node.y, 5), 750);
    });
  }
   **/

  saveNodePositionsDebounced();
};

// debounce so this only triggers when the animation settles
const saveNodePositionsDebounced = () => {
  if (saveTimeout) clearTimeout(saveTimeout);

  saveTimeout = setTimeout(async () => {
    const currentComponent = components[selectedComponent.value];

    if (currentComponent.layouts?.nodes) {
      const nodes = Object.entries(currentComponent.layouts.nodes)
          .map(([key, value]) => ({
            id: key,
            ...value
          }))
          .filter(node => node.id !== 'undefined');

      // save to server
      if (nodes.length > 0) {
        try {
          await axios.post('/go/save-nodes',
            { core_host: currentComponent.core.name, nodes: nodes,},
            { headers: { 'Content-Type': 'application/json', },
          });
        } catch (error) {
          console.error('Error saving node positions:', error);
        }
      }
    }
  }, 100); // 100ms debounce
};

const loadNodePositions = async () => {
  if (!Array.isArray(props.components)) {
    console.error("props.components is undefined or not an array");
    return;
  }

  let preexisting_layouts = hasObjectsInLayout(components[0]);

  // sort components by id
  const sortedComponents = [...props.components].sort((a, b) => {
    if (a.id !== undefined && b.id !== undefined) {
      return a.id - b.id;
    }
    return 0;
  });

  // add layouts (in position) tp layout in correct format
  sortedComponents.forEach((component) => {
    component.layouts = {};
    component.layouts["nodes"] = component.positions.reduce((layoutMap, position) => {
      layoutMap[position.id] = {
        x: position.x, y: position.y, fixed: position.fixed,
      };
      return layoutMap;
    }, {});

    // if no position, set to the middle of the viewport
    Object.keys(component.nodes).forEach((nodeId) => {
      if (!component.layouts["nodes"][nodeId]) {
        const randomOffsetX = () => Math.floor(Math.random() * (center_offset_x*2)) - center_offset_x;
        const randomOffsetY = () => Math.floor(Math.random() * (center_offset_y*2)) - center_offset_y;
        component.layouts["nodes"][nodeId] = { x: 375 + randomOffsetX(), y: 325 + randomOffsetY(), fixed: false };
      }
    });
  });

  components.splice(0, components.length, ...sortedComponents);

  // if we already have values for this item, leave it as settled
  // do this before replacing components with new components
  if (preexisting_layouts) {
    //console.log("component downloaded - use settled")
    initialConfigs.value = {...settledLayout};
  } else {
    //console.log("components downloaded - use new")
  }
};

const setHighlightedNodes = () => {
  Object.keys(highlightedNodes).forEach((key) => {
    delete highlightedNodes[key];
  });

  // highlight the core component if it exists
  components.forEach((component) => {
    if (component.core && component.core.name) {
      highlightedNodes[component.core.name] = true;
    }
  });
};

const clearLayout = async () => {

  const currentComponent = components[selectedComponent.value];
  if (!currentComponent) {
    console.error("No component selected or available");
    return;
  }

  // assign random x and y positions to all nodes
  const randomOffsetX = () => Math.floor(Math.random() * (center_offset_x*2)) - center_offset_x;
  const randomOffsetY = () => Math.floor(Math.random() * (center_offset_y*2)) - center_offset_y;
  currentComponent.layouts = {
    nodes: Object.keys(currentComponent.nodes).reduce((layoutMap, nodeId) => {
      layoutMap[nodeId] = {x: 375 + randomOffsetX(), y: 325 + randomOffsetY(), fixed: false,};
      return layoutMap;
    }, {}),
  };

  try {
    const payload = { core_host: currentComponent.core.name };
    await axios.post('/go/clearlayout', payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {
    console.error('Error clearing layout:', error);
  }

  haveClearedLayout = true;

  graphKey.value++;
};

</script>

<style scoped>
.graph {
  width: 1195px;
  height: 750px;
  border: 1px solid #2f353d;
  background: #0d1117;
  color: #bcbcbc;
  border-radius: 4px;
}

div.container div.v-network-graph.v-ng-container.graph svg.v-ng-canvas.show.touches {
  opacity: 1 !important;
  transition: none !important;
}

.button-container {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;
  display: flex;
  flex-direction: column;
}

.toggle-button {
  padding: 8px 12px;
  margin-bottom: 8px;
  cursor: pointer;
  background-color: #2f353d;
  color: #bcbcbc;
  width: 180px;
  border: none;
  border-radius: 4px;
}

.toggle-button.active {
  background-color: #2c3f51; /* Highlight color for active button */
  color: #ffffff;
}

.layout-container {
  margin-top: 5px;
}

.graph-container {
  flex: 1; /* Allow graph container to take up available space */
  position: relative;
}

.clear-layout-button {
  padding: 0px 16px;
  background-color: #8b0000; /* Red background for clear button */
  color: #ffffff;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 32px;

  position: absolute;
  top: 40px;
  left: 1307px;

}

.clear-layout-button:hover {
  background-color: #a00000; /* Slightly brighter red on hover */
}

.custom-node {
  width: 120px;
  padding: 10px;
  background-color: #2f353d;
  color: #ffffff;
  border-radius: 4px;
  text-align: center;
  border: 1px solid #444;
}

.node-header {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 8px;
}

.node-label {
  font-size: 12px;
  margin-bottom: 8px;
}

.node-processes {
  list-style: none;
  padding: 0;
  margin: 0;
}

.node-process {
  font-size: 10px;
}

</style>