// stores/calculatorStore.js
import { defineStore } from "pinia";
import localforage from "localforage";

let hasLoaded = false;

export const useCalculatorStore = defineStore("calculatorStore", {
  state: () => ({
    region: "Asia Pacific (Sydney)",
    instance_type: "ec2",
    platform: "lin",
    free_text: "",
    cpu: "",
    mem: "",
    db_type: "postgres",
    db_edition: "Standard",
    results: [],
    savings_plan: "csp_1nu",
    graviton: false,
  }),
  actions: {
    setResults(data) {
      this.results = data || [];
      this.saveToStorage();
    },
    async saveToStorage() {
      try {
        const serializableState = {
          ...this.$state,
          results: this.results.map((item) => ({ ...item })),
        };
        await localforage.setItem("calculatorStore", serializableState);
      } catch (error) {
        console.error("Failed to save calculator store:", error);
      }
    },
    async loadFromStorage() {
      if (hasLoaded) return;
      try {
        const savedState = await localforage.getItem("calculatorStore");
        if (savedState) {
          this.$patch(savedState);
        }
        hasLoaded = true;
      } catch (error) {
        console.error("Failed to load calculator store:", error);
      }
    },
  },
});