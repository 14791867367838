<template>
  <div>

    <div v-if="Object.keys(groupedIncomingProcessInfo).length > 0">
      <div class="incoming-header">
        <div class="header">Incoming Connections</div>
        <NetworkAnimation direction="left" />
      </div>
      <div class="process-table-container">
        <table>
          <thead>
            <tr>
              <th style="width: 190px; max-width: 190px;">Application</th>
              <th style="width: 40px;">Port</th>
              <th style="width: 40px;">Traffic</th>
              <th class="process_width">Process</th>
              <th style="width: 100px;">Source IP</th>
              <th style="width: 150px;">Source Application</th>
              <th style="width: 384px;">Source Host</th>
              <th style="width: 75px;">Last Seen</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(group, groupIndex) in Object.values(groupedIncomingProcessInfo)" :key="groupIndex">
              <tr>
                <td @click="toggleIncomingGroup(group.group_name)">
                  <span v-if="group.processes.length > 1" style="cursor: pointer;">
                    <ChevronDownIcon v-if="group.expanded" class="icon-chevron-down" />
                    <ChevronRightIcon v-else class="icon-chevron-right" />
                  </span>
                  {{ group.local_app_group }}
                </td>
                <td>{{ group.local_port }}</td>
                <td style="margin-left: 3px;"><VolumeBar :value="group.count_hr"/></td>
                <td class="process_width"> {{ group.local_process }} </td>
                <td>
                  <template v-if="(group.expanded && group.mainItem) || group.processes.length === 1">
                    {{ group.mainItem.remote_ip }}
                  </template>
                  <template v-else>
                    {{ group.processes.length }}x IP{{ group.processes.length > 1 ? 's' : '' }}
                  </template>
                </td>
                <td>{{ group.remote_app_group}}</td>
                <td>{{ group.remote_host }}</td>
                <td>{{ timeDifference(group.last_seen) }}</td>
              </tr>
              <template v-if="group.expanded">
                <tr v-for="(item, index) in group.processes.slice(1)" :key="index">
                  <td></td>
                  <td></td>
                  <td><VolumeBar :value="item.count_hr"/></td>
                  <td class="process_width"> {{ item.local_process }} </td>
                  <td>{{ item.remote_ip }}</td>
                  <td>{{ item.remote_app_group }}</td>
                  <td>{{ item.remote_host }}</td>
                  <td>{{ timeDifference(item.last_seen) }}</td>
                </tr>
              </template>
            </template>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="Object.keys(groupedOutgoingProcessInfo).length > 0">
      <div class="incoming-header">
        <div class="header">Outgoing Connections</div>
        <NetworkAnimation></NetworkAnimation>
      </div>

      <div class="process-table-container">
        <table>
          <thead>
            <tr>
              <th style="width: 190px; max-width: 190px;">Application</th>
              <th style="width: 40px;">Port</th>
              <th style="width: 40px;">Traffic</th>
              <th class="process_width">Process</th>
              <th style="width: 100px;">Target IP</th>
              <th style="width: 150px;">Target Application</th>
              <th style="width: 384px;">Target Host</th>
              <th style="width: 75px;">Last Seen</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(group, groupIndex) in Object.values(groupedOutgoingProcessInfo)" :key="groupIndex">
              <tr>
                <td @click="toggleOutgoingGroup(group.group_name)">
                  <span v-if="group.processes.length > 1" style="cursor: pointer;">
                    <ChevronDownIcon v-if="group.expanded" class="icon-chevron-down" />
                    <ChevronRightIcon v-else class="icon-chevron-right" />
                  </span>
                  {{ group.local_app_group }}
                </td>
                <td>{{ group.remote_port }}</td>
                <td><VolumeBar :value="group.count_hr"/></td>
                <td class="process_width"> {{ group.local_process }} </td>
                <td>
                  <template v-if="(group.expanded && group.mainItem) || group.processes.length === 1">
                    {{ group.mainItem.remote_ip }}
                  </template>
                  <template v-else>
                    {{ group.processes.length }}x IP{{ group.processes.length > 1 ? 's' : '' }}
                  </template>
                </td>
                <td>{{ group.remote_app_group }}</td>
                <td>{{ group.remote_host }}</td>
                <td>{{ timeDifference(group.last_seen) }}</td>
              </tr>
              <template v-if="group.expanded">
                <tr v-for="(item, index) in group.processes.slice(1)" :key="index">
                  <td></td>
                  <td></td>
                  <td><VolumeBar :value="item.count_hr"/></td>
                  <td class="process_width"> {{ item.local_process }} </td>
                  <td>{{ item.remote_ip }}</td>
                  <td>{{ item.remote_app_group }}</td>
                  <td>{{ item.remote_host }}</td>
                  <td>{{ timeDifference(item.last_seen) }}</td>
                </tr>
              </template>
            </template>
          </tbody>
        </table>
      </div>
    </div>

    <div class="nonetwork" v-if="Object.keys(groupedIncomingProcessInfo).length === 0 && Object.keys(groupedOutgoingProcessInfo).length === 0">
      no network connections
    </div>

    <div v-if="hiddenAppGroups.length > 0" class="hidden-connections">
      <div class="header">Hidden</div>
      <div>{{ hiddenAppGroups.join(', ') }}</div>
    </div>

  </div>
</template>

<script setup>
import { defineProps, reactive, watch, toRaw, computed } from 'vue';
import ChevronRightIcon from "@/components/icons/ChevronRightIcon.vue";
import ChevronDownIcon from "@/components/icons/ChevronDownIcon.vue";
import VolumeBar from "@/components/discovery/VolumeBar.vue";
import NetworkAnimation from "@/components/discovery/network_animation/NetworkAnimation.vue";

const props = defineProps({
  networkIn: {
    type: Array,
    default: () => [],
    required: true
  },
  networkOut: {
    type: Array,
    default: () => [],
    required: true
  }
});

const groupedIncomingProcessInfo = reactive({});
const groupedOutgoingProcessInfo = reactive({});

// show a list of hidden processes
const hiddenAppGroups = computed(() => {
  const hiddenGroups = new Set();

  // Check incoming connections
  props.networkIn.forEach((item) => {
    if (item.hidden && item.local_app_group) {
      hiddenGroups.add(item.local_app_group);
    }
  });

  // Check outgoing connections
  props.networkOut.forEach((item) => {
    if (item.hidden && item.local_app_group) {
      hiddenGroups.add(item.local_app_group);
    }
  });

  return Array.from(hiddenGroups);
});

function timeDifference(timestamp) {
    const now = new Date();
    const then = new Date(timestamp);
    const diffInSeconds = (now - then) / 1000;

    if (diffInSeconds < 3600) { // less than 1 hour
        return 'Now';
    } else if (diffInSeconds < 86400) { // less than 24 hours
        return 'Today';
    } else { // more than 24 hours
        const diffInDays = Math.floor(diffInSeconds / 86400);
        return diffInDays === 1 ? '1 day ago' : `${diffInDays} days ago`;
    }
}

const groupIncomingProcesses = (processInfo) => {
  Object.keys(groupedIncomingProcessInfo).forEach(key => delete groupedIncomingProcessInfo[key]);

  // filter out items where hidden is true
  processInfo = processInfo.filter(item => !item.hidden);

  // sort first, local process (alpha) then last seen, last seen to the top
  processInfo = processInfo.sort((a, b) => {

    if (a.local_port < b.local_port) return -1;
    if (a.local_port > b.local_port) return 1;

    if (a.local_app_group < b.local_app_group) return -1;
    if (a.local_app_group > b.local_app_group) return 1;

    return new Date(b.last_seen) - new Date(a.last_seen);
  });

  processInfo.forEach(item => {
    const groupName = item.local_app_group + ":" + item.local_port;

    if (!groupedIncomingProcessInfo[groupName]) {

      // create the first group
      groupedIncomingProcessInfo[groupName] = {
        is_private: item.is_private,
        local_app_group: item.local_app_group,
        remote_app_group: item.remote_app_group,
        local_process: item.local_process,
        remote_host: item.remote_host,
        local_port: item.local_port,
        remote_process: item.remote_process,
        count_hr: parseFloat(item.count_hr) || 0,
        last_seen: item.last_seen,
        expanded: false,
        mainItem: toRaw(item), // Store the main item with the most recent last_seen
        processes: [toRaw(item)], // Store the first item in the processes array
        group_name: groupName
      };
    } else {
      // add the rest to the group if it exists

      // if any item is not private, set the whole thing as not private
      if (!item.is_private) {
        groupedIncomingProcessInfo[groupName].is_private = false;
      }

      groupedIncomingProcessInfo[groupName].count_hr += parseFloat(item.count_hr) || 0;
      groupedIncomingProcessInfo[groupName].processes.push(toRaw(item));
    }
  });

};

const groupOutgoingProcesses = (processInfo) => {
  Object.keys(groupedOutgoingProcessInfo).forEach(key => delete groupedOutgoingProcessInfo[key]);

  // filter out items where hidden is true
  processInfo = processInfo.filter(item => !item.hidden);

  // sort first
  processInfo = processInfo.sort((a, b) => {

    if (a.remote_port < b.remote_port) return -1;
    if (a.remote_port > b.remote_port) return 1;

    if (a.local_app_group < b.local_app_group) return -1;
    if (a.local_app_group > b.local_app_group) return 1;

    return new Date(b.last_seen) - new Date(a.last_seen);
  });

  processInfo.forEach(item => {
    const groupName = item.local_app_group + ":" + item.remote_port;

    if (!groupedOutgoingProcessInfo[groupName]) {
      groupedOutgoingProcessInfo[groupName] = {
        is_private: item.is_private,
        local_app_group: item.local_app_group,
        remote_app_group: item.remote_app_group,
        local_process: item.local_process,
        remote_host: item.remote_host,
        remote_port: item.remote_port,
        remote_process: item.remote_process,
        count_hr: parseFloat(item.count_hr) || 0,
        last_seen: item.last_seen,
        expanded: false,
        mainItem: toRaw(item), // Store the main item with the most recent last_seen
        processes: [toRaw(item)], // Store the first item in the processes array
        group_name: groupName
      };
    } else {
      groupedOutgoingProcessInfo[groupName].count_hr += parseFloat(item.count_hr) || 0;
      groupedOutgoingProcessInfo[groupName].processes.push(toRaw(item));
    }
  });
};

const toggleIncomingGroup = (group_name) => {
  const group = groupedIncomingProcessInfo[group_name];
  if (group) {
    group.expanded = !group.expanded;
  }
};

const toggleOutgoingGroup = (group_name) => {
  const group = groupedOutgoingProcessInfo[group_name];
  if (group) {
    group.expanded = !group.expanded;
  }
};

watch(() => props.networkIn, (newNetworkIn) => {
  groupIncomingProcesses(newNetworkIn);
}, {deep: true, immediate: true});

watch(() => props.networkOut, (newNetworkOut) => {
  groupOutgoingProcesses(newNetworkOut);
}, {deep: true, immediate: true});

</script>

<style scoped>
.incoming-header {
  display: flex;
}

.icon-chevron-right,
.icon-chevron-down {
  width: 12px;
  height: 12px;
  vertical-align: middle;
  margin-left: -10px;
  margin-right: -1px;
  margin-top: -1.5px;
}

.header {
  font-weight: 600;
  margin-bottom: 8px;
  width: 150px;
}

.process-table-container {
  width: 1195px;
  margin-bottom: 12px;
  overflow-x: auto;
  box-shadow: 3px 3px 16px -8px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  border: 1px solid #d3d3d3;
}

.dark .process-table-container {
  border: 1px solid #2f353d;
}

.nonetwork {
  color: grey;
  margin-left: 500px;
  margin-top: 30px;
}

.process_width {
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  max-width: 120px;
}

.hidden-connections {
  margin-top: 20px;
  color: #7e7e7e;
  display: flex;
  align-items: center;
}

.hidden-connections .header {
  font-weight: 600;
  width: 55px;
  margin-bottom: 0px;
}

</style>
