<template>
  <div style="font-weight: 600; margin-bottom: 8px;">Amortized Yearly Run Costs</div>
  <div>
    <table>
      <thead>
        <tr>
          <th style="width: 105px;">Category</th>
          <th style="width: 110px;"> </th>
          <th style="width: 100px;"> </th>
          <th style="width: 290px;">Price/Month (USD)</th>
          <th style="width: 230px;">Price/Year (USD)</th>
        </tr>
      </thead>
      <tbody>

        <tr class="black-row"><td/><td/><td/><td/><td/></tr>

        <tr>
          <td :style="{ color: ec2Price === 0 ? 'grey' : null }">EC2</td>
          <td :style="{ color: ec2Price === 0 ? 'grey' : null }">Compute</td>
          <td :style="{ color: ec2Price === 0 ? 'grey' : null }">{{ ec2Count }}x</td>
          <td :style="{ color: ec2Price === 0 ? 'grey' : null }">{{ formatPrice(ec2Price) }}</td>
          <td :style="{ color: ec2Price === 0 ? 'grey' : null }">{{ formatPrice(mult(12, ec2Price)) }}</td>
        </tr>
        <tr>
          <td :style="{ color: ec2Licence === 0 ? 'grey' : null }"></td>
          <td :style="{ color: ec2Licence === 0 ? 'grey' : null }">Licenses</td>
          <td :style="{ color: ec2Licence === 0 ? 'grey' : null }"></td>
          <td :style="{ color: ec2Licence === 0 ? 'grey' : null }">{{ formatPrice(ec2Licence) }}</td>
          <td :style="{ color: ec2Licence === 0 ? 'grey' : null }">{{ formatPrice(mult(12, ec2Licence)) }}</td>
        </tr>
        <tr>
          <td :style="{ color: ec2StoragePrice === 0 ? 'grey' : null }"></td>
          <td :style="{ color: ec2StoragePrice === 0 ? 'grey' : null }">Storage</td>
          <td :style="{ color: ec2StoragePrice === 0 ? 'grey' : null }">{{ getStoragePrice(ec2StorageGBs) }} <span style="color: grey;">{{
              getStorageUnit(ec2StorageGBs)
            }}</span></td>
          <td :style="{ color: ec2StoragePrice === 0 ? 'grey' : null }">{{ formatPrice(ec2StoragePrice) }}</td>
          <td :style="{ color: ec2StoragePrice === 0 ? 'grey' : null }">{{ formatPrice(mult(12, ec2StoragePrice)) }}</td>
        </tr>
        <tr>
          <td :style="{ color: backupPrice === 0 ? 'grey' : null }"></td>
          <td :style="{ color: backupPrice === 0 ? 'grey' : null }">Backups</td>
          <td :style="{ color: backupPrice === 0 ? 'grey' : null }">{{ getStoragePrice(ec2BackupGBs) }} <span style="color: grey;">{{
              getStorageUnit(ec2BackupGBs)
            }}</span></td>
          <td :style="{ color: backupPrice === 0 ? 'grey' : null }">{{ formatPrice(backupPrice) }}</td>
          <td :style="{ color: backupPrice === 0 ? 'grey' : null }">{{ formatPrice(mult(12, backupPrice)) }}</td>
        </tr>
        <tr>
          <td :style="{ color: ec2diskLimits === 0 ? 'grey' : null }"></td>
          <td :style="{ color: ec2diskLimits === 0 ? 'grey' : null }">IOPs/MBs</td>
          <td></td>
          <td :style="{ color: ec2diskLimits === 0 ? 'grey' : null }">{{ formatPrice(ec2diskLimits) }}</td>
          <td :style="{ color: ec2diskLimits === 0 ? 'grey' : null }">{{ formatPrice(mult(12, ec2diskLimits)) }}</td>
        </tr>

        <tr class="black-row"><td/><td/><td/><td/><td/></tr>

        <tr>
          <td :style="{ color: rdsPrice === 0 ? 'grey' : null }">RDS</td>
          <td :style="{ color: rdsPrice === 0 ? 'grey' : null }">Compute</td>
          <td :style="{ color: rdsPrice === 0 ? 'grey' : null }">{{ rdsCount }}x</td>
          <td :style="{ color: rdsPrice === 0 ? 'grey' : null }">{{ formatPrice(rdsPrice) }}</td>
          <td :style="{ color: rdsPrice === 0 ? 'grey' : null }">{{ formatPrice(mult(12, rdsPrice)) }}</td>
        </tr>
        <tr>
          <td :style="{ color: rdsLicence === 0 ? 'grey' : null }"></td>
          <td :style="{ color: rdsLicence === 0 ? 'grey' : null }">Licenses</td>
          <td :style="{ color: rdsLicence === 0 ? 'grey' : null }"></td>
          <td :style="{ color: rdsLicence === 0 ? 'grey' : null }">{{ formatPrice(rdsLicence) }}</td>
          <td :style="{ color: rdsLicence === 0 ? 'grey' : null }">{{ formatPrice(mult(12, rdsLicence)) }}</td>
        </tr>
        <tr>
          <td :style="{ color: rdsStoragePrice === 0 ? 'grey' : null }"></td>
          <td :style="{ color: rdsStoragePrice === 0 ? 'grey' : null }">Storage / Backup</td>
          <td :style="{ color: rdsStoragePrice === 0 ? 'grey' : null }">{{ getStoragePrice(rdsStorageGBs) }} <span style="color: grey;">{{ getStorageUnit(rdsStorageGBs) }}</span></td>
          <td :style="{ color: rdsStoragePrice === 0 ? 'grey' : null }">{{ formatPrice(rdsStoragePrice) }}</td>
          <td :style="{ color: rdsStoragePrice === 0 ? 'grey' : null }">{{ formatPrice(mult(12, rdsStoragePrice)) }}</td>
        </tr>
        <tr>
          <td :style="{ color: rdsdiskLimits === 0 ? 'grey' : null }"></td>
          <td :style="{ color: rdsdiskLimits === 0 ? 'grey' : null }">IOPs/MBs</td>
          <td></td>
          <td :style="{ color: rdsdiskLimits === 0 ? 'grey' : null }">{{ formatPrice(rdsdiskLimits) }}</td>
          <td :style="{ color: rdsdiskLimits === 0 ? 'grey' : null }">{{ formatPrice(mult(12, rdsdiskLimits)) }}</td>
        </tr>

        <tr class="black-row"><td/><td/><td/><td/><td/></tr>

        <tr>
          <td :style="{ color: overhead_val === 0 ? 'grey' : null }">VPC</td>
          <td :style="{ color: overhead_val === 0 ? 'grey' : null }">Overhead</td>
          <td :style="{ color: overhead_val === 0 ? 'grey' : null }">{{ props.overhead + "%" }}</td>
          <td :style="{ color: overhead_val === 0 ? 'grey' : null }">{{ formatPrice(overhead_val) }}</td>
          <td :style="{ color: overhead_val === 0 ? 'grey' : null }">{{ formatPrice(mult(12, overhead_val)) }}</td>
        </tr>
        <tr>
          <td :style="{ color: s3_cost === 0 ? 'grey' : null }">S3</td>
          <td :style="{ color: s3_cost === 0 ? 'grey' : null }">Storage</td>
          <td :style="{ color: s3_cost === 0 ? 'grey' : null }">{{ s3_tibs }} <span style="color: grey;">TiB</span></td>
          <td :style="{ color: s3_cost === 0 ? 'grey' : null }">{{ formatPrice( s3_cost ) }}</td>
          <td :style="{ color: s3_cost === 0 ? 'grey' : null }">{{ formatPrice(mult(12, s3_cost)) }}</td>
        </tr>
        <tr>
          <td :style="{ color: egressPrice === 0 ? 'grey' : null }">Network</td>
          <td :style="{ color: egressPrice === 0 ? 'grey' : null }">Egress *</td>
          <td :style="{ color: egressPrice === 0 ? 'grey' : null }">{{ getStoragePrice(egressGBs) }} <span style="color: grey;">{{ getStorageUnit(egressGBs) }}</span></td>
          <td :style="{ color: egressPrice === 0 ? 'grey' : null }">{{ formatPrice(egressPrice) }}</td>
          <td :style="{ color: egressPrice === 0 ? 'grey' : null }">{{ formatPrice(mult(12, egressPrice)) }}</td>
        </tr>
        <tr>
          <td :style="{ color: discount_val === 0 ? 'grey' : null }">Discount</td>
          <td :style="{ color: discount_val === 0 ? 'grey' : null }">EDP</td>
          <td :style="{ color: discount_val === 0 ? 'grey' : null }">{{ props.discount === 0 ? "None" : ("-" + props.discount + "%") }}</td>
          <td :style="{ color: discount_val === 0 ? 'grey' : 'rgb(91, 196, 89)' }">{{ formatPrice(-discount_val) }}</td>
          <td :style="{ color: discount_val === 0 ? 'grey' : 'rgb(91, 196, 89)' }">{{ formatPrice(-mult(12, discount_val)) }}</td>
        </tr>
        <tr>
          <td :style="{ color: support_fee === 0 ? 'grey' : null }">Support</td>
          <td :style="{ color: support_fee === 0 ? 'grey' : null }">{{ support === 'none'?"None":support === 'business'?'Business':'Enterprise' }}</td>
          <td :style="{ color: support_fee === 0 ? 'grey' : null }"></td>
          <td :style="{ color: support_fee === 0 ? 'grey' : 'orange' }">{{ formatPrice(support_fee) }}</td>
          <td :style="{ color: support_fee === 0 ? 'grey' : 'orange' }">{{ formatPrice(mult(12, support_fee)) }}</td>
        </tr>

        <tr class="black-row"><td/><td/><td/><td/><td/></tr>

        <tr>
          <th style="text-align: left !important;">Total</th>
          <th> </th>
          <th> </th>
          <th>{{ formatPrice(total) }}</th>
          <th>{{ formatPrice(mult(12, total)) }}</th>
        </tr>
      </tbody>
    </table>
  </div>
  <div style="margin-top: 10px; color: grey;">* First 100GB of Egress Data is free</div>
</template>

<script setup>
import { defineProps, watch, ref } from 'vue';
import { calculateSupportFees } from './CostShared';

const props = defineProps({
  costings: {
    type: Array,
    required: true
  },
  free_egress: {
    type: Number,
    required: true
  },
  support: {
    type: String,
    required: true
  },
  discount: {
    type: Number,
    required: true
  },
  overhead: {
    type: Number,
    required: true
  },
  s3_cost: {
    type: Number,
    required: true,
    default: 0,
  },
  s3_tibs: {
    type: Number,
    required: true,
    default: 0,
  },
});

const ec2Price = ref(0.0);
const ec2Licence = ref(0.0);
const ec2StoragePrice = ref(0.0);
const ec2StorageGBs = ref(0.0);
const ec2BackupGBs = ref(0.0);
const ec2Count = ref(0.0);
const rdsPrice = ref(0.0);
const rdsLicence = ref(0.0);
const rdsStoragePrice = ref(0.0);
const rdsStorageGBs = ref(0.0);
const rdsBackupGBs = ref(0.0);
const rdsCount = ref(0.0);
const backupPrice = ref(0.0);
const egressPrice = ref(0.0);

const ec2diskLimits = ref(0.0);
const rdsdiskLimits = ref(0.0);
const egressGBs = ref(0.0);

const total = ref(0.0);
const discount_val = ref(0.0);
const overhead_val = ref(0.0);
const s3_cost = ref(0.0);
const support_fee = ref(0.0);

const formatPrice = (value) => {
  return Number(value).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  //return Number(value).toLocaleString("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 });
};

const add = (a, b) => {
  return new Decimal(a || 0).plus(b || 0).toNumber();
};

const sub = (a, b) => {
  return new Decimal(a || 0).minus(b || 0).toNumber();
};

const mult = (a, b) => {
  return new Decimal(a || 0).times(b || 0).toNumber();
};

const getStoragePrice = (storage) => {
  if (storage > 1048576) {
    storage = storage / 1048576;
    return storage.toFixed(1);
  }

  if (storage > 1024) {
    storage = storage / 1024;

    if (storage > 10) {
      return storage.toFixed(0);
    }

    return storage.toFixed(1);
  }
  return storage;
};

const getStorageUnit = (storage) => {
  if (storage > 1048576) {
    return 'PiB';
  }
  if (storage > 1024) {
    return 'TiB';
  }
  return 'GiB';
};


watch(() => [props.costings, props.support, props.discount, props.overhead, props.free_egress, props.s3_cost], async ([costings, support, discount, overhead, free_egress, s3]) => {

  // skip on page reload as it takes a tick to load from pinia
  if (!costings || costings.length === 0) return;

  // TO FIX
  if (!free_egress) free_egress = 0;
  if (!s3) s3 = 0;

  let ec2 = 0.0;
  let ec2_licence = 0.0;
  let ec2_storage = 0.0;
  let ec2_storage_gbs = 0.0;
  let ec2_backup_gbs = 0.0;
  let ec2_count = 0;
  let ec2_io = 0.0;
  let rds = 0.0;
  let rds_licence = 0.0;
  let rds_storage = 0.0;
  let rds_storage_gbs = 0.0;
  let rds_backup_gbs = 0.0;
  let rds_count = 0;
  let rds_io = 0.0;
  let egress_mbs = 0.0;

  let ec2_backup = 0.0;
  let egress = 0.0;

  costings.forEach(item => {
    if (!item.retire) {
      egress = add(egress, item.pricing.egress_price);
      egress_mbs =  add(egress_mbs, item.pricing.egress_mb);

      if (item.target === "ec2") {
        ec2_count = add(1, ec2_count);
        ec2_storage = add(ec2_storage, item.pricing.price_disk);
        ec2_storage_gbs = add(ec2_storage_gbs, item.pricing.new_disk);
        ec2_backup_gbs = add(ec2_backup_gbs, item.pricing.disk_used);
        ec2_io = add(ec2_io, item.pricing.price_iops);
        ec2_io = add(ec2_io, item.pricing.price_throughput);
        ec2_licence = add(ec2_licence, sub(item.pricing.price_instance, item.pricing.price_os));
        ec2 = add(ec2, sub(item.pricing.price_nusp, sub(item.pricing.price_instance, item.pricing.price_os)));
        ec2_backup = add(ec2_backup, item.pricing.price_backup);
      }
      if (item.target === "rds") {
        rds_count = add(1, rds_count);
        rds_storage = add(rds_storage, item.pricing.rds_disk_price);
        rds_storage_gbs = add(rds_storage_gbs, item.pricing.rds_disk);
        rds_backup_gbs = add(rds_backup_gbs, item.pricing.disk_used);
        rds_io = add(rds_io, item.pricing.rds_iops_price);
        rds_io = add(rds_io, item.pricing.rds_thrpt_price);

        let licence = sub(item.pricing.rds_price, item.pricing.rds_db_price)

        rds_licence = add(rds_licence, licence);
        rds = add(rds, sub(item.pricing.rds_price_ri, licence));
      }
      if (item.target === "rdsm") {
        rds_count = add(1, rds_count);
        rds_storage = add(rds_storage, mult(item.pricing.rds_disk_price, 2));
        rds_storage_gbs = add(rds_storage_gbs, item.pricing.rds_disk);
        rds_backup_gbs = add(rds_backup_gbs, item.pricing.disk_used);
        rds_io = add(rds_io, mult(2, item.pricing.rds_iops_price));
        rds_io = add(rds_io, mult(2, item.pricing.rds_thrpt_price));

        let licence = sub(item.pricing.rds_price_mz, item.pricing.rds_db_price_mz)

        rds_licence = add(rds_licence, licence);
        rds = add(rds, sub(item.pricing.rds_price_ri_mz, licence));
      }
    }
  });

  // remove free tier egress
  egress = sub(egress, free_egress);
  if (egress < 0) egress = 0;

  // add total before rounding (use decimal)
  let total_amount = new Decimal(ec2)
    .plus(ec2_storage)
    .plus(ec2_licence)
    .plus(ec2_backup)
    .plus(ec2_io)
    .plus(rds)
    .plus(rds_storage)
    .plus(rds_licence)
    .plus(rds_io)
    .toNumber();

  // vpc overhead (add s3 and egress after this)
  overhead_val.value = new Decimal(total_amount).times(overhead / 100).toNumber();
  let totalWithOverhead = new Decimal(overhead_val.value).plus(total_amount).plus(egress).plus(s3).toNumber();

  // enterprise discount
  let discount_amount = new Decimal(totalWithOverhead).times(discount / 100).toNumber();
  let totalAfterDiscount = new Decimal(totalWithOverhead).minus(discount_amount).toNumber();

  // support fees
  support_fee.value = calculateSupportFees(support, totalAfterDiscount);
  let finalTotal = add(totalAfterDiscount, support_fee.value);

  discount_val.value = discount_amount
  total.value = finalTotal;

  // do not round, or we will introduce small errors
  ec2Price.value = ec2;
  ec2Licence.value = ec2_licence;
  ec2StoragePrice.value = ec2_storage;
  rdsPrice.value = rds;
  rdsStoragePrice.value = rds_storage;
  backupPrice.value = ec2_backup;
  egressPrice.value = egress;
  ec2diskLimits.value = ec2_io;
  rdsdiskLimits.value = rds_io;
  rdsLicence.value = rds_licence;
  s3_cost.value = s3;

  // counts
  ec2Count.value = ec2_count;
  rdsCount.value = rds_count;

  ec2StorageGBs.value = ec2_storage_gbs.toFixed(0);
  rdsStorageGBs.value = rds_storage_gbs.toFixed(0);
  ec2BackupGBs.value = ec2_backup_gbs.toFixed(0);
  rdsBackupGBs.value = rds_backup_gbs.toFixed(0);
  egressGBs.value = (egress_mbs/1024.0).toFixed(0);

}, { immediate: true });

import Decimal from 'decimal.js';

</script>

<style scoped>
table {
  border-collapse: collapse;
  border-radius: 9px !important;
  border: 1px solid #2f353d;
}
th, td {
  border-bottom: 1px solid #2f353d;
  padding: 3px 7px 3px 11px;
  text-align: left;
}
th:nth-child(1),
td:nth-child(1) {
  text-align: left;
}

th:nth-child(3), th:nth-child(4), th:nth-child(5),
td:nth-child(3), td:nth-child(4), td:nth-child(5) {
  text-align: right;
}

.black-row td {
  background-color: #000;
}
.black-row {
  height: 5px;
  border-left: none;
  border-right: none;
}
</style>