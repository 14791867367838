<template>
  <div @mouseleave="hideTooltipNow" class="process-table-container" :style="{opacity: type==='system'?0.5:1}">
    <table>
      <thead @mouseenter="hideTooltipNow">
        <tr>
          <th style="width: 190px; max-width: 190px;">Application</th>
          <th style="width: 60px;">Port</th>
          <th style="width: 25px;"/> <!-- help icon -->
          <th style="width: 25px; text-align: center;">CPU</th>
          <th style="width: 25px; text-align: center;">Mem</th>
          <th style="width: 5px;"> <!-- spacer --></th>
          <th style="width: 95px;">Process</th>
          <th style="width: 95px;">User</th>
          <th style="width: 500px;">Executable</th>
          <th style="text-align: center; padding-left: 0px; padding-right: 0px;">
            <span v-if="props.type !== 'hidden'">Hide</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="group in Object.values(processes)" :key="group.name">
          <tr>
            <td @mouseenter="hideTooltipNow" :style="{width: '150px',  maxWidth: '150px',  whiteSpace: 'nowrap', overflow: 'hidden',
              textOverflow: 'ellipsis',  paddingLeft: group.processes.length === 1 ? '8px' : '10px'}">
              <span v-if="group.processes.length > 1" @click="toggleGroup(group.name)" style="cursor: pointer;">
                <ChevronDownIcon v-if="group.expanded" class="icon-chevron-down" /> <ChevronRightIcon v-else class="icon-chevron-right" />
              </span>
              {{ group.name }}
            </td>
            <td :style="{ opacity: group.expanded ? 0 : 1 }" @mouseenter="hideTooltipNow" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
              {{ formatAddresses(group.listening_on) }}
            </td>
            <td style="width: 20px; text-align: center; padding-right: 10px;" @mouseleave="startHideTooltip">
              <span @mouseenter="showTooltip(group)" style="height: 100%; margin: -10px; padding: 1px -10px -10px; display: inline-block; vertical-align: middle; cursor: pointer;"
                    :ref="el => { if (el) helpIconContainers[group.name] = el }">
                <HelpIcon class="help"/>
              </span>
            </td>
            <td @mouseenter="hideTooltipNow" :style="cpuStyle(group)">{{ Math.round(group.cpu_percent) }}</td>
            <td @mouseenter="hideTooltipNow" :style="memoryStyle(group)">{{ Math.round(group.memory_percent) }}</td>
            <td @mouseenter="hideTooltipNow"></td>
            <td :style="{ opacity: group.expanded ? 0 : 1 }" @mouseenter="hideTooltipNow" style="width: 70px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
              {{ group.processes[0]?.name }}
            </td>
            <td :style="{ opacity: group.expanded ? 0 : 1 }" @mouseenter="hideTooltipNow" style="width: 70px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
              {{ group.processes[0]?.pr_user }}
            </td>
            <td :style="{ opacity: group.expanded ? 0 : 1 }" @mouseenter="hideTooltipNow" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
              {{ group.processes[0]?.executable }}
            </td>
            <td @mouseenter="hideTooltipNow" style="text-align: center; user-select: none;">
              <span @click="hideProcess(props.uuid, group.name, props.type === 'main')" class="icon-hide" style="display: inline-flex; justify-content: center; align-items: center;">
                <ChevronDownIcon v-if="type === 'main'" />
                <ChevronUpIcon v-if="type === 'hidden'" />
              </span>
            </td>
          </tr>

          <!-- if expanded -->
          <template v-if="group.expanded">
              <tr v-for="(item, subIndex) in group.processes.slice(0)" :key="subIndex" style="background-color: #161c24;">
                <td></td>
                <td @mouseenter="hideTooltipNow">{{ formatAddresses(item.listening_on) }}</td>
                <td></td>
                <td
                  :style="{ textAlign: 'center', color: item.cpu_percent < 1 ? '#2f353d' : 'inherit' }">
                  {{ Math.round(item.cpu_percent) }}
                </td>
                <td
                  :style="{ textAlign: 'center', color: item.memory_percent < 1 ? '#2f353d' : 'inherit' }">
                  {{ Math.round(item.memory_percent) }}
                </td>
                <td></td>
                <td style="width: 70px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                  {{ item.name }}
                </td>
                <td style="width: 70px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                  {{ item.pr_user }}
                </td>
                <td style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                  {{ trimCommandLine(item.executable) }}
                </td>
                <td></td>
              </tr>
            </template>

        </template>
      </tbody>
    </table>

    <!-- Tooltip -->
    <Teleport to="body">
      <div
        v-if="tooltip.show"
        class="tooltip"
        :class="{ 'dark-tooltip': isDark }"
        :style="{ left: `${tooltip.x}px`, top: `${tooltip.y}px` }"
      >
        <div><strong>{{ tooltip.category }}</strong></div>
        <div>{{ tooltip.explanation }}</div>
      </div>
    </Teleport>
  </div>

  <div style="width: 400px; height: 20px;" @mouseenter="hideTooltipNow"/>

</template>

<script setup>
import { defineProps, reactive, watch } from 'vue';

import ChevronRightIcon from "@/components/icons/ChevronRightIcon.vue";
import ChevronDownIcon from "@/components/icons/ChevronDownIcon.vue";
import ChevronUpIcon from "@/components/icons/ChevronUpIcon.vue";
import HelpIcon from "@/components/icons/HelpIcon.vue";

import axios from 'axios';

const props = defineProps({
  processes: { type: Object, required: true },
  type: { type: String, required: true },
  uuid: { type: String, required: true },
});

watch(
  () => props.processes,
  () => {},
  { deep: true }
);

const emit = defineEmits(['update:hidden','update:toggle']);

const helpIconContainers = reactive({});
const tooltip = reactive({
  show: false,
  category: '',
  explanation: '',
  x: 0,
  y: 0,
});

function showTooltip(group) {
  const helpIcon = helpIconContainers[group.name];
  if (helpIcon) {
    const rect = helpIcon.getBoundingClientRect();
    tooltip.x = rect.right + 10;
    tooltip.y = rect.top - 12;
    tooltip.category = group.processes[0]?.category || 'No Category';
    tooltip.explanation = group.processes[0]?.explanation || 'No Explanation';
    tooltip.show = true;
  }
}

function hideTooltipNow() {
  tooltip.show = false;
}

function toggleGroup(groupName) {
  console.log("toggleGroup " + groupName + " " + props.type);
  emit('update:toggle', groupName, props.type);
}

async function hideProcess(uuid, name, hide) {
  emit('update:hidden', name, hide);

  await axios.post(`/go/hide`, {
    uuid: uuid, name: name, hide: hide
  }, {
    headers: {'Content-Type': 'application/json'}
  });
}

function cpuStyle(group) {
  return { opacity: group.expanded ? 0.7 : 1, textAlign: 'center', color: Math.round(group.cpu_percent) < 1 ? '#2f353d' : 'inherit' };
}

function memoryStyle(group) {
  return { opacity: group.expanded ? 0.7 : 1, textAlign: 'center', color: Math.round(group.memory_percent) < 1 ? '#2f353d' : 'inherit' };
}

function formatAddresses(addresses) {
  if (!addresses) return '';
  return Array.from(new Set(addresses.map((addr) => addr.split(':')[1] || null).filter(Boolean))).join(' ');
}

function trimCommandLine(commandLine) {
  if (!commandLine) return '';
  return commandLine.length > 90 ? `${commandLine.slice(0, 90)}...` : commandLine;
}
</script>


<style scoped>
.icon-chevron-right,
.icon-chevron-down {
  width: 12px;
  height: 12px;
  vertical-align: middle;
  margin-left: -10px;
  margin-right: -1px;
  margin-top: -1.5px;
}

.icon-hide {
  width: 12px;
  height: 12px;
  vertical-align: middle;
  margin-left: -10px;
  margin-right: -1px;
  margin-top: -1.5px;
  cursor: pointer;
}

.help {
  margin-right: 0;
  color: #b1b1b1;
  cursor: pointer;
}

.star {
  width: 15px;
  height: 15px;
  vertical-align: middle;
  margin-right: 0;
  color: #f18127;
  cursor: pointer;
}

.unstar {
  width: 15px;
  height: 15px;
  vertical-align: middle;
  margin-right: 0;
  color: #787878;
  cursor: pointer;
}

.process-table-container {
  width: 1195px;
  overflow-x: auto;
  box-shadow: 3px 3px 16px -8px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  border: 1px solid #d3d3d3;
}

table {
  width: 100%;
  table-layout: fixed;
}

.dark .process-table-container {
  border: 1px solid #2f353d;
}

.tooltip {
  position: fixed;
  z-index: 100;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 7px 9px;
  max-width: 250px;
  white-space: normal;
  font-size: 12px;
  line-height: 1.4;
  transition: opacity 0.2s ease;
  background-color: #262f39;
  color: #fff;
  border: 1px solid #434f5c;
}


/*noinspection CssUnusedSymbol */
.tooltip-fade-enter-active, .tooltip-fade-leave-active {
  transition: opacity 0.1s ease;
}

/*noinspection CssUnusedSymbol */
.tooltip-fade-enter, .tooltip-fade-leave-to /* .tooltip-fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
