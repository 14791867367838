<template>
  <div class="strategy">

    <div class="strategy-label">Additional Costs</div>

    <div style="margin-top: 20px; margin-bottom: 23px;">Support</div>

    <div class="radio-buttons" style="user-select: none;">
      <label v-for="type in portfolioTypes" :key="type.value" :class="{ active: support === type.value }">
        <input type="radio" :value="type.value" v-model="support" />
        {{ type.label }}
      </label>
    </div>

    <!-- VPC Overhead Slider -->
    <div style="margin-top: 15px;">
      <label for="discount-slider" style="display: block; margin-bottom: 5px;"><span style="display: inline-block; width: 100px;">VPC Overhead</span><span style="display: inline-block; text-align: right; width: 115px;">{{ overhead }}%</span></label>
      <label for="discount-slider" style="display: block; margin-bottom: 10px; font-style: italic; font-size: 10px; color: #6e6e6e;">Landing Zone, NAT, ELBs, Security</label>
      <input
        id="overhead-slider"
        type="range"
        min="0"
        max="20"
        step="1"
        v-model="overhead"
        class="slider"
        style="width: 100%;"
        @mouseup="updatePortfolio"
        @touchend="updatePortfolio"
      />
    </div>

    <!-- S3 Slider -->
    <div style="margin-top: 15px;">
      <label for="s3-slider" style="display: block; margin-bottom: 10px;">
        <span style="display: inline-block; width: 100px;">S3 Storage</span><span style="display: inline-block; text-align: right; width: 115px;">{{ displayS3 }} TiB</span>
      </label>
      <input
        id="s3-slider"
        type="range"
        min="0"
        max="100"
        step="1"
        v-model="s3"
        class="slider"
        style="width: 100%;"
        @mouseup="updatePortfolio"
        @touchend="updatePortfolio"
      />
    </div>

    <!-- Discount Slider -->
    <div style="margin-top: 15px;">
      <label for="discount-slider" style="display: block; margin-bottom: 10px;"><span style="display: inline-block; width: 105px;">Enterprise Discount</span><span style="display: inline-block; text-align: right; width: 115px;">{{ discount }}%</span></label>
      <input
        id="discount-slider"
        type="range"
        min="0"
        max="20"
        step="1"
        v-model="discount"
        class="slider"
        style="width: 100%;"
        @mouseup="updatePortfolio"
        @touchend="updatePortfolio"
      />
    </div>

  </div>
</template>

<script setup>
import { ref, computed, watch, onBeforeUnmount } from 'vue';
import { usePortfolioStore } from '@/stores/DataStore';
import axios from 'axios';

const emit = defineEmits(['updatePortfolio', 'stopLoadIcon']);
const props = defineProps({ portfolio: { type: String, required: true } });

const portfolioStore = usePortfolioStore();
const portfolios = computed(() => portfolioStore.get);
const portfolioObj = ref(null);

const support = ref('business');
const discount = ref(0);
const overhead = ref(20);
const s3 = ref(0);

const portfolioTypes = [
  { value: 'none', label: 'None' },
  { value: 'business', label: 'Business' },
  { value: 'enterprise', label: 'Enterprise' }
];

const displayS3 = computed(() => {
  if (s3.value <= 100) return s3.value; // 1-50 TiB, step 1
  return 100;
});

watch(() => props.portfolio, (newportfolio) => {
  portfolioObj.value = portfolios.value?.find(p => p.portfolio === newportfolio);

  if (portfolioObj.value) {
    support.value = portfolioObj.value.support || 'business';
    discount.value = portfolioObj.value.discount || 0;
    overhead.value = portfolioObj.value.overhead || 20;

    // take the saved value and convert it back to the s3 slider value
    s3.value = portfolioObj.value.s3 || 0;

    /**
    if (savedS3 <= 50) {
      s3.value = savedS3; // 1-50 TiB, step 1 (no conversion needed)
    } else if (savedS3 <= 200) {
      s3.value = 50 + (savedS3 - 50) / 10; // reverse 50-200 TiB mapping
    } else if (savedS3 <= 2000) {
      s3.value = 70 + (savedS3 - 200) / 50; // reverse 200-2000 TiB mapping
    }
    **/
  }
}, { immediate: true });

watch(support, async () => {
  if (!portfolioObj.value) return;
  await updatePortfolio();
});

let controller = null;

async function updatePortfolio() {
  if (!portfolioObj.value) return;

  Object.assign(portfolioObj.value, { support: support.value, discount: discount.value, overhead: overhead.value, s3: displayS3.value });
  portfolioStore.updatePortfolio(props.portfolio, portfolioObj.value);

  emit('startLoadIcon');

  if (controller) {
    controller.abort();
  }

  controller = new AbortController();
  const { signal } = controller;

  try {
    await axios.post(
      `/go/portfolio/enterprise`,
      { support: support.value, discount: String(discount.value), overhead: String(overhead.value), s3: String(displayS3.value) },
      { headers: { 'Content-Type': 'application/json' }, signal }
    );
  } catch (error) {
    if (error.name === 'CanceledError') {
      console.log("Request canceled");
    } else {
      console.error("Error updating portfolio strategy:", error);
    }
  } finally {
    controller = null;
  }

  emit('updatePortfolio', { ...portfolioObj.value });
}

onBeforeUnmount(() => {
  if (controller) {
    emit('stopLoadIcon');
    controller.abort();
  }
});
</script>

<style scoped>
.radio-buttons {
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  border-color: #2f353d;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  background-color: #0d1117;
  padding: .25rem;
  width: 215px;
  margin-bottom: 14px;
  box-shadow: 0 0 0 1px #0000000f;
}

.radio-buttons label {
  display: flex;
  padding-left: 10px;
  align-items: center;
  background-color: #0d1117;
  color:  #e4e4e4;
  padding-top: 4px;
  padding-bottom: 5px;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid transparent;
}

.radio-buttons label.active {
  background-color: #384459 !important;
  border: 1px solid transparent;
  color: #e4e4e4;
}

.radio-buttons input[type="radio"] {
  display: none;
}

.strategy-label {
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 500;
}

.strategy {
  width: 225px;
  margin-left: 20px;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 215px;
  margin-top: 10px;
  height: 9px;
  background: #384459;
  border-radius: 5px;
  outline: none;
  transition: all 0.3s ease;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  background: #e4e4e4;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.slider::-moz-range-thumb {
  width: 18px;
  height: 18px;
  background: #e4e4e4;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
</style>