<template>
  <div class="tab_container">
    <div class="tabs" :style="tabsStyle">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        :class="['tab', { active: activeTab === index }]"
        @click="selectTab(index)"
      >
        {{ tab }}
      </div>
    </div>
    <div class="tab-content">
      <slot :name="`tab-${activeTab}`"></slot>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue'

const emit = defineEmits(['change-tab'])

const props = defineProps({
  tabs: {
    type: Array,
    required: true
  },
  activeTab: {
    type: Number,
    default: 0
  },
  tabsWidth: {
    type: String,
    default: '900px'
  }
})

const activeTab = ref(props.activeTab)

watch(() => props.activeTab, (newVal) => {
  activeTab.value = newVal
})

const selectTab = (index) => {
  activeTab.value = index
  emit('change-tab', index)
}

const tabsStyle = computed(() => ({
  width: props.tabsWidth
}))
</script>

<style scoped>

.tab_container {
  position: fixed;
  margin-left: 360px;
  margin-top: 8px;
  z-index: 2;
}

.tabs {
  display: flex;
  margin-bottom: 12px;
  font-size: 13px;
  width: 900px;
}

.dark .tabs {
}

.tab {
  padding: 3px 20px 5px;
  width: 100px;
  text-align: center;
  cursor: pointer;
  color: #000;
  font-weight: 500;
  border: 1px solid #cccccc;
  border-radius: 3px;
  background: #fff;
  margin-left: 3px;
  font-size: 12px;
  -ms-user-select: none;
  user-select: none;
}

.dark .tab {
  color: #d9d9d9;
  border: 1px solid #2f353d;
  background: #11161e;
}

.tab:hover {
  background-color: #e8e8e8;
  border: 1px solid #a8a8a8;
  border-radius: 3px;
  border-bottom: 0;
}

.dark .tab:hover {
  background-color: #273646;
  border: 1px solid #273646;
}

.tab.active {
  color: #000000;
  background: #ebebeb;
  border: 1px solid #b4b4b4;
  border-radius: 3px;
}

.dark .tab.active {
  color: #e0e0e0;
  background: #273646;
  border: 1px solid #434f5c;
}

.tab-content {
  padding-top: 2px;
  padding-right: 10px;
  padding-left: 5px;
}
</style>
