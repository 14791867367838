import { defineStore } from "pinia";
import localforage from "localforage";

const mappingDB = localforage.createInstance({
  name: "CloudHound",
  storeName: "mapping",
});

export const useMappingStore = defineStore("mappingData", {
  state: () => ({
    componentsMap: {}, // stores components mapped by portfolio
  }),
  actions: {
    async getComponents(portfolio) {
      if (!portfolio) {
        console.error("Portfolio name is required to load components.");
        return null;
      }

      try {
        // check local state first
        if (this.componentsMap[portfolio]) {
          return this.componentsMap[portfolio];
        }

        // if not in local state, fetch from IndexedDB
        const storedState = await mappingDB.getItem(portfolio);
        if (storedState) {
          this.componentsMap[portfolio] = storedState; // update local state
          return storedState;
        }

        return null;
      } catch (error) {
        console.error(`Error loading components for portfolio ${portfolio}:`, error);
        return null;
      }
    },
    async setComponents(portfolio, components) {
      if (!portfolio) {
        console.error("Portfolio name is required to save components.");
        return;
      }

      try {
        this.componentsMap[portfolio] = components; // in memory
        const sanitizedComponents = components.map((component) => {
          return JSON.parse(JSON.stringify(component)); // removes non-serializable properties
        });
        await mappingDB.setItem(portfolio, sanitizedComponents); // in db
      } catch (error) {
        console.error(`Error saving components for portfolio ${portfolio}:`, error);
      }
    },
  },
});