<script setup>

</script>

<template>
<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path class="st0" d="M7.5,0.9c-3.7,0-6.6,3-6.6,6.6c0,3.7,3,6.6,6.6,6.6c3.7,0,6.6-3,6.6-6.6C14.1,3.8,11.2,0.9,7.5,0.9z M1.8,7.5
	c0-3.1,2.5-5.7,5.7-5.7c3.1,0,5.7,2.5,5.7,5.7s-2.5,5.7-5.7,5.7C4.4,13.2,1.8,10.6,1.8,7.5z M7.5,11.5c2.2,0,4-1.8,4-4s-1.8-4-4-4
	s-4,1.8-4,4S5.3,11.5,7.5,11.5z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"/>
</svg>
</template>

<style scoped>

</style>