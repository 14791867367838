<template>
  <div class="strategy">
    <div class="strategy-label">EC2 Strategy</div>

    <div class="radio-buttons-row">
      <label :class="{ active: plan_type_compute === true }">
        <input type="radio" :value="true" v-model="plan_type_compute" @change="onRadioChange" /> Compute
      </label>
      <label :class="{ active: plan_type_compute === false }">
        <input type="radio" :value="false" v-model="plan_type_compute" @change="onRadioChange"  /> EC2 Instance
      </label>
    </div>

    <div class="radio-buttons">
      <label style="margin-top: -2px; padding: 0;">
        <input type="radio" value="" v-model="yearly"/>
      </label>

      <label :class="{ active: yearly === '1' }">
        <input type="radio" value="1" v-model="yearly" @change="onRadioChange" /> 1yr
      </label>
      <label :class="{ active: yearly === '3' }">
        <input type="radio" value="3" v-model="yearly" @change="onRadioChange" /> 3yr
      </label>
    </div>

    <div>Payment term</div>

    <div class="radio-buttons">
      <label style="margin-top: -2px; padding: 0;">
        <input type="radio" value="" v-model="upfront"/>
      </label>

      <label :class="{ active: upfront === 'nu' }">
        <input type="radio" value="nu" v-model="upfront" @change="onRadioChange" /> No Upfront
      </label>
      <label :class="{ active: upfront === 'pu' }">
        <input type="radio" value="pu" v-model="upfront" @change="onRadioChange" /> Partial Upfront
      </label>
      <label :class="{ active: upfront === 'au' }">
        <input type="radio" value="au" v-model="upfront" @change="onRadioChange" /> All Upfront
      </label>
    </div>

    <div>Use On-Demand</div>

    <div class="radio-buttons">
      <label style="margin-top: -2px; padding: 0;">
        <input type="radio" value="0" v-model="ondemand"/>
      </label>

      <label :class="{ active: ondemand === '1' }">
        <input type="radio" value="1" v-model="ondemand" @change="onOnDemand" /> No Savings Plan
      </label>
    </div>


  </div>
</template>

<script setup>
import { ref, computed, watch, onBeforeUnmount } from 'vue';
import { usePortfolioStore } from '@/stores/DataStore';
import axios from 'axios';

const emit = defineEmits(['updatePortfolio','postUpdatePortfolio', 'stopLoadIcon']);
const props = defineProps({
  portfolio: {
    type: String,
    required: true
  }
});

const portfolioStore = usePortfolioStore();
const portfolios = computed(() => portfolioStore.get);

const yearly = ref('1');  // default to 1-year
const upfront = ref('nu');  // default to No Upfront
const ondemand = ref('0');  // default to none
const selectedStrategy = ref('csp_1nusp');  // default strategy

const plan_type_compute = ref(true);

const previousState = {
  yearly: yearly.value,
  upfront: upfront.value,
};

const filteredPortfolio = ref(null);

// watch apikey changes
watch(() => props.portfolio, (newportfolio) => {
  filteredPortfolio.value = portfolios.value?.find(p => p.portfolio === newportfolio);
  if (filteredPortfolio.value) {
    selectedStrategy.value = filteredPortfolio.value.ec2_strategy;

    if (selectedStrategy.value === "ondemand") {
      ondemand.value = '1'
      yearly.value = '';
      upfront.value = '';
    } else {
      ondemand.value = ''
      plan_type_compute.value = (selectedStrategy.value.charAt(0) === 'c');
      yearly.value = selectedStrategy.value.charAt(4); // 1 or 3
      upfront.value = selectedStrategy.value.slice(5, 7); // nu/pu/au
    }

    // update previousState with the initial values
    previousState.yearly = yearly.value;
    previousState.upfront = upfront.value;
  }

}, { immediate: true });

// go to on demand and deselect the savings plans
async function onOnDemand() {
  yearly.value = '';
  upfront.value = '';
  selectedStrategy.value = `ondemand`;
  sendStrategy();
}

async function onRadioChange() {
  ondemand.value = '0'

  // if switching back to a previously selected option (ie: back to all upfront) pick the other prev year/term selected
  if (yearly.value === previousState.yearly && upfront.value === '') {
    upfront.value = previousState.upfront;
  } else if (upfront.value === previousState.upfront && yearly.value === '') {
    yearly.value = previousState.yearly;
  }

  if (yearly.value === '')    { yearly.value = '1'; }
  if (upfront.value === '')   { upfront.value = 'nu'; }

  // compute savings plan vs ec2 savings plan
  let prefix = 'csp_'
  if (!plan_type_compute.value) {
    prefix = 'ec2_'
  }

  selectedStrategy.value = `${prefix}${yearly.value}${upfront.value}`;

  sendStrategy();

  previousState.yearly = yearly.value;
  previousState.upfront = upfront.value;
}

let controller = null;

async function sendStrategy() {
  let ec2_strategy = selectedStrategy.value;

  if (!filteredPortfolio.value) {
    return;
  }

  emit('startLoadIcon');

  filteredPortfolio.value.ec2_strategy = ec2_strategy;

  portfolioStore.updatePortfolio(props.portfolio, filteredPortfolio.value);

  if (controller) {
    controller.abort();
  }

  controller = new AbortController();
  const { signal } = controller;

  try {
    await axios.post(
      `/go/portfolio/ec2`,
      { ec2_strategy },
      {
        headers: { 'Content-Type': 'application/json' },
        signal,
      }
    );
  } catch (error) {
    if (error.name === 'CanceledError') {
      console.log("Request canceled");
    } else {
      console.error("Error updating EC2 strategy:", error);
    }
  } finally {
    controller = null; // reset controller
  }

  // call parent to update costs
  emit('updatePortfolio');
}

onBeforeUnmount(() => {
  if (controller) {
    emit('stopLoadIcon');
    controller.abort(); // cancel any ongoing request on unmount
  }
});

</script>

<style scoped>
.radio-buttons {
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  border-color: #2f353d;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  background-color: #0d1117;
  padding: .25rem;
  width: 205px;
  margin-bottom: 14px;
  box-shadow: 0 0 0 1px #0000000f;
}

.radio-buttons label {
  display: flex;
  padding-left: 10px;
  align-items: center;
  background-color: #0d1117;
  color:  #e4e4e4;
  padding-top: 4px;
  padding-bottom: 5px;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid transparent;
}

.radio-buttons label.active {
  background-color: #384459 !important;
  border: 1px solid transparent;
  color: #e4e4e4;
}

.radio-buttons input[type="radio"] {
  display: none;
}

.strategy-label {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 500;
}

.strategy {
  width: 210px;
  margin-left: 20px;
}

.radio-buttons-row {
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  border-color: #2f353d;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  background-color: #0d1117;
  padding: .25rem;
  width: 205px;
  margin-bottom: 14px;
  box-shadow: 0 0 0 1px #0000000f;
}

.radio-buttons-row label {
  display: flex;
  justify-content: center;
  background-color: #0d1117;
  color:  #e4e4e4;
  padding-top: 2px;
  padding-bottom: 3px;
  width: 120px;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid transparent;
}

.radio-buttons-row label.active {
  background-color: #384459 !important;
  border: 1px solid transparent;
  color: #e4e4e4;
}

.radio-buttons-row input[type="radio"] {
  display: none;
}

</style>
