<template>
  <div class="outer-container" :style="{ height: topViewHeight }">
    <div style="margin-bottom: 30px;">
      <div style="display: flex; align-items: center; margin-bottom: 15px; vertical-align: middle;">
        <div class="header" style="margin-bottom: 0px;">Discovery</div>
        <input
          type="text"
          v-model="searchQuery"
          placeholder="Search Hosts, Apps, Labels, Tags..."
          class="search-box"
          spellcheck="false"
          ref="searchBox"
        />
        <div style="width: 28px; height: 30px; margin-left: -30px;"></div>
        <CrossIcon style="cursor: pointer; margin-left: -22px; margin-top: 1px; color: #858585;" @click="clearSearch"></CrossIcon>

        <label class="retire-style" :style="{ color: hideRetired ? 'grey' : 'inherit' }"
               style="margin-left: 25px; display: flex; align-items: center;" @click="hideRetired = !hideRetired"
        >
          <EyeClosedIcon style="width: 20px; margin-right: 10px;" v-if="hideRetired"/><EyeOpenIcon style="width: 20px; margin-right: 10px;" v-else/>
          {{ hideRetired ? 'Hide Retired' : 'Show Retired' }}
        </label>

        <label class="retire-style" style="margin-left: 320px; display: flex; align-items: center; width: 105px;">
          <ImportIcon style="width: 20px; margin-right: 8px;"/>
          Label Import
        </label>

        <label class="retire-style" :style="{ color: idleDetection ? 'inherit' : 'grey' }"
               style="margin-left: 15px; display: flex; align-items: center; width: 131px;" @click="toggleIdleDetection"
        >
          <MoonIcon style="width: 20px; margin-right: 10px;" v-if="idleDetection"/><MoonIcon style="width: 20px; margin-right: 10px;" v-else/>
          {{ idleDetection ? 'Idle Detection On' : 'Idle Detection Off' }}
        </label>

      </div>

      <div class="discovery-table-container">
      <table class="monitoring-table" ref="monitoringTable">
        <tr>
          <th class="header-cell" style="width: 12px; max-width: 12px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"></th>
          <th class="header-cell" style="width: 125px;">Host</th>
          <th class="header-cell" style="width: 15px;"></th>
          <th class="header-cell" style="width: 35px;">OS</th>
          <th class="header-cell" style="width: 33px;">Platform</th>
          <!-- <th class="header-cell" style="width: 100px;">Primary IP</th> -->
          <th class="header-cell" style="width: 18px; text-align: center; ">CPU</th>
          <th class="header-cell" style="width: 18px; text-align: center; ">Mem</th>
          <th class="header-cell" style="width: 30px; text-align: center;">Target</th>
          <th class="header-cell" style="width: 180px; padding-left: 15px;">Label</th>
          <th class="header-cell" style="width: 210px;"> {{ idleDetection ? "Utilisation Peak" : "Tags" }}</th>
        </tr>
        <tr v-for="item in paginatedMonitoring"
            :key="item.uuid"
            :class="{ 'selected-row': item.uuid === selectedUuid }"
            :style="{ opacity: item.retire ? 0.4 : 1 }"
            @click="selectLine(item.uuid)"
        >
          <td @click.stop="selectLine(item.uuid)">
            <CheckOnIcon style="height: 13px; width: 13px" v-if="item.uuid === selectedUuid" class="monitor-check" />
            <CheckOffIcon style="height: 13px; width: 13px" v-else class="monitor-check" />
          </td>
          <td style="width: 210px;">{{ item.host }}</td>
          <td class="icon-cell"><CheckIcon :ageInSeconds="item.updated" /></td>
          <td>{{ item.os }}</td>
          <td>{{ formatPlatform(item.platform) }}</td>
          <!-- <td>{{ item.primary_ip }}</td> -->
          <td style="text-align: center;">{{ item.vcpu }}</td>
          <td style="text-align: center;">{{ item.mem_gb }}</td>
          <td style="text-align: center;"><TargetLabel :target="item.target" :retire="item.retire" /></td>
          <td>
            <LabelComponent :key="item.uuid + item.label" :label="item.label" :uuid="item.uuid" @update:label="updateLabel(item.uuid, $event)" @mousedown.stop/>
          </td>
          <td>
            <IdleDetection v-if="idleDetection" :cpu_p99="item.cpu_p99" :mem_p99="item.mem_p99" :mem_gb="item.mem_gb" :iops_p99="item.iops_p99" :imported="item.imported"/>
            <TagComponent v-else :tags="item.tags" />
          </td>
        </tr>
      </table>
    </div>
    <div class="pagination-controls">
      <a href="#" @click.prevent="prevPage" :class="{ disabled: currentPage === 1 }" style="width: 90px;">Previous</a>
      <span style="margin-left: 15px; margin-right: 15px;">Page {{ currentPage }} of {{ totalPages }}</span>
      <a href="#" @click.prevent="nextPage" :class="{ disabled: currentPage === totalPages }" style="width: 90px;">Next</a>
    </div>
    </div>
  </div>
  <div v-if="selectedUuid" ref="detailsContainer" class="split-view" :style="{ height: splitViewHeight }">
    <div class="resizer" @mousedown="startResizing"></div>
    <div class="hostheader">.</div>
    <div class="hostheader-trim">{{ selectedHost }}</div>
    <Tabs
        :tabs="['Server Details', 'Right Sizing', 'Processes', 'Networking', 'Utilisation', 'Connections']"
        :activeTab="activeTab"
        @change-tab="changeTab"
    />
    <DetailView
        :uuid="selectedUuid"
        :outerdiv="detailsContainer"
        :activeTab="activeTab"
        @update:selectedArchitecture="handleArchitectureChange"
        @update:Retired="handleRetire"
    />
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, defineProps, watch } from 'vue';
import DetailView from "@/components/discovery/DetailView.vue";
import {useDiscoveryStore, usePortfolioStore} from '@/stores/DataStore.js';
import { useServerDataStore } from '@/stores/DataStore.js';
import CheckIcon from "@/components/icons/CheckIcon.vue";
import fetchData from '@/prefetch/PreDataFetch'; // Adjust the import path
import TargetLabel from '@/components/discovery/TargetComponent'; // Adjust the import path based on your file structure
import Tabs from "@/components/discovery/TabComponent.vue";

import LabelComponent from "@/components/discovery/LabelComponent.vue";
import {globalStore} from "@/components/discovery/LabelGlobal";
import CrossIcon from "@/components/icons/CrossIcon.vue";
import EyeOpenIcon from "@/components/icons/EyeOpenIcon.vue";
import EyeClosedIcon from "@/components/icons/EyeClosedIcon.vue";
import IdleDetection from "@/components/discovery/IdleDetection.vue";
import CheckOnIcon from "@/components/icons/CheckOnIcon.vue";
import CheckOffIcon from "@/components/icons/CheckOffIcon.vue";
import MoonIcon from "@/components/icons/MoonIcon.vue";
import ImportIcon from "@/components/icons/ImportIcon.vue";
import TagComponent from "@/components/discovery/TagComponent.vue";

const splitViewHeight = ref('48%');
const topViewHeight = ref('45%');
const isResizing = ref(false);
const monitoringTable = ref(null);
const detailsContainer = ref(null);

const selectedUuid = ref(null);
const selectedIndex = ref(null);
const selectedHost = ref('');

const store = useDiscoveryStore();
const server_store = useServerDataStore();

const activeTab = ref(0);
const hideRetired = ref(true);
const idleDetection = ref(false);
const searchQuery = ref("");

const currentPage = ref(1);
const pageSize = ref(12);

const persistentState = ref({
  currentPage: 1,
  showRetired: true,
  search: '',
});

const toggleIdleDetection = () => {
  idleDetection.value = !idleDetection.value
  currentPage.value = 1
}

const searchBox = ref(null); // for search box

const clearSearch = () => {
  searchQuery.value = '';
  searchBox.value?.focus();
};

watch(() => searchQuery.value, () => {
  currentPage.value = 1; // Reset to the first page
});

// data from parent
const props = defineProps({
  monitoring: {
    type: Array,
    required: true,
    default: () => [],
  },
  selectedHost: {
    type: String,
    default: ''
  }
});

const filteredMonitoring = computed(() => {

  let result = props.monitoring.filter((item) => {
    const notRetired = !hideRetired.value || !item.retire;
    const isUsed = item.cpu_p99 > 4 && ((item.mem_p99 * item.mem_gb) / 100) > 10;
    const passesIdleFilter =
      !idleDetection.value ||
      !isUsed && (item.cpu_p99 <= 5 && item.iops_p99 < 100 && (item.mem_p99 * item.mem_gb) / 100 < 10); // cpu < 5%, mem < 10 GiB, IOPS <100
    return notRetired && passesIdleFilter;
  });

  // sort by cpu % + GB mems used
  if (idleDetection.value) {
    result.sort((a, b) => (a.cpu_p99 + ((a.mem_p99 * a.mem_gb) / 100)) - (b.cpu_p99 + (b.mem_p99 * b.mem_gb) / 100));
  }

  if (searchQuery.value) {
    const query = searchQuery.value.toLowerCase().trim();
    const queries = query.includes(',')
      ? query.split(',').map((q) => q.trim()).filter(Boolean)
      : [query];

    result = result.filter((item) => {
      const matchesQuery = (query) =>
        item.host.toLowerCase().includes(query) ||
        (item.label && item.label.toLowerCase().includes(query)) ||
        (item.app_groups && item.app_groups.toLowerCase().includes(query)) ||
        (item.os && item.os.toLowerCase().includes(query)) ||
        (item.platform && item.platform.toLowerCase().includes(query)) ||
        (item.target && item.target.toLowerCase().includes(query)) ||
        (item.tags && item.tags.some(tag => tag.toLowerCase().includes(query)));

      return queries.some(matchesQuery);
    });
  }

  return result;
});

// if we select down to one, make sure that server gets selected
watch(filteredMonitoring, (newVal) => {
  if (!newVal || newVal.length === 0) {
    selectedUuid.value = null;
    selectedIndex.value = null;
    selectedHost.value = '';
    return;
  }

  if (newVal.length === 1) {
    const singleItem = newVal[0];
    selectedUuid.value = singleItem.uuid;
    selectedIndex.value = props.monitoring.findIndex(item => item.uuid === singleItem.uuid);
    selectedHost.value = singleItem.host;
    store.setSelectedUuid(selectedUuid.value, selectedIndex.value);
  }
});

// ensure the current page is within the valid range
watch(filteredMonitoring, () => {
  if (currentPage.value > totalPages.value) {
    currentPage.value = 1;
  }
});

const changeTab = (index) => {
  activeTab.value = index;
  store.setSelectedTab(index);
};

// called by label component
const updateLabel = (uuid, newLabel) => {
  const item = props.monitoring.find(item => item.uuid === uuid);
  if (item) {
    item.label = newLabel;
  }
};

// catch architecture change from the pricing widget in detail view
// update the prop directly for ease of use
/* eslint-disable vue/no-mutating-props */
const handleArchitectureChange = (newArchitecture, newprice) => {
  if (selectedIndex.value !== -1) {
    props.monitoring[selectedIndex.value].target = newArchitecture;
    props.monitoring[selectedIndex.value].target_price = newprice;
    props.monitoring = [...props.monitoring];
  }
};

// this retires a box -> turns price to zero
const handleRetire = (toretire) => {
  console.log("Handle retire " + toretire)
  if (selectedIndex.value !== -1) {

    console.log("Old retire " + props.monitoring[selectedIndex.value].retire)

    props.monitoring[selectedIndex.value].retire = toretire;

    console.log("New retire " + props.monitoring[selectedIndex.value].retire)

    props.monitoring = [...props.monitoring];
  }
};

const selectLine = (uuid) => {

  // if an editable label is active, do not click
  if (globalStore.activeLabel) {
    //globalStore.activeLabel.saveLabel();
    globalStore.activeLabel = null;
    return
  }

  selectedUuid.value = (selectedUuid.value === uuid) ? null : uuid;
  selectedIndex.value = props.monitoring.findIndex(item => item.uuid === uuid);

  if (selectedIndex.value !== -1) {
    selectedHost.value = props.monitoring[selectedIndex.value].host;
  } else {
    selectedHost.value = '';
  }

  if (selectedUuid.value == null) {
    topViewHeight.value = ''
    splitViewHeight.value = '48%'
  }

  store.setSelectedUuid(selectedUuid.value, selectedIndex.value);
};

const startResizing = (event) => {
  isResizing.value = true;
  event.preventDefault();
};

const resize = (event) => {
  if (isResizing.value) {
    const lowerHeight = window.innerHeight - event.clientY;
    const upperHeight = event.clientY + 3;

    splitViewHeight.value = `${lowerHeight}px`;
    topViewHeight.value = `${upperHeight}px`;
  }
};

const stopResizing = () => {
  isResizing.value = false;
};

window.addEventListener('mousemove', resize);
window.addEventListener('mouseup', stopResizing);

const formatPlatform = (platform) => {
  if (!platform) return "";
  platform = platform.replace(/ DC/g, "");
  platform = platform.replace(/ Enterprise/g, "");
  platform = platform.replace(/ Standard/g, "");
  return platform; // return the original if no match
};

let prefetchCancel;

// prefresh all actual internal server details for speed
const prefetchData = async () => {
  if (!props.monitoring){return}
  prefetchCancel = new AbortController();
  const { signal } = prefetchCancel;

  // ensure props.monitoring is an array before applying filter
  const uuidsToFetch = Array.isArray(props.monitoring)
    ? props.monitoring
        .filter(item => {
          return server_store.isDataStale(item.uuid);
        })
        .map(item => item.uuid)
    : [];

  if (uuidsToFetch.length > 0) {
     fetchData(uuidsToFetch, { signal }); // no await
  }

};

const portfolioStore = usePortfolioStore();

onMounted(async () => {

  // persist options between page loads
  const savedState = localStorage.getItem("discoveryOptions");
  if (savedState) {
    const state = JSON.parse(savedState);
    currentPage.value = state.currentPage || 1;
    searchQuery.value = state.search || '';
    hideRetired.value = state.showRetired !== undefined ? state.showRetired : true;
  }

  topViewHeight.value = `calc(100vh - ${splitViewHeight.value})`;

  // set selected item from store
  if (store.getSelectedUuid) {
    const server = store.getServerByUuid(portfolioStore.getCurPortfolio, store.getSelectedUuid);

    if (server) {
      selectedUuid.value = store.getSelectedUuid;
      selectedIndex.value = store.getSelectedIndex;

      selectedHost.value = server.host;
    }
  }

  // set tab selected previously
  activeTab.value = store.getSelectedTab;

  // set selected host if it exists & override active tab
  if (props.selectedHost) {
    searchQuery.value = props.selectedHost;
    activeTab.value = 1; // go to right sizing tab
  }

  // init prefetch
  prefetchData().catch(error => {
    if (error.name === 'AbortError') {
      console.log('Prefetch cancelled');
    } else {
      console.error('Error in prefetchData:', error);
    }
  });

  window.addEventListener('keydown', handleKeyDown);

  // if portfolio has changed since last load, clear search
  {
    const currentPortfolio = portfolioStore.getCurPortfolio;
    const lastPortfolio = localStorage.getItem('lastPortfolio');
    if (lastPortfolio && lastPortfolio !== currentPortfolio) {
      searchQuery.value = '';
    }
    localStorage.setItem('lastPortfolio', currentPortfolio);
  }

});

// save state when it changes to persistence options
watch(() => persistentState.value, (newState) => {
    localStorage.setItem("discoveryOptions", JSON.stringify(newState));
  }, { deep: true }
);

// watch individual options
watch(searchQuery, (newSearch) => {
  if (filteredMonitoring.value.length > 0) {
    persistentState.value.search = newSearch;
  } else {
    // if no results, do not save
    persistentState.value.search = '';
  }
});

watch(currentPage, (newPage) => {
  persistentState.value.currentPage = newPage;
});

watch(hideRetired, (newShowRetired) => {
  persistentState.value.showRetired = newShowRetired;
});

watch(() => props.monitoring, (newVal) => {
  if (!newVal || newVal.length === 0) {
    return;
  }

  // do prefetch
  if (props.monitoring && newVal.length > 0) {
    prefetchData();
  }

  // select first item
  if (newVal && newVal.length > 0 && !selectedUuid.value) {
    selectedUuid.value = newVal[0].uuid;
    selectedIndex.value = 0;
    selectedHost.value = newVal[0].host;
    store.setSelectedUuid(selectedUuid.value, selectedIndex.value);
  }

}, { immediate: true });

onUnmounted(() => {
  window.removeEventListener('mousemove', resize);
  window.removeEventListener('mouseup', stopResizing);
  window.removeEventListener('keydown', handleKeyDown);

  // cancel prefetch if leaving the page
  if (prefetchCancel) {
    prefetchCancel.abort();
  }
});

const handleKeyDown = (event) => {
  if (!selectedUuid.value) return;

  // prevent default window scrolling behavior
  if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
    event.preventDefault();
  }

  const paginatedList = paginatedMonitoring.value;
  const currentIndex = paginatedList.findIndex((item) => item.uuid === selectedUuid.value);

  // Handle up arrow navigation
  if (event.key === 'ArrowUp') {
    if (currentIndex > 0) {
      // Navigate within the current page
      const newItem = paginatedList[currentIndex - 1];
      selectLine(newItem.uuid);
    } else if (currentPage.value > 1) {
      // Move to the previous page
      prevPage();
      const previousPageList = paginatedMonitoring.value;
      const lastItem = previousPageList[previousPageList.length - 1];
      selectLine(lastItem.uuid);
    }
  }

  // Handle down arrow navigation
  if (event.key === 'ArrowDown') {
    if (currentIndex < paginatedList.length - 1) {
      // Navigate within the current page
      const newItem = paginatedList[currentIndex + 1];
      selectLine(newItem.uuid);
    } else if (currentPage.value < totalPages.value) {
      // Move to the next page
      nextPage();
      const nextPageList = paginatedMonitoring.value;
      const firstItem = nextPageList[0];
      selectLine(firstItem.uuid);
    }
  }

  // Navigation for tabs (left/right keys)
  if (event.key === 'ArrowLeft' && activeTab.value > 0) {
    activeTab.value -= 1;
    store.setSelectedTab(activeTab.value);
  } else if (event.key === 'ArrowRight' && activeTab.value < 5) {
    activeTab.value += 1;
    store.setSelectedTab(activeTab.value);
  }
};

const totalPages = computed(() => {
  return filteredMonitoring.value ? Math.ceil(filteredMonitoring.value.length / pageSize.value) : 1;
});

const paginatedMonitoring = computed(() => {
  const start = (currentPage.value - 1) * pageSize.value;
  const end = start + pageSize.value;
  return filteredMonitoring.value.slice(start, end);
});

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
};
</script>

<style scoped>
.search-box {
  margin-left: 30px;
  background-color: #0d1117;
  border: 1px solid #2f353d;
  color: #d9d9d9;
  font-size: 12px;
  padding: 5px 27px 6px 9px;
  border-radius: 4px 4px 4px 4px;
  outline: none;
  width: 265px;
}

.search-box:focus {
  border-color: #767b84;
}

.retire-style {
  margin-left: 30px;
  background-color: #1d2532;
  border: 1px solid #2f353d;
  color: #d9d9d9;
  font-size: 12px;
  padding: 5px 9px 6px 9px;
  border-radius: 4px 4px 4px 4px;
  outline: none;
  width: 108px;
  cursor: pointer;
}

.retire-style:hover {
  background-color: #273646;
  border: 1px solid #273646;
}

.outer-container {
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: rgb(165,165,165) transparent;
  -ms-overflow-style: none; /* Edge, Internet Explorer */
}

/* Style the scrollbar for Webkit browsers (Chrome, Safari) */
.outer-container::-webkit-scrollbar {
  width: 6px;
}

.outer-container::-webkit-scrollbar-track {
  background: transparent;
}

.outer-container::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.header {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 10pt;
}

.monitor-check {
  vertical-align: middle;
  width: 12px;
}

.monitor-check:focus {
  outline: none;
}

.discovery-table-container {
  width: 1195px;
  border: 1px solid #2f353d;
  border-radius: 2px;
  overflow: hidden;
}

.monitoring-table {
  width: 100%;
  overflow: auto;
}

.monitoring-table th, .monitoring-table td {
  padding: 2px 2px 4px 10px;

  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.split-view {
  position: fixed;
  bottom: 0;
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;
  z-index: 52;
  /*box-shadow: 4px 0 32px rgba(0, 0, 0, 0.053);*/
  background: #f2f3f3;
  margin-left: -30px;
  border-left: 0px solid #434f5c;
}

.dark .split-view {
  background: #010409;
}

.resizer {
  cursor: ns-resize;
  height: 6px;
  width: 100%;
  border-top: 1.5px #a5a5a5 solid;
  position: fixed;
  z-index: 50;
}

.hostheader {
  font-weight: bold;
  margin-top: 0;
  padding-top: 11px;
  padding-left: 40px;
  padding-bottom: 10px;
  font-size: 10pt;
  position: fixed;
  background-color: #0D1117;
  width: 100%;
  /*z-index: 49;*/
  border-bottom: 1px solid #2F353D;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 1;
  box-shadow: #000000b3 0px 7px 10px
}

.hostheader-trim {
  font-weight: bold;
  margin-top: 0;
  padding-top: 11px;
  padding-left: 30px;
  padding-bottom: 10px;
  font-size: 10pt;
  position: fixed;
  background-color: #0D1117;
  width: 315px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 2;
}

.icon-cell {
  align-items: center;
  justify-content: center;
  height: 60%;
}

.icon-cell > * {
  margin: auto;
}

.pagination-controls {
  margin-top: 15px;
}

.darker-white {
  color: #868686;
}

tr.retired-text td {
  color: #7E7E7E !important;
}
</style>
