<template>
  <div v-if="paginatedMonitoring.length > 0" class="outer-container">
    <div style="font-weight: 600; margin-bottom: 8px;">SUSE Enterprise Linux {{ paginatedMonitoring.length > 0 ? (isBYOL ? " - BYOL" : " - Licence Included") : "" }}</div>
    <div style="margin-bottom: 20px;">
      <div class="monitoring-table-container">
      <table class="monitoring-table" ref="monitoringTable">
       <tr>
          <th class="header-cell" style="width: 115px; padding-left: 8px; user-select: none;">
            Host
          </th>
          <th class="header-cell" style="width: 115px; user-select: none;">
            Label
          </th>
         <th class="value-center" style="width: 70px;">
            Licence
          </th>
          <th class="value-center" style="width: 70px;">
            Target
          </th>
          <th class="value-center" style="width: 70px;">
          </th>
          <th class="value-center" style="width: 70px;">
          </th>
          <th class="value-center" style="width: 70px;">
          </th>
          <th class="value-center" style="width: 70px;">
            Licence Cost ($)
          </th>
        </tr>
        <tr v-for="(item) in paginatedMonitoring" :key="item.uuid">
          <td style="width: 210px;  padding-left: 8px;" :style="{ opacity: item.retire ? 0.5 : 1 }">{{ item.host }}</td>
          <td :style="{ opacity: item.retire ? 0.5 : 1 }">{{ item.label }}</td>
          <td class="value-center" :style="{ opacity: item.retire ? 0.5 : 1 }">SUSE</td>
          <td class="value-center" :style="{ opacity: item.retire ? 0.5 : 1 }">{{ formatTarget(item) }}</td>
          <td class="value-center" :style="{ opacity: item.retire ? 0.5 : 1 }">{{}}</td>
          <td class="value-center" :style="{ opacity: item.retire ? 0.5 : 1 }">{{ }}</td>
          <td class="value-center" :style="{ opacity: item.retire ? 0.5 : 1 }">{{ }}</td>
          <td class="value-center" :style="{ opacity: (item.retire || getLicencePrice(item).toFixed(2)==='0.00') ? 0.5 : 1 }">{{ getLicencePrice(item).toFixed(2) }}</td>
        </tr>
        <tr>
          <th class="header-cell" style="width: 115px; padding-left: 8px; user-select: none;">
            Total
          </th>
          <th style="width: 115px;"/>
          <th style="width: 70px;"/>
          <th style="width: 70px;"/>
          <th style="width: 70px;"/>
          <th style="width: 70px;"/>
          <th style="width: 70px;"/>
          <th class="value-center" style="width: 70px;">
            {{ new Intl.NumberFormat().format(getTotalLicenceCost()) }}
          </th>
        </tr>
      </table>
    </div>
    <div class="pagination-controls" v-if="totalPages > 1">
      <a href="#" @click.prevent="prevPage" :class="{ disabled: currentPage === 1 }">Previous</a>
      <span style="margin-left: 15px; margin-right: 15px;">Page {{ currentPage }} of {{ totalPages }}</span>
      <a href="#" @click.prevent="nextPage" :class="{ disabled: currentPage === totalPages }">Next</a>
    </div>
    </div>
  </div>
</template>

<script setup>
import {computed, defineProps, ref} from 'vue';
import {usePortfolioStore} from "@/stores/DataStore";

const monitoringTable = ref(null);

// data from parent
const props = defineProps({
  costings: {
    type: Array,
    required: true,
    default: () => [],
  }
});

const currentPage = ref(1);
const pageSize = ref(400);

const totalPages = computed(() => {
  return props.costings ? Math.ceil(paginatedMonitoring.value.length / pageSize.value) : 1;
});

const getLicencePrice = (item) => {
    if (item.retire) {
      return 0;
    }

    if (item.target === 'ec2') {
      return (item.pricing.price_instance - item.pricing.price_os);
    }

    return 0;
}

const formatTarget = (item) => {

  if (item.retire) {
    return "Retire"
  }

  switch(item.target) {
    case 'ec2':
      return 'EC2';
    case 'rds':
      return 'RDS';
    case 'rdsm':
      return 'RDS x2';
    default:
      return item.target;
  }
};

const getTotalLicenceCost = () => {
  const win_filter = props.costings.filter(
    (item) => !item.retire && item.platform === 'sles'
  );

  return win_filter.reduce((total, item) => {
    return total + getLicencePrice(item);
  }, 0)
}

const paginatedMonitoring = computed(() => {
  if (!props.costings || !Array.isArray(props.costings)) {
    return [];
  }

  const filteredData = props.costings.filter((item) => {
    if (item.platform === 'sles') return true;
    return false;
  });

  const sortedData = [...filteredData].sort((a, b) => {
    const order = { ec2: 0, rds: 1, rdsm: 2 };
    const aOrder = order[a.target] ?? 3;
    const bOrder = order[b.target] ?? 3;

    if (a.retire && !b.retire) return 1;
    if (!a.retire && b.retire) return -1;

    return aOrder - bOrder;
  });

  const start = (currentPage.value - 1) * pageSize.value;
  const end = start + pageSize.value;

  return sortedData.slice(start, end);
});

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
};

const portfolioStore = usePortfolioStore();
const portfolios = computed(() => portfolioStore.get);

const isBYOL = computed(() => {
  const portfolio = portfolios.value?.find(
    (p) => p.portfolio === portfolioStore.getCurPortfolio
  );
  return portfolio ? portfolio.suse_byol : true;
});
</script>

<style scoped>
.outer-container {
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: rgb(165,165,165) transparent;
  -ms-overflow-style: none; /* Edge, Internet Explorer */
}

/* Style the scrollbar for Webkit browsers (Chrome, Safari) */
.outer-container::-webkit-scrollbar {
  width: 6px;
}

.outer-container::-webkit-scrollbar-track {
  background: transparent;
}

.outer-container::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.header {
  font-weight: 700;
  margin-bottom: 14px;
  font-size: 10pt;
  margin-top: 10px;
  text-align: left;
}

.monitoring-table-container {
  width: 1195px;
  border: 1px solid #2f353d;
  border-radius: 2px;
  overflow: hidden;
}

.monitoring-table {
  border-collapse: collapse;
  width: 100%;
}

.monitoring-table th, .monitoring-table td {
  padding: 2px 5px 3px 5px;

  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-cell > * {
  margin: auto;
}

.pagination-controls {
  margin-top: 15px;
}

.value-center {
  text-align: center !important;
}
</style>
