import axios from 'axios';
import { useChartCacheStore } from '@/stores/ChartStore';

const fetchMetricData = async (uuid, token, cancel = {}) => {
  // console.log("Chart UUID " + uuid)

  const { signal } = cancel;

  const chartCacheStore = useChartCacheStore();

  try {

    const response = await axios.get(`/go/util/${uuid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      signal,
    });

    if (response.data) {
      chartCacheStore.setCache(uuid, response.data); // update the cache
    }

  } catch (error) {
    if (axios.isCancel(error)) {
      // cancelled on portfolio change, halts the pre-fetch
      // console.log('Request canceled:', error.message);
    } else {
      console.log('Request error:', error.message);
    }
  }
};

export default fetchMetricData;

