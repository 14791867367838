<template>
  <div class="chart-container">
    <div
      v-for="(row, rowIndex) in rows"
      :key="'row-' + rowIndex"
      class="row"
    >
      <div
        v-for="(box, boxIndex) in row"
        :key="'row-' + rowIndex + '-box-' + boxIndex"
        class="box"
        :style="{ backgroundColor: box ? '#FB8C00' : '#43A047' }"
      ></div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
  count: {
    type: Number,
    required: true,
  },
  percentage: {
    type: Number,
    required: true,
  },
});

const rows = ref([]);

const low = 20;
const high = 64;
const scale = 0.8;

// scale the values down from 50 -> 100 to give more space for big disks
const scaleValue = (value) => {
  if (value <= low) return value;

  // dynamic scaling beyond the `high` threshold
  if (value > high) {
    return high * Math.pow(value / high, 0.2); // adjust the exponent as needed
  }

  // linear transition from `low` to `high`
  return value * (1 - ((value - low) / (high - low)) * scale);
};

const updateRows = () => {
  rows.value = [];
  const scaledCount = scaleValue(props.count);
  const totalBoxes = Math.ceil(scaledCount);
  let orangeBoxes = Math.ceil((props.percentage / 100) * totalBoxes);
  let remainingBoxes = totalBoxes;

  while (remainingBoxes > 0) {
    const rowCount = Math.min(12, remainingBoxes);
    const row = new Array(rowCount).fill(0).map((_, index) => index < orangeBoxes);
    rows.value.push(row);
    orangeBoxes -= rowCount;
    remainingBoxes -= rowCount;
  }
};

watch(() => [props.count, props.percentage], updateRows, { deep: true });

updateRows();
</script>

<style scoped>
.chart-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-top: 4px;
}

.row {
  display: flex;
  gap: 2px;
}

.box {
  width: 5px;
  height: 5px;
  background-color: #43A047;
}
</style>
