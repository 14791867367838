import * as vNG from 'v-network-graph';
import { ForceLayout } from "v-network-graph/lib/force-layout"

const baseConfig = {
  view: {
    viewport: {
      width: 1150,
      height: 750,
      scale: 1,
    },
    autoPanAndZoomOnLoad: false,
    panEnabled: true,
    zoomEnabled: true,
    doubleClickZoomEnabled: false,
    mouseWheelZoomEnabled: true,
    layoutHandler: null, // To be customized per layout
  },
  node: {
    normal: {
      color: '#d6d4d4',
      radius: 12
    },
    hover: {
      color: '#d6d4d4',
      radius: 13
    },
    label: {
      visible: false
    },
  },
  edge: {
    selectable: true,
    normal: {
      width: 1.0,
      color: '#dd8800',
      dasharray: '0',
      linecap: 'butt',
      animate: false,
    },
    hover: {
      width: 2.0,
      color: '#dd8800',
      dasharray: '0',
      linecap: 'butt',
      animate: false,
    },
    selected: {
      width: 2.0,
      color: '#dd4400',
      dasharray: '0',
      linecap: 'round',
      animate: false,
      animationSpeed: 50,
    },
    gap: 5,
    type: 'straight',
    margin: 6,
    marker: {
      source: {
        type: 'none',
        width: 4,
        height: 4,
        margin: 1,
        offset: 0,
        units: 'strokeWidth',
        color: null,
      },
      target: {
        type: 'arrow',
        width: 6,
        height: 6,
        margin: -1,
        offset: 0,
        units: 'strokeWidth',
        color: null,
      },
    },
  },
};

// create settled layout by overriding the layoutHandler
export const settledLayout = vNG.defineConfigs({
  ...baseConfig,
  view: {
    ...baseConfig.view,
    layoutHandler: new ForceLayout({
      positionFixedByDrag: true,
      positionFixedByClickWithAltKey: false,
      noAutoRestartSimulation: false,
      createSimulation: (d3, nodes, edges) => {
        const forceLink = d3.forceLink(edges).id(d => d.id);
        return d3
          .forceSimulation(nodes)
          .force("edge", forceLink.distance(180).strength(0.5))
          .force("charge", d3.forceManyBody().strength(-400).distanceMax(150))
          .alpha(0) // settled layout alpha
          .alphaMin(0.01);
      }
    }),
  },
});

// create new layout by overriding the layoutHandler
export const newLayout = vNG.defineConfigs({
  ...baseConfig,
  view: {
    ...baseConfig.view,
    layoutHandler: new ForceLayout({
      positionFixedByDrag: true,
      positionFixedByClickWithAltKey: false,
      noAutoRestartSimulation: false,
      createSimulation: (d3, nodes, edges) => {
        const forceLink = d3.forceLink(edges).id(d => d.id);
        return d3
          .forceSimulation(nodes)
          .force("edge", forceLink.distance(250).strength(0.5))
          .force("charge", d3.forceManyBody().strength(-400).distanceMax(150))
          .alpha(1)
          .alphaMin(0.01);
      }
    }),
  },
});

