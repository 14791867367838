<template>
  <div class="counter-container">
    <div class="header">{{ headerText }}</div>

    <div :title="tooltip" class="status-checkbox">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <path d="M32,2C15.431,2,2,15.432,2,32c0,16.568,13.432,30,30,30c16.568,0,30-13.432,30-30C62,15.432,48.568,2,32,2z M25.025,50
            l-0.02-0.02L24.988,50L11,35.6l7.029-7.164l6.977,7.184l21-21.619L53,21.199L25.025,50z"
          :fill="color" />
      </svg>
    </div>

    <span style="margin-left: 7px;">{{ recordCount }}</span>
    <span style="margin-left: 7px;">{{ oldestTask }}</span>

  </div>
</template>

<script setup>
import {ref, onMounted, onUnmounted, toRefs, computed} from 'vue';
import axios from 'axios';
import {useLiveCounterStore} from "@/stores/DataStore";

const counterStore = useLiveCounterStore();

const props = defineProps({
  headerText: {
    type: String,
    default: 'Network (kb/s)' // default header text if not provided
  },
  pollSec: {
    type: Number,
    required: true,
    default: 3
  },
  path: {
    type: String,
    required: true
  }
});

const {pollSec, path, headerText} = toRefs(props);

const recordCount = ref(0);
const oldestTask = ref();

let cancelTokenSource;
let intervalId;

// Tooltip for the checkbox
const tooltip = 'Status Indicator';

// Dynamic color for the SVG
const color = computed(() => {
  const secondChar = recordCount.value.toString().charAt(1);
  return /^[1-9]$/.test(secondChar) ? '#43a047' : '#787878'; // Green if the second char is a number from 1 to 9, Grey otherwise
});

onMounted(async () => {
  getNewValue("sysmon_all");
});

onUnmounted(() => {
  clearInterval(intervalId);
  if (cancelTokenSource) {
    cancelTokenSource.cancel('Component unmounted.');
  }
});

const getNewValue = async (newPortfolio) => {
  clearInterval(intervalId);

  if (cancelTokenSource) {
    cancelTokenSource.cancel('Operation canceled due to new request.');
  }

  let old_data = await counterStore.getCache(newPortfolio + "." + props.path);

  if (old_data !== null && old_data !== undefined) {
    recordCount.value = old_data;
  }

  fetchRecordCount();
  intervalId = setInterval(fetchRecordCount, pollSec.value * 1000);
};

const fetchRecordCount = async () => {
  if (cancelTokenSource) {
    cancelTokenSource.cancel('Operation canceled due to new request.');
  }

  cancelTokenSource = axios.CancelToken.source();

  try {
    const baseURL = `/go/${path.value}`;

    const response = await axios.get(baseURL, {
      headers: { 'Content-Type': 'application/json' },
      cancelToken: cancelTokenSource.token
    });

    recordCount.value = "x" + response.data.count;

    if (response.data.count===0) {
      recordCount.value = "Offline"
    }

    counterStore.setCache("sysmon." + props.path, response.data.count);

    if (response.data.oldest) {
      const oldestTime = new Date(response.data.oldest)
      const currentTime = new Date();

      const timeDifference = currentTime - oldestTime; // difference in milliseconds
      const hoursAgo = Math.floor(timeDifference / (1000 * 60 * 60)); // hours rounded down

      if (hoursAgo < 24) {
        oldestTask.value = hoursAgo + " hrs";
        if (hoursAgo === 1) oldestTask.value = hoursAgo + " hr";
      } else {
        const daysAgo = Math.floor(hoursAgo / 24);
        oldestTask.value = daysAgo + " days";
        if (daysAgo === 1) oldestTask.value = daysAgo + " day";
      }

      if (hoursAgo === 0) {
        const minsAgo =Math.floor(timeDifference / (1000 * 60));
        oldestTask.value = minsAgo + " mins";
        if (minsAgo === 1) oldestTask.value = minsAgo + " min";
      }

    } else {
      oldestTask.value = ""
    }

  } catch (error) {
    if (!axios.isCancel(error)) {
      console.error('Error fetching record count:', error);
    }
  }
};
</script>

<style scoped>
.counter-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 3px;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center; /* Vertically aligns the content within the row */
  gap: 8px; /* Optional: Adds space between elements */
}

.status-checkbox {
  width: 14px;
  height: 14px;
  padding-top: 2px;
}

.header {
  font-weight: 600;
  font-size: 1rem;
  width: 135px;
  margin-bottom: 0.5rem;
  align-self: flex-start;
}
</style>
