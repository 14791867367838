<template>
  <div style="display: flex; gap: 30px; align-items: flex-start; margin-left: 15px; margin-top: 20px;">

    <div style="position: relative; width: 700px; height: 349px; margin-top: 10px;">
      <img src="/images/regions.png" alt="World" style="width: 700px; display: block; opacity: 0.8;" />
      <div
        v-for="(coord, region) in regionCoordinates"
        :key="region"
        :style="getStyle(coord, region)"
        :title="region"
      />
    </div>

  <!-- Prices -->
  <div style="min-width: 300px; margin-left: 35px; margin-top: 5px;">
    <h3 style="margin-bottom: 10px;">Regional Prices</h3>
    <ul style="list-style: none; padding: 0; font-family: monospace;">
      <li v-for="(price, region, index) in regionPrices" :key="region">
        <span style="display: inline-block; width: 180px;">{{ region }}</span>

        <span style="display: inline-block; width: 60px;">
          ${{ Number(price).toLocaleString(undefined, { maximumFractionDigits: 0 }) }}
        </span>

        <template v-if="index > 0">
          <span style="color: gray; display: inline-block; width: 50px; text-align: right;">
            {{ Math.round(getPercentageDifference(price)) }}%
          </span>
        </template>
      </li>
    </ul>
  </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue';
import axios from 'axios';

const regionPrices = reactive({})
const basePrice = ref(0)

function getPercentageDifference(price) {
  if (!basePrice.value) return 0
  return ((price - basePrice.value) / basePrice.value) * 100
}

onMounted(async () => {
  try {
    const res = await axios.get('/go/regions')
    for (const entry of res.data.regions) {
      regionPrices[entry.region] = entry.total_cost
    }
    const first = res.data.regions[0]
    if (first) basePrice.value = first.total_cost
  } catch (err) {
    console.error('Failed to fetch regions:', err)
  }
})

const regionCoordinates = reactive({
  "Europe (Milan)": [47.7, 8.8],
  "Europe (Frankfurt)": [51.6, 7.1],
  "Europe (Paris)": [49.6, -0.3],
  "Europe (Spain)": [45, -3.7],
  "Europe (Zurich)": [49.6, 6.9],
  "Middle East (Bahrain)": [28.5, 49.9],
  "Middle East (UAE)": [28.5, 54.5],
  "Israel (Tel Aviv)": [37.5, 34],
  "AWS GovCloud (US-East)": [38.8, -82.1],
  "US East (Ohio)": [42, -84.9],
  "US East (N. Virginia)": [41.5, -78.8],
  "AWS GovCloud (US-West)": [39.9, -120.8],
  "US West (N. California)": [40.3, -124.7],
  "US West (Oregon)": [43.6, -123.9],
  "Canada West (Calgary)": [51.1, -114.1],
  "Canada (Central)": [49.8, -69.8],
  "Asia Pacific (Jakarta)": [-7.6, 112.1],
  "Asia Pacific (Hyderabad)": [20.4, 79.7],
  "Asia Pacific (Mumbai)": [23, 74.7],
  "Asia Pacific (Thailand)": [15.7, 105.6],
  "Asia Pacific (Melbourne)": [-40.5, 144],
  "Asia Pacific (Malaysia)": [2.3, 104.8],
  "Asia Pacific (Singapore)": [1.3, 107.8],
  "Mexico (Central)": [25.2, -109.5],
  "South America (Sao Paulo)": [-27.8, -52.9],
  "Europe (Stockholm)": [56.8, 14.3],
  "Europe (London)": [52.4, -2.6],
  "Europe (Ireland)": [53.3, -8],
  "Africa (Cape Town)": [-37, 18],
  "Asia Pacific (Hong Kong)": [26, 117],
  "Asia Pacific (Seoul)": [40, 126.5],
  "Asia Pacific (Osaka)": [38.5, 135],
  "Asia Pacific (Tokyo)": [39, 139],
  "Asia Pacific (Sydney)": [-36, 152],
});

// tweak these constants to align properly
const imageWidth = 700;
const imageHeight = 349;

const offsetX = -68;
const offsetY = -194;
const scaleX = 1.1;
const scaleY = 2.26;

// use mercator projection
function getStyle([lat, lon], region) {
  const x = ((lon + 180) / 360) * imageWidth * scaleX + offsetX;
  const latRad = (lat * Math.PI) / 180;
  const mercN = Math.log(Math.tan(Math.PI / 4 + latRad / 2));
  const y = (1 - mercN / Math.PI) / 2 * imageHeight * scaleY + offsetY;

  return {
    position: 'absolute',
    left: `${x}px`,
    top: `${y}px`,
    width: '8px',
    height: '8px',
    backgroundColor: regionPrices[region] !== undefined ? '#4c79c8' : 'orange',
    borderRadius: '50%',
    pointerEvents: 'auto',
    border: '1px solid #FFF'
  };
}
</script>