<template>
  <div class="summary-placeholder">

    <CpuMemBars
      :cpu-avg-total="cpuAvgTotal"
      :cpu-peak-total="cpuPeakTotal"
      :mem-avg-total="memAvgTotal"
      :mem-peak-total="memPeakTotal"
      :total-cpu="totalCpu"
      :total-mem="totalMem"
      :total-disk="totalDisk"
      :used-disk="usedDisk"
    />

    <OsSplitPie :os-split="osSplit" />
    <WinSplitPie :os-split="winSplit" />
    <DatabaseSplitPie :os-split="dbSplit" />
    <AppCounts :os-split="appCounts" />

  </div>
</template>

<script setup>
import { watch, ref } from 'vue'
import axios from 'axios'
import CpuMemBars from "@/components/portfolio/CpuMemBars.vue";
import OsSplitPie from "@/components/portfolio/OsSplitPie.vue";
import WinSplitPie from "@/components/portfolio/WinSplitPie.vue";
import DatabaseSplitPie from "@/components/portfolio/DatabaseSplitPie.vue";
import AppCounts from "@/components/portfolio/AppCounts.vue";

const props = defineProps({
  selectedPortfolio: {
    type: String,
    required: true,
  },
})

const cpuAvgTotal = ref(0)
const cpuPeakTotal = ref(0)
const memAvgTotal = ref(0)
const memPeakTotal = ref(0)
const totalCpu = ref(0)
const totalMem = ref(0)
const totalDisk = ref(0)
const usedDisk = ref(0)

const osSplit = ref([])
const winSplit = ref([])
const dbSplit = ref([])
const appCounts = ref([])

watch(() => props.selectedPortfolio, async (newVal) => {
  if (newVal) {
    try {
      const res = await axios.get('/go/summarystats', {
        params: { portfolio: newVal }
      })

      const cpuMem = res.data.cpu_mem

      cpuAvgTotal.value = cpuMem.cpu_avg_total
      cpuPeakTotal.value = cpuMem.cpu_peak_total
      memAvgTotal.value = cpuMem.mem_avg_total
      memPeakTotal.value = cpuMem.mem_peak_total
      totalCpu.value = cpuMem.total_cpu
      totalMem.value = cpuMem.total_mem
      totalDisk.value = cpuMem.total_disk
      usedDisk.value = cpuMem.used_disk

      osSplit.value = res.data.os_split || []
      winSplit.value = res.data.win_split || []
      dbSplit.value = res.data.db_split || []
      appCounts.value = res.data.app_counts || []

      console.log('Summary stats:', res.data)
    } catch (err) {
      console.error('Error fetching summary stats:', err)
    }
  }
}, { immediate: true })
</script>

<style scoped>
.summary-placeholder {
  width: 1050px;
  height: 275px;
  display: flex;
  justify-content: left;
  align-items: center;
  border: 1px solid #2f353d;
  background: #0d1117;
  border-radius: 4px;
  margin-top: 10px;
}
</style>