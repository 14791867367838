<template>
  <div v-if="show" class="modal-overlay">
    <div class="modal-content">
      <div class="header">User Password</div>
      <div class="modal-body">
        <div class="modal-label"><b>Warning:</b> This password will not be shown again.</div>
        <div class="password-container">
          <input
            :value="password"
            class="modal-input"
            readonly
            @click="copyToClipboard"
          />
          <button @click="copyToClipboard" class="modal-button copy-button">
            Copy
          </button>
        </div>
      </div>
      <div class="modal-actions">
        <button @click="$emit('close')" class="modal-button modal-close">Close</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  password: {
    type: String,
    required: true
  }
});

const show = ref(true);

const copyToClipboard = () => {
  navigator.clipboard.writeText(props.password);
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00000085;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 100;
  padding-top: calc(100vh / 4);
}

.modal-content {
  background-color: #06080A;
  padding: 15px 20px;
  border: 1px solid #2f353d;
  border-radius: 4px;
  width: 400px;
  max-width: 90vw;
  box-shadow: 0 0 58px #ffffff08;
}

.header {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 15px;
  width: 100%;
  background: #0d1117;
  margin-top: -15px;
  margin-left: -20px;
  padding: 12px 19px 12px 21px;
  border-radius: 3px;
  border-bottom: 1px solid #2f353d;
}

.modal-body {
  display: flex;
  flex-direction: column;
}

.modal-label {
  display: block;
  margin-bottom: 10px;
  margin-top: 5px;
  font-size: 12px;
  color: #d4d4d4;
}

.password-container {
  display: flex;
  align-items: center;
  height: 45px
}

.modal-input {
  flex: 1;
  background-color: #0D1117;
  border: 1px solid #2f353d;
  color: #d9d9d9;
  font-size: 12px;
  padding: 6px 10px;
  border-radius: 4px 0 0 4px;
  width: 100%;
  outline: none;
}

.modal-input:focus {
  outline: none;
}

.copy-button {
  border: 1px solid #2f353d;
  margin-left: -2px;
  padding: 0px 14px 1px;
  margin-top: 20px;
  height: 32px;
  border-radius: 0 4px 4px 0;
  background: #d05c00;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 15px;
}

.modal-close {
  background-color: #1d232d;
  color: #fff;
  border: 1px solid #2f353d;
  margin-left: 13px;
  padding: 6px 12px;
  margin-top: 5px;
  margin-bottom: 0px;
}

.warning {
  color: #ff4d4f;
  font-size: 12px;
  margin-top: 10px;
}
</style>