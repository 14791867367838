
<template>
  <div class="outer-container">
    <div style="display: flex; align-items: center; vertical-align: middle; margin-bottom: 15px;">
      <div class="header" style="margin-bottom: 0px;">Instance Calculator</div>
    </div>

    <div style="display: flex; align-items: center; vertical-align: middle; margin-bottom: 15px;">

      <div class="input-container">
        <label for="region">Region</label>
        <select id="region" v-model="store.region" class="modal-input" style="width: 190px;">
          <option value="US West (Oregon)">US West (Oregon)</option>
          <option value="US West (N. California)">US West (N. California)</option>
          <option value="US East (Ohio)">US East (Ohio)</option>
          <option value="US East (N. Virginia)">US East (N. Virginia)</option>
          <option value="AWS GovCloud (US-West)">AWS GovCloud (US-West)</option>
          <option value="AWS GovCloud (US-East)">AWS GovCloud (US-East)</option>
          <option value="Mexico (Central)">Mexico (Central)</option>
          <option value="South America (Sao Paulo)">South America (Sao Paulo)</option>
          <option value="Middle East (UAE)">Middle East (UAE)</option>
          <option value="Middle East (Bahrain)">Middle East (Bahrain)</option>
          <option value="Israel (Tel Aviv)">Israel (Tel Aviv)</option>
          <option value="Europe (Zurich)">Europe (Zurich)</option>
          <option value="Europe (Stockholm)">Europe (Stockholm)</option>
          <option value="Europe (Spain)">Europe (Spain)</option>
          <option value="Europe (Paris)">Europe (Paris)</option>
          <option value="Europe (Milan)">Europe (Milan)</option>
          <option value="Europe (London)">Europe (London)</option>
          <option value="Europe (Ireland)">Europe (Ireland)</option>
          <option value="Europe (Frankfurt)">Europe (Frankfurt)</option>
          <option value="Canada West (Calgary)">Canada West (Calgary)</option>
          <option value="Canada (Central)">Canada (Central)</option>
          <option value="Asia Pacific (Tokyo)">Asia Pacific (Tokyo)</option>
          <option value="Asia Pacific (Sydney)">Asia Pacific (Sydney)</option>
          <option value="Asia Pacific (Singapore)">Asia Pacific (Singapore)</option>
          <option value="Asia Pacific (Seoul)">Asia Pacific (Seoul)</option>
          <option value="Asia Pacific (Osaka)">Asia Pacific (Osaka)</option>
          <option value="Asia Pacific (Mumbai)">Asia Pacific (Mumbai)</option>
          <option value="Asia Pacific (Melbourne)">Asia Pacific (Melbourne)</option>
          <option value="Asia Pacific (Malaysia)">Asia Pacific (Malaysia)</option>
          <option value="Asia Pacific (Thailand)">Asia Pacific (Thailand)</option>
          <option value="Asia Pacific (Jakarta)">Asia Pacific (Jakarta)</option>
          <option value="Asia Pacific (Hyderabad)">Asia Pacific (Hyderabad)</option>
          <option value="Asia Pacific (Hong Kong)">Asia Pacific (Hong Kong)</option>
          <option value="Africa (Cape Town)">Africa (Cape Town)</option>
        </select>
      </div>

      <div class="input-container" style="margin-left: 10px;">
        <label for="instance_type">Service</label>
        <select id="instance_type" v-model="store.instance_type" class="modal-input" style="width: 93px;">
          <option value="ec2">EC2</option>
          <option value="rds">RDS</option>
          <option value="rdsm">RDS mAZ</option>
        </select>
      </div>

      <div class="input-container" v-if="store.instance_type === 'ec2'" style="margin-left: 10px;">
        <label for="platform">Platform</label>
        <select id="platform" v-model="store.platform" class="modal-input" style="width: 140px;">
          <option value="lin">Linux</option>
          <option value="win">Windows</option>
        </select>
      </div>

      <div class="input-container" v-else-if="store.instance_type !== 'ec2'" style="margin-left: 10px;">
        <label for="db_type">Database</label>
        <select id="db_type" v-model="store.db_type" class="modal-input" style="width: 140px;">
          <option value="postgres">Postgres</option>
          <option value="mariadb">MySQL / MariaDB</option>
          <option value="sqlservr">SQL Server</option>
          <option value="oracle">Oracle</option>
          <option value="db2">DB2</option>
        </select>
      </div>

      <div class="input-container"
           v-if="store.instance_type !== 'ec2' && (store.db_type === 'sqlservr' || store.db_type === 'oracle' || store.db_type === 'db2')"
           style="margin-left: 10px;"
      >
        <label for="engine_type">Edition</label>
        <select id="engine_type" v-model="store.db_edition" class="modal-input" style="width: 120px;">
          <option v-if="store.db_type === 'sqlservr' && store.instance_type === 'rds'" value="Express">Express</option>
          <option v-if="store.db_type === 'sqlservr' && store.instance_type === 'rds'" value="Web">Web</option>
          <option v-if="store.db_type === 'sqlservr' || store.db_type === 'db2'" value="Standard">Standard</option>
          <option v-if="store.db_type === 'oracle'" value="Standard Two">Standard Two</option>
          <option v-if="store.db_type === 'sqlservr' || store.db_type === 'oracle' || store.db_type === 'db2'" value="Enterprise">Enterprise</option>
        </select>
      </div>

      <div class="input-container" style="margin-left: 10px;"
        v-if="store.instance_type === 'ec2'">
        <label for="free_text">Graviton</label>

        <select id="savings_plan" v-model="store.graviton" class="modal-input" style="width: 80px;">
          <option :value="false">No</option>
          <option :value="true">Yes</option>
        </select>
      </div>

      <div class="input-container" style="margin-left: 10px;">
        <label for="cpu">CPU</label>
        <div style="display: flex;">
          <input type="text" id="cpu" v-model="store.cpu" class="cpu-mem" style="width: 40px;" spellcheck="false" autocomplete="off"/>
          <CrossIcon style="cursor: pointer; margin-left: -22px; margin-top: 8px; color: #858585;" @click="clearCpu" />
        </div>
      </div>

      <div class="input-container" style="margin-left: 17px;">
        <label for="mem">Memory</label>
        <div style="display: flex;">
          <input type="text" id="mem" v-model="store.mem" class="cpu-mem" style="width: 40px;" spellcheck="false" autocomplete="off"/>
          <CrossIcon style="cursor: pointer; margin-left: -22px; margin-top: 8px; color: #858585;" @click="clearMem" />
        </div>
      </div>

      <div class="input-container" style="margin-left: 17px;">
        <label for="free_text">Text Search</label>

        <div style="display: flex;">
          <input type="text" id="free_text" v-model="store.free_text" style="width: 160px;" class="cpu-mem" spellcheck="false" autocomplete="off"/>
          <CrossIcon style="cursor: pointer; margin-left: -22px; margin-top: 8px; color: #858585;" @click="clearFree" />
        </div>
      </div>

      <div class="input-container" style="margin-left: 17px;">
        <label for="free_text">Savings Type</label>

        <select id="savings_plan" v-model="store.savings_plan" class="modal-input" style="width: 120px;">
          <option value="">None</option>

          <option v-if="store.instance_type === 'ec2'" value="csp_1nu">1y NU CSP</option>
          <option v-if="store.instance_type === 'ec2'" value="csp_1pu">1y PU CSP</option>
          <option v-if="store.instance_type === 'ec2'" value="csp_1au">1y AU CSP</option>
          <option v-if="store.instance_type === 'ec2'" value="csp_3nu">3y NU CSP</option>
          <option v-if="store.instance_type === 'ec2'" value="csp_3pu">3y PU CSP</option>
          <option v-if="store.instance_type === 'ec2'" value="csp_3au">3y AU CSP</option>

          <option v-if="store.instance_type === 'ec2'" value="ec2_1nu">1y NU EIP</option>
          <option v-if="store.instance_type === 'ec2'" value="ec2_1pu">1y PU EIP</option>
          <option v-if="store.instance_type === 'ec2'" value="ec2_1au">1y AU EIP</option>
          <option v-if="store.instance_type === 'ec2'" value="ec2_3nu">3y NU EIP</option>
          <option v-if="store.instance_type === 'ec2'" value="ec2_3pu">3y PU EIP</option>
          <option v-if="store.instance_type === 'ec2'" value="ec2_3au">3y AU EIP</option>

          <option v-if="store.instance_type !== 'ec2'" value="ri_1nu">1y NURI</option>
          <option v-if="store.instance_type !== 'ec2'" value="ri_1pu">1y PURI</option>
          <option v-if="store.instance_type !== 'ec2'" value="ri_1au">1y AURI</option>
          <option v-if="store.instance_type !== 'ec2'" value="ri_3pu">3y PURI</option>
          <option v-if="store.instance_type !== 'ec2'" value="ri_3au">3y AURI</option>

        </select>

      </div>

    </div>

    <div class="discovery-table-container">
     <table>
      <thead>
        <tr>
          <th style="font-weight: 600; width: 150px; padding-left: 10px;">Instance</th>
          <th style="font-weight: 600; width: 40px; text-align: center;">CPU</th>
          <th style="font-weight: 600; width: 40px; text-align: center;">Mem</th>
          <th style="font-weight: 600; width: 60px; text-align: center;">Vendor</th>
          <th style="font-weight: 600; width: 170px;">Processor</th>
          <th style="font-weight: 600; width: 90px;">Clock</th>
          <th style="font-weight: 600; width: 90px;">Released</th>
          <th style="font-weight: 600; width: 100px;">NVMe</th>
          <th style="font-weight: 600; width: 90px;">Net Mbps</th>
          <th style="font-weight: 600; width: 90px;">EBS IOPS/MiBs</th>
          <th style="font-weight: 600; width: 85px; text-align: right;"> {{ "Savings ($)" }} </th>
          <th style="font-weight: 600; width: 85px; text-align: right; padding-right: 10px;">On Demand ($)</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(instance, index) in paginatedMonitoring"
          :key="index">
          <td style="padding-left: 10px;">{{ instance.instance }}</td>
          <td style="text-align: center;">{{ instance.cpu }}</td>
          <td style="text-align: center;">{{ instance.mem }}</td>
          <td style="text-align: center;">{{ instance.vendor }}</td>
          <td>{{ instance.processor }}</td>
          <td>{{ instance.clockspeed }}</td>
          <td>{{ instance.released }}</td>
          <td>{{ instance.storage }}</td>
          <td>{{ instance.baseline_net_mbs === 0 ? "" : new Intl.NumberFormat().format(instance.baseline_net_mbs) }}</td>
          <td>{{ new Intl.NumberFormat().format(Math.round(instance.baseline_iops)) + ' / ' + new Intl.NumberFormat().format(Math.round(instance.baseline_mbs)) }}</td>
          <td style="text-align: right;"> {{ formatPrice(instance.discount) }}</td>
          <td style="text-align: right; color: grey; padding-right: 10px;">{{ formatPrice(instance.price) }}</td>
        </tr>
      </tbody>
     </table>
    </div>

    <div v-if="paginatedMonitoring?.length > 0" class="pagination-controls">
      <a href="#" @click.prevent="prevPage" :class="{ disabled: currentPage === 1 }" style="width: 90px;">Previous</a>
      <span style="margin-left: 15px; margin-right: 15px;">Page {{ currentPage }} of {{ totalPages }}</span>
      <a href="#" @click.prevent="nextPage" :class="{ disabled: currentPage === totalPages }" style="width: 90px;">Next</a>
    </div>

  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import axios from "axios";
import { useCalculatorStore } from "@/calculator/calculatorStore";
import CrossIcon from "@/components/icons/CrossIcon.vue";
import {usePortfolioStore} from "@/stores/DataStore";

const store = useCalculatorStore();

const currentPage = ref(1);
const itemsPerPage = 25;

let abortController = null;

const fetchData = async () => {
  if (abortController) {
    abortController.abort();
  }

  abortController = new AbortController();
  const { signal } = abortController;

  try {
    const response = await axios.post(
      "/go/calculator",
      {
        region: store.region,
        instance_type: store.instance_type,
        platform: store.platform,
        cpu: Number(store.cpu),
        mem: Number(store.mem),
        free_text: store.free_text.trim(),
        db_type: store.db_type,
        db_engine: store.db_edition,
        savings: store.savings_plan,
        graviton: !!store.graviton,
      },
      { signal }
    );
    store.setResults(response.data.instances);
    currentPage.value = 1;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      console.error("Error fetching monitoring data:", error);
    }
  } finally {

    abortController = null;
  }
};

// always revert to lowest type
watch(
  () => store.db_type,
  (newType) => {
    store.db_edition = 'Standard';
    if (newType === 'oracle') {
      store.db_edition = 'Standard Two';
    }
  }
);

watch(
  () => [store.region, store.platform, store.cpu, store.mem, store.free_text, store.instance_type, store.db_type, store.db_edition, store.savings_plan, store.graviton],
  () => {
    currentPage.value = 1;
    fetchData();
  }
);

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
};

const clearFree = () => {
  store.free_text = "";
}

const clearCpu = () => {
  store.cpu = "";
};

const clearMem = () => {
  store.mem = "";
};

const portfolioStore = usePortfolioStore();

onMounted(async () => {
  await store.loadFromStorage();

  // set region to the region of our current portfolio
  let thisportfolio = portfolioStore.get?.find(p => p.portfolio === portfolioStore.getCurPortfolio);
  store.region = thisportfolio.aws_region

  if (!store.isLoaded) {
    fetchData(); // fetch data only if it's the first load
  }
});

const paginatedMonitoring = computed(() => {
  const startIndex = (currentPage.value - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  if (!Array.isArray(store.results)) {
    return [];
  }
  return store.results.slice(startIndex, endIndex);
});

const totalPages = computed(() => Math.ceil(store.results.length / itemsPerPage));

watch(
  () => store.instance_type,
  (newType) => {
    if (newType === 'ec2') {
      store.savings_plan = 'csp_1nu'; // default for EC2
    } else {
      store.savings_plan = 'ri_1nu'; // default for RDS/RDS mAZ
    }
  }
);

const formatPrice = (value) => {
  return Number(value).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

</script>

<style scoped>
.cpu-mem {
  background-color: #0d1117;
  border: 1px solid #2f353d;
  color: #d9d9d9;
  font-size: 12px;
  padding: 6px 27px 7px 9px;
  border-radius: 4px 4px 4px 4px;
  outline: none;
  width: 100px;
}

.modal-input {
  background-color: #0d1117;
  border: 1px solid #2f353d;
  color: #d9d9d9;
  font-size: 12px;
  padding: 5px 5px 6px 9px;
  border-radius: 4px 4px 4px 4px;
  outline: none;
  width: 265px;
}

.search-box:focus {
  border-color: #767b84;
}

.outer-container {
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: rgb(165,165,165) transparent;
  -ms-overflow-style: none; /* Edge, Internet Explorer */
}

/* Style the scrollbar for Webkit browsers (Chrome, Safari) */
.outer-container::-webkit-scrollbar {
  width: 6px;
}

.outer-container::-webkit-scrollbar-track {
  background: transparent;
}

.outer-container::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.header {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 10pt;
}


.discovery-table-container {
  width: 1195px;
  border: 1px solid #2f353d;
  border-radius: 2px;
  overflow: hidden;
}

.icon-cell > * {
  margin: auto;
}

.pagination-controls {
  margin-top: 15px;
  width: 1195px;
}

tr.retired-text td {
  color: #7E7E7E !important;
}

.header {
  font-size: 14px;
  font-weight: 700;
  margin-top: 6px;
  margin-bottom: 21px;
}

.instance-selector table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 3px;
}

th {
  text-align: left;
  padding: 1.5px 4px 2px;
  font-weight: normal;
  color: #d9d9d9;
}

td {
  text-align: left;
  padding: 1.5px 4px 2px;
  font-weight: normal;
  color: #d9d9d9;
  border-top: 1px solid #2F353D;
}

thead {
  background-color: #0d1117 !important;
}

th {
  cursor: default !important;
}

tr {
  background-color: #0D1117;
  height: 22px;
}

tr:hover {
  background-color: #262e3d;
}

.selected {
  background-color: #262e3d;
}

button {
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  margin-top: 13px;
  margin-bottom: 0px;
  margin-left: 890px;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

label {
  font-size: 11px;
  color: #d9d9d9;
  text-align: left;
  margin-left: 5px;
}
</style>