<template>
  <div v-if="show" class="modal-overlay">
    <div class="modal-content">
      <div class="header">{{ isEditing ? 'Edit Portfolio' : 'Add New Portfolio' }}</div>
      <div class="modal-body">
        <div class="modal-label">Portfolio ID</div>
        <input v-model="formData.portfolioId" spellcheck="false"
               id="portfolioId" type="text" class="modal-input"
               autocomplete="off" ref="portfolioIdInput"
               :disabled="isEditing" :class="{ 'disabled-input': isEditing }"
        />

        <div class="modal-label">Customer</div>
        <input v-model="formData.customer" id="customer" type="text" class="modal-input" autocomplete="off"/>

        <div class="modal-label">Description</div>
        <input
            v-model="formData.description"
            id="description"
            type="text"
            class="modal-input"
            autocomplete="off"
            @keydown.enter="submitForm"
        />

        <div class="modal-label">AWS Region</div>
        <select
          v-model="formData.aws_region"
          id="region"
          class="modal-input"
          style="width: 400px;"
          :disabled="isEditing"
          :class="{ 'disabled-input': isEditing }">
          <option value="US West (Oregon)">US West (Oregon)</option>
          <option value="US West (N. California)">US West (N. California)</option>
          <option value="US East (Ohio)">US East (Ohio)</option>
          <option value="US East (N. Virginia)">US East (N. Virginia)</option>
          <option value="AWS GovCloud (US-West)">AWS GovCloud (US-West)</option>
          <option value="AWS GovCloud (US-East)">AWS GovCloud (US-East)</option>
          <option value="Mexico (Central)">Mexico (Central)</option>
          <option value="South America (Sao Paulo)">South America (Sao Paulo)</option>
          <option value="Middle East (UAE)">Middle East (UAE)</option>
          <option value="Middle East (Bahrain)">Middle East (Bahrain)</option>
          <option value="Israel (Tel Aviv)">Israel (Tel Aviv)</option>
          <option value="Europe (Zurich)">Europe (Zurich)</option>
          <option value="Europe (Stockholm)">Europe (Stockholm)</option>
          <option value="Europe (Spain)">Europe (Spain)</option>
          <option value="Europe (Paris)">Europe (Paris)</option>
          <option value="Europe (Milan)">Europe (Milan)</option>
          <option value="Europe (London)">Europe (London)</option>
          <option value="Europe (Ireland)">Europe (Ireland)</option>
          <option value="Europe (Frankfurt)">Europe (Frankfurt)</option>
          <option value="Canada West (Calgary)">Canada West (Calgary)</option>
          <option value="Canada (Central)">Canada (Central)</option>
          <option value="Asia Pacific (Tokyo)">Asia Pacific (Tokyo)</option>
          <option value="Asia Pacific (Sydney)">Asia Pacific (Sydney)</option>
          <option value="Asia Pacific (Singapore)">Asia Pacific (Singapore)</option>
          <option value="Asia Pacific (Seoul)">Asia Pacific (Seoul)</option>
          <option value="Asia Pacific (Osaka)">Asia Pacific (Osaka)</option>
          <option value="Asia Pacific (Mumbai)">Asia Pacific (Mumbai)</option>
          <option value="Asia Pacific (Melbourne)">Asia Pacific (Melbourne)</option>
          <option value="Asia Pacific (Malaysia)">Asia Pacific (Malaysia)</option>
          <option value="Asia Pacific (Thailand)">Asia Pacific (Thailand)</option>
          <option value="Asia Pacific (Jakarta)">Asia Pacific (Jakarta)</option>
          <option value="Asia Pacific (Hyderabad)">Asia Pacific (Hyderabad)</option>
          <option value="Asia Pacific (Hong Kong)">Asia Pacific (Hong Kong)</option>
          <option value="Africa (Cape Town)">Africa (Cape Town)</option>
        </select>

        <div class="modal-label">Assessment Type</div>
        <select
          v-model="formData.type"
          id="region"
          class="modal-input"
          style="width: 400px;"
          :disabled="isEditing"
          :class="{ 'disabled-input': isEditing }">
          <option value="standard">Full Assessment</option>
          <option value="preassess">Pre-Assessment (Import Only)</option>
        </select>

        <div v-if="formData.type==='preassess'" style="display: flex; align-items: center; gap: 15px; margin-top: 15px;">
          <button
            style="font-weight: 400; color: #d5d5d5; background: #0d1117; height: 30px; padding: 0 15px; margin-bottom: -5px;"
            @click="triggerFileSelect"
          >
            Upload RVTools Export
          </button>
          <input ref="fileInput" type="file" @change="uploadFile" style="display: none" />

          <!-- show checkbox if file is uploaded -->
          <div v-if="uploaded" style="display: flex; align-items: center; margin-top: 3px;">
            <span style="color: #d5d5d5;">✅ File uploaded</span>
          </div>
        </div>

        <!--
        <div class="modal-label">Data Residency</div>
        <select
          v-model="formData.region"
          id="region"
          class="modal-input"
          style="width: 400px;"
          :disabled="isEditing"
          :class="{ 'disabled-input': isEditing }">
          <option value="United States">United States (Ohio)</option>
          <option value="Europe">Europe (Frankfurt)</option>
          <option value="Asia-Pacific">Asia-Pacific (Sydney)</option>
          <option value="Japan">Japan (Tokyo)</option>
        </select>
        -->

      </div>
      <div class="modal-actions">
        <div class="error-message">{{ errorMessage }}</div>
        <button @click="submitForm" class="modal-button">{{ isEditing ? 'Update' : 'Submit' }}</button>
        <button @click="closeModal" class="modal-button modal-close">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted } from 'vue';
import {usePortfolioStore} from "@/stores/DataStore";

const emit = defineEmits(['close', 'submit']);

const props = defineProps({
  isEditing: {
    type: Boolean,
    default: false
  },
  initialData: {
    type: Object,
    default: () => ({ portfolioId: '', customer: '', description: '', api_key: '', region: '', type: 'standard' })
  }
});

// modal visibility and error handling
const show = ref(true);
const errorMessage = ref('');
const formData = ref({
  portfolioId: '',
  customer: '',
  description: '',
  api_key: '',
  aws_region: 'Asia Pacific (Sydney)',
  type: 'standard'
});

// declare ref for portfolio ID input
const portfolioIdInput = ref(null);

let preedit_portfolio_id = "";

// initialize formData when editing and focus on portfolio ID input
onMounted(() => {
  if (props.isEditing) {
    formData.value = { ...props.initialData };

    // save actual portfolio_id, only display the orginal sans suffix
    preedit_portfolio_id = formData.value.portfolioId;
    const index = formData.value.portfolioId.indexOf('.');
    formData.value.portfolioId = index === -1 ? formData.value.portfolioId : formData.value.portfolioId.slice(0, index);
  }

  portfolioIdInput.value.focus();
});

const validateForm = () => {
  const alphaNumericRegex = /^[a-zA-Z0-9_-]+$/;

  formData.value.portfolioId = formData.value.portfolioId.trim();
  formData.value.customer = formData.value.customer.trim();
  formData.value.description = formData.value.description.trim();

  if (!formData.value.portfolioId || !formData.value.customer || !formData.value.description) {
    errorMessage.value = 'All fields must be entered.';
    return false;
  }

  // if formData.value.type is 'preassess', ensure that an RV Tools export has been uploaded
  if (formData.value.type === 'preassess' && (!uploadedUuid.value || uploadedUuid.value.trim() === '')) {
    errorMessage.value = 'Please upload an RV Tools export.';
    return false;
  }

  if (!formData.value.aws_region && !props.isEditing) {
    errorMessage.value = 'Please select a region.';
    return false;
  }

  // don't check portfolio id on editing
  if (!props.isEditing) {
    if (!alphaNumericRegex.test(formData.value.portfolioId)) {
      errorMessage.value = 'Portfolio ID can only contain alphanumeric chars, underscores, and hyphens.';
      return false;
    }
  }

  const portfolioStore = usePortfolioStore();
  const pickedPortfolioId = formData.value.portfolioId.trim();

  const portfolioExists = portfolioStore.get.some(port => {
    const portname = port.portfolio
    const basePortfolioId = portname.split('.')[0];
    return basePortfolioId === pickedPortfolioId;
  });

  if (portfolioExists) {
    errorMessage.value = 'Portfolio ID already exists.';
    return false;
  }

  errorMessage.value = '';
  return true;
};

const submitForm = () => {
  if (validateForm()) {
    closeModal();

    // trim to region only
    //if (formData.value.region.startsWith("United States")) formData.value.region = "United States"
    //if (formData.value.region.startsWith("Europe")) formData.value.region = "Europe"
    //if (formData.value.region.startsWith("Asia-Pacific")) formData.value.region = "Asia-Pacific"
    //if (formData.value.region.startsWith("Japan")) formData.value.region = "Japan"

    // restore full portfolio id + api key if editing
    if (props.isEditing) {
      formData.value.portfolioId = preedit_portfolio_id;
      formData.value.api_key = props.initialData.api_key;
    }

    // if formData.value.type is 'preassess', add .xlsx_uuid = uploadedUuid
    if (formData.value.type === 'preassess') {
      formData.value.xlsx_uuid = uploadedUuid.value;
    }

    emit('submit', formData.value);
  }
};

const closeModal = () => {
  show.value = false;
  emit('close');
};

const file = ref(null)
const uploaded = ref(false)

const fileInput = ref(null);

const triggerFileSelect = () => {
  if (fileInput.value) {
    fileInput.value.click();
  }
};

const uploadedUuid = ref(null);

const uploadFile = async (event) => {
  const selectedFile = event.target.files[0];

  if (!selectedFile) return;

  const formData = new FormData();
  formData.append('file', selectedFile);

  try {
    const response = await fetch('/go/portfolio/import', {
      method: 'POST',
      body: formData,
    });

    if (response.ok) {
      const data = await response.json();
      uploaded.value = true;
      uploadedUuid.value = data.uuid; // Save the returned UUID
    } else {
      uploaded.value = false;
    }
  } catch (error) {
    uploaded.value = false;
  }

  event.target.value = '';
};
</script>

<style scoped>
.header {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 15px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00000085;
  display: flex;
  justify-content: center;
  align-items: flex-start; /* align to the top */
  z-index: 100;
  padding-top: calc(100vh / 5);
}

.modal-content {
  background-color: #06080A;
  padding: 15px 20px 15px;
  border: 1px solid #2f353d;
  border-radius: 4px;
  width: 400px;
  max-width: 90vw;
  box-shadow: 0 0 58px #ffffff08;
}

.modal-input:focus {
  outline: none;
  box-shadow: none;
  border-color: #2f353d;
}

.modal-input {
  width: 358px;
  background-color: #0D1117;
  border: 1px solid #2f353d;
  color: #d9d9d9;
  font-size: 12px;
  padding: 6px 30px 7px 10px;
  border-radius: 4px 4px 4px 4px;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.modal-button {
  padding: 6px 12px;
  margin-top: 20px;
  margin-bottom: 0px;
}

.modal-close {
  background-color: #1d232d;
  color: #fff;
  border: 1px solid #2f353d;
  margin-left: 13px;
}

.modal-label {
  display: block;
  margin-bottom: 4px;
  margin-top: 10px;
  font-size: 12px;
  color: #d4d4d4;
}

.error-message {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff4d4f;
  font-size: 12px;
  font-weight: 600;
  margin-right: 0px;
  padding-right: 20px;
  width: 250px;
  height: 30px;
  margin-top: 15px;
  text-align: center;
}

.disabled-input {
  opacity: 0.5;
}
</style>