<template>
  <div class="strategy">

    <div class="strategy-label">Right-Sizing Recommendations</div>

    <div style="margin-top: 20px; margin-bottom: 15px;">AWS Region</div>

    <select id="region-dropdown" name="regions" class="dropdown" v-model="selectedRegion">
        <option value="US West (Oregon)">US West (Oregon)</option>
        <option value="US West (N. California)">US West (N. California)</option>
        <option value="US East (Ohio)">US East (Ohio)</option>
        <option value="US East (N. Virginia)">US East (N. Virginia)</option>
        <option value="AWS GovCloud (US-West)">AWS GovCloud (US-West)</option>
        <option value="AWS GovCloud (US-East)">AWS GovCloud (US-East)</option>
        <option value="Mexico (Central)">Mexico (Central)</option>
        <option value="South America (Sao Paulo)">South America (Sao Paulo)</option>
        <option value="Middle East (UAE)">Middle East (UAE)</option>
        <option value="Middle East (Bahrain)">Middle East (Bahrain)</option>
        <option value="Israel (Tel Aviv)">Israel (Tel Aviv)</option>
        <option value="Europe (Zurich)">Europe (Zurich)</option>
        <option value="Europe (Stockholm)">Europe (Stockholm)</option>
        <option value="Europe (Spain)">Europe (Spain)</option>
        <option value="Europe (Paris)">Europe (Paris)</option>
        <option value="Europe (Milan)">Europe (Milan)</option>
        <option value="Europe (London)">Europe (London)</option>
        <option value="Europe (Ireland)">Europe (Ireland)</option>
        <option value="Europe (Frankfurt)">Europe (Frankfurt)</option>
        <option value="Canada West (Calgary)">Canada West (Calgary)</option>
        <option value="Canada (Central)">Canada (Central)</option>
        <option value="Asia Pacific (Tokyo)">Asia Pacific (Tokyo)</option>
        <option value="Asia Pacific (Sydney)">Asia Pacific (Sydney)</option>
        <option value="Asia Pacific (Singapore)">Asia Pacific (Singapore)</option>
        <option value="Asia Pacific (Seoul)">Asia Pacific (Seoul)</option>
        <option value="Asia Pacific (Osaka)">Asia Pacific (Osaka)</option>
        <option value="Asia Pacific (Mumbai)">Asia Pacific (Mumbai)</option>
        <option value="Asia Pacific (Melbourne)">Asia Pacific (Melbourne)</option>
        <option value="Asia Pacific (Malaysia)">Asia Pacific (Malaysia)</option>
        <option value="Asia Pacific (Thailand)">Asia Pacific (Thailand)</option>
        <option value="Asia Pacific (Jakarta)">Asia Pacific (Jakarta)</option>
        <option value="Asia Pacific (Hyderabad)">Asia Pacific (Hyderabad)</option>
        <option value="Asia Pacific (Hong Kong)">Asia Pacific (Hong Kong)</option>
        <option value="Africa (Cape Town)">Africa (Cape Town)</option>
    </select>

    <div style="margin-top: 20px; margin-bottom: 15px;">{{ userLocale.includes('en-US') || userLocale.includes('en-CA') ? 'Optimization' : 'Optimisation' }} Strategy</div>

    <div class="radio-buttons" style="user-select: none;">
      <label v-for="type in portfolioTypes" :key="type.value" :class="{ active: portfolioType === type.value }">
        <input type="radio" :value="type.value" v-model="portfolioType" />
        {{ type.label }}<span v-if="type.recommended" style="margin-left: 19px; color: rgb(160 160 160);">(Recommended)</span>
      </label>
    </div>

    <div v-if="portfolioObj">
      <p v-for="metric in metrics" :key="metric.label" style="display: flex; align-items: center; margin-bottom: 5px; color: #9a9a9a;">
        <span style="width: 40px; display: inline-block; color: #9a9a9a;">{{ metric.label }}:</span>
        {{ metric.formattedValue }}
        <span v-if="metric.icon" style="margin-left: 7px; color: #9a9a9a;">{{ metric.icon }}</span>
      </p>
    </div>

    <div style="margin-top: 20px; margin-bottom: 5px;">Performance Upscaling</div>
    <label for="discount-slider" style="display: block; font-style: italic; font-size: 10px; color: #9a9a9a;">Increase CPU/Mem if under {{ userLocale.includes('en-US') || userLocale.includes('en-CA') ? 'optimized' : 'optimised' }} </label>

    <div class="radio-buttons-row">
      <label :class="{ active: upscaling === false }">
        <input type="radio" :value="false" v-model="upscaling" /> Off
      </label>
      <label :class="{ active: upscaling === true }">
        <input type="radio" :value="true" v-model="upscaling" /> On
      </label>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { usePortfolioStore } from '@/stores/DataStore';
import axios from 'axios';

const emit = defineEmits(['updatePortfolio']);
const props = defineProps({ portfolio: { type: String, required: true } });

const userLocale = navigator.language || 'en';
const portfolioStore = usePortfolioStore();
const portfolios = computed(() => portfolioStore.get);
const portfolioObj = ref(null);
const portfolioType = ref(null);
const upscaling = ref(true)

// sync upscaling value from portfolioObj when it changes
watch(() => portfolioObj.value, (newPortfolio) => {
  if (newPortfolio) {
    upscaling.value = newPortfolio.upscaling ?? false;
    selectedRegion.value = newPortfolio.aws_region;
    updatePortfolioType();
  }
}, { immediate: true });

const portfolioTypes = [
  { value: 'balanced', label: 'Balanced', recommended: true },
  { value: 'aggressive', label: 'Aggressive' },
  { value: 'conservative', label: 'Conservative' }
];

const metrics = computed(() => [
  { label: 'CPU', formattedValue: `${formattedCpuPerc.value} + ${formattedCpuBuff.value}`, icon: arrow('cpu') },
  { label: 'Mem', formattedValue: `${formattedMemPerc.value} + ${portfolioObj.value.mem_buff}%`, icon: arrow('mem') }
]);

const arrow = (type) => {
  if (type === 'cpu' && portfolioType.value === 'aggressive') return '▾';
  if (type === 'cpu' && portfolioType.value === 'conservative') return '▴';
  if (type === 'mem' && portfolioType.value === 'conservative') return '▴';
  return '';
};

const formattedCpuPerc = computed(() => portfolioObj.value?.cpu_perc ? `p${portfolioObj.value.cpu_perc}` : '');
const formattedMemPerc = computed(() => portfolioObj.value?.mem_perc ? `p${portfolioObj.value.mem_perc}` : '');
const formattedCpuBuff = computed(() => portfolioObj.value?.cpu_buff ? `${portfolioObj.value.cpu_buff}%` : '');

const updatePortfolioType = () => {
  const { cpu_perc, cpu_buff } = portfolioObj.value;
  if (cpu_perc === 95 && cpu_buff === 20) portfolioType.value = 'balanced';
  else if (cpu_perc === 95 && cpu_buff === 10) portfolioType.value = 'aggressive';
  else if (cpu_perc === 99 && cpu_buff === 20) portfolioType.value = 'conservative';
  else portfolioType.value = null;
};

const selectedRegion = ref(portfolioObj.value?.aws_region || '');

// watch portfolio id
watch(() => props.portfolio, (newportfolio) => {
  portfolioObj.value = portfolios.value?.find(p => p.portfolio === newportfolio);

  if (portfolioObj.value) {
    selectedRegion.value = portfolioObj.value.aws_region;
    upscaling.value = portfolioObj.value?.upscaling ?? false;
    updatePortfolioType();
  }
}, { immediate: true });

watch(portfolioType, (newType) => {
  if (!portfolioObj.value) return;

  // only update server if there is a change (ie: if the button is clicked and the cpu_perc / type changes)
  const { cpu_perc, cpu_buff } = portfolioObj.value;
  if (newType === 'balanced' && (cpu_perc !== 95 || cpu_buff !== 20)) {
    updatePortfolio(95, 20, 95, 10);
  } else if (newType === 'aggressive' && (cpu_perc !== 95 || cpu_buff !== 10)) {
    updatePortfolio(95, 10, 95, 10);
  } else if (newType === 'conservative' && (cpu_perc !== 99 || cpu_buff !== 20)) {
    updatePortfolio(99, 20, 99, 10);
  }
});

async function updatePortfolio(cpuPerc, cpuBuff, memPerc, memBuff) {
  Object.assign(portfolioObj.value, { cpu_perc: cpuPerc, cpu_buff: cpuBuff, mem_perc: memPerc, mem_buff: memBuff });

  portfolioStore.updatePortfolio(props.portfolio, portfolioObj.value);

  emit('startLoadIcon');

  try {
    await axios.post(`/go/portfolio/strategy`, { cpu_perc: cpuPerc, mem_perc: memPerc, cpu_buff: cpuBuff, mem_buff: memBuff }, {
      headers: { 'Content-Type': 'application/json' }
    });
  } catch (error) {
    console.error("Error updating portfolio strategy:", error);
  }

  emit('updatePortfolio', { ...portfolioObj.value });
}

watch(selectedRegion, async (newRegion) => {
  Object.assign(portfolioObj.value, { aws_region: newRegion });

  emit('startLoadIcon');

  try {
    await axios.post(`/go/portfolio/region`, { aws_region: newRegion }, {
      headers: { 'Content-Type': 'application/json' }
    });
  } catch (error) {
    console.error("Error updating portfolio strategy:", error);
  }

  emit('updatePortfolio', { ...portfolioObj.value });

}, { immediate: false });

// update portfolioObj and server when upscaling changes
watch(upscaling, async (newUpscaling) => {
  if (portfolioObj.value) {
    portfolioObj.value.upscaling = newUpscaling; // locally
    portfolioStore.updatePortfolio(props.portfolio, portfolioObj.value); // persist to store

    emit('startLoadIcon');
    try {
      await axios.post(`/go/portfolio/upscaling`, { upscaling: newUpscaling }, {
        headers: { 'Content-Type': 'application/json' }
      });
    } catch (error) {
      console.error("Error updating upscaling:", error);
    }
    emit('updatePortfolio', { ...portfolioObj.value });
  }
}, { immediate: false });

</script>

<style scoped>
.radio-buttons {
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  border-color: #2f353d;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  background-color: #0d1117;
  padding: .25rem;
  width: 210px;
  margin-bottom: 14px;
  box-shadow: 0 0 0 1px #0000000f;
}

.radio-buttons label {
  display: flex;
  padding-left: 10px;
  align-items: center;
  background-color: #0d1117;
  color:  #e4e4e4;
  padding-top: 4px;
  padding-bottom: 5px;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid transparent;
}

.radio-buttons label.active {
  background-color: #384459 !important;
  border: 1px solid transparent;
  color: #e4e4e4;
}

.radio-buttons input[type="radio"] {
  display: none;
}

.radio-buttons-row {
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  border-color: #2f353d;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  background-color: #0d1117;
  padding: .25rem;
  width: 205px;
  margin-bottom: 14px;
  box-shadow: 0 0 0 1px #0000000f;
}

.radio-buttons-row label {
  display: flex;
  justify-content: center;
  background-color: #0d1117;
  color:  #e4e4e4;
  padding-top: 3px;
  padding-bottom: 4px;
  width: 120px;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid transparent;
}

.radio-buttons-row label.active {
  background-color: #384459 !important;
  border: 1px solid transparent;
  color: #e4e4e4;
}

.radio-buttons-row input[type="radio"] {
  display: none;
}

.strategy-label {
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 500;
}

.strategy {
  width: 215px;
  margin-left: 15px;
}

.dropdown:focus {
  outline: none;
  box-shadow: none;
  border-color: #2f353d;
}

.dropdown {
  width: 220px;
  background-color: #0D1117;
  border: 1px solid #2f353d;
  color: #d9d9d9;
  font-size: 12px;
  padding: 6px 30px 7px 10px;
  border-radius: 4px 4px 4px 4px;
}

</style>