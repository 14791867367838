<template>

  <!-- background container, use absolute positioning -->
  <div style="position: absolute; top: 83px; left: 25px; width: 1195px; height: 239px; z-index: -1; background: rgb(13, 17, 23); border: 1px solid rgb(47, 53, 61); border-radius: 4px;"></div>

  <!-- UUID -->
  <div style="position: absolute; top: 340px; left: 30px; color: #606060;">
    <div><span style="font-weight: 600;">UUID:</span> {{ props.uuid }}</div>
  </div>

  <div class="sys-info-container" style="display: grid; grid-template-columns: 280px 1px 275px 1px 600px; align-items: start;">

    <!-- Column 1 -->
    <div style="margin-left: 20px; display: flex; flex-direction: column; gap: 10px;">

      <div style="margin-left: -10px; display: flex; margin-bottom: 28px;">
        <div style="font-weight: 600; width: 110px;">System</div>
      </div>

      <div style="display: flex;">
        <div style="font-weight: 600; width: 110px; margin-left: 5px;">Platform</div>
        <div>{{ getOS() }}</div>
      </div>

      <div style="display: flex;">
        <div style="font-weight: 600; width: 110px; margin-left: 5px;">Host</div>
        <div>{{ getHost() }}</div>
      </div>

      <div style="display: flex;">
        <div style="font-weight: 600; width: 110px; margin-left: 5px;">Primary IP</div>
        <div>{{ getIP() }}</div>
      </div>

      <div style="display: flex; margin-bottom: 10px;">
        <div style="font-weight: 600; width: 110px; margin-left: 5px;">Uptime</div>
        <div>{{ formatUptime }}</div>
      </div>

      <div style="display: flex;">
        <div style="font-weight: 600; width: 110px; margin-left: 5px;">First Seen</div>
        <div>{{ formatDay(sysInfo.first_seen) }}</div>
      </div>

      <div style="display: flex;">
        <div style="font-weight: 600; width: 110px; margin-left: 5px;">Last Seen</div>
        <div>{{ formatDay(sysInfo.updated) }}</div>
      </div>

      <div style="display: flex;">
        <div style="font-weight: 600; width: 110px; margin-left: 5px;">Period</div>
        <div>{{ fromTo(sysInfo.first_seen, sysInfo.updated) }}</div>
      </div>
    </div>

    <!--- barrier -->
    <div class="barrier" style="height: 210px; width: 1px; background-color: #2f353d; margin-top: 40px;"></div>

    <!-- Column 2 -->
    <div style="margin-left: 20px; display: flex; flex-direction: column; gap: 10px;">

      <div style="margin-left: -12px; display: flex; margin-bottom: 28px;">
        <div style="font-weight: 600; width: 100px;">Specifications</div>
      </div>

      <div style="display: flex;">
        <div style="font-weight: 600; width: 90px;">Processor</div>
        <div>{{ getCPUType() }}</div>
      </div>

      <div style="display: flex;">
        <div style="font-weight: 600; width: 90px;">Arch</div>
        <div>{{ getArch() }}</div>
      </div>

       <!--
      <div style="display: flex; margin-bottom: 10px;">
        <div style="font-weight: 600; width: 100px;">Clock Speed</div>
        <div>{{ getCPUSpeed }}<span style="color: grey;" v-if="getCPUSpeed !== ''"> Ghz</span></div>
      </div>
      -->

      <div style="display: flex; align-items: center;  margin-top: 10px;">
        <div style="font-weight: 600; width: 90px;">CPU</div>
        <div style="width: 75px;">{{ sysInfo.vcpu }}</div>
        <SquareBoxChart :count="sysInfo.vcpu" :percentage="sysInfo.cpu_p99" />
      </div>

      <div style="display: flex; align-items: center;">
        <div style="font-weight: 600; width: 90px;">Mem</div>
        <div style="width: 75px;">{{ sysInfo.mem_gb }} <span style="color: grey;">GiB</span></div>
        <SquareBoxChart :count="sysInfo.mem_gb" :percentage="sysInfo.mem_p99" />
      </div>

      <div style="display: flex; margin-bottom: 10px;">
        <div style="font-weight: 600; width: 90px;">Storage</div>
        <div style="width: 75px;">{{ getDisk() }} <span style="color: grey;">GiB</span></div>
        <SquareBoxChart :count="props.sysInfo.totaldisk / 1024.0 / 1024.0 / 20.0" :percentage="(sysInfo.useddisk / sysInfo.totaldisk) * 100" />
      </div>

    </div>

    <!--- barrier -->
    <div class="barrier" style="height: 210px; width: 1px; background-color: #2f353d; margin-top: 40px;"></div>

    <!-- Column 4 -->
    <div style="margin-left: 20px; display: flex; flex-direction: column; gap: 3px;">

      <div style="margin-left: -5px; display: flex; font-weight: 600; width: 200px; margin-bottom: 33px;">Disk Partitions / Network Interfaces</div>

       <!-- Disk Partitions -->
      <div style="min-height: 80px;">
        <div style="display: flex; flex-direction: row; gap: 5px; font-weight: 600; margin-bottom: 5px;">
          <div style="width: 160px;">Name</div>
          <div style="width: 70px; text-align: left;">Used</div>
          <div style="width: 70px; text-align: left;">Size</div>
          <div style="width: 70px; text-align: left;">IOPs</div>
          <div style="width: 70px; text-align: left;">MiB/s</div>
          <div style="width: 100px; margin-left: 10px;">Mount</div>
        </div>

        <div
          v-for="(disk, index) in props.sysInfo.disks"
          :key="index"
          style="display: flex; flex-direction: row; gap: 5px;"
        >
          <div style="width: 160px;">{{ disk.device }}</div>
          <div style="width: 70px; text-align: left;">{{ getGB(disk.usedkb) }} <span style="color: grey;">{{ getMBorGB(disk.usedkb) }}</span></div>
          <div style="width: 70px; text-align: left;">{{ getGB(disk.totalkb) }} <span style="color: grey;">{{ getMBorGB(disk.totalkb) }}</span></div>
          <div style="width: 70px; text-align: left;">{{ disk.iops.toFixed(0) }}</div>
          <div style="width: 70px; text-align: left;">{{ (disk.mbps / 1024).toFixed(0) }}</div>
          <div style="width: 100px; margin-left: 10px;">{{ disk.mountpoint }}</div>
        </div>
      </div>

      <div>

        <!-- Network Interfaces -->
        <div style="display: flex; flex-direction: row; gap: 5px; font-weight: 600; margin-top: 10px; margin-bottom: 5px;">
          <div style="width: 160px;">Name</div>
          <div style="width: 130px;">MAC</div>
          <div style="width: 60px;">MTU</div>
          <div style="flex-grow: 1;">IPv4</div>
        </div>

        <div
          v-for="(iface, index) in props.sysInfo.interfaces"
          :key="index"
          style="display: flex; flex-direction: row; gap: 5px;"
        >
          <div style="width: 160px;">{{ iface.name }}</div>
          <div style="width: 130px;">{{ iface.hardware_addr }}</div>
          <div style="width: 60px;">{{ iface.mtu }}</div>
          <div style="flex-grow: 1;">
            <span v-for="(ip, ipIndex) in iface.ipv4_addrs" :key="ipIndex" style="display: block;">
              {{ ip }}
            </span>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script setup>
import SquareBoxChart from "@/components/discovery/BoxGraphic.vue";
import { computed, defineProps } from 'vue';

const props = defineProps({
  sysInfo: {
    type: Object,
    required: true
  },
  uuid: {
    type: String,
    required: true
  }
});

// / 1024 / 1024
const getGB = (kb) => {
  let gb = Math.floor(kb / 1024 / 1024);
  if (gb > 0) return gb.toFixed(0);

  return Math.floor(kb / 1024);
}

const getMBorGB = (kb) => {
  let gb = Math.floor(kb / 1024 / 1024);
  if (gb > 0) return "GiB";
  return "MiB";
}

const getHost = () => {
  let host = props.sysInfo.host;

  // remove for AWS instance testing
  const matchPattern = /\.([a-z0-9-]+)\.compute\.internal$/;
  if (host.match(matchPattern)) {
    return host.replace(matchPattern, "");
  }

  return props.sysInfo.host;
}

const getIP = () => {
  let ip = props.sysInfo.primary_ip;

  if (ip && ip.endsWith("/32")) {
    ip = ip.replace("/32", "");
    return ip;
  }

  if (!ip && props.sysInfo.ips && props.sysInfo.ips.length > 0) {
    return props.sysInfo.ips[0];
  }

  return ip;
}

const getOS = () => {
  if (!props.sysInfo.platform) return ""; // imported only

  let os = props.sysInfo.platform;

  if (props.sysInfo.os === "windows") {

    if (!os.includes("Windows")) {
      os = "Windows " + os;
    }

    return os;
  }

  let version = props.sysInfo.version;

  switch (os.toLowerCase()) {
    case "amazon":
      return "Amazon Linux " + (version.length >= 4 ? version.slice(0, 4) : version);
    case "debian":
      return "Debian " + version;
    case "ubuntu":
      return "Ubuntu " + version;
    case "redhat":
      return "RedHat Linux " + version;
    case "alpine":
      return "Alpine Linux " + version;
    case "sles":
      return "SUSE Linux " + version;
    case "photon":
      return "Photon " + version;
    default:
      return os.charAt(0).toUpperCase() + os.slice(1) + " " + version;
  }
}

const getCPUType = () => {
  let cpu = props.sysInfo.cpu_type;
  if (cpu==="") return "Undefined";

  // remove cpu speed if it is there
  const match = cpu.match(/@ ([\d.]+)GHz$/);
  if (match) cpu = cpu.split('@')[0].trim();
  cpu = cpu.replace(" Processor", "");
  cpu = cpu.replace(" CPU", "");
  cpu = cpu.replace(/\(R\)/g, "");
  cpu = cpu.replace(/\(TM\)/g, "");
  cpu = cpu.replace(/\s+/g, " ").trim();
  return cpu;
}

const getArch = () => {
  if (props.sysInfo.arch==="x86_32") return "x86 32";
  if (props.sysInfo.arch==="x86_64") return "x86 64";
  if (props.sysInfo.arch==="aarch64") return "ARM";
  if (props.sysInfo.arch==="ppc64") return "PowerPC 64";

  return props.sysInfo.arch
}

const getDisk = () => {
  let totaldisk = props.sysInfo.totaldisk;
  totaldisk = totaldisk / 1024.0 / 1024.0;
  return Math.round(totaldisk).toLocaleString();
}

const fromTo = (from, to) => {
  if (!from || !to) return "";

  const fromDate = new Date(from);
  const toDate = new Date(to);

  if (fromDate >= toDate) return "";

  const seconds = Math.floor((toDate - fromDate) / 1000);

  if (seconds <= 0) return "0 seconds";

  const years = Math.floor(seconds / (365 * 24 * 60 * 60));
  const months = Math.floor((seconds % (365 * 24 * 60 * 60)) / (30 * 24 * 60 * 60));
  const days = Math.floor((seconds % (30 * 24 * 60 * 60)) / (24 * 60 * 60));
  const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
  const minutes = Math.floor((seconds % (60 * 60)) / 60);
  const secs = seconds % 60;

  const parts = [
    { value: years, label: "year" },
    { value: months, label: "month" },
    { value: days, label: "day" },
    { value: hours, label: "hour" },
    { value: minutes, label: "minute" },
    { value: secs, label: "second" },
  ];

  // filter non-zero parts and map to strings with singular/plural labels
  const nonZeroParts = parts.filter((part) => part.value > 0)
    .map((part) => `${part.value} ${part.label}${part.value > 1 ? "s" : ""}`);

  // return the highest non-zero value, or "0 seconds" if no difference
  return nonZeroParts.length > 0
    ? nonZeroParts.slice(0, 1).join(", ")
    : "0 seconds";
};

const formatDay = (timestamp, locale = navigator.language) => {

  if (!timestamp) return "";
  const date = new Date(timestamp);

  const today = new Date();
  const isToday =
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate();

  if (isToday) {
    return "Today";
  }

  return new Intl.DateTimeFormat(locale, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(date);
};

const formatUptime = computed(() => {
  const seconds = Number(props.sysInfo.uptime);

  if (!seconds || seconds === 0) return "";

  const years = Math.floor(seconds / (365 * 24 * 60 * 60));
  const months = Math.floor((seconds % (365 * 24 * 60 * 60)) / (30 * 24 * 60 * 60));
  const days = Math.floor((seconds % (30 * 24 * 60 * 60)) / (24 * 60 * 60));
  const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
  const minutes = Math.floor((seconds % (60 * 60)) / 60);
  const secs = seconds % 60;

  const parts = [
    { value: years, label: "year" },
    { value: months, label: "month" },
    { value: days, label: "day" },
    { value: hours, label: "hour" },
    { value: minutes, label: "minute" },
    { value: secs, label: "second" },
  ];

  // filter non-zero parts and map to strings with singular/plural labels
  const nonZeroParts = parts.filter((part) => part.value > 0)
    .map((part) => `${part.value} ${part.label}${part.value > 1 ? "s" : ""}`);

  // return the two highest non-zero values, or "0 seconds" if no uptime
  return nonZeroParts.length > 0
    ? nonZeroParts.slice(0, 1).join(", ")
    : "";
});
</script>

<style scoped>

.sys-info-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 0px;
}

</style>