<template>
  <div class="modal-overlay">
    <div class="modal-content">
      <div class="header">Raw Uploads</div>
      <div class="modal-body">
        Displaying the last 5x unique records received for the current portfolio.
        <div class="data-box" style="margin-top: 10px;">
          <pre>{{ JSON.stringify(rawData, null, 2) }}</pre>
        </div>
      </div>
      <div class="modal-actions">
        <div class="error-message">{{ errorMessage }}</div>
        <button @click="closeModal" class="modal-button modal-close">Close</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, ref, onMounted } from 'vue';
import axios from 'axios';

const emit = defineEmits(['close']);
const rawData = ref([]);
const errorMessage = ref('');

onMounted(async () => {
  try {
    const res = await axios.get('/go/rawdata');
    rawData.value = res.data.map(item => JSON.parse(item));

    rawData.value = res.data.map(item => {
      const parsed = JSON.parse(item);
      const cleanObject = obj => {
        if (typeof obj !== 'object' || obj === null) return obj;
        if (Array.isArray(obj)) return obj.map(cleanObject);
        return Object.entries(obj).reduce((acc, [key, val]) => {
          if (val !== "" && val !== null) acc[key] = cleanObject(val);
          return acc;
        }, {});
      };
      return cleanObject(parsed);
    });

  } catch (error) {
    errorMessage.value = 'Failed to load data.';
    console.error(error);
  }
});

const closeModal = () => {
  emit('close');
};
</script>

<style scoped>
.header {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 15px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00000085;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 100;
  padding-top: calc(100vh / 7);
  text-align: left;
}

.modal-content {
  background-color: #06080A;
  padding: 15px 20px 15px;
  border: 1px solid #2f353d;
  border-radius: 4px;
  width: 550px;
  box-shadow: 0 0 58px #ffffff08;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.modal-button {
  padding: 6px 12px;
  margin-top: 20px;
  margin-bottom: 0px;
}

.modal-close {
  background-color: #1d232d;
  color: #fff;
  border: 1px solid #2f353d;
  margin-left: 13px;
}

.error-message {
  color: #ff4d4f;
  font-size: 12px;
  font-weight: 600;
  margin-top: 15px;
  padding-right: 20px;
  width: 250px;
  height: 30px;
  text-align: center;
}

.data-box {
  margin-top: 10px;
  height: 400px;
  max-height: 400px;
  overflow-y: auto;
  background-color: #1d232d;
  border: 1px solid #2f353d;
  border-radius: 4px;
  padding: 10px;
}

.data-box pre {
  color: #fff;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0;
}

.data-box::-webkit-scrollbar {
  width: 8px;
}

.data-box::-webkit-scrollbar-thumb {
  background-color: #D0D0D0;
  border-radius: 2px;
}

.data-box::-webkit-scrollbar-track {
  background: #1d232d;
}

</style>