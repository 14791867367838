<template>
  <div v-if="paginatedMonitoring.length > 0" class="outer-container">
    <div style="font-weight: 600; margin-bottom: 8px;">SQL Server <span style="color:grey;">(Note: RDS combines SQL Server and Windows Licensing)</span></div>
    <div style="margin-bottom: 20px;">
      <div class="monitoring-table-container">
      <table class="monitoring-table" ref="monitoringTable">
       <tr>
          <th class="header-cell" style="width: 115px; padding-left: 8px; user-select: none;">
            Host
          </th>
          <th class="header-cell" style="width: 115px; user-select: none;">
            Label
          </th>
         <th class="value-center" style="width: 70px;">
            Licence
          </th>
          <th class="value-center" style="width: 70px;">
            Target
          </th>
          <th class="value-center" style="width: 70px;">
            Source vCPU
          </th>
          <th class="value-center" style="width: 70px;">
            Target vCPU
          </th>
         <th class="value-center" style="width: 70px;">
          </th>
          <th class="value-center" style="width: 70px;">
            Licence Cost ($)
          </th>
        </tr>
        <tr v-for="(item) in paginatedMonitoring" :key="item.uuid">
          <td style="width: 210px;  padding-left: 8px;" :style="{ opacity: item.retire ? 0.5 : 1 }">{{ item.host }}</td>
          <td :style="{ opacity: item.retire ? 0.5 : 1 }">{{ item.label }}</td>
          <td class="value-center" :style="{ opacity: item.retire ? 0.5 : 1 }">SQL Server {{ item.pricing?.rds_edition }}</td>
          <td class="value-center" :style="{ opacity: item.retire ? 0.5 : 1 }">{{ formatTarget(item) }}</td>
          <td class="value-center" :style="{ opacity: item.retire ? 0.5 : 1 }">{{ item.pricing.cpu }}</td>
          <td class="value-center" :style="{ opacity: item.retire ? 0.5 : 1 }">{{ item.pricing.new_cpu }}</td>
          <td class="value-center" :style="{ opacity: item.retire ? 0.5 : 1 }">{{ }}</td>
          <td class="value-center" :style="{ opacity: (item.retire || getLicencePrice(item)==='0.00') ? 0.5 : 1 }">{{ getLicencePrice(item) }}</td>
        </tr>
        <tr>
          <th class="header-cell" style="width: 115px; padding-left: 8px; user-select: none;">
            Total
          </th>
          <th class="header-cell" style="width: 115px; user-select: none;"/>
         <th class="value-center" style="width: 70px;"/>
          <th class="value-center" style="width: 70px;"/>
          <th class="value-center" style="width: 70px;">
            {{ getTotalSourceCPU() }}
          </th>
          <th class="value-center" style="width: 70px;">
            {{ getTotalDestCPU() }}
          </th>
          <th class="value-center" style="width: 70px;"/>
          <th class="value-center" style="width: 70px;">
            {{ new Intl.NumberFormat().format(getTotalLicenceCost()) }}
          </th>
        </tr>
      </table>
    </div>
    <div class="pagination-controls" v-if="totalPages > 1">
      <a href="#" @click.prevent="prevPage" :class="{ disabled: currentPage === 1 }">Previous</a>
      <span style="margin-left: 15px; margin-right: 15px;">Page {{ currentPage }} of {{ totalPages }}</span>
      <a href="#" @click.prevent="nextPage" :class="{ disabled: currentPage === totalPages }">Next</a>
    </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps } from 'vue';
const monitoringTable = ref(null);

// data from parent
const props = defineProps({
  costings: {
    type: Array,
    required: true
  }
});

const currentPage = ref(1);
const pageSize = ref(400);

const totalPages = computed(() => {
  return props.costings ? Math.ceil(paginatedMonitoring.value.length / pageSize.value) : 1;
});

const getTotalSourceCPU = () => {
  const sql__filter = props.costings.filter(
    (item) => item.target.startsWith("rds") && ['SQL Server'].includes(item.pricing.rds_database)
  );
  return sql__filter.reduce((total, item) => total + (item.pricing.cpu || 0), 0);
}

const getTotalDestCPU = () => {
  const sql__filter = props.costings.filter(
    (item) => !item.retire && item.target.startsWith("rds") && ['SQL Server'].includes(item.pricing.rds_database)
  );
  return sql__filter.reduce((total, item) => total + (item.pricing.new_cpu || 0), 0);
}

const getTotalLicenceCost = () => {
  const sql__filter = props.costings.filter(
    (item) => !item.retire && item.target.startsWith("rds") && ['SQL Server'].includes(item.pricing.rds_database)
  );
  const total = sql__filter.reduce((total, item) => {
    return total + getLicenceNumber(item);
  }, 0);
  return total.toFixed(2)
}

const getLicenceNumber = (item) => {
    if (item.target === 'ec2') {
      return 0
    }
    if (item.target === 'rdsm') {
      return item.pricing.rds_price_mz - item.pricing.rds_db_price_mz;
    }
    return item.pricing.rds_price - item.pricing.rds_db_price
}

const getLicencePrice = (item) => {
    if (item.retire || item.target === 'ec2') {
      return (0).toFixed(2);
    }

    if (item.target === 'rdsm') {
      return (item.pricing.rds_price_mz - item.pricing.rds_db_price_mz).toFixed(2);
    }

    return (item.pricing.rds_price - item.pricing.rds_db_price).toFixed(2);
}

const formatTarget = (item) => {

  if (item.retire) {
    return "Retire"
  }

  switch(item.target) {
    case 'ec2':
      return 'EC2 - BYOL';
    case 'rds':
      return 'RDS';
    case 'rdsm':
      return 'RDS x2';
    default:
      return item.target;
  }
};

const paginatedMonitoring = computed(() => {
  if (!props.costings) {
    return [];
  }

  const filteredData = props.costings.filter((item) => {
    //if (item.target.startsWith("rds") ) {
      return ['SQL Server'].includes(item.pricing.rds_database);
    //}
    //return false;
  });

  const sortedData = [...filteredData].sort((a, b) => {
    const order = { ec2: 0, rds: 1, rdsm: 2 };
    const aOrder = order[a.target] ?? 3;
    const bOrder = order[b.target] ?? 3;

    if (a.retire && !b.retire) return 1;
    if (!a.retire && b.retire) return -1;

    return aOrder - bOrder;
  });

  const start = (currentPage.value - 1) * pageSize.value;
  const end = start + pageSize.value;

  return sortedData.slice(start, end);
});

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
};
</script>

<style scoped>
.outer-container {
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: rgb(165,165,165) transparent;
  -ms-overflow-style: none; /* Edge, Internet Explorer */
}

/* Style the scrollbar for Webkit browsers (Chrome, Safari) */
.outer-container::-webkit-scrollbar {
  width: 6px;
}

.outer-container::-webkit-scrollbar-track {
  background: transparent;
}

.outer-container::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.header {
  font-weight: 700;
  margin-bottom: 14px;
  font-size: 10pt;
  margin-top: 10px;
  text-align: left;
}

.monitoring-table-container {
  width: 1195px;
  border: 1px solid #2f353d;
  border-radius: 2px;
  overflow: hidden;
}

.monitoring-table {
  border-collapse: collapse;
  width: 100%;
}

.monitoring-table th, .monitoring-table td {
  padding: 2px 5px 3px 5px;

  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-cell > * {
  margin: auto;
}

.pagination-controls {
  margin-top: 15px;
}

.value-center {
  text-align: center !important;
}
</style>
