<template>
  <div class="editable-label" ref="editableLabelContainer">
    <div class="input-container">
      <input
        v-model="editableLabel"
        @blur="handleBlur"
        @focus="startEditing"
        @keyup.enter="saveLabel"
        :class="{ editing: isEditing }"
        readonly
        @mousedown.stop="handleMouseDownOnInput"
        spellcheck="false"
      />
      <PencilIcon class="pencil-icon" @click="(event) => enableEditing(event, true)" @mousedown.stop />
    </div>
  </div>
</template>

<script setup>
import {ref, onBeforeUnmount, nextTick} from 'vue';
import axios from "axios";
import PencilIcon from '@/components/icons/PencilIcon.vue';
import { globalStore } from '@/components/discovery/LabelGlobal'; // Import the global store

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  uuid: {
    type: String,
    required: true,
  }
});

const emit = defineEmits(['update:label']);

const isEditing = ref(false);
const editableLabel = ref(props.label);
const editableLabelContainer = ref(null);
let isMouseDownOnInput = false;

const enableEditing = (event, moveCursorToEnd = false) => {
  // prevent event propagation so the underlying line is not selected
  event.stopPropagation();

  // blur the currently active label if it's not the current one
  if (globalStore.activeLabel && globalStore.activeLabel !== editableLabel.value) {
    globalStore.activeLabel.saveLabel();
  }

  isEditing.value = true;

  // set label style as editable and focus
  nextTick(() => {
    const inputElement = editableLabelContainer.value.querySelector('input');
    inputElement.removeAttribute('readonly');
    inputElement.focus();

    if (moveCursorToEnd) {
      const valueLength = inputElement.value.length;
      inputElement.setSelectionRange(valueLength, valueLength);
    }
  });

  // to prevent unblur behavior on mouse drags that end outside of the label
  document.addEventListener('click', handleClickOutside);
  document.addEventListener('mousedown', handleMouseDown);

  // set the current label as the active label in the global store
  globalStore.activeLabel = { editableLabel, saveLabel };
};

const saveLabel = async () => {

  if (isEditing.value) {

    isEditing.value = false;

    document.removeEventListener('click', handleClickOutside);
    document.removeEventListener('mousedown', handleMouseDown);

    editableLabel.value = editableLabel.value.trim()

    if (editableLabel.value !== props.label) {
      try {
        await axios.post('/go/label',
            {uuid: props.uuid, label: editableLabel.value},
            {headers: {'Content-Type': 'application/json'}}
        );
        emit('update:label', editableLabel.value);
      } catch (error) {
        console.error('Error updating label:', error);
      }
    }

    // reapply readonly after editing
    nextTick(() => {
      editableLabelContainer.value.querySelector('input').setAttribute('readonly', true);
    });

  }

  // clear the active label from the global store
  if (globalStore.activeLabel === editableLabel.value) {
    globalStore.activeLabel = null;
  }
};

const handleClickOutside = (event) => {
  if (!isMouseDownOnInput && !editableLabelContainer.value.contains(event.target)) {
    saveLabel();
  }
  isMouseDownOnInput = false;
};

const handleMouseDown = (event) => {
  isMouseDownOnInput = editableLabelContainer.value.contains(event.target);
};

const handleMouseDownOnInput = () => {
  isMouseDownOnInput = true;
};

const handleBlur = (event) => {
  if (isMouseDownOnInput) {
      event.preventDefault(); // Prevent the blur if the mousedown was on the input
  } else {
    saveLabel();
  }
};

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
  document.removeEventListener('mousedown', handleMouseDown);

  // clear the active label from the global store on unmount
  if (globalStore.activeLabel === editableLabel.value) {
    globalStore.activeLabel = null;
  }
});

</script>

<style scoped>
.editable-label {
  display: flex;
  align-items: center;
}

.input-container {
  display: flex;
  align-items: center;
  width: 100%;
}

input {
  flex-grow: 1;
  padding: 0 6px;
  border: 0;
  border-radius: 2px;
  background-color: initial;
  text-overflow: ellipsis;
  color: #d9d9d9;
  height: 26px;
  margin-bottom: -7px;
  margin-top: -6px;
  cursor: default;
}

input.editing {
  background-color: #273646;
  cursor: text;
}

input:focus {
  outline: none;
}

.pencil-icon {
  margin-top: 2px;
  margin-bottom: -1px;
  cursor: pointer;
  padding-left: 3px;
  padding-right: 3px;
  color: #434f53;
}
</style>
