<template>
  <div>

  <div>
    <div class="header">Export</div>

    <div style="display: flex; align-items: flex-start; text-align: left; gap: 10px;">
      <div style="display: flex; align-items: center; gap: 5px;">
        <!-- Generate On-Premise Costs Button and Spinner -->
        <button class="button" style="margin-bottom: 0px;" @click="showModal = true">
          On-Premise Costs
        </button>
      </div>

      <div style="height: 35px; width: 200px;">
        <div v-if="latestTimestamp && latestTotal" style="font-size: 9pt; color: gray; margin-top: 8px;">
          Entered on {{ formatDate(latestTimestamp) }} for ${{ formatNumber(latestTotal) }}
        </div>
        <div v-else style="font-size: 9pt; color: gray; margin-top: 8px; visibility: hidden;">&nbsp;</div>
      </div>

      <div style="display: flex; align-items: center; gap: 5px;">
        <!-- Generate Export Button and Spinner -->
        <button class="button" @click="downloadXLS" style="margin-bottom: 0px; margin-right: 5px;">Generate Export</button>
        <span v-if="isDownloading" class="loading-spinner"></span>
      </div>
    </div>


    <div style="text-align: left;">

      <!--
      <button class="button" @click="generateOnprem">Generate On-Premise Costs</button>

      <span v-if="isGenerating" class="loading-spinner"></span>

      <div v-if="latestTimestamp && latestTotal" style="font-size: 9pt; color: gray; margin-top: -13px;">
        cost estimate at {{ getDate(latestTimestamp) }} for ${{ latestTotal }}
      </div>

      <button class="button" style="margin-left: 40px;" @click="downloadXLS">Generate Export</button>

      <span v-if="isDownloading" class="loading-spinner"></span>
      -->

      <div class="sub-header">Exports for _ Portfolio</div>

      <table v-if="paginatedExports?.length" border="1" style="margin-top: 20px;">
        <thead>
          <tr>
            <th style="width: 300px;">Server Summary</th>
            <th style="width: 300px;">PowerPoint Overview</th>
            <th>Exported At</th>
            <th>User</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(exportData, index) in paginatedExports" :key="index">
            <td>
              <a :href="`https://web.cloudhound.io/go/export/${exportData.filename}`" target="_blank">
                {{ exportData.filename }}
              </a>
            </td>
            <td>
              <a :href="`https://web.cloudhound.io/go/export/${exportData.powerpoint}`" target="_blank">
                {{ exportData.powerpoint }}
              </a>
            </td>
            <td>{{ getDate(exportData.exported_at) }}</td>
            <td>{{ exportData.user }}</td>
          </tr>
        </tbody>
      </table>

      <div class="pagination-controls" v-if="totalPageCount  > 1">
        <a href="#" @click.prevent="prevPage" style="width: 90px;">Previous</a>
        <span style="margin-left: 15px; margin-right: 15px;">Page {{ currentPage }} of {{ totalPageCount  }}</span>
        <a href="#" @click.prevent="nextPage" style="width: 90px;">Next</a>
      </div>
    </div>
  </div>

  <OnpremCosts
    v-if="showModal"
    :onprem="customerOnprem"
    :useaigen="useAigen"

    @close="handleSaveCosts"
    @submit="handleAddPortfolio"
  />

  </div>
</template>

<script setup>
import {ref, computed, onMounted } from 'vue';
import {useExportStore} from '@/stores/ExportStore';
import axios from 'axios';
import OnpremCosts from "@/export/OnpremCosts.vue";

const showModal = ref(false);

const exportStore = useExportStore();

const exports = ref([]);
const currentPage = ref(1);
const isDownloading = ref(false);
const isGenerating = ref(false);
const itemsPerPage = 10;

const paginatedExports = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage;
  const end = start + itemsPerPage;
  return exports.value.slice(start, end);
});

const totalPageCount = computed(() => Math.ceil(exports.value.length / itemsPerPage));

onMounted(async () => {
  currentPage.value = 1;
  exports.value = await exportStore.loadState();
  await fetchExports();
});

const handleSaveCosts = async () => {
  showModal.value = false;
  const response = await axios.get('/go/generating');

  latestTimestamp.value = response.data.timestamp
  latestTotal.value = response.data.onpremise
  useAigen.value = response.data.useaigen
}

const fetchExports = async () => {
  try {
    const response = await axios.get('/go/exports');
    const sortedExports = response.data.exports.sort(
        (a, b) => new Date(b.exported_at) - new Date(a.exported_at)
    );
    exports.value = [...sortedExports];
    await exportStore.saveState(exports.value);
  } catch (error) {
    console.error('Error fetching exports:', error);
  }
};

const downloadXLS = async () => {
  isDownloading.value = true;
  try {
    const response = await axios.post('/go/export', null);
    console.log('Filename:', response.data.filename);
    await fetchExports();
  } catch (error) {
    console.error('Error downloading XLS:', error);
    alert('Failed to generate the file. Please try again.');
  }
  isDownloading.value = false;
};

const latestTimestamp = ref("");
const latestTotal = ref(0);

const customerOnprem = ref(0);
const useAigen = ref(false);

onMounted(async () => {
  currentPage.value = 1;
  exports.value = await exportStore.loadState();
  await fetchExports();

  // check generating status on mount and start polling if needed
  try {
    const response = await axios.get('/go/generating');
    if (response.data.status) {
      isGenerating.value = true;
    }

    latestTimestamp.value = response.data.timestamp
    latestTotal.value = response.data.total
    customerOnprem.value = response.data.onpremise
    useAigen.value = response.data.useaigen

  } catch (error) {
    console.error('Error checking initial generation status:', error);
  }
});

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value -= 1;
  }
};

const nextPage = () => {
  if (currentPage.value < totalPageCount.value) {
    currentPage.value += 1;
  }
};

const getDate = (date) => {
  const dateObj = new Date(date);
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h23',
  }).format(dateObj);
};

const formatDate = (timestamp) => {
  if (!timestamp) return "";

  const date = new Date(timestamp);
  const locale = navigator.language || "en-US"; // Detects user locale

  return date.toLocaleDateString(locale === "en-US" ? "en-US" : "en-GB", {
    day: "2-digit",
    month: "2-digit",
  });
};

const formatNumber = (num) => {
  return typeof num === "number" ? num.toLocaleString("en-US") : "0";
};

</script>

<style scoped>
.button {
  background: #d05c00;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  padding: 3px 10px 4px;
  border: 1px solid #2f353d;
}

.header {
  text-align: left;
  font-weight: 700;
  margin-bottom: 21px;
  margin-top: 6px;
  font-size: 10pt;
}

.sub-header {
  font-weight: 600;
  margin-bottom: 8px;
}

.pagination-controls {
  margin-top: 15px;
}

.loading-spinner {
  display: inline-block;
  width: 12px;
  height: 12px;
  border: 1.5px solid #ffffff;
  border-top: 1.5px solid transparent;
  border-radius: 50%;
  animation: spin 0.6s linear infinite;
  margin-left: 5px; /* Reduce the space to keep it closer to the button */
  position: relative; /* Remove absolute positioning */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
