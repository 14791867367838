<template>
  <div class="cpu-totals">
    <div style="font-weight: 700; margin-left: 0px; margin-bottom: -5px; font-size: 13px;">Cloud Optimisation Score</div>

    <div class="chart-container">
      <!-- Pie chart -->
      <div id="system-efficiency-chart" class="chart"></div>

      <!-- Efficiency items (CPU, Mem, Disk) -->
      <div class="efficiency-items">
        <div class="efficiency-item">
          <span class="label">CPU:</span>
          <span class="score">{{ cpuEfficiency.toFixed() }}%</span>
        </div>
        <div class="efficiency-item">
          <span class="label">Mem:</span>
          <span class="score">{{ memEfficiency.toFixed() }}%</span>
        </div>
        <div class="efficiency-item">
          <span class="label">Disk:</span>
          <span class="score">{{ diskEfficiency.toFixed() }}%</span>
        </div>
      </div>
    </div>

    <p v-if="efficiencyScore > 0" class="efficiency-label">{{ efficiencyScore.toFixed() }}%</p>
  </div>
</template>

<script setup>
import { computed, watch, toRefs, onMounted, nextTick } from 'vue';
import Highcharts from 'highcharts';

const props = defineProps({
  dataArray: {
    type: Array,
    required: true
  }
});

const { dataArray } = toRefs(props);

const cpuEfficiency = computed(() => {
  const totalCpu = dataArray.value?.reduce((sum, item) => sum + (item?.pricing?.cpu || 0), 0) || 0;
  const totalCpuCloud = dataArray.value?.reduce((sum, item) => sum + (item?.pricing?.new_cpu || 0), 0) || 0;

  // max of 100
  if (totalCpu > 0 && totalCpuCloud / totalCpu > 1) {
    return 100
  }

  return totalCpu > 0 ? (totalCpuCloud / totalCpu) * 100 : 0;
});

const diskEfficiency = computed(() => {
  const totalDisk = dataArray.value?.reduce((sum, item) => sum + (item?.pricing?.total_disk || 0), 0) || 0;
  const totalDiskCloud = dataArray.value?.reduce((sum, item) => sum + (item?.pricing?.new_disk || 0), 0) || 0;

  // max of 100
  if (totalDisk > 0 && totalDiskCloud / totalDisk > 1) {
    return 100
  }

  return totalDisk > 0 ? (totalDiskCloud / totalDisk) * 100 : 0;
});

const memEfficiency = computed(() => {
  const totalMem = dataArray.value?.reduce((sum, item) => sum + (item?.pricing?.mem || 0), 0) || 0;
  const totalMemCloud = dataArray.value?.reduce((sum, item) => sum + (item?.pricing?.new_mem || 0), 0) || 0;

  // max of 100
  if (totalMem > 0 && totalMemCloud / totalMem > 1) {
    return 100
  }

  return totalMem > 0 ? (totalMemCloud / totalMem) * 100 : 0;
});

const efficiencyScore = computed(() => {
  return (cpuEfficiency.value + diskEfficiency.value + memEfficiency.value) / 3;
});

const createChart = (efficiency) => {
  Highcharts.chart('system-efficiency-chart', {
    chart: {
      type: 'pie',
      backgroundColor: "rgba(0, 0, 0, 0)",
      plotBackgroundColor: "rgba(0, 0, 0, 0)",
      plotShadow: false,
      height: '100%', // Set to full height
    },
    title: {
      text: null
    },
    accessibility: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: false // Disable data labels
        },
        enableMouseTracking: true,
        startAngle: 0,
        center: ['50%', '50%'], // Centered properly
        size: '100%', // Adjust the size to fit the chart
        innerSize: '75%', // Still a semi-circle
        borderColor: "rgba(0, 0, 0, 0)",
        borderWidth: 0,
      }
    },
    tooltip: {
      enabled: false // Disables tooltips
    },
    series: [{
      name: 'Efficiency',
      colorByPoint: true,
      data: [{
        name: 'Efficiency',
        y: efficiency, // Use the calculated efficiency score
        color: '#7cb5ec', // Blue
        borderWidth: 0.5, // Add a gap between this slice and the next
        borderColor: '#3a3a3a' // Border color
      }, {
        name: 'Remaining',
        y: 100 - efficiency, // Remaining part of 100%
        color: '#808080', // Orange
        borderWidth: 1, // Add a gap between this slice and the next
        borderColor: '#3a3a3a' // Border color
      }]
    }]
  });
};

// On mount, create the chart with the efficiency score
onMounted(() => {
  nextTick(() => {
    if (efficiencyScore.value > 0) {
      createChart(efficiencyScore.value);
    }
  });
});

// Watch for changes in the efficiency score and update the chart accordingly
watch(efficiencyScore, (newEfficiency) => {
  if (newEfficiency > 0) {
    createChart(newEfficiency);
  }
});

</script>

<style scoped>
.cpu-totals {
  font-size: 14px;
  margin-top: 10px;
  text-align: left;
  margin-bottom: 30px;
}

.chart-container {
  display: flex;
  align-items: center;
}

.chart {
  width: 160px;
  height: 160px;
  position: relative;
  margin-left: -10px;
}

.efficiency-items {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-top: 5px;
}

.efficiency-item {
  display: flex;
  justify-content: space-between;
  width: 80px;
  margin-bottom: 10px;
}

.efficiency-label {
  width: 160px;
  height: 20px;
  font-size: 19px;
  text-align: center;
  margin-top: -98.5px !important;
  margin-left: -8px;
  margin-right: 0;
  font-weight: 600;
}

.label {
  font-weight: bold;
}

.score {
}
</style>
