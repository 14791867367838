import Decimal from 'decimal.js';

export function calculateSupportFeesYear(supportLevel, totalCost) {
  let fee = calculateSupportFees(supportLevel, totalCost);

  if (supportLevel === "business" && fee < 100 * 12){
    return 100 * 12;
  }

  if (supportLevel === "enterprise" && fee < 15000 * 12){
    return 15000 * 12;
  }

  return fee;
}


export function calculateSupportFees(supportLevel, totalCost) {
  if (!supportLevel || supportLevel === "") {
    supportLevel = "business"
  }

  let supportFee = 0;

  switch (supportLevel) {
     case "none":
       supportFee = 0;
       break;

    case "business":
      // Business support is 10% of the total cost, with a minimum fee of $100
      supportFee = totalCost * 0.10;
      if (supportFee < 100) {
        supportFee = 100;
      }
      break;

    case "enterprise":
      // Enterprise support has a tiered structure with a minimum fee of $15,000:
      // - $15K for total cost up to $150K
      // - 7% for the next $350K ($150K to $500K)
      // - 5% for the next $500K ($500K to $1M)
      // - 3% for costs above $1M
      if (totalCost <= 150000) {
        supportFee = 15000;
      } else if (totalCost <= 500000) {
        supportFee = 15000 + (totalCost - 150000) * 0.07;
      } else if (totalCost <= 1000000) {
        supportFee = 15000 + (500000 - 150000) * 0.07 + (totalCost - 500000) * 0.05;
      } else {
        supportFee =
          15000 +
          (500000 - 150000) * 0.07 +
          (1000000 - 500000) * 0.05 +
          (totalCost - 1000000) * 0.03;
      }

      // Enforce minimum charge of $15,000
      if (supportFee < 15000) {
        supportFee = 15000;
      }
      break;

    default:
      throw new Error("Invalid support level: must be 'none', 'business' or 'enterprise', currently " + supportLevel);
  }

  return supportFee;
}

export function calculateSupportFeesYearDecimal(supportLevel, totalCost) {
  let fee = calculateSupportFeesDecimal(supportLevel, totalCost);

  if (supportLevel === "business" && fee.lessThan(new Decimal(100).mul(12))) {
    return new Decimal(100).mul(12);
  }

  if (supportLevel === "enterprise" && fee.lessThan(new Decimal(15000).mul(12))) {
    return new Decimal(15000).mul(12);
  }

  return fee;
}

export function calculateSupportFeesDecimal(supportLevel, totalCost) {
  if (!supportLevel || supportLevel === "") {
    supportLevel = "business";
  }

  let supportFee = new Decimal(0);
  const cost = new Decimal(totalCost);

  switch (supportLevel) {
    case "none":
      // no support fee
      supportFee = new Decimal(0);
      break;

    case "business":
      // Business support is 10% of the total cost, with a minimum fee of $100
      supportFee = cost.mul(0.10);
      if (supportFee.lessThan(100)) {
        supportFee = new Decimal(100);
      }
      break;

    case "enterprise":
      // Enterprise support has a tiered structure with a minimum fee of $15,000:
      // - $15K for total cost up to $150K
      // - 7% for the next $350K ($150K to $500K)
      // - 5% for the next $500K ($500K to $1M)
      // - 3% for costs above $1M
      if (cost.lessThanOrEqualTo(150000)) {
        supportFee = new Decimal(15000);
      } else if (cost.lessThanOrEqualTo(500000)) {
        supportFee = new Decimal(15000).add(cost.minus(150000).mul(0.07));
      } else if (cost.lessThanOrEqualTo(1000000)) {
        supportFee = new Decimal(15000)
          .add(new Decimal(350000).mul(0.07))
          .add(cost.minus(500000).mul(0.05));
      } else {
        supportFee = new Decimal(15000)
          .add(new Decimal(350000).mul(0.07))
          .add(new Decimal(500000).mul(0.05))
          .add(cost.minus(1000000).mul(0.03));
      }

      // Enforce minimum charge of $15,000
      if (supportFee.lessThan(15000)) {
        supportFee = new Decimal(15000);
      }
      break;

    default:
      throw new Error(
        "Invalid support level: must be 'none', 'business' or 'enterprise', currently " +
          supportLevel
      );
  }

  return supportFee;
}