<template>
  <div class="os-wrapper">
    <div class="label">Database Engines</div>
    <div class="os-pie-container">
      <div id="db-split-chart" class="chart"></div>
    </div>

    <div class="os-table-container">
      <table class="os-table">
        <tbody>
          <tr v-for="os in top5" :key="os.Name">
            <td class="os-name">{{ os.Name }}</td>
            <td class="os-count">{{ os.Count.toLocaleString() }}</td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</template>

<script setup>
import { ref, onMounted, watch, nextTick } from 'vue'
import Highcharts from 'highcharts'

import { computed } from 'vue'

const top5 = computed(() => {
  return [...props.osSplit]
    .sort((a, b) => b.Count - a.Count)
    .slice(0, 5)
})

const props = defineProps({
  osSplit: {
    type: Array,
    required: true,
  },
})

const chartRef = ref(null)

const colorMap = {
  'SQL Server':  '#d9534f',  // deep red (like Bootstrap danger)
  'MariaDB':     '#5bc0de',  // cyan-teal (similar to SUSE)
  'MySQL':       '#5cb85c',  // strong green
  'PostgreSQL':  '#428bca',  // deep blue
  'Db2':         '#f0ad4e',  // amber/orange (similar to RHEL)
  'Oracle':      '#ab47bc',  // rich purple
}

const createChart = (data) => {
  chartRef.value = Highcharts.chart('db-split-chart', {
    chart: {
      type: 'pie',
      backgroundColor: 'transparent',
      height: '100%',
      animation: true,
    },
    title: {
      text: null
    },
    tooltip: {
      pointFormat: '{point.percentage:.0f}%',
      style: {
        fontSize: 14, // match legend or your desired size
        color: '#000' // optional: match other text color
      }
    },
    accessibility: {
      point: {valueSuffix: '%'},
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        size: 95, // px
        center: ['20%', '50%'],
        dataLabels: { enabled: false },
        borderRadius: 2,
        showInLegend: true,
        borderColor: '#939393',
        borderWidth: 1
      }
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
      x: -40, // px
      y: -3, // px
      floating: true,
      itemStyle: { color: 'rgb(204, 204, 204)', fontSize: '10.5px' }
    },
    series: [{
      name: 'OS',
      colorByPoint: false,
      data: data.map(os => ({
        name: os.Name,
        y: os.Count,
        color: colorMap[os.Name] || '#888'
      })),
    }],
  })
}

const updateChart = (data) => {
  if (chartRef.value) {
    const series = chartRef.value.series[0]
    const newData = data.map(os => ({
      name: os.Name,
      y: os.Count,
      color: colorMap[os.Name] || '#888'
    }))
    series.setData(newData, true, true, false)
  } else {
    createChart(data)
  }
}

onMounted(() => {
  nextTick(() => {
    createChart(props.osSplit)
  })
})

watch(() => props.osSplit, (newVal) => {
  nextTick(() => updateChart(newVal))
})
</script>

<style scoped>
.os-wrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: left;
  width: 150px;
  height: 275px;
  margin-top: 43px;
  margin-left: 50px;
}

.label {
  font-size: 12px;
  color: #d3d3d3;
  margin-bottom: 8px;
  font-weight: 700;
}

.os-pie-container {
  width: 270px;
  height: 275px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -72px;
  margin-left: -30px;
}

.chart {
  width: 100%;
  height: 100%;
}

.os-wrapper {
  position: relative;
}

.os-table-container {
  position: absolute;
  top: 145px;
  left: -6px;
}

.os-table {
  font-size: 10px;
  color: #ccc;
  border-spacing: 6px 2px;
}

.os-name {
  text-align: left;
  padding-right: 12px;
  font-size: 11px;
  padding: 0px;
  width: 90px;
  padding-top: 1px;
  padding-bottom: 2px;
  padding-left: 3px;
}

.os-count {
  text-align: right;
  min-width: 20px;
  font-size: 11px;
  padding: 0px;
  width: 30px;
  padding-right: 3px;
}
</style>
