<template>
  <div class="autocomplete-container">
    <input
      v-model="searchQuery"
      @focus="showDropdown = true"
      @input="showDropdown = true"
      @keydown.down.prevent="navigate(1)"
      @keydown.up.prevent="navigate(-1)"
      @keydown.enter.prevent="selectCountry(filteredCountries[selectedIndex])"
      type="text"
      class="modal-input"
      :disabled="disabled"
      :class="{ 'disabled-input': disabled }"
      placeholder="Start typing a country..."
    />
    <ul v-if="showDropdown && filteredCountries.length" class="autocomplete-dropdown">
      <li
        v-for="(country, index) in filteredCountries"
        :key="country"
        :class="{ 'selected': index === selectedIndex }"
        @mousedown.prevent="selectCountry(country)"
      >
        {{ country }}
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, defineProps, defineEmits, watch } from "vue";

const props = defineProps({
  modelValue: String, // For v-model
  disabled: Boolean
});

const emit = defineEmits(["update:modelValue"]);

const searchQuery = ref(props.modelValue || "");
const showDropdown = ref(false);
const selectedIndex = ref(-1);

const countries = ref([
  "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Argentina", "Armenia",
  "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados",
  "Belarus", "Belgium", "Belize", "Benin", "Bhutan", "Bolivia", "Bosnia and Herzegovina",
  "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cambodia",
  "Cameroon", "Canada", "Central African Republic", "Chad", "Chile", "China", "Colombia",
  "Comoros", "Congo", "Costa Rica", "Croatia", "Cuba", "Cyprus", "Czech Republic",
  "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Ecuador", "Egypt",
  "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia",
  "Fiji", "Finland", "France", "Gabon", "Gambia", "Georgia", "Germany", "Ghana",
  "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana", "Haiti",
  "Honduras", "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland",
  "Israel", "Italy", "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati",
  "Kuwait", "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya",
  "Liechtenstein", "Lithuania", "Luxembourg", "Madagascar", "Malawi", "Malaysia",
  "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania", "Mauritius",
  "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco",
  "Mozambique", "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand",
  "Nicaragua", "Niger", "Nigeria", "North Korea", "North Macedonia", "Norway",
  "Oman", "Pakistan", "Palau", "Palestine", "Panama", "Papua New Guinea", "Paraguay",
  "Peru", "Philippines", "Poland", "Portugal", "Qatar", "Romania", "Russia", "Rwanda",
  "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines", "Samoa",
  "San Marino", "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone",
  "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa",
  "South Korea", "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Sweden",
  "Switzerland", "Syria", "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Timor-Leste",
  "Togo", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan",
  "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom",
  "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela",
  "Vietnam", "Yemen", "Zambia", "Zimbabwe"
]);

watch(searchQuery, (newValue) => {
  emit("update:modelValue", newValue);
});

const filteredCountries = computed(() =>
  countries.value.filter(country =>
    country.toLowerCase().includes(searchQuery.value.toLowerCase())
  )
);

const selectCountry = (country) => {
  searchQuery.value = country;
  showDropdown.value = false;
  selectedIndex.value = -1;
  emit("update:modelValue", country);
};

const navigate = (direction) => {
  if (!showDropdown.value) return;
  selectedIndex.value += direction;

  if (selectedIndex.value < 0) {
    selectedIndex.value = filteredCountries.value.length - 1;
  } else if (selectedIndex.value >= filteredCountries.value.length) {
    selectedIndex.value = 0;
  }
};

const handleClickOutside = (event) => {
  if (!event.target.closest(".autocomplete-container")) {
    showDropdown.value = false;
  }
};

onMounted(async () => {

    console.log("Fetching user country...");

    const res = await fetch("https://api.country.is/");
    const data = await res.json();
    const countryCode = data.country; // e.g., "NZ", "US"

    console.log(`Detected country code: ${countryCode}`);

    // Mapping country code to full name
    const countryMap = {
      "AF": "Afghanistan",
      "AL": "Albania",
      "DZ": "Algeria",
      "AD": "Andorra",
      "AO": "Angola",
      "AR": "Argentina",
      "AM": "Armenia",
      "AU": "Australia",
      "AT": "Austria",
      "AZ": "Azerbaijan",
      "BS": "Bahamas",
      "BH": "Bahrain",
      "BD": "Bangladesh",
      "BB": "Barbados",
      "BY": "Belarus",
      "BE": "Belgium",
      "BZ": "Belize",
      "BJ": "Benin",
      "BT": "Bhutan",
      "BO": "Bolivia",
      "BA": "Bosnia and Herzegovina",
      "BW": "Botswana",
      "BR": "Brazil",
      "BN": "Brunei",
      "BG": "Bulgaria",
      "BF": "Burkina Faso",
      "BI": "Burundi",
      "KH": "Cambodia",
      "CM": "Cameroon",
      "CA": "Canada",
      "CV": "Cape Verde",
      "CF": "Central African Republic",
      "TD": "Chad",
      "CL": "Chile",
      "CN": "China",
      "CO": "Colombia",
      "KM": "Comoros",
      "CG": "Congo",
      "CR": "Costa Rica",
      "HR": "Croatia",
      "CU": "Cuba",
      "CY": "Cyprus",
      "CZ": "Czech Republic",
      "DK": "Denmark",
      "DJ": "Djibouti",
      "DM": "Dominica",
      "DO": "Dominican Republic",
      "EC": "Ecuador",
      "EG": "Egypt",
      "SV": "El Salvador",
      "GQ": "Equatorial Guinea",
      "ER": "Eritrea",
      "EE": "Estonia",
      "SZ": "Eswatini",
      "ET": "Ethiopia",
      "FJ": "Fiji",
      "FI": "Finland",
      "FR": "France",
      "GA": "Gabon",
      "GM": "Gambia",
      "GE": "Georgia",
      "DE": "Germany",
      "GH": "Ghana",
      "GR": "Greece",
      "GD": "Grenada",
      "GT": "Guatemala",
      "GN": "Guinea",
      "GW": "Guinea-Bissau",
      "GY": "Guyana",
      "HT": "Haiti",
      "HN": "Honduras",
      "HU": "Hungary",
      "IS": "Iceland",
      "IN": "India",
      "ID": "Indonesia",
      "IR": "Iran",
      "IQ": "Iraq",
      "IE": "Ireland",
      "IL": "Israel",
      "IT": "Italy",
      "JM": "Jamaica",
      "JP": "Japan",
      "JO": "Jordan",
      "KZ": "Kazakhstan",
      "KE": "Kenya",
      "KI": "Kiribati",
      "KW": "Kuwait",
      "KG": "Kyrgyzstan",
      "LA": "Laos",
      "LV": "Latvia",
      "LB": "Lebanon",
      "LS": "Lesotho",
      "LR": "Liberia",
      "LY": "Libya",
      "LI": "Liechtenstein",
      "LT": "Lithuania",
      "LU": "Luxembourg",
      "MG": "Madagascar",
      "MW": "Malawi",
      "MY": "Malaysia",
      "MV": "Maldives",
      "ML": "Mali",
      "MT": "Malta",
      "MH": "Marshall Islands",
      "MR": "Mauritania",
      "MU": "Mauritius",
      "MX": "Mexico",
      "FM": "Micronesia",
      "MD": "Moldova",
      "MC": "Monaco",
      "MN": "Mongolia",
      "ME": "Montenegro",
      "MA": "Morocco",
      "MZ": "Mozambique",
      "MM": "Myanmar",
      "NA": "Namibia",
      "NR": "Nauru",
      "NP": "Nepal",
      "NL": "Netherlands",
      "NZ": "New Zealand",
      "NI": "Nicaragua",
      "NE": "Niger",
      "NG": "Nigeria",
      "KP": "North Korea",
      "MK": "North Macedonia",
      "NO": "Norway",
      "OM": "Oman",
      "PK": "Pakistan",
      "PW": "Palau",
      "PS": "Palestine",
      "PA": "Panama",
      "PG": "Papua New Guinea",
      "PY": "Paraguay",
      "PE": "Peru",
      "PH": "Philippines",
      "PL": "Poland",
      "PT": "Portugal",
      "QA": "Qatar",
      "RO": "Romania",
      "RU": "Russia",
      "RW": "Rwanda",
      "KN": "Saint Kitts and Nevis",
      "LC": "Saint Lucia",
      "VC": "Saint Vincent and the Grenadines",
      "WS": "Samoa",
      "SM": "San Marino",
      "SA": "Saudi Arabia",
      "SN": "Senegal",
      "RS": "Serbia",
      "SC": "Seychelles",
      "SL": "Sierra Leone",
      "SG": "Singapore",
      "SK": "Slovakia",
      "SI": "Slovenia",
      "SB": "Solomon Islands",
      "SO": "Somalia",
      "ZA": "South Africa",
      "KR": "South Korea",
      "SS": "South Sudan",
      "ES": "Spain",
      "LK": "Sri Lanka",
      "SD": "Sudan",
      "SR": "Suriname",
      "SE": "Sweden",
      "CH": "Switzerland",
      "SY": "Syria",
      "TW": "Taiwan",
      "TJ": "Tajikistan",
      "TZ": "Tanzania",
      "TH": "Thailand",
      "TL": "Timor-Leste",
      "TG": "Togo",
      "TO": "Tonga",
      "TT": "Trinidad and Tobago",
      "TN": "Tunisia",
      "TR": "Turkey",
      "TM": "Turkmenistan",
      "TV": "Tuvalu",
      "UG": "Uganda",
      "UA": "Ukraine",
      "AE": "United Arab Emirates",
      "GB": "United Kingdom",
      "US": "United States",
      "UY": "Uruguay",
      "UZ": "Uzbekistan",
      "VU": "Vanuatu",
      "VA": "Vatican City",
      "VE": "Venezuela",
      "VN": "Vietnam",
      "YE": "Yemen",
      "ZM": "Zambia",
      "ZW": "Zimbabwe"
    };
    const matchedCountry = countryMap[countryCode] || "";

    if (matchedCountry && countries.value.includes(matchedCountry)) {
      console.log(`Matched country: ${matchedCountry}`);
      searchQuery.value = matchedCountry;
    }

  document.addEventListener("click", handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener("click", handleClickOutside);
});
</script>

<style scoped>
.modal-input {
  width: 358px;
  background-color: #0D1117;
  border: 1px solid #2f353d;
  color: #d9d9d9;
  font-size: 12px;
  padding: 6px 30px 7px 10px;
  border-radius: 4px 4px 4px 4px;
}

.modal-input:focus {
  outline: none;
  box-shadow: none;
  border-color: #2f353d;
}

.autocomplete-container {
  position: relative;
  width: 400px;
}

.autocomplete-dropdown {
  position: absolute;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background: #0d1117;
  border: 1px solid #2f353d;
  border-radius: 4px;
  z-index: 10;
  padding: 0;
  margin: 0;
  list-style: none;
}

.autocomplete-dropdown li {
  padding: 8px 10px;
  color: #d4d4d4;
  cursor: pointer;
}

.autocomplete-dropdown li:hover, .autocomplete-dropdown li.selected {
  background: #2f353d;
}
</style>
