<!--suppress VueMissingComponentImportInspection -->
<template>
  <v-network-graph
      v-if="dataLoaded"
      class="graph"
      :nodes="nodes"
      :edges="edges"
      :configs="initialConfigs"
      :layouts="layouts"
      :key="graphKey"
      @update:layouts="updateLayouts"
  >
  <template #edge-label="{ edge, ...slotProps }">
    <v-edge-label :text="edge.label" align="center" vertical-align="above" style="color: rgb(204, 204, 204) !important; fill: rgb(204, 204, 204) !important; font-size: 11px !important; " v-bind="slotProps" />
  </template>

  <template #override-node="{ nodeId, scale, config, ...slotProps }">
    <circle :r="config.radius * scale" :fill="config.color" v-bind="slotProps"/>
    <text
        font-family="Material Icons"
        :font-size="22 * scale"
        fill="#ffffff"
        text-anchor="middle"
        dominant-baseline="central"
        style="pointer-events: none"
        v-html="nodes[nodeId].icon"
    />
  </template>
  </v-network-graph>
</template>

<script setup>

import { defineProps, reactive, watch, ref, nextTick } from 'vue'
import { initialConfigs as defaultConfigs } from './ConnectionsConfig';

const props = defineProps({
  uuid: {
    type: String,
    required: true
  },
  host: String,
  networking: {
    type: Object,
    default: () => ({
      incoming: [],
      outgoing: []
    })
  }
});

const graphKey = ref(0);  // Key to force re-render of v-network-graph

const nodes = reactive({});
const edges = reactive({});
const layouts = reactive({});
const dataLoaded = ref(false);
const initialConfigs = ref({ ...defaultConfigs });

const updateLayouts = () => {
  for (const nodeId in layouts.nodes) {
    if (layouts.nodes[nodeId]) {
      const node = layouts.nodes[nodeId];
      node.x = Math.max(30, Math.min(node.x, 765)); // clamp x
      node.y = Math.max(35, Math.min(node.y, 265)); // clamp y
    }
  }
};

class Point {
    constructor(x, y) {
        this.x = x;
        this.y = y;
    }
}

const loadNodePositions = async () => {
  dataLoaded.value = false;

  // Clear the existing data in nodes, edges, and layouts
  Object.keys(nodes).forEach(key => delete nodes[key]);
  Object.keys(edges).forEach(key => delete edges[key]);
  layouts.value = {};  // Clear layouts by reassigning an empty object

  const radius  = 140.0;
  const centerX = 400;
  const centerY = 150;
  const center  = new Point(centerX, centerY);

  // add fixed layouts for core server
  layouts["nodes"] = {
    [props.host]: {x: centerX, y: centerY, fixed: true}
  };

  // generate unique hosts
  const uniqueHosts = Array.from(new Set(
      [...props.networking.incoming, ...props.networking.outgoing]
          .filter(item => item.is_private)
          .filter(item => !item.hidden)
          .map(item => item.remote_host || item.remote_ip)
  ));

  let z = 0;
  let node_positions = generateEquallySpacedNodes(center, radius, uniqueHosts.length);

  // assign host + initial layout
  uniqueHosts.forEach((host) => {
    nodes[host] = {name: host, icon: "&#xe320"};
    layouts["nodes"][host] = {x: node_positions[z].x, y: node_positions[z].y, fixed: false};
    z = z + 1;
  });

  console.log(uniqueHosts)
  console.log(layouts["nodes"])
  console.log(nodes)

  // add edges
  props.networking.incoming.forEach((item) => {
    const source = item.is_private ? (item.remote_host || item.remote_ip) : "Public";
    const target = props.host;
    const label = item.remote_app_group;

    const edgeExists = Object.values(edges).some(
        e => e.source === source && e.target === target
    );

    if (!edgeExists && !item.hidden) {
      const edgeKey = `dynamicEdge${Object.keys(edges).length + 1}`;
      edges[edgeKey] = {source, target, label};
    }
  });

  props.networking.outgoing.forEach((item) => {
    const source = props.host;
    const target = item.is_private ? (item.remote_host || item.remote_ip) : "Public";
    const label = item.local_app_group;

    const edgeExists = Object.values(edges).some(
        e => e.source === source && e.target === target
    );

    if (!edgeExists && !item.hidden) {
      const edgeKey = `dynamicEdge${Object.keys(edges).length + 1}`;
      edges[edgeKey] = {source, target, label};
    }
  });

  // add core server node and public internet node if needed
  nodes[props.host] = {name: props.host, icon: "&#xe328"};
  const publicInEdges = Object.values(edges).some(
      e => e.source === "Public" || e.target === "Public"
  );
  if (publicInEdges) {
    nodes["Public"] = {name: "Public", icon: "&#xe320"};
    layouts["nodes"]["Public"] = {x: centerX - radius, y: centerY, fixed: true};
  }

  dataLoaded.value = true;
  graphKey.value += 1;

  initialConfigs.value = {...defaultConfigs};

  await nextTick();
};

function generateEquallySpacedNodes(center, radius, nodeCount) {
    const nodes = [];
    if (nodeCount === 1) {
        // if there's only one node, just place it directly at the specified radius distance
        nodes.push(new Point(center.x + radius, center.y));
    } else {
        const angleIncrement = Math.PI / (nodeCount - 1); // -π/2 to π/2
        for (let i = 0; i < nodeCount; i++) {
            const angle = -Math.PI / 2 + angleIncrement * i; // -π/2 to π/2
            const nodeX = center.x + radius * Math.cos(angle);
            const nodeY = center.y + radius * Math.sin(angle);
            nodes.push(new Point(nodeX, nodeY));
        }
    }
    return nodes;
}
watch(() => props.uuid, (newVal, oldVal) => {
    if (newVal !== oldVal && newVal) {
      loadNodePositions();
    }
  },
  { immediate: true }
);
</script>

<style scoped>
.graph {
  width: 800px;
  height: 300px;
  border: 1px solid #2f353d;
  background: #0d1117;
  color: #bcbcbc;
  border-radius: 4px;
}

div.container div.v-network-graph.v-ng-container.graph svg.v-ng-canvas.show.touches {
    opacity: 1 !important;
    transition: none !important;
}

</style>