<template>
  <div v-if="isVisible" class="modal-overlay">
    <div ref="content" class="popup-box" @click.stop>

      <div class="header">
        <span class="header-title">{{ header_title }}</span>
        <div class="header-right">
          <div class="search-container">
            <input
              type="text"
              v-model="inputValue"
              class="header-input"
              placeholder="Instance Search"
              @input="fetchAutocomplete"
              @blur="clearAutocomplete"
            />
            <ul v-if="autocompleteResults.length" class="autocomplete-list">
              <li v-for="result in autocompleteResults" :key="result" @click="selectSuggestion(result)">
                {{ result }}
              </li>
            </ul>
          </div>
          <button class="clear-button" @click="clearInstance">Reset</button>
        </div>
      </div>

      <div class="content">
        <div class="instance-selector">
          <table>
            <thead>
              <tr>
                <th style="width: 100px; padding-left: 10px;">Instance</th>
                <th style="width: 30px; text-align: center;">CPU</th>
                <th style="width: 30px; text-align: center;">Mem</th>
                <th style="width: 50px; text-align: center;">Vendor</th>
                <th style="width: 140px;">Processor</th>
                <th style="width: 60px;">Clock</th>
                <th style="width: 60px;">Released</th>
                <th style="width: 100px;">NVMe</th>
                <th style="width: 100px;">Net Mbps</th>
                <th style="width: 90px;">EBS IOPS / MiBs</th>
                <th style="width: 80px; text-align: right; padding-right: 10px;">Price ($)</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(instance, index) in instances"
                :key="index"
                :class="{ 'selected': selectedInstance === index }"
                @click="selectInstance(index)">
                <td style="padding-left: 10px;">{{ instance.instance }}</td>
                <td style="text-align: center;">{{ instance.cpu }}</td>
                <td style="text-align: center;">{{ instance.mem }}</td>
                <td style="text-align: center;">{{ instance.vendor }}</td>
                <td>{{ instance.processor }}</td>
                <td>{{ instance.clockspeed }}</td>
                <td>{{ instance.released }}</td>
                <td>{{ instance.storage }}</td>
                <td>{{ instance.baseline_net_mbs === 0 ? "" : new Intl.NumberFormat().format(instance.baseline_net_mbs) }}</td>
                <td>{{ new Intl.NumberFormat().format(Math.round(instance.baseline_iops)) + ' / ' + new Intl.NumberFormat().format(Math.round(instance.baseline_mbs)) }}</td>
                <td style="text-align: right; padding-right: 10px;">
                   {{ Number(instance.discount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- <button @click="close">Close</button> -->
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, onUnmounted } from 'vue';
import axios from 'axios';

const props = defineProps({
  show: Boolean,
  uuid: String,
  arch: String,
  database: String,
  edition: String,
  hovered: Boolean,
});

const isVisible = ref(false);
const instances = ref([]);
const selectedInstance = ref(0);
const inputValue = ref("");
const header_title = ref("EC2 Instance Types");
const autocompleteResults = ref([]);

const fetchAutocomplete = async () => {
  if (inputValue.value.trim() === "") {
    autocompleteResults.value = [];
    return;
  }

  try {
    const response = await axios.post('/go/autocomplete', {uuid: props.uuid, target: props.arch === 'rdsm' ? 'rds' : props.arch, text: inputValue.value}, {
      headers: {'Content-Type': 'application/json'}
    });
    autocompleteResults.value = response.data.instances || [];
  } catch (error) {
    console.error("autocomplete fetch error:", error);
  }
};

const clearAutocomplete = async () => {
  setTimeout(() => {
    autocompleteResults.value = [];
  }, 100);
}

const selectSuggestion = (suggestion) => {
  autocompleteResults.value = [];

  const serverTarget = {
    uuid: props.uuid,
    target: props.arch === 'rdsm' ? 'rds' : props.arch,
    instance: suggestion
  };

  sendTarget(serverTarget, false);
};

// watch hover and trigger when mouse
watch(() => props.hovered, async (newVal) => {
  if (newVal) {
    load(); // preload pricing on mouse-over
  }
});

// watch for external changes to 'show' prop and update 'isVisible'
watch(() => props.show, async (newVal) => {
  isVisible.value = newVal;
  if (newVal) {
    load(); // load pricing on show
  }
});

const selectInstance = async (index) => {
  selectedInstance.value = index;

  // set the correct new selected value
  instances.value.forEach(instance => {
    instance.selected = false;
  });
  instances.value[index].selected = true;

  // if index is 0, clear the instance override
  const serverTarget = {
    uuid: props.uuid,
    target: props.arch === 'rdsm' ? 'rds' : props.arch,
    instance: index === 0 ? "" : instances.value[index].instance
  };

  sendTarget(serverTarget, true);
};

const clearInstance = async () => {
  const serverTarget = {
    uuid: props.uuid,
    target: props.arch === 'rdsm' ? 'rds' : props.arch,
    instance: ""
  };

  sendTarget(serverTarget, true);
}

const sendTarget = async (serverTarget, toClose) => {

  if (toClose) {
    close();
  }

  // clear text box & auto complete
  autocompleteResults.value = [];
  inputValue.value = ""

  try {
    await axios.post('/go/override', serverTarget, {
      headers: {'Content-Type': 'application/json'}
    });
  } catch (error) {
    console.error('Axios request failed:', error);
  }

  emit('update:instance');

  // reload data for when we reopen screen
  prev_uuid_target = "";
  load();
}

let prev_uuid_target = ""

const load = async () => {
  // send either ec2 or rds, not rdsm
  let target = props.arch;
  if (target === 'rdsm') target = 'rds';

  if (target === 'rds') {
    header_title.value = "RDS Instance Types";

    if (props.database !== "") {
      if (props.database === "sqlservr") {
        header_title.value = "RDS Instance Types - SQL Server " + props.edition;
      }

      if (props.database === "oracle") {
        let edition = props.edition
        if (edition==="Free") {
          edition = "Standard Two"
        }
        header_title.value = "RDS Instance Types - Oracle " + edition;
      }

      if (props.database === "db2") {
        header_title.value = "RDS Instance Types - Db2";
      }
    }
  } else {
    header_title.value = "EC2 Instance Types";
  }

  if (prev_uuid_target === props.uuid + target) {
    return;
  }

  const response = await axios.post('/go/instances', {uuid: props.uuid, target: target}, {
    headers: {'Content-Type': 'application/json'}
  });
  instances.value = response.data.instances;
  let override = response.data.override;

  // set the selected override
  const index = instances.value.findIndex(inst => inst.selected);
  if (index !== -1) {
    selectedInstance.value = index;
    inputValue.value = "";
  } else {
    selectedInstance.value = 0;
    inputValue.value = override; // add override if not in list
  }

  prev_uuid_target = props.uuid + target;
};

const emit = defineEmits(['update:show', 'update:instance']);
const content = ref(null);

const close = () => {
  isVisible.value = false;
  emit('update:show', false);
};

const handleClickOutside = (event) => {
  if (content.value && !content.value.contains(event.target)) {
    event.preventDefault();
    event.stopPropagation();
    close();

    autocompleteResults.value = [];
    inputValue.value = ""
  }
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});

</script>

<style scoped>
.content {
  border: 1px solid #262b32;
  border-radius: 3px;
}

.popup-box {
  position: fixed;
  top: 212px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  background-color: #06080a;
  border: 1px solid #2f353d;
  border-radius: 4px;
  width: 1040px;
  height: 320px;
  box-shadow: 0 0 58px #ffffff08;
  overflow: hidden;
  padding: 16px 25px;
}

.instance-selector table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 3px;
}

th {
  text-align: left;
  padding: 1.5px 4px 2px;
  font-weight: normal;
  color: #d9d9d9;
}

td {
  text-align: left;
  padding: 1.5px 4px 2px;
  font-weight: normal;
  color: #d9d9d9;
  border-top: 1px solid #2F353D;
}

thead {
  background-color: #0d1117 !important;
}

th {
  cursor: default !important;
}

tr {
  background-color: #0D1117;
}

tr:hover {
  background-color: #262e3d;
  cursor: pointer;
}

.selected {
  background-color: #262e3d;
}

button {
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  margin-top: 13px;
  margin-bottom: 0px;
  margin-left: 890px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00000085;
  display: flex;
  justify-content: center;
  align-items: flex-start; /* align to the top */
  z-index: 9999;
  padding-top: calc(100vh / 4);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1040px;
  font-size: 14px;
  font-weight: 700;
  margin-top: 2px;
  margin-bottom: 17px;
}

.header-title {
  flex-shrink: 0;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 10px; /* Adds spacing between input and button */
}

.search-container {
  position: relative;
  display: inline-block;
  width: 150px;
}

.header-input {
  margin-left: auto;
  width: 150px;
  text-align: left;
  padding: 3px 3px 4px 8px;
  border-radius: 2px;
  background-color: #1d2532;
  color: #d9d9d9 !important;
  border: 1px solid #2f353d;
}

.header-input:focus {
  outline: none;
  border: 1px solid #3f4752;
}

.go-button {
  width: 35px;
  height: 25px;
  background-color: #1d2532;
  border: 1px solid #2f353d;
  color: #d9d9d9;
  padding: 0px 10px;
  border-radius: 2px;
  cursor: pointer;
  margin: 0 0 0 10px;
}

.clear-button {
  width: 55px;
  height: 25px;
  background-color: #1d2532;
  border: 1px solid #2f353d;
  color: #d9d9d9;
  padding: 0px 10px;
  border-radius: 2px;
  cursor: pointer;
  margin: 0 0 0 10px;
}

.autocomplete-list {
  position: absolute;
  top: 110%;
  left: 0;
  width: 193px;
  max-height: 300px;
  overflow-y: auto;
  background-color: #1d2532;
  border: 1px solid #2f353d;
  border-radius: 3px;
  z-index: 10;
  padding: 0;
}

.autocomplete-list li {
  padding: 3px 8px 4px;
  color: #d9d9d9;
  cursor: pointer;
  font-weight: 400;
}

.autocomplete-list li:hover {
  background-color: #2f353d;
}

</style>