<template>
  <div>{{ labelText }}</div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  target: {
    type: String,
    required: true,
  },
  retire: {
    type: Boolean,
    required: true,
  },
});

const labelText = computed(() => {
  if (props.retire) return "Retire"

  switch (props.target.toLowerCase()) {
    case 'ec2':
      return 'EC2';
    case 'rds':
      return 'RDS';
    case 'rdsm':
      return 'RDS x2';
    default:
      return 'Unknown';
  }
});
</script>