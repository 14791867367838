<template>
  <div class="tag-container">
    <div class="tags">
      <span v-for="tag in formattedTags" :key="tag.text" class="tag">
        <span class="tag-circle" :style="{ backgroundColor: tag.color }"></span>
        {{ tag.text }}
      </span>
    </div>

    <AddIcon class="add-icon" />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import AddIcon from "@/components/icons/AddIcon.vue";

const props = defineProps({ tags: Array });

const tagColors = {
  Prod: 'rgb(152,24,24)', // red
  UAT: '#13932c', // green
  Dev: 'rgb(29,64,187)', // blue
  Test: '#a6760c', // yellow
};

const hashColor = (tag) => {
  let hash = 0;
  for (let i = 0; i < tag.length; i++) {
    hash = tag.charCodeAt(i) + ((hash << 5) - hash);
  }
  return `hsl(${hash % 360}, 60%, 60%)`;
};

const formattedTags = computed(() =>
  props.tags.map((tag) => ({
    text: tag,
    color: tagColors[tag] || hashColor(tag),
  }))
);
</script>

<style scoped>
.tag-container {
  display: flex;
  align-items: center;
  gap: 6px;
  width: fit-content; /* Prevents stretching */
  max-width: 100%; /* Ensures it doesn't overflow */
}

.tags {
  min-width: 250px; /* Ensures tags take up at least 150px */
  display: flex;
  flex-wrap: wrap;
  flex-grow: 0; /* Prevents unnecessary stretching */
}

.add-icon {
  flex-shrink: 0; /* Prevents icon from shrinking */
  width: 15px;
  height: 15px;
  margin-top: 1px;
  margin-bottom: -1px;
  margin-left: 6px;
  cursor: pointer;
  color: #616b6e;
}

.add-icon:hover {
  color: #929b9d;
}

.tag-circle {
  margin-right: 3px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  margin-top: 3px;
  margin-bottom: -1.5px;
  display: inline-block;
}
</style>
