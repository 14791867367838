import { defineStore } from 'pinia';
import localForage from 'localforage';

export const useExportStore = defineStore('exportStore', {
  actions: {
    async loadState() {
      try {
        const savedState = await localForage.getItem('exportStore');
        return savedState?.exports || [];
      } catch (error) {
        console.error('Failed to load state:', error);
        return [];
      }
    },
    async saveState(data) {
      try {
        let data2 = data.map((item) => JSON.parse(JSON.stringify(item)));
        await localForage.setItem('exportStore', { exports: [...data2] });
      } catch (error) {
        console.error('Failed to save state:', error);
      }
    },
  },
});