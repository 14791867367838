<template>
  <div style="margin-top: 42px">
    <div class="container">
      <template v-if="props.activeTab === 0">
        <DetailsSysInfo :sysInfo="sysinfo" :uuid="uuid" />
      </template>
      <template v-else-if="props.activeTab === 1">
        <PricingWidget :pricing="pricing" :sysinfo="sysinfo" :uuid="uuid" @update:instance="triggerPriceUpdate" @update:selectedArchitecture="handleSelectedArchitectureChange" @update:Retired="handleRetired" />
      </template>
      <template v-else-if="props.activeTab === 2">
        <ProcessManager :uuid="uuid" :processInfo="processes" @update:hidden="handleHideProcess" />
      </template>
      <template v-else-if="props.activeTab === 3">
        <NetworkInfo :networkIn="networkIn" :networkOut="networkOut" />
      </template>
      <template v-else-if="props.activeTab === 4">
        <CpuAndMemWidget :uuid="uuid" :pricing="pricing"/>
      </template>
      <template v-else-if="props.activeTab === 5">
        <ConnectionsWidget :uuid="props.uuid" :host="sysinfo.host" :networking="networking" />
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import axios from 'axios';
import DetailsSysInfo from "@/components/discovery/SysInfo.vue";
import { useServerDataStore } from "@/stores/DataStore";
import NetworkInfo from "@/components/discovery/NetworkInfo.vue";
import PricingWidget from "@/components/discovery/PricingWidget.vue";
import ConnectionsWidget from "@/components/discovery/ConnectionsWidget.vue";
import CpuAndMemWidget from "@/components/util-chart/CpuMemChart.vue";
import ProcessManager from "@/components/discovery/process/ProcessManager.vue";

const props = defineProps({ uuid: String, outerdiv: Object, activeTab: Number });
const sysinfo = ref(null);
const processes = ref(null);
const networkIn = ref(null);
const networkOut = ref(null);
const pricing = ref(null);
const emit = defineEmits(['update:selectedArchitecture']);

const networking = ref(null);

let controller = null;
const store = useServerDataStore();

const triggerPriceUpdate = async () => {
  fetchData(props.uuid, true);
}

const fetchData = async (uuid, price_only) => {
  if (!uuid) return;

  if (controller) controller.abort(); // cancel old requests
  controller = new AbortController();
  const { signal } = controller;

  try {
    let request = `/go/server/${uuid}`
    if (price_only) request = `/go/server/${uuid}?po=1`

    const response = await axios.get(request, {
      headers: { 'Content-Type': 'application/json' },
      signal
    });

    const data = response.data
    
    if (data) {
      sysinfo.value = data.sysinfo;
      pricing.value = data.pricing;

      if (!price_only) {
        processes.value = data.process;
        networkIn.value = data.incoming;
        networkOut.value = data.outgoing;

        networking.value = {
          incoming: data.incoming,
          outgoing: data.outgoing
        };
      }

      // check if price only and update accordingly
      const existingServer = store.getServerByUuid(data.uuid);
      if (price_only && existingServer) {
        console.log("Update price on existing server")
        existingServer.sysinfo = data.sysinfo;
        existingServer.pricing = data.pricing;
        store.saveServer(data.uuid, existingServer);
      } else {
        store.saveServer(data.uuid, data); // update or save new complete server data
      }
    }

  } catch (error) {
    // cancelled requests and errors
  }
};

// update parent with arch change for this uuid
const handleSelectedArchitectureChange = (newArchitecture, newprice) => {
  sysinfo.value.target = newArchitecture;

  store.saveServer(props.uuid, {
    ...store.getServerByUuid(props.uuid),
    sysinfo: sysinfo.value,
  })

  emit('update:selectedArchitecture', newArchitecture, newprice);
};

const handleHideProcess = (name, hide) => {
  // get any with app group and set hide
  processes.value.forEach(proc => {
    if (proc.app_group === name) {
      proc.hidden = hide;
    }
  });

  // save to DB
  store.saveServer(props.uuid, {
    ...store.getServerByUuid(props.uuid),
    process: processes.value,
  });
};

// update parent with retire change for this uuid
const handleRetired = (toretire) => {
  sysinfo.value.retire = toretire;

  store.saveServer(props.uuid, {
    ...store.getServerByUuid(props.uuid),
    sysinfo: sysinfo.value,
  })

  emit('update:Retired', toretire);
};

// called on mount and on change
watch(() => props.uuid, (newUuid, oldUuid) => {
  if (newUuid !== oldUuid && newUuid) {
    const server = store.getServerByUuid(newUuid);
    if (server) {
      sysinfo.value = server.sysinfo;
      processes.value = server.process;
      networkIn.value = server.incoming;
      networkOut.value = server.outgoing;
      pricing.value = server.pricing

      networking.value = {
        incoming: server.incoming,
        outgoing: server.outgoing
      };
    }
    fetchData(newUuid, false);

    // scroll to top on each change, otherwise it retains previous scroll height
    if (props.outerdiv) {
      props.outerdiv.scrollTo(0, 0);
    }
  }
}, {immediate: true});

</script>

<style scoped>
.container {
  padding: 12px 0 20px;
  margin-left: 25px;
}
</style>
