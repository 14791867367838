import { defineStore } from 'pinia';
import localForage from "localforage";

const userDB = localForage.createInstance({name: 'CloudHound', storeName: 'users'});

export const useUserStore = defineStore('users', {
  state: () => ({
    users: []
  }),
  actions: {
    async save(data) {
      this.users = data;
      await userDB.setItem('users', data);
    },
    async load() {
      const storedUsers = await userDB.getItem('users');
      if (storedUsers) {
        this.users = storedUsers;
      }
    }
  },
  getters: {
    get: (state) => state.users,
  }
});