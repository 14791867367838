<template>
  <div>

    <div style="
        position: absolute;
        top: 83px;
        left: 25px;
        width: 1195px;
        height: 239px;
        z-index: -1;
        background: rgb(13, 17, 23);
        border: 1px solid #2f353d;
        border-radius: 4px;"
    >
    </div>
    <!-- Retire Checkbox -->
    <!-- eslint-disable vue/no-mutating-props -->

    <div style="display: flex; align-items: center; margin-bottom: 3px; margin-left: 0px;">
      <label style="width: 298px; font-weight: 600; margin-left: 10px;">Current Architecture</label>
      <label :class="{ 'content-disabled': toRetire }" style="width: 90px; font-weight: 600;">Instance Sizing</label>

      <label :class="{ 'content-disabled': toRetire }" style="color: #a7a7a7; font-size: 12px; width: 163px; text-align: right;">Uptime: {{ sysinfo.target === "ec2" ? pricing.uptime_strategy : 100 }}%</label>
      <ChevronDownIcon class="chevron" ref="chevron" :class="{ 'chevron-disable': sysinfo.target !== 'ec2' }" @click="() => toggleUptimeMenu(index)"/>
      <div v-if="uptimeMenu === index" :style="{ left: '540px', top: '73px' }" class="menu-popup">
        <ul>
          <li v-for="(option, optionIndex) in getUptimeOptions()" :key="optionIndex" @click="selectUptimeOption(item, option)">
            {{ option }}
          </li>
        </ul>
      </div>

      <div :class="{ 'content-disabled': toRetire }" style="width: 280px; font-weight: 600; margin-left: 325px;">
        {{ pricingLabel }}
      </div>
    </div>

    <div class="details" style="margin-left: 25px; margin-top: 10px;">
      <div class="column">
        <div class="item" style="font-weight: 600; margin-top: 13px;">
          <div class="label" style="opacity:0;">0</div>
        </div>
        <div class="item">
          <div class="label" style="width: 70px;">CPU</div>
        </div>
        <div class="item" style="margin-top: 20px">
          <div class="label" style="width: 70px;">MEM</div>
        </div>
        <div class="item" style="margin-top: 20px">
          <div class="label" style="width: 70px;">Storage</div>
        </div>
        <div class="item" style="font-size: 8pt; margin-left: 7px; margin-top: 8px; width: 60px;">
          <div :class="{ lowvalue: getArchitectureValue('iops') < 1000 }">- IOPS</div>
        </div>
        <div class="item" style="font-size: 7pt; margin-left: 7px; margin-top: 4px; width: 60px;">
          <div :class="{ lowvalue: getArchitectureValue('throughput') < 25 }">- MiB/s</div>
        </div>
        <div class="item" style="margin-top: 5px">
          <div :class="{ lowvalue: Math.round(getArchitectureValue('network_kb_min') / 1024.0 / 60.0) < 4 }" class="label" style="width: 70px;">Network</div>
        </div>

      </div>

      <div class="column">
        <div class="item" style="font-weight: 600; margin-top: 13px">
          <div class="label2" style="opacity:0;">0</div>
        </div>
        <div class="item">
          <div class="value">{{ getArchitectureValue('cpu') }}<span class="darker-white"> CPU</span></div>
        </div>
        <div class="item" style="margin-top: 20px">
          <div class="value">{{ getArchitectureValue('mem') }}<span class="darker-white"> GiB</span></div>
        </div>
        <div class="item" style="margin-top: 20px">
          <div class="value">{{ getArchitectureValue('total_disk') }}<span class="darker-white"> GiB</span>
          </div>
        </div>

        <!-- IOPs and Throughput -->
        <div class="item" style="margin-top: 8px">
          <div class="value" :class="{ lowvalue: getArchitectureValue('iops') < 1000 }">
            {{ getArchitectureValue('iops').toFixed(0) }}
          </div>
        </div>
        <div class="item" style="margin-top: 4px">
          <div class="value" :class="{ lowvalue: getArchitectureValue('throughput') < 25 }">
            {{ getArchitectureValue('throughput').toFixed(0) }}
          </div>
        </div>

        <div class="item" style="margin-top: 5px">
          <div :class="{ lowvalue: Math.round(getArchitectureValue('network_kb_min') / 1024.0 / 60) < 10 }"
               class="value">{{ (getArchitectureValue('network_kb_min') / 1024.0 / 60).toFixed(0) }}
            <span class="darker-white"> Mbps</span>
          </div>
        </div>
      </div>

      <div class="column" style="align-items: center; width: 48px;">
        <div class="">
          <div style="font-size: 8pt; color: rgb(161 161 161); margin-top: -24px; margin-bottom: 16px;">Avg</div>
        </div>
        <div class="" style="margin-top: 23px">
          <div class="">{{ Math.round(sysinfo.cpu_avg) }}<span class="darker-white">%</span></div>
        </div>
        <div class="" style="margin-top: 20px">
          <div class="">{{ Math.round(sysinfo.mem_avg) }}<span class="darker-white">%</span></div>
        </div>
        <div class="" style="margin-top: 20px">
          <div class="">{{
              ((getArchitectureValue('disk_used') / getArchitectureValue('total_disk')) * 100).toFixed(0)
            }}<span class="darker-white">%</span></div>
        </div>
      </div>

      <div class="column" style="align-items: center; width: 48px;">
        <div class="">
          <div style="font-size: 8pt; color: rgb(161 161 161); margin-top: -24px; margin-bottom: 16px;">Peak</div>
        </div>
        <div class="" style="margin-top: 23px">
          <div class="">{{ Math.round(getArchitectureValue('cpu_peak')) }}<span class="darker-white">%</span></div>
        </div>
        <div class="" style="margin-top: 20px">
          <div class="">{{ Math.round(getArchitectureValue('mem_peak')) }}<span class="darker-white">%</span></div>
        </div>
      </div>

      <!-- used to show a retired box -->
      <div style="display: flex;" :class="{ 'content-disabled': toRetire }">

        <!-- slim barrier -->
        <div class="barrier" style="height: 210px; width: 1px; background-color: #2f353d; margin-top: 15px; margin-left: 15px;"></div>

        <div style="margin-top: 30px; margin-left: 4px;">
          <UsageBars
              :cpu="sysInfo.vcpu"
              :mem="sysInfo.mem_gb"
              :newcpu="getArchitectureValue('new_cpu')"
              :newmem="getArchitectureValue('new_mem')"
              :disk="getArchitectureValue('total_disk')"
              :diskAvg="(getArchitectureValue('disk_used') / getArchitectureValue('total_disk')) * 100"
              :newdisk="getArchitectureValue('new_disk')"
              :cpuAvg="sysInfo.cpu_avg"
              :cpuPeak="getArchitectureValue('cpu_peak')"
              :memAvg="sysInfo.mem_avg"
              :memPeak="getArchitectureValue('mem_peak')"
              :cpu-buff="getArchitectureValue('cpu_buff')"
              :mem-buff="getArchitectureValue('mem_buff')"
          />

          <div v-if="getShowIO()" style="font-size: 11px; margin-top: 14px; width: 240px; text-align: center; margin-left: -5px;">Increased instance requirements due to Disk IO</div>
        </div>

        <!-- slim barrier -->
        <div class="barrier" style="height: 210px; width: 1px; background-color: #2f353d; margin-top: 15px;"></div>

        <!-- AWS NUMBERS PART -->
        <div>
          <!-- Label Spanning Columns -->
          <div style="font-weight: 600; margin-top: -25px; margin-bottom: 22px; display: flex;">
             <div style="width: 44px;">
              {{ getArchitectureValue('type') }}
            </div>

            <div style="width: 164px; text-align: center;">
              {{ getArchitectureValue('instance_view') }}
            </div>

            <div style="width: 46px; text-align: center; margin-top: 0.5px; margin-bottom: -5px; cursor: pointer;"
                 @click.stop="togglePopup" @mouseenter="onMixerMouseOver" @mouseleave="onMixerMouseOut">
              <MixerIcon />
            </div>

            <InstanceChooser :show="showPopup" :uuid="props.uuid" :database="getArchitectureValue('db_type')" :edition="getArchitectureValue('db_edition')" :arch="selectedArchitecture" @update:instance="updateInstance" @update:show="showPopup = $event" :hovered="isHovered" />
          </div>

          <div class="merged-column" style="margin-top: -15px;">

            <!-- HEADERS -->
            <div class="column" style="width: 45px; margin-top: 13px;">
              <div class="item" style="font-weight: 600; margin-top: 3px;">
                <div class="label" style="opacity:0;">0</div>
              </div>
              <div class="item">
                <div class="label">CPU</div>
              </div>
              <div class="item" style="margin-top: 20px">
                <div class="label">MEM</div>
              </div>
              <div class="item" style="margin-top: 20px">
                <div class="label">Storage</div>
              </div>
              <div class="item" style="margin-top: 25px;">
                <div class="label">Price</div>
              </div>
            </div>

            <!-- CURRENT VALUES -->
            <div style="width: 60px; margin-top: 13px; overflow: visible;">
              <div class="item" style="font-weight: 600; margin-top: 3px;">
                <div class="label" style="opacity:0;">0</div>
              </div>
              <div class="item">
                <div class="before-label">{{ getArchitectureValue('cpu') }}</div>
              </div>
              <div class="item" style="margin-top: 20px">
                <div class="before-label">{{ getArchitectureValue('mem') }}</div>
              </div>
              <div class="item" style="margin-top: 20px">
                <div class="before-label">{{ getArchitectureValue('total_disk') }}</div>
              </div>
              <div class="item" style="margin-top: 25px">
                <div class="before-label">${{ formatPriceZeroDP(getArchitectureValue('price_original')) }}</div>
              </div>

              <!-- OS Info && DB Infp -->
              <div class="item" style="top: 40px; position: relative; left: -45px; width: 270px; display: flex;">

                <div style="width: 95px;">
                  <span style="color: #ffffff66; margin-right: 6px;">OS:</span> {{ getOS() }}
                </div>

                <div><span style="color: #ffffff66; margin-right: 6px;">DB:</span>
                  <span style="display: inline-block; width: 100px;" :style="{ color: getDB() === 'None' ? '' : '' }">{{ getDB()}}</span>
                  <br>
                  <span style="display: inline-block; padding-left: 25px; margin-top: 2px;">{{ getArchitectureValue('db_edition') }}</span>
                </div>

                <div v-if="sysInfo['db_version']" style="margin-top: -1px;">
                  <template v-if="!isUnsupported && getDB() !== 'None'">
                    <IconCheck class="help" title="Supported in RDS"/>
                  </template>
                  <template v-else-if="isUnsupported">
                    <IconCross class="help-unsupported" title="Unsupported in RDS"/>
                  </template>
                </div>

              </div>

            </div>

            <!-- ARROWS -->
            <div style="width: 35px; margin-top: 13px;">
              <div class="item" style="font-weight: 600; margin-top: 6px;">
                <div class="label" style="opacity:0;">0</div>
              </div>
              <div class="item">
                <div class="arrow-label">
                  <template v-if="getArchitectureValue('new_cpu') > getArchitectureValue('cpu')">
                    <ArrowRightUp style="width:12px;height:12px"/>
                  </template>
                  <template v-else-if="getArchitectureValue('new_cpu') === getArchitectureValue('cpu')">
                    <ArrowRight style="width:12px;height:12px"/>
                  </template>
                  <template v-else>
                    <ArrowRightDown style="width:12px;height:12px"/>
                  </template>
                </div>
              </div>
              <div class="item" style="margin-top: 19px">
                <div class="arrow-label">
                  <template v-if="getArchitectureValue('new_mem') > getArchitectureValue('mem')">
                    <ArrowRightUp style="width:12px;height:12px"/>
                  </template>
                  <template v-else-if="getArchitectureValue('new_mem') === getArchitectureValue('mem')">
                    <ArrowRight style="width:12px;height:12px"/>
                  </template>
                  <template v-else>
                    <ArrowRightDown style="width:12px;height:12px"/>
                  </template>
                </div>
              </div>
              <div class="item" style="margin-top: 19px">
                <div class="arrow-label">
                  <template v-if="getArchitectureValue('total_disk') < getArchitectureValue('new_disk')">
                    <ArrowRightUp style="width:12px;height:12px"/>
                  </template>
                  <template v-else-if="getArchitectureValue('total_disk') === getArchitectureValue('new_disk')">
                    <ArrowRight style="width:12px;height:12px"/>
                  </template>
                  <template v-else>
                    <ArrowRightDown style="width:12px;height:12px"/>
                  </template>
                </div>
              </div>

              <!-- price arrow -->
              <div class="item" style="margin-top: 25px">
                <div class="arrow-label">
                  <template v-if="getArchitectureValue('price_total') < getArchitectureValue('price_original')">
                    <ArrowRightDown style="width:12px;height:12px"/>
                  </template>
                  <template v-else-if="getArchitectureValue('price_total') === getArchitectureValue('price_original')">
                    <ArrowRight style="width:12px;height:12px"/>
                  </template>
                  <template v-else>
                    <ArrowRightUp style="width:12px;height:12px"/>
                  </template>
                </div>
              </div>
            </div>

            <!-- NEW VALUES + PRICE -->
            <div style="width: 80px; margin-top: 3px;">
              <div class="label" style="opacity:0;">0</div>
              <div style="margin-top: 13px;">
                {{ getArchitectureValue('new_cpu') }} <span class="darker-white"> CPU</span>
              </div>
              <div style="margin-top: 20px">
                {{ getArchitectureValue('new_mem') }} <span class="darker-white"> GiB</span>
              </div>
              <div style="margin-top: 20px">{{ getArchitectureValue('new_disk') }}<span class="darker-white"> GiB</span>
              </div>
              <div></div>
              <div style="margin-top: 25px;">${{ formatPriceZeroDP(getArchitectureValue('price_total')) }}<span
                  class="darker-white"></span></div>
            </div>

            <div style="width: 35px; margin-top: 32px;">
              <div>{{
                  Math.round(getArchitectureValue('cpu_peak') * (getArchitectureValue('cpu') / getArchitectureValue('new_cpu')))
                }}<span class="darker-white">%</span></div>
              <div style="margin-top: 20px;">{{
                  Math.round(getArchitectureValue('mem_peak') * (getArchitectureValue('mem') / getArchitectureValue('new_mem')))
                }}<span class="darker-white">%</span></div>
              <div style="margin-top: 20px">
                {{ Math.round((getArchitectureValue('disk_used') / getArchitectureValue('new_disk')) * 100) }}<span
                  class="darker-white">%</span></div>
            </div>

          </div>
        </div>

        <!-- NEW PEAK -->

        <!-- slim barrier -->
        <div class="barrier" style="height: 210px; width: 1px; background-color: #2f353d; margin-top: 15px; margin-left: 15px;"></div>

        <!-- PRICE AND SAVINGS -->
        <div style="display: flex; flex-direction: row; align-items: flex-start; margin-top: 10px;">

          <div style="display: flex; flex-direction: column; width: 200px">
            <div class="row-item" style="margin-top: 13px">
              <div class="price-label">Instance</div>
            </div>
            <div class="row-item">
              <div class="price-label" style="margin-top: 3px">{{ licenceLabel }}</div>
            </div>
            <div class="row-item">
              <div class="price-label" style="margin-top: 3px">
                {{ savingsLabel }}
              </div>
            </div>
            <div class="row-item">
              <div class="price-label" style="margin-top: 3px">Storage</div>
            </div>
            <div class="row-item" :class="{ 'darker-white': getArchitectureValue('price_backup') === 0 }">
              <div class="price-label" style="margin-top: 3px">Backups</div>
            </div>
            <div class="row-item" :class="{ 'darker-white': getArchitectureValue('price_iops') === 0 }">
              <div class="price-label" style="margin-top: 5px">IOPS</div>
            </div>
            <div class="row-item" :class="{ 'darker-white': getArchitectureValue('price_throughput') === 0 }">
              <div class="price-label" style="margin-top: 6px">MiB/s</div>
            </div>
            <div class="row-item" style="margin-top: 14px">
              <div class="price-label">Total</div>
            </div>

            <!-- don't show if negative -->
            <div
                v-if="(100 - (getArchitectureValue('price_total') / getArchitectureValue('price_original')) * 100) >= 0"
                style="margin-top: 16px; font-weight: 500; color: rgb(143 191 138); font-size: 14px;  width: 260px;  text-align: center;">
              {{
                Math.round(100 - (getArchitectureValue('price_total') / getArchitectureValue('price_original')) * 100)
              }}% Savings
            </div>
          </div>

          <div style="display: flex; flex-direction: column; text-align: right; width: 62px;">

            <div class="row-item" style="margin-top: 13px">
              <div class="valueright">${{ formatPrice(getArchitectureValue('price_os')) }}</div>
            </div>

            <div>
              <div class="row-item" :class="{ 'darker-white': +(getArchitectureValue('price_instance') - getArchitectureValue('price_os')) === 0 }">
                <div class="valueright" style="margin-top: 3px">
                  ${{ formatPrice(getArchitectureValue('price_instance') - getArchitectureValue('price_os')) }}
                </div>
              </div>
            </div>

            <div class="row-item"
                 :class="{ 'darker-white': +(getArchitectureValue('price_instance') - getArchitectureValue('price_nusp')) === 0 }">
              <div class="valueright" style="margin-top: 3px; color: rgb(148 198 142);">
                -${{ formatPrice(getArchitectureValue('price_instance') - getArchitectureValue('price_nusp')) }}
              </div>
            </div>
            <div class="row-item">
              <div class="valueright" style="margin-top: 3px">${{ getArchitectureValue('price_disk').toFixed(2) }}</div>
            </div>

            <div>
              <div v-if="selectedArchitecture === 'rds' || selectedArchitecture === 'rdsm'">
                <div class="row-item darker-white">
                  <div class="valueright" style="margin-top: 3px">Included</div>
                </div>
              </div>
              <div v-else>
                <div class="row-item" :class="{ 'darker-white': +getArchitectureValue('price_backup') === 0 }">
                  <div class="valueright" style="margin-top: 3px">${{
                      formatPrice(getArchitectureValue('price_backup'))
                    }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row-item" :class="{ 'darker-white': getArchitectureValue('price_iops') === 0 }">
              <div class="valueright" style="margin-top: 3px">${{ formatPrice(getArchitectureValue('price_iops')) }}</div>
            </div>
            <div class="row-item" :class="{ 'darker-white': getArchitectureValue('price_throughput') === 0 }">
              <div class="valueright" style="margin-top: 5px">${{
                  formatPrice(getArchitectureValue('price_throughput'))
                }}
              </div>
            </div>
            <div class="row-item" style="margin-top: 14px">
              <div class="valueright" style="margin-top: 6px">${{
                  formatPrice(getArchitectureValue('price_total'))
                }}
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>

    <div style="display: flex;">

      <div
        class="radio-container"
        style="display: flex; align-items: center; margin-left: 20px; margin-top: -3px;">
        <div class="radio-inputs" style="display: flex;">
          <label class="radio">
            <input type="radio" name="radio2" :value="false" v-model="toRetire" @change="handleRetireChange">
            <span class="name">Retain</span>
          </label>
          <label class="radio">
            <input type="radio" name="radio2" :value="true" v-model="toRetire" @change="handleRetireChange">
            <span class="name">Retire</span>
          </label>
        </div>
      </div>

      <div class="radio-container" :class="{ 'content-disabled': toRetire }"
           style="display: flex; align-items: center; margin-left: 49px; margin-top: -3px;">
        <div class="radio-inputs" style="display: flex;">
          <label class="radio">
            <input type="radio" name="radio" value="ec2" v-model="selectedArchitecture"
                   @change="onSelectedArchitectureChange">
            <span class="name">EC2</span>
          </label>
          <label class="radio" style="width: 40px;">
            <input type="radio" name="radio" value="rds" v-model="selectedArchitecture"
                   @change="onSelectedArchitectureChange">
            <span class="name">RDS</span>
          </label>
          <label v-if="sysinfo['db_edition'] !== 'Web' && sysinfo['db_edition'] !== 'Express'" class="radio" style="width: 20px;">
            <input type="radio" name="radio" value="rdsm" v-model="selectedArchitecture"
                   @change="onSelectedArchitectureChange">
            <span class="name">RDS x2</span>
          </label>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import {reactive, watch, ref, computed, onMounted, onUnmounted, refs } from 'vue';
import UsageBars from "@/components/discovery/UsageBar.vue";
import ArrowRight from "@/components/icons/ArrowRight.vue";
import ArrowRightUp from "@/components/icons/ArrowRightUp.vue";
import ArrowRightDown from "@/components/icons/ArrowRightDown.vue";
import axios from 'axios';
import IconCheck from "@/components/icons/IconCheck.vue";
import IconCross from "@/components/icons/IconCross.vue";
import MixerIcon from "@/components/icons/MixerIcon.vue";
import InstanceChooser from "@/components/discovery/InstanceChooser.vue";
import ChevronDownIcon from "@/components/icons/ChevronDownIcon.vue";

const emit = defineEmits(['update:selectedArchitecture', 'update:Retired', 'update:instance']);

const showPopup = ref(false);
const isHovered = ref(false);

const togglePopup = () => {
  showPopup.value = !showPopup.value;
};

const onMixerMouseOver = () => {
  isHovered.value = true;
};

const onMixerMouseOut = () => {
  isHovered.value = false;
};

const handleOutsideClick = (event) => {
  if (!event.target.closest('.menu-popup') && !event.target.closest('.chevron')) {
    uptimeMenu.value = null;
  }
};

onMounted(() => {
  document.addEventListener('click', handleOutsideClick);
});

onUnmounted(() => {
  document.removeEventListener('click', handleOutsideClick);
});

// Props
const props = defineProps({
  pricing: {
    type: Object,
    required: true,
  },
  sysinfo: {
    type: Object,
    required: true,
  },
  uuid: {
    type: Object,
    required: true,
  },
});

// Local state
const selectedArchitecture = ref('ec2'); // Set default value to 'EC2'
const pricingInfo = reactive({...props.pricing});
const sysInfo = reactive({...props.sysinfo});
const toRetire = ref(sysInfo.retire);

const isUnsupported = ref(false);

const uptimeMenu = ref(null);

const popupPosition = reactive({ top: 0, left: 0 });

const toggleUptimeMenu = (index) => {
  if (props.sysinfo.target !== "ec2") {
    return
  }

  uptimeMenu.value = uptimeMenu.value === index ? null : index;

  const chevron = refs.chevron?.$el || refs.chevron;
  if (chevron) {
    const rect = chevron.getBoundingClientRect();
    popupPosition.top = rect.bottom + window.scrollY; // Position below the chevron
    popupPosition.left = rect.left + window.scrollX;  // Align left with chevron
  }

};

const getUptimeOptions = () => {
  let options = [];
  options.push("100%");
  options.push("75%");
  options.push("24%");
  return options;
};

const selectUptimeOption = async (item, option) => {
  console.log(`Uptime ${option} selected for item ${item}`);
  let uptime = parseInt( option.replace("%", ""), 10);

  // eslint-disable-next-line vue/no-mutating-props
  props.pricing.uptime_strategy = uptime; // update immediately

  uptimeMenu.value = null; // close menu4

  try {
    await axios.post(`/go/portfolio/uptime`,
      { uuid: props.uuid, uptime: uptime },
      {
        headers: { 'Content-Type': 'application/json' }
      }
    );
  } catch (error) {
    console.error("Error updating Uptime strategy:", error);
  }

  updateInstance();
};


watch(
    () => props.sysinfo.retire,
    (newRetire) => {
      toRetire.value = newRetire;
    },
    {immediate: true}
);


// Watcher to react to changes in the prop
watch(
    () => props.pricing,
    (newPricing) => {
      if (newPricing) {
        Object.assign(pricingInfo, newPricing);
        isUnsupported.value = getDbUnsupported() === "Unsupported";
      }
    },
    {immediate: true}
);

watch(
    () => props.sysinfo,
    (newSysinfo) => {
      if (newSysinfo) {
        Object.assign(sysInfo, newSysinfo);
        selectedArchitecture.value = sysInfo.target
        toRetire.value = sysInfo.retire

        isUnsupported.value = getDbUnsupported() === "Unsupported";
        // console.log("sysinfo watch called, to retire: " + sysInfo.retire)
      }
    },
    {immediate: true}
);

const savingsLabel = computed(() => {
  if (!selectedArchitecture.value) return ' ';
  return selectedArchitecture.value.startsWith('rds') ? 'Reserved Instance' : 'Savings Plan';
});

const licenceLabel = computed(() => {
  if (!selectedArchitecture.value) return ' ';
  return selectedArchitecture.value.startsWith('rds') ? 'DB Licence' : 'OS Licence';
});

function getShowIO() {

  // return the actual DB result for EC2
  if (props.sysinfo.target === 'ec2') {
    return props.pricing.ec2_io_limited;
  }

  // not yet implemented for RDS
  let high_io = getArchitectureValue('iops') >= 1000;
  let high_th = getArchitectureValue('throughput') >= 25;

  let cpu = getArchitectureValue('cpu')
  let mem = getArchitectureValue('mem')
  let new_cpu = getArchitectureValue('new_cpu')
  let new_mem = getArchitectureValue('new_mem')

  let new_higher = new_cpu > cpu || new_mem > mem;
  return new_higher && (high_io || high_th)
}

// toggle retire checkbox
const handleRetireChange = async () => {
  emit('update:Retired', toRetire.value);

  console.log("Retire changes to " + toRetire.value);

  try {
    await axios.post(`/go/retire`, {
      uuid: props.uuid,
      retire: toRetire.value,
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {
    console.error('Error during POST request:', error);
  }

};

async function onSelectedArchitectureChange(event) {
  const selectedArch = event.target.value;

  // don't allow switching to Multi-AZ if SQL Standard Web or Express
  if (selectedArch === "rdsm" && (sysInfo["db_edition"] === "Web" || sysInfo["db_edition"] === "Express")) {
    selectedArchitecture.value = "rds" // revert to rds single AZ
    return
  }

  // Update the target price item
  pricingInfo.target_price =
      selectedArch === "ec2" ? pricingInfo.price_total :
      selectedArch === "rds" ? pricingInfo.rds_price_total :
      selectedArch === "rdsm" ? pricingInfo.rds_maz_price_total : null;

  // Emit the selectedArchitecture value to the parent
  emit('update:selectedArchitecture', selectedArch, pricingInfo.target_price);

  // Update server
  try {
    await axios.post(`/go/target`, {
      uuid: props.uuid,
      target: selectedArch,
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {
    console.error('Error during POST request:', error);
  }
}

function getOS() {
  let os = sysInfo["os"]

  if (os==="windows") return "Windows"
  return "Linux"
}

const pricingLabel = computed(() => {
  if (selectedArchitecture.value === "ec2") {
    if (sysInfo.os === "windows") return "EC2 - Windows Server";
    if (sysInfo.platform === "sles") return "EC2 - SUSE Enterprise Linux";
    if (sysInfo.platform === "redhat") return "EC2 - Red Hat Enterprise Linux";
    return "EC2 - Linux";
  }
  if (sysInfo.db_type === "sqlservr") return "RDS - SQL Server " + sysInfo.db_edition;
  if (sysInfo.db_type === "oracle") {
    if (sysInfo.db_edition === "Free") {
      return "RDS - Oracle DB Standard Two";
    }
    return "RDS - Oracle DB " + sysInfo.db_edition;
  }
  if (sysInfo.db_type === "db2") {
    if (sysInfo.db_edition === "Community") {
      return "RDS - Db2 Standard";
    }
    return "RDS - IBM Db2 " + sysInfo.db_edition;
  }
  if (sysInfo.db_type === "mariadb") return "RDS - MariaDB";
  if (sysInfo.db_type === "mysql") return "RDS - MySQL";
  if (sysInfo.db_type === "postgres") return "RDS - Postgres";
  return "RDS - Postgres";
});


function getDB() {
  let db = sysInfo["db_type"]
  if (db===null) return "None"

  if (db==="sqlservr") {
    // new style
    if (sysInfo["db_version"].length === 4) return "SQL Server " + sysInfo["db_version"]

    // older style - version has SQL Server in it
    if (sysInfo["db_version"]) return sysInfo["db_version"];

    // if version not present just return SQL Server
    return "SQL Server";
  }
  if (db==="mariadb") {
    if (sysInfo["db_version"]) return "MariaDB " + sysInfo["db_version"]
    return "MariaDB"
  }
  if (db==="mysql") {
    if (sysInfo["db_version"]) return "MySQL " + sysInfo["db_version"]
    return "MySQL"
  }
  if (db==="db2") {
    if (sysInfo["db_version"]) return "Db2 " + sysInfo["db_version"]
    return "Db2"
  }
  if (db==="oracle") {
    if (sysInfo["db_version"]) return "Oracle DB " + sysInfo["db_version"]
    return "Oracle DB"
  }
  if (db==="postgres") {
    if (sysInfo["db_version"]) return "PostgreSQL " + sysInfo["db_version"]
    return "PostgreSQL"
  }

  return "None"
}

function getDbUnsupported() {
  const type = sysInfo["db_type"];
  const version = sysInfo["db_version"];
  if (!version || !type) {
    return "";
  }

  let unsupported = false;

  // SQL Server 2016
  if (type === "sqlservr") {

    // new style version is a number only, 2022 etc
    if (version.length === 4) {
      const year = parseInt(version);
      if (year < 2016) {
        unsupported = true;
      }
      return unsupported ? "Unsupported" : "";
    }

    // old version format is 'SQL Server YYYY'
    const versionParts = version.split(" ");
    const year = parseInt(versionParts[2]);
    if (year < 2016) {
      unsupported = true;
    }

    return unsupported ? "Unsupported" : "";
  }

  // Oracle 21c and 19b only
  if (type === "oracle") {
    unsupported = true;
    if (version === "21c" || version === "19c") {
      unsupported = false;
    }
    return unsupported ? "Unsupported" : "";
  }

  // for other databases, use major/minor/patch
  const versionRegex = /(\d+)\.(\d+)(?:\.(\d+))?/;

  // eslint-disable-next-line no-unused-vars
  const [_, major, minor, patch] = version.match(versionRegex).map(x => Number(x) || 0); // 0 if a part does not exist

  // PostgreSQL 11.22
  if (type === "postgres") {
    if (major < 11 || (major === 11 && minor < 22)) {
      unsupported = true;
    }
  }

  // MariaDB 10.4.29
  if (type === "mariadb") {
    if (major < 10 || (major === 10 && minor < 4) || (major === 10 && minor === 4 && (patch === undefined || patch < 29))) {
      unsupported = true;
    }
  }

  // DB2 11.5
  if (type === "db2") {
    if ((major < 11) || (major === 11 && minor < 5)) {
      unsupported = true;
    }
  }

  // MySQL 5.7.44 or >8.0.32
  if (type === "mysql") {
    if (major < 8 || (major === 5 && minor === 7 && (patch === undefined || patch !== 44)) || (major === 8 && minor === 0 && (patch === undefined || patch < 32))) {
      unsupported = true;
    }
  }

  return unsupported ? "Unsupported" : "";
}

// if RDS is selected, it gets the RDS value, if not present,
// the pricingInfo value, if not present, the sysInfo value
function getArchitectureValue(key) {

  if (key==='type') {
    if (selectedArchitecture.value === 'rds') return 'RDS';
    if (selectedArchitecture.value === 'rdsm') return 'RDS x2';
    if (selectedArchitecture.value === 'ec2') return 'EC2';
  }

  let value;
  if (selectedArchitecture.value === 'rds' || selectedArchitecture.value === 'rdsm') {

    if (key === "instance_view") {
      return pricingInfo['rds_instance'] + (sysInfo["rds_override"]?" *":"");
    }

    // rds pricing with licensing
    if (selectedArchitecture.value === 'rds') {
      if (key === "price_os") {
        return pricingInfo['rds_db_price']
      }
    } else {
      if (key === "price_os") {
        return pricingInfo['rds_db_price_mz']
      }
    }

    // switch to rds prices
    const rdsMapping = {
      instance: 'rds_instance',
      new_cpu: 'rds_cpu',
      new_mem: 'rds_mem',
      new_disk: 'rds_disk',
      price: 'rds_price',
      price_original: 'rds_price_original',
      price_total: 'rds_price_total',
      price_instance: 'rds_price',
      price_os: 'rds_db_price',
      price_nusp: 'rds_price_ri',
      price_disk: 'rds_disk_price',
      price_backup: 'rds_price_backup',
      price_iops: 'rds_iops_price',
      price_throughput: 'rds_thrpt_price'
    };

    // multi-az specific
    if (selectedArchitecture.value === 'rdsm') {
      rdsMapping.price_instance = 'rds_price_mz';
      rdsMapping.price_os = 'rds_db_price_mz';
      rdsMapping.price_nusp = 'rds_price_ri_mz';
      rdsMapping.price_original = 'rds_maz_price_total_orig';
      rdsMapping.price_total = 'rds_maz_price_total'
    }

    // Use rdsMapping[key] if the key exists in rdsMapping, otherwise use key
    const mappedKey = key in rdsMapping ? rdsMapping[key] : key;
    value = pricingInfo[mappedKey];

    // list of keys which values should be doubled if rds multi-az
    const keysToDouble = ['rds_disk_price', 'rds_iops_price', 'rds_thrpt_price'];

    if (selectedArchitecture.value === 'rdsm' && keysToDouble.includes(mappedKey)) {
      value *= 2;
    }

    if (selectedArchitecture.value === 'rdsm' && mappedKey === 'rds_instance') {
      value = value + " x2"
    }

  } else {

    // specific to the instance label
    if (key === "instance_view") {
      return pricingInfo['instance'] + (sysInfo["ec2_override"]?" *":"");
    }

    value = pricingInfo[key];
  }

  // If value is undefined or null, fall back to sysInfo
  if (value === undefined || value === null) {
    value = sysInfo[key];
  }

  if (value !== undefined || value != null) {
    return value;
  }

  return ""
}

const formatPrice = (value) => {
  return Number(value).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

const formatPriceZeroDP = (value) => {
  return Number(value).toLocaleString("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 });
};

function updateInstance() {
  emit('update:instance');
}

</script>

<style scoped>
.details {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0px;
  margin-top: 10px;
  height: 200px;
}

.column {
  display: flex;
  flex-direction: column;
}

.radio-container {
  align-items: center;
  margin-bottom: 13px;
}

.radio-inputs {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  border-radius: 4px;
  background-color: #273646;
  box-sizing: border-box;
  box-shadow: 0 0 0 1px #0000000f;
  padding: 0.15rem;
  width: 245px;
  font-size: 14px;
  border-color: #434f5c;
  border-width: .5px;
  border-style: solid;
}

.radio-inputs .radio {
  flex: 1 1 auto;
  text-align: center;
}

.radio-inputs .radio input {
  display: none;
}

.radio-inputs .radio .name {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  border: none;
  padding: 4px 0;
  color: #e4e4e4;
  transition: all .1s ease-in-out;
  user-select: none;
}

.radio-inputs .radio input:checked + .name {
  background-color: #3c4e61;
  font-weight: 600;
}

.checkbox-container label {
  display: flex;
  align-items: center;
  gap: 5px;
}

.item {
  display: flex;
}

.label {
  width: 80px;
  text-align: left;
  margin: 0 !important;
}

.value {
  text-align: center;
  margin: 0 !important;
}

.darker-white {
  color: rgba(255, 255, 255, 0.4);
}

.lowvalue {
  color: rgba(255, 255, 255, 0.4);
}

.before-label {
  text-align: right;
  width: 100px;
  margin: 0 !important;
  color: rgba(255, 255, 255, 0.4);
}

.arrow-label {
  text-align: center;
  width: 100px;
  margin: 0 !important;
  color: rgba(255, 255, 255, 0.4);
}

.row-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 3px; /* Adjust for spacing between rows */
}

.row-item::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  border-bottom: 1px dashed #464646;
  z-index: 0;
}

.label, .value {
  position: relative;
  z-index: 1;
}

.valueright {
  text-align: right;
  width: 110px;
  margin: 0 !important;
}

.label {
  width: 85px;
  text-align: left;
  margin: 0 !important;
}

.label2 {
  width: 69px;
  text-align: left;
  margin: 0 !important;
}

.price-label {
  padding-left: 2px;
  width: 110px;
  text-align: left;
  margin: 0 !important;
}

.merged-column {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.barrier {
  margin-left: 25px;
  margin-right: 25px;
}

.details {
  display: flex;
  justify-content: flex-start;
}

/* disable and darken content when retired */
.content-disabled {
  filter: opacity(0.25);
  pointer-events: none; /* prevent interaction */
}

.help {
  width: 15px;
  height: 15px;
  margin-top: 0px;
  position: relative;
  top: 2px;
  color: #8fbf8a;
  left: 6px;
}

.help-unsupported {
  width: 15px;
  height: 15px;
  margin-top: 0px;
  position: relative;
  top: 2px;
  color: #f18127;
  left: 6px;
}

.chevron {
  width: 10px;
  margin-bottom: -3.5px;
  cursor: pointer;
  padding-left: 3px;
  padding-right: 3px;
}

.chevron-disable {
  cursor: default;
  color: transparent;
}

.menu-popup {
  position: absolute;
  background-color: #11161e;
  border: 1px solid #2f353d;
  border-radius: 3px;
  z-index: 1000;
  width: 70px;
  box-shadow: 0 8px 16px 0 #0003;
  margin-top: 6px;
  margin-left: -5px;
  text-align: center;
  color: #cccccc;
}

.menu-popup ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-popup li {
  padding: 4px 5px;
  cursor: pointer;
}

.menu-popup li:hover {
  background-color: #1d2532;
}
</style>
