<template>
  <div style="display: flex; align-items: center;">
    {{ formattedLicences }}
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';

const licences = ref(Number(localStorage.getItem('licences')) || 0);

const fetchLicences = async () => {
  try {
    const response = await axios.get('/go/licences');
    licences.value = response.data.licences;
    localStorage.setItem('licences', licences.value);
  } catch (error) {
    console.error('failed to fetch licences', error);
  }
};

const formattedLicences = computed(() => licences.value.toLocaleString());

onMounted(fetchLicences);
</script>
