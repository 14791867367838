<template>
  <AllConnectionsWidget :components="components" />
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import AllConnectionsWidget from "@/mapping/AllConnectionsWidget.vue";
const components = ref(null); // Define a ref to hold the fetched data

onMounted(async () => {
  await fetchNetworking();
});

async function fetchNetworking() {
  const response = await axios.get('/go/mapping', {
    headers: { 'Content-Type': 'application/json' }
  });
  components.value = response.data.components;
}
</script>