<template>
  <div v-if="show" class="modal-overlay">
    <div class="modal-content">
      <div class="header">{{ isEditing ? 'Edit User' : 'Add User' }}</div>
      <div class="modal-body">
        <div class="modal-label">Email</div>
        <input v-model="formData.email"
               id="portfolioId" type="text" class="modal-input"
               autocomplete="off" ref="portfolioIdInput"
               :disabled="isEditing" :class="{ 'disabled-input': isEditing }"
        />

        <div class="modal-label">Firstname</div>
        <input v-model="capitalizedFirstname" id="customer" type="text" class="modal-input" autocomplete="off"/>

        <div class="modal-label">Lastname</div>
        <input
            v-model="capitalizedLastname"
            id="description"
            type="text"
            class="modal-input"
            autocomplete="off"
            @keydown.enter="submitForm"
        />
      </div>
      <div class="modal-actions">
        <div class="error-message">{{ errorMessage }}</div>
        <button @click="submitForm" class="modal-button">{{ isEditing ? 'Update' : 'Submit' }}</button>
        <button @click="closeModal" class="modal-button modal-close">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits, computed, onMounted } from 'vue';

const emit = defineEmits(['close', 'submit']);


// modal visibility and error handling
const show = ref(true);
const errorMessage = ref('');
const formData = ref({
  email: '',
  firstname: '',
  lastname: '',
});

// used to focus on email
const portfolioIdInput = ref(null);

onMounted(() => {
  portfolioIdInput.value.focus();
});

const capitalizedFirstname = computed({
  get: () => formData.value.firstname,
  set: (value) => formData.value.firstname = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
});

const capitalizedLastname = computed({
  get: () => formData.value.lastname,
  set: (value) => formData.value.lastname = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
});


const validateForm = () => {
  formData.value.email = formData.value.email.trim();
  formData.value.firstname = formData.value.firstname.trim();
  formData.value.lastname = formData.value.lastname.trim();

  if (!formData.value.email || !formData.value.firstname || !formData.value.lastname) {
    errorMessage.value = 'All fields must be entered.';
    return false;
  }

  const emailRegex = /.+@.+\..+/;

  if (!emailRegex.test(formData.value.email)) {
    errorMessage.value = 'Invalid email format.';
    return false;
  }

  errorMessage.value = '';
  return true;
};

const submitForm = () => {
  if (validateForm()) {
    closeModal();

    // capitalise
    formData.value.firstname = formData.value.firstname.charAt(0).toUpperCase() + formData.value.firstname.slice(1).toLowerCase();
    formData.value.lastname = formData.value.lastname.charAt(0).toUpperCase() + formData.value.lastname.slice(1).toLowerCase();

    emit('submit', formData.value);
  }
};

const closeModal = () => {
  show.value = false;
  emit('close'); // required to reset modal
};
</script>

<style scoped>
.header {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 15px;
  width: 100%;
  background: #0d1117;
  margin-top: -15px;
  padding: 12px 19px 12px 21px;
  margin-left: -20px;
  border-radius: 3px;
  border-bottom: 1px solid #2f353d;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00000085;
  display: flex;
  justify-content: center;
  align-items: flex-start; /* align to the top */
  z-index: 100;
  padding-top: calc(100vh / 4);
}

.modal-content {
  background-color: #06080A;
  padding: 15px 20px 15px;
  border: 1px solid #2f353d;
  border-radius: 4px;
  width: 400px;
  max-width: 90vw;
  box-shadow: 0 0 58px #ffffff08;
}

.modal-input:focus {
  outline: none;
  box-shadow: none;
  border-color: #2f353d;
}

.modal-input {
  width: 358px;
  background-color: #0D1117;
  border: 1px solid #2f353d;
  color: #d9d9d9;
  font-size: 12px;
  padding: 6px 30px 7px 10px;
  border-radius: 4px 4px 4px 4px;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.modal-button {
  padding: 6px 12px;
  margin-top: 20px;
  margin-bottom: 0px;
}

.modal-close {
  background-color: #1d232d;
  color: #fff;
  border: 1px solid #2f353d;
  margin-left: 13px;
}

.modal-label {
  display: block;
  margin-bottom: 4px;
  margin-top: 10px;
  font-size: 12px;
  color: #d4d4d4;
}

.error-message {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff4d4f;
  font-size: 12px;
  font-weight: 600;
  margin-right: 0px;
  padding-right: 20px;
  width: 250px;
  height: 30px;
  margin-top: 15px;
  text-align: center;
}

.disabled-input {
  opacity: 0.5;
}
</style>