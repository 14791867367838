<template>
  <div class="rating-bars">
    <div
      v-for="n in 4"
      :key="n"
      :class="['bar', { active: n <= activeBars }]"
      :style="getBarStyle(n)"
      :title="value ? getTooltipScale(value) : ''">
      <div class="bar-fill" :style="getBarFillStyle(n)"></div>
    </div>
  </div>
</template>

<script setup>
import { useDarkModeStore } from "@/stores/DataStore";
import { computed, toRefs, ref, onMounted } from 'vue';

const props = defineProps({
  value: {
    type: Number,
    required: true,
    default: 0
  }
});

const store = useDarkModeStore();
const isDark = computed(() => store.darkmode);

const { value } = toRefs(props);
const animatedBars = ref(0);

function getTooltipScale(value) {
  let val = (value / 360)*100;
  if (val > 100) {val = 100;}
  return val.toFixed(1) + ' ' + value.toFixed(1);
}

function gradeValue(value, maxValue = 360, minGrade = 0, maxGrade = 4) {
  value = Math.max(0, Math.min(value, maxValue));
  const base = 1.5; // decrease to bias higher vol levels, increase to decrease vol levels
  const scaledValue = Math.log(value + 1) / Math.log(maxValue + 1);
  const grade = minGrade + (maxGrade - minGrade) * Math.pow(scaledValue, base);
  return Math.round(grade);
}

const activeBars = computed(() => {
  const scaledValue = gradeValue(value.value);
  return Math.round(scaledValue);
});

const getColor = (n) => {
  if (value.value === 0) return '#a9a9a9';

  const colors = ['#2786ab', '#359bb9', '#41afc5', '#4fc4d7'];
  return colors[n - 1];
};

const getBarStyle = (n) => {
  const minHeight = 4;
  const maxHeight = 12;
  const height = minHeight + ((n - 1) / 3) * (maxHeight - minHeight);

  return {
    height: `${height}px`,
    backgroundColor: isDark.value ? '#253241' : '#e0e0e0'
  };
};

const getBarFillStyle = (n) => {
  const isActive = n <= activeBars.value;
  const isAnimated = n <= animatedBars.value;

  return {
    backgroundColor: getColor(n),
    width: '100%',  // Always set to 100% to allow opacity animation
    opacity: isAnimated && isActive ? 1 : 0
  };
};

onMounted(() => {
  const animationInterval = 7; // ms between each bar animation
  animatedBars.value = 0;
  for (let i = 1; i <= 4; i++) {
    setTimeout(() => {
      animatedBars.value = i;
    }, i*i * animationInterval);
  }
});
</script>

<style scoped>
.rating-bars {
  display: flex;
  align-items: flex-end;
  height: 16px;
  margin-left: 9px;
}

.bar {
  width: 3px;
  margin-right: 1px;
  position: relative;
  overflow: hidden;
}

.bar-fill {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: opacity 0.5s ease;
  border-radius: 3px;
}
</style>