<template>
  <div class="usage-bars">

    <div class="usage-bar cpu-bar">
      <div class="value-label">{{ cpu }}</div>
      <div class="avg-bar" :style="{ width: `${Math.min(cpuAvg, 100)}%` }"></div>
      <div class="peak-bar" :style="{ width: `${Math.min(cpuPeak, 100)}%` }"></div>
      <div class="new-bar" :style="{ width: `${ newCpuPosition }%` }"></div>
      <div v-if="showPeakArrowCpu" class="peak-arrow" :style="{ left: `calc(${ Math.min(cpuBuffBarWidth, 100) }% - 2px)` }"></div>
      <div class="buff-bar" :style="{ width: `${Math.min(cpuBuffBarWidth, 100)}%` }"></div>
      <div v-if="newcpu < cpu" class="new-label" :style="{ left: `${ newCpuPosition }%` }">{{ newcpu }}</div>
      <div v-if="newcpu < cpu" class="arrow" :style="{ left: `calc(${ newCpuPosition }% + 15px)`, width: `calc(${ 100 - newCpuPosition }% - 30px)` }"></div>
    </div>

    <div class="usage-bar mem-bar">
      <div class="value-label">{{ mem }}</div>
      <div class="avg-bar" :style="{ width: `${Math.min(memAvg, 100)}%` }"></div>
      <div class="peak-bar" :style="{ width: `${Math.min(memPeak, 100)}%` }"></div>
      <div v-if="showPeakArrowMem" class="peak-arrow" :style="{ left: `calc(${ Math.min(memBuffBarWidth, 100) }% - 2px)` }"></div>
      <div class="new-bar" :style="{ width: `${ newMemPosition }%` }"></div>
      <div class="buff-bar" :style="{ width: `${Math.min(memBuffBarWidth, 100)}%` }"></div>
      <div v-if="newmem < mem" class="new-label" :style="{ left: `${ newMemPosition }%` }">{{ newmem }}</div>
      <div v-if="newmem < mem" class="arrow" :style="{ left: `calc(${ newMemPosition }% + 15px)`, width: `calc(${ 100 - newMemPosition }% - 30px)` }"></div>
    </div>

    <div class="usage-bar store-bar">
      <div class="value-label">{{ disk }} <span class="darker-white">GiB</span></div>
      <div class="avg-bar" :style="{ width: `${Math.min(diskAvg, 100)}%` }"></div>
      <div class="new-bar" :style="{ width: `${Math.min(diskBuffBarWidth, 100)}%` }"></div>

      <div v-if="newdisk < disk" class="new-label" :style="{ left: `${ newDiskPosition }%` }">{{ newdisk }} <span class="darker-white">GiB</span></div>

      <div v-if="newdisk < disk" class="arrow" :style="{ left: `calc(${ newDiskPosition }% + 25px)`, width: `calc(${ 100 - newDiskPosition }% - 55px)` }"></div>

    </div>

    <!-- Legend -->
    <div class="legend">
      <div class="legend-item">
        <div class="legend-color avg-bar"></div>
        <div class="legend-label" style="width: 51px;">Average</div>
      </div>
      <div class="legend-item">
        <div class="legend-color peak-bar"></div>
        <div class="legend-label" style="width: 31px;">Peak</div>
      </div>
      <div class="legend-item">
        <div class="legend-color buff-bar"></div>
        <div class="legend-label">Headroom</div>
      </div>
    </div>

  </div>
</template>

<script setup>
import { defineProps, ref, onMounted, computed } from 'vue';

const containerWidth = ref(0);

// hide peak arrow if too close
const showPeakArrowCpu = computed(() => {
  const peakArrowPosition = cpuBuffBarWidth.value;
  const newLabelPosition = newCpuPosition.value;
  const positionDifference = Math.abs(peakArrowPosition - newLabelPosition);
  const percentageThreshold = (20 / containerWidth.value) * 100;
  return positionDifference > percentageThreshold;
});

const showPeakArrowMem = computed(() => {
  const peakArrowPosition = memBuffBarWidth.value;
  const newLabelPosition = newMemPosition.value;
  const positionDifference = Math.abs(peakArrowPosition - newLabelPosition);
  const percentageThreshold = (20 / containerWidth.value) * 100;
  return positionDifference > percentageThreshold;
});

onMounted(() => {
  containerWidth.value = document.querySelector('.usage-bar').clientWidth;
});

const props = defineProps({
  cpu: {
    type: Number,
    required: true
  },
  mem: {
    type: Number,
    required: true
  },
  disk: {
    type: Number,
    required: true
  },
  newcpu: {
    type: Number,
    required: true
  },
  newmem: {
    type: Number,
    required: true
  },
  newdisk: {
    type: Number,
    required: true
  },
  diskAvg: {
    type: Number,
    required: true
  },
  cpuAvg: {
    type: Number,
    required: true
  },
  cpuPeak: {
    type: Number,
    required: true
  },
  memAvg: {
    type: Number,
    required: true
  },
  memPeak: {
    type: Number,
    required: true
  },
  cpuBuff: {
    type: Number,
    required: true
  },
  memBuff: {
    type: Number,
    required: true
  }
});

const newCpuPosition = computed(() => Math.min((props.newcpu / props.cpu) * 100, 100));
const newMemPosition = computed(() => Math.min((props.newmem / props.mem) * 100, 100));
const newDiskPosition = computed(() => Math.min((props.newdisk / props.disk) * 100, 100));

const cpuBuffBarWidth = computed(() => Math.min(props.cpuBuff * props.cpuPeak, 100));
const memBuffBarWidth = computed(() => Math.min(props.memBuff * props.memPeak, 100));

const diskBuffBarWidth = computed(() => Math.min((props.newdisk / props.disk) * 100, 100));

</script>

<style scoped>
.usage-bars {
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: visible;
}

.mem-bar {
  margin-top: 15px;
}

.store-bar {
  margin-top: 15px;
}

.usage-bar {
    position: relative;
    height: 14px;
    width: 230px;
    border: #7c7c7c solid 1px;
    border-radius: 3px;
    overflow: visible;
    background: repeating-linear-gradient(135deg,
      rgba(239, 239, 239, 0.5), rgba(239, 239, 239, 0.5) 4px, rgba(205, 205, 205, 0.1) 4px, rgba(205, 205, 205, 0.1) 8px);
}

.avg-bar, .peak-bar, .new-bar, .buff-bar  {
  position: absolute;
  height: 100%;
  border-right: 1px solid #132130;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.avg-bar {
  background-color: rgb(241, 129, 39);
  z-index: 2;
    border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.peak-bar {
  background-color: #BD9584;
  z-index: 1;
    border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.buff-bar {
  background-color: #9DBAEF; /* Different color for buff-bar */
  z-index: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.new-bar {
  background-color: #c4c4c4;
  z-index: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 4px;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: -1px 5px 0;
  margin-top: -1px;
  font-size: 12px;
  color: #000;
  pointer-events: none;
}

.value-label {
  position: absolute;
  right: 0;
  top: -17px;
  transform: translateX(50%);
  white-space: nowrap;
  font-size: 8pt;
  color: #e9e9e9;
  background: #010409;
  padding-bottom: 2px;
  z-index: 100;
}

.new-label {
  position: absolute;
  top: -17px; /* Adjust this value for vertical positioning */
  transform: translateX(-50%);
  white-space: nowrap;
  color: #d8d8d8;
  background: #010409;
  font-size: 8pt;
}

.arrow {
  position: absolute;
  top: -9px; /* Adjust this value for vertical positioning */
  height: 1px;
  background: #828282;
}

.arrow::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -1px;
  width: 0;
  height: 0;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-right: 5px solid #bcbcbc;
}

.peak-arrow {
    position: absolute;
    top: -8px;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 6px solid #cacaca;
}

.darker-white {
  color: rgba(255, 255, 255, 0.4);
  font-size: 8pt;
}


/* Legend Styles */
.legend {
  display: flex;
  margin-top: 15px;
  color: #000;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 3px;
}

.legend-color {
  width: 10px;
  height: 10px;
  border-radius: 2px;
}

/* Colors matching the bars */
.legend-color.avg-bar {
  background-color: rgb(241, 129, 39);
}

.legend-color.peak-bar {
  background-color: #BD9584;
}

.legend-color.buff-bar {
  background-color: #9DBAEF;
}

.legend-color.new-bar {
  background-color: #dddddd;
}

.legend-color.unused-bar {
  background-color: #cacaca; /* Assuming unused is gray */
}

.legend-label {
  font-size: 12px;
  color: #d3d3d3;
  padding-left: 18px;
  padding-right: 20px;
  width: 45px;
}

</style>
