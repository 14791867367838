<template>
  <div class="strategy">
    <div class="strategy-label">Instance Options</div>

    <div>Burstable Instances</div>
    <div class="radio-buttons">
      <label :class="{ active: burstable === false }">
        <input type="radio" :value="false" v-model="burstable" @change="updateOther" /> No
      </label>
      <label :class="{ active: burstable === true }">
        <input type="radio" :value="true" v-model="burstable" @change="updateOther" /> Yes
      </label>
    </div>

    <div>AMD Processors</div>
    <div class="radio-buttons">
      <label :class="{ active: amd === false }">
        <input type="radio" :value="false" v-model="amd" @change="updateOther" /> No
      </label>
      <label :class="{ active: amd === true }">
        <input type="radio" :value="true" v-model="amd" @change="updateOther" /> Yes
      </label>
    </div>

    <!--
    <div>Match Architectures</div>
    <div class="radio-buttons">
      <label :class="{ active: graviton === true }">
        <input type="radio" :value="true" v-model="graviton" @change="updateOther" /> No
      </label>
      <label :class="{ active: graviton === false }">
        <input type="radio" :value="false" v-model="graviton" @change="updateOther" /> Yes
      </label>
    </div>
    -->

    <div>Red Hat Enterprise Linux</div>
    <div class="radio-buttons">
      <label :class="{ active: rhel_byol === false }">
        <input type="radio" :value="false" v-model="rhel_byol" @change="updateOther" /> Licenced
      </label>
      <label :class="{ active: rhel_byol === true }">
        <input type="radio" :value="true" v-model="rhel_byol" @change="updateOther" /> BYOL
      </label>
    </div>

    <div>SUSE Enterprise Linux</div>
    <div class="radio-buttons">
      <label :class="{ active: suse_byol === false }">
        <input type="radio" :value="false" v-model="suse_byol" @change="updateOther" /> Licenced
      </label>
      <label :class="{ active: suse_byol === true }">
        <input type="radio" :value="true" v-model="suse_byol" @change="updateOther" /> BYOL
      </label>
    </div>

  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { usePortfolioStore } from '@/stores/DataStore';
import axios from 'axios';

const emit = defineEmits(['updatePortfolio','postUpdatePortfolio']);
const props = defineProps({
  portfolio: {
    type: String,
    required: true
  }
});

const portfolioStore = usePortfolioStore();
const portfolios = computed(() => portfolioStore.get);

const burstable = ref(true);
const amd = ref(true);
const graviton = ref(true);
const rhel_byol = ref(true);
const suse_byol = ref(true);

const portfolio = ref(null);

watch(() => props.portfolio, (newportfolio) => {
  portfolio.value = portfolios.value?.find(p => p.portfolio === newportfolio);
  if (portfolio.value) {
    burstable.value = portfolio.value.burstable;
    amd.value = portfolio.value.allowamd;
    graviton.value = portfolio.value.graviton;
    rhel_byol.value = portfolio.value.rhel_byol;
    suse_byol.value = portfolio.value.suse_byol;
  }

}, { immediate: true });

async function updateOther() {

  // update selected portfolio
  portfolio.value.burstable = burstable.value;
  portfolio.value.allowamd = amd.value;
  portfolio.value.graviton = graviton.value;
  portfolio.value.rhel_byol = rhel_byol.value;
  portfolio.value.suse_byol = suse_byol.value;

  portfolioStore.updatePortfolio(props.portfolio, portfolio.value);

  emit('startLoadIcon');

  // upload to web
  try {
    await axios.post(
      `/go/portfolio/other`,
      { burstable: burstable.value, allowamd: amd.value, graviton: graviton.value, rhel_byol: rhel_byol.value, suse_byol: suse_byol.value }
    );
  } catch (error) {
    console.error("Error updating RDS strategy:", error);
  }

  // update costings
  emit('updatePortfolio');
}

</script>

<style scoped>
.radio-buttons {
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  border-color: #2f353d;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  background-color: #0d1117;
  padding: .25rem;
  width: 220px;
  margin-bottom: 14px;
  box-shadow: 0 0 0 1px #0000000f;
}

.radio-buttons label {
  display: flex;
  justify-content: center;
  background-color: #0d1117;
  color:  #e4e4e4;
  padding-top: 3px;
  padding-bottom: 4px;
  width: 120px;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid transparent;
}

.radio-buttons label.active {
  background-color: #384459 !important;
  border: 1px solid transparent;
  color: #e4e4e4;
}

.radio-buttons input[type="radio"] {
  display: none;
}

.strategy-label {
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 500;
}

.strategy {
  width: 225px;
  margin-left: 20px;
}
</style>