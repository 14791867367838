// stores/serverData.js
import { defineStore } from 'pinia';

import localForage from "localforage";

// indexedDB instances
const portfolioDB = localForage.createInstance({name: 'CloudHound', storeName: 'portfolio'});
const discoveryDB = localForage.createInstance({name: 'CloudHound', storeName: 'discovery'});
const serverDB = localForage.createInstance({name: 'CloudHound', storeName: 'servers'});
const counterDB = localForage.createInstance({name: 'CloudHound', storeName: 'livecounter'});

export const useDarkModeStore = defineStore('darkmode', {
  state: () => ({
    darkmode: localStorage.getItem('darkmode') === 'true' || true
  }),
  actions: {
    save(bool) {
      this.darkmode = bool;
      localStorage.setItem('darkmode', bool);
    }
  },
  getters: {
    get: (state) => state.darkmode
  }
});

// portfolio page keys
const PORTFOLIO_KEY = 'portfolio';
const API_KEY = 'api_key';
const CUR_PORTFOLIO_KEY = 'cur_portfolio';

export const usePortfolioStore = defineStore('portfolioData', {
  state: () => ({
    portfolio_data: null,    // full portfolio page
    api_key: null,      // current api-key for the portfolio
    cur_portfolio: null // current portfolio string
  }),
  actions: {
    async save(data) {
      this.portfolio_data = data;
      await portfolioDB.setItem(PORTFOLIO_KEY, data)
    },
    async saveApiKey(key) {
      this.api_key = key;
      await portfolioDB.setItem(API_KEY, key)
    },
    async setCurPortfolio(name) {
      this.cur_portfolio = name;
      await portfolioDB.setItem(CUR_PORTFOLIO_KEY, name)
    },
    async load() {
      const savedPortfolio = await portfolioDB.getItem(PORTFOLIO_KEY);
      const savedApiKey = await portfolioDB.getItem(API_KEY);
      const savedCurPortfolio = await portfolioDB.getItem(CUR_PORTFOLIO_KEY);

      if (savedPortfolio) this.portfolio_data = savedPortfolio;
      if (savedApiKey) this.api_key = savedApiKey;
      if (savedCurPortfolio) this.cur_portfolio = savedCurPortfolio;
    },
    updatePortfolio(portfolio, data) {
      if (!this.portfolio_data) {
        console.error('No portfolio data available to update.');
        return;
      }

      const port_obj = this.portfolio_data.find(p => p.portfolio === portfolio);
      if (port_obj) {
        Object.assign(port_obj, data);

        try {
          const serializableData = JSON.parse(JSON.stringify(this.portfolio_data));
          portfolioDB.setItem(PORTFOLIO_KEY, serializableData).catch(err => console.error('Failed to persist updated portfolio data:', err));
        } catch (serializationError) {
          console.error('Serialization error:', serializationError);
        }
      } else {
        console.error(`Portfolio with ID ${portfolio} not found.`);
      }
    }
  },
  getters: {
    get: (state) => state.portfolio_data,
    getApiKey: (state) => state.api_key,
    getCurPortfolio: (state) => state.cur_portfolio
  }
});

// pinia store, that now uses indexDB via portfolioStore
export const useDiscoveryStore = defineStore('monitoringData', {
  state: () => ({
    discovery: {},  // key = portfolio, returns discovery page
    selUuid: null,  // don't persist in indexedDB
    selIndex: null,  // don't persist in indexedDB
    selTab: 0
  }),
  actions: {
    async save(portfolio, data) {
      this.discovery[portfolio] = data;
      await discoveryDB.setItem(portfolio, data); // use portfolio as the key
    },
    async setSelectedUuid(uuid, index) {
      this.selUuid = uuid;
      this.selIndex = index;
    },
    async setSelectedTab(index) {
      this.selTab = index;
    },
    async get(portfolio) {

      if (this.discovery[portfolio]) {
        return this.discovery[portfolio];
      }

      const savedData = await discoveryDB.getItem(portfolio);
      if (savedData) {
        this.discovery[portfolio] = savedData;
        return this.discovery[portfolio]
      }

      return null;
    }
  },
  getters: {
    getSelectedUuid: (state) => state.selUuid,
    getSelectedIndex: (state) => state.selIndex,
    getSelectedTab: (state) => state.selTab,
    getServerByUuid: (state) => (portfolio, uuid) => {
      return state.discovery[portfolio]?.find(s => s.uuid === uuid) || null;
    }
  }
});

export const useServerDataStore = defineStore('serverData', {
  state: () => ({
    servers: {},
    activeTab: 0 // don't persist in IndexedDB
  }),
  actions: {
    async saveServer(uuid, server) {
      if (!uuid) return;

      const sanitizedServer = JSON.parse(JSON.stringify(server)); // make it serialisable
      const timestamp = Date.now();

      const serverData = { timestamp: timestamp, data: sanitizedServer };

      // mem store
      this.servers[uuid] = serverData;

      // indexed DB
      await serverDB.setItem(uuid, serverData);
    },
    async setActiveTab(tabIndex) {
      this.activeTab = tabIndex;
    },
    async load() {
      //console.log("Load Server DB");
      const servers = {};

      // iterate over the stored servers and add them to the map
      await serverDB.iterate((value, key) => {
        servers[key] = value;
      });

      this.servers = servers;
    }
  },
  getters: {
    getServerByUuid: (state) => (uuid) => {
      const serverEntry = state.servers[uuid];
      return serverEntry ? serverEntry.data : null;
    },
    isDataStale: (state) => (uuid) => {
      const server = state.servers[uuid];
      if (!server) return true;
      const timestamp = server.timestamp;
      return (Date.now() - timestamp) > 15*60*1000; // 15 minutes
    },
    getActiveTab: (state) => {
      return state.activeTab;
    }
  }
});

export const useLiveCounterStore = defineStore('livecounters', {
  state: () => ({
    cache: {}
  }),
  actions: {
    setCache(key, value) {
      this.cache[key] = value;

      // noinspection JSIgnoredPromiseFromCall
      counterDB.setItem(key, value)
    },
    async getCache(key) {

      // get from mem if it exists
      if (this.cache[key]) {
        return this.cache[key];
      }

      // or from indexDB
      const db_count = await counterDB.getItem(key);
      if (db_count !== null) {
        return db_count;
      }

      return null;
    }
  }
});

export const useNodeStore = defineStore('nodeStore', {
  state: () => ({
    nodePositions: {}
  }),
  actions: {
    saveNodePositions(uuid, positions) {
      this.nodePositions[uuid] = positions
    },
    loadNodePositions(uuid) {
      return this.nodePositions[uuid] || {}
    }
  }
})