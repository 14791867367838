// src/chartOptions.js
export const getCommonChartOptions = (props, data, peak, buff) => ({
  chart: {
    backgroundColor: '#0D1117',
    height: 220,
    width: 500,
    zooming: {
      type: 'x',
    },
    boost: {
      enabled: true
    },
    animation: false
  },
  accessibility: {
    enabled: false
  },
  time: {
    useUTC: false
  },
  tooltip: {
    hideDelay: 10,
    shadow: false
  },
  credits: {
    enabled: false
  },
  rangeSelector: {
    buttons: [
      {type: 'all', text: 'All'}
    ],
    buttonTheme: {
      fill: '#384459',        // Background color
      stroke: '#FFFFFF',      // Border color
      style: {
        color: '#FFFFFF',     // Text color
        fontWeight: 'bold',
      },
      states: {
        hover: {
          fill: '#141a23'     // Background color on hover
        },
        select: {
          fill: '#11161E'     // Background color when selected
        }
      }
    },
    selected: 0
  },
  xAxis: {
    gridLineWidth: 0,
    labels: {
      style: {
        color: '#d9d9d9',
      },
    },
    lineColor: '#d9d9d9',
    lineWidth: 1,
    minorGridLineColor: '#d9d9d9',
    tickColor: '#d9d9d9',
    tickWidth: 1,
    title: {
      style: {
        color: '#d9d9d9',
      },
    },
  },
  yAxis: {
    gridLineColor: '#888888',
    gridLineWidth: 0.5, // major grid lines
    labels: {
      style: {
        color: '#d9d9d9',
      },
    },
    title: {
      style: {
        color: '#d9d9d9',
      },
    },
    max: Math.max(Math.min((buff * peak) * 1.1, 100), 5),
    min: 0,
    plotLines: [{
      color: '#F18127',
      width: 1,
      value:  Math.min(peak, 100) ,
      dashStyle: 'Dash',
      label: {
        text: "Peak",
        style: {
          color: '#fff'
        },
        align: 'left',
        x: 10
      }
    },
    {
      color: '#2caffe',
      width: 1,
      value: Math.min(buff * peak, 100),
      dashStyle: 'LongDash',
      label: {
        text: "Headroom",
        style: {
          color: '#fff'
        },
        align: 'left',
        x: 10
      }
    }]
  },
  scrollbar: {
    enabled: false
  },
  navigator: {
    enabled: false,
    height: 30,
    accessibility: false,
    adaptToUpdatedData: false,
    maskInside: false
  },
  plotOptions: {
    series: {
      dataGrouping: {
        enabled: true,
        approximation: 'average',
        groupPixelWidth: 2
      },
      states: {
        hover: {
          enabled: false
        }
      }
    }
  },
});
