<template>
  <div class="main-content placeholder-header" style="flex-direction: column; margin-right: 250px;">
    <div>Migration Planner</div>
    <div style="margin-top: 5px;">Coming Soon</div>
  </div>
</template>

<script>
</script>

<style>
</style>
