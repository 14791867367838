<template>
  <div ref="sidebar" class="sidebar">

    <div class="logoheader">
      <img src="/images/logo-greyscale.png" style="width: 160px; height: 30.5px; margin-right: 1px;" alt="Main Icon" />
    </div>

    <div class="username">
      CloudHound Limited
    </div>

    <div :class="['sidebar-content', { 'sidebar-content-collapsed': isCollapsed }]">
      <ul >

        <li>
          <div class="logout-space-bot"></div>
          <div class="logout-border"></div>
          <div class="logout-space-top"></div>
        </li>

        <router-link draggable="false" to="/portfolios">
          <li :class="{ 'active-link': isActive('/portfolios') }" >
            <a ><PortfolioIcon style="padding-top: 10px;"/><span class="linktext">{{ isCollapsed ? '' : 'Portfolios' }}</span></a>
          </li>
        </router-link>

        <router-link draggable="false" to="/discovery">
          <li :class="{ 'active-link': isActive('/discovery') }">
            <!--
            <a ><CameraIcon style="padding-top: 10px;" /><span class="linktext">Discovery</span></a>
            -->
            <a ><MonitoringIcon style="padding-top: 10px;" /><span class="linktext">Discovery</span></a>
          </li>
        </router-link>

        <!--
        <router-link draggable="false" to="/inspector">
          <li :class="{ 'active-link': isActive('/inspector') }">
            <a ><MonitoringIcon style="padding-top: 10px;" /><span class="linktext">Inspector</span></a>
          </li>
        </router-link>
        -->

        <router-link draggable="false" to="/costing">
          <li :class="{ 'active-link': isActive('/costing') }">
            <a ><InfraIcon style="padding-top: 10px;" /><span class="linktext">Costing</span></a>
          </li>
        </router-link>

        <router-link draggable="false" to="/applications">
          <li :class="{ 'active-link': isActive('/applications') }">
            <a ><ApplicationsIcon style="padding-top: 10px;" /><span class="linktext">Applications</span></a>
          </li>
        </router-link>

        <!--
        <router-link draggable="false" to="/migration">
          <li :class="{ 'active-link': isActive('/migration') }">
            <a ><EstateIcon style="padding-top: 10px;" /><span class="linktext">Migration Planner</span></a>
          </li>
        </router-link>
        -->

        <li>
          <div class="logout-space-top"></div>
          <div class="logout-border"></div>
          <div class="logout-space-bot"></div>
        </li>

        <router-link draggable="false" to="/calculator">
          <li :class="{ 'active-link': isActive('/calculator') }">
            <a ><CalculatorIcon style="padding-top: 10px;" /><span class="linktext">Calculator</span></a>
          </li>
        </router-link>

        <router-link draggable="false" to="/settings">
          <li :class="{ 'active-link': isActive('/settings') }">
            <a ><SettingsIcon style="padding-top: 10px;" /><span class="linktext">Settings</span></a>
          </li>
        </router-link>

        <router-link draggable="false" to="/export">
          <li :class="{ 'active-link': isActive('/export') }">
            <a ><ExportIcon style="padding-top: 10px;" /><span class="linktext">Export</span></a>
          </li>
        </router-link>

        <!--
        <li :class="{ 'active-link': isActive('/contact') }" @click="openCrispChat">
          <a><ContactIcon style="padding-top: 10px;" /><span class="linktext">Contact</span></a>
        </li>
        -->

        <li>
          <div class="logout-space-top"></div>
          <div class="logout-border"></div>
          <div class="logout-space-bot"></div>
        </li>

        <li>
          <a @click.prevent="clearIndexedDBAndLogout" >
            <LogoutIcon style="padding-top: 10px;" /><span class="linktext">Logout</span>
          </a>
        </li>

        <router-link draggable="false" to="/system" >
          <li style="margin-top: 50px; cursor: default; height: 0px;">
            <a style="cursor: default; pointer-events: none;"/>
          </li>
        </router-link>
      </ul>

    </div>

    <!-- Fixed footer -->
    <div class="fixed-footer">
      <div class="footer-horizontal">
        <SideCounter headerText="" :pollSec="10" :selectedPortfolio="nil"  />
      </div>
      <div style="height:1px; background-color: rgb(47 53 61); width: 200px; margin-bottom: 5px;"></div>
      <a href="/guide/" class="horizontal-container footer-link" target="_blank">
        <DocumentationIcon style="margin-left: 22px; margin-right: 10px; margin-bottom: 4px;" />
        <span>System Documentation</span>
      </a>
      <span style="margin-top: 5px; margin-bottom: 15px;">© {{ currentYear }} CloudHound Ltd</span>
    </div>
  </div>

</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import PortfolioIcon from "@/components/icons/PortfolioIcon.vue";
import MonitoringIcon from "@/components/icons/MonitoringIcon.vue";
import SettingsIcon from "@/components/icons/SettingsIcon.vue";
import LogoutIcon from "@/components/icons/LogoutIcon.vue";
import ApplicationsIcon from "@/components/icons/ApplicationsIcon.vue";
import InfraIcon from "@/components/icons/InfraIcon.vue";
import DocumentationIcon from "@/components/icons/DocumentationIcon.vue";
import CalculatorIcon from "@/components/icons/CalculatorIcon.vue";
import SideCounter from "@/components/sidebar/SideCounter.vue";

const isCollapsed = ref(false);
const emit = defineEmits(['update:collapsed']);
const route = useRoute();
import { useCookies } from 'vue3-cookies';
import { useRouter } from 'vue-router';

const sidebar = ref(null);

const currentYear = ref('');
const { cookies } = useCookies();
const router = useRouter();

onMounted(() => {
  currentYear.value = new Date().getFullYear();

  if (sidebar.value) {
    sidebar.value.addEventListener('dragstart', (event) => {
      event.preventDefault();
    });
  }
});

watch(isCollapsed, (newValue) => {
  emit('update:collapsed', newValue);
});

const isActive = (path) => {
  if (route.path === "/" && path === "/portfolios") return true;
  return route.path === path;
};

import localForage from 'localforage';
import ExportIcon from "@/components/icons/ExportIcon.vue";
import CameraIcon from "@/components/icons/CameraIcon.vue";

const clearIndexedDBAndLogout = async () => {

  try {
    await localForage.clear();
  } catch (error) {
    console.error('Error clearing IndexedDB before logout:', error);
  }

  cookies.remove('session_id');

  // disable transitions immediately
  if (sidebar.value) {
    sidebar.value.style.transition = 'none';
  }

  router.push('/login');
};
</script>

<style scoped>
.sidebar {
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 200px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  font-size: 13px;
  border-right: 1px solid #d7d7d7;
  box-shadow: 4px 0 32px rgba(0, 0, 0, 0.043);
  z-index: 51;
  transition: background-color 0.1s, width 0.1s;
}

.dark .sidebar {
  background: linear-gradient(270deg, #0f1319, #0d1117);
  color: #d9d9d9;
  border-right: 1px solid #2f353d
}

.sidebar-content {
  flex-grow: 1;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
}

.sidebar ul li {
  padding: 0;
  text-align: center;
}

.sidebar ul li a {
  user-select: none;
  text-decoration: none;
  display: flex;
  /* padding-top: 10px; */
  height: 35px;
  justify-content: center;
  text-align: left;
  cursor: pointer;
}

.sidebar ul li a:hover {
  background-color: #E5E6E6;
}

.dark .sidebar ul li a:hover {
  background-color: #171d28;
}

.active-link {
  background-color: #d3d3d3;
}

.dark .active-link {
  background-color: #1d2532;
}

/* Prevent hover effect on active links */
.dark .sidebar ul li.active-link a:hover {
  background-color: #1d2532 !important;
}

.linktext.linktext {
  margin-left: 13px;
  width: 121px;
  font-size: 9.5pt;
  padding-top: 8.5px;
}

.username {
  font-size: 13px;
  margin-bottom: 6px;
}

.logout-space-top {
  height: 16px;
}

.logout-space-bot {
  height: 11px;
}

.logout-border {
  height: 1px;
  background: #2f353d;
}

.dark .logout-border {
  background: #2f353d;
}

.logoheader {
  margin-top: 1.9em;
  margin-bottom: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fixed-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #f8f9fa;
  text-align: center;
  padding: 10px 10px 0px;
  border-top: 0px;
  font-size: 13px;
  z-index: 101;
  width: 180px;

  /* Flexbox for vertical alignment */
  display: flex;
  flex-direction: column;
  align-items: center; /* Optional: centers items horizontally */
}

.fixed-footer span {
  margin-right: 5px;
  margin-bottom: 5px;
}

.footer-link {
  margin: 0 10px;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

.dark .fixed-footer {
  background-color: #0d1117;
  color: #d9d9d9;
  border-top: 1px solid #2f353d;
}

.horizontal-container {
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 200px;
  padding-top: 5px;
  color: inherit; /* Matches text color */
  margin-bottom: 5px;
  height: 30px;
}

.horizontal-container:hover {
  background-color: #1d2532; /* Highlight color */
  text-decoration: none;
}

.footer-link {
  text-decoration: none;
  color: inherit;
}

.footer-link:hover {
  text-decoration: none;
}

.footer-horizontal {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}

</style>
