import { defineStore } from 'pinia';
import localForage from "localforage";

const costingDB = localForage.createInstance({name: 'CloudHound', storeName: 'costing'});

export const useCostingStore = defineStore('costing', {
  state: () => ({
    costingDataArray: [],
    selTab: 0,
    free_egress: 0.0,
    discount: 0,
    support: "business",
    overhead: 0,
    s3_cost: 0.0,
  }),
  actions: {
    async setCostingData(portfolio, data, free_egress, discount, support, overhead, s3_cost, s3_tibs) {
      this.costingDataArray[portfolio] = data;
      this.free_egress = free_egress;
      this.support = support;
      this.discount = discount;
      this.overhead = overhead;
      this.s3_cost = s3_cost;
      this.s3_tibs = s3_tibs;

      try {
        // save data and free egress to IndexedDB
        await costingDB.setItem(portfolio, { data, free_egress, support, discount, overhead, s3_cost, s3_tibs });
      } catch (error) {
        console.error("Error saving costing data:", error);
      }

    },
    async getCostingData(portfolio) {

      const mem_data = this.costingDataArray[portfolio];
      if (mem_data) {
        return {
          data: mem_data, free_egress: this.free_egress, support: this.support,
          discount: this.discount, overhead: this.overhead, s3_cost: this.s3_cost, s3_tibs: this.s3_tibs
        };
      }

      try {
        const savedData = await costingDB.getItem(portfolio);
        if (savedData) {
          const { data, free_egress, support, discount, overhead, s3_cost, s3_tibs } = savedData;
          this.costingDataArray[portfolio] = data;
          this.free_egress = free_egress;
          this.support = support;
          this.discount = discount;
          this.overhead = overhead;
          this.s3_cost = s3_cost;
          this.s3_tibs = s3_tibs;

          return savedData;
        }
      } catch (error) {
        console.error("Error retrieving costing data:", error);
      }

      return null;
    },
    async setSelectedTab(index) {
      this.selTab = index;
    },
  },
  getters: {
    getSelectedTab: (state) => state.selTab,
  },
});