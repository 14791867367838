import { createRouter, createWebHistory } from 'vue-router';
import DiscoveryPage from "@/pages/DiscoveryPage.vue";
import ApplicationsPage from "@/pages/ApplicationsPage.vue";
import PortfolioPage from "@/pages/PortfolioPage.vue";
import PlannerPage from "@/pages/PlannerPage.vue";
import CostingPage from "@/pages/CostingPage.vue";
import CalculatorPage from "@/pages/CalculatorPage.vue";
import SettingsPage from "@/pages/SettingsPage.vue";
import InspectorPage from "@/pages/InspectorPage.vue";
import ExportPage from "@/pages/ExportPage.vue";
import SystemMonitoring from "@/pages/SystemMonitoring.vue";
import RegisterPage from "@/pages/RegisterPage.vue";
import LoginPage from "@/pages/LoginPage.vue";

import { useCookies } from 'vue3-cookies';
const { cookies } = useCookies();

function customAuthGuard(to, from, next) {
  const sessionId = cookies.get('session_id');
  if (!sessionId) {
    next({ path: '/login' }); // Make sure you have a route defined for '/login'
  } else {
    next();
  }
}

export function makeRoutes() {
  return createRouter({
    history: createWebHistory(),
    routes: [
      {
        path: '/login',
        name: 'Login',
        component: LoginPage
      },
      {
        path: '/',
        name: 'Home',
        component: PortfolioPage,
        beforeEnter: customAuthGuard
      },
      {
        path: '/portfolios',
        name: 'Portfolios',
        component: PortfolioPage,
        beforeEnter: customAuthGuard
      },
      {
        path: '/discovery',
        name: 'Discovery',
        component: DiscoveryPage,
        beforeEnter: customAuthGuard
      },
      {
        path: '/calculator',
        name: 'Calculator',
        component: CalculatorPage,
        beforeEnter: customAuthGuard
      },
      {
        path: '/costing',
        name: 'Costing',
        component: CostingPage,
        beforeEnter: customAuthGuard
      },
      {
        path: '/inspector',
        name: 'Inspector',
        component: InspectorPage,
        beforeEnter: customAuthGuard
      },
      {
        path: '/applications',
        name: 'Applications',
        component: ApplicationsPage,
        beforeEnter: customAuthGuard
      },
      {
        path: '/migration',
        name: 'Migration Planner',
        component: PlannerPage,
        beforeEnter: customAuthGuard
      },
      {
        path: '/settings',
        name: 'Settings',
        component: SettingsPage,
        beforeEnter: customAuthGuard
      },
      {
        path: '/export',
        name: 'Export',
        component: ExportPage,
        beforeEnter: customAuthGuard
      },
      {
        path: '/system',
        name: 'System',
        component: SystemMonitoring,
        beforeEnter: customAuthGuard
      },
      {
        path: '/success',
        name: 'Register',
        component: RegisterPage,
      },
    ]
  });
}