<template>
  <div class="portfolio-screen">
    <div class="portfolio-header">Users</div>
    <UserTable :users="users" :selectedPortfolio="seletedApiKey"
                        @portfolioSwitched="handlePortfolioSwitch" @prePortfolioSwitched="handlePrePortfolioSwitch"
                        @refresh="getUserData"/>

    <div style="display: flex; flex-direction: row; align-items: flex-start; margin-top: 32px;">

      <div style="margin-right: 32px; width: 125px;">
        <div style="font-weight: bold; margin-bottom: 8px;">Connectivity</div>
        <BedrockCheck style="margin-top: 10px;"/>
      </div>

      <div style="margin-right: 32px; width: 125px;">
        <div style="font-weight: bold; margin-bottom: 8px;">Available Licences</div>
        <LicenceCheck style="margin-top: 10px;"/>
      </div>

      <div style="margin-right: 22px;">
        <div style="font-weight: bold; margin-bottom: 8px;">Audit</div>
        <button style="height: 22px; padding-left: 10px; padding-right: 10px;" @click="showRaw = true">Raw Uploads</button>
      </div>

      <!--
      <div>
        <div style="font-weight: bold; margin-bottom: 8px;">&nbsp;</div>
        <button style="height: 22px; padding-left: 10px; padding-right: 10px;" @click="showLoginRecords = true">Access Logs</button>
      </div>
      -->

    </div>

    <RawData
      v-if="showRaw"
      @close="handleClose"
    />

    <LoginRecords
      v-if="showLoginRecords"
      @close="handleClose"
    />

  </div>
</template>

<script setup>
import UserTable from "@/settings/users/UserTable.vue";
import {onMounted, ref} from 'vue';
import axios from 'axios';

import { useUserStore } from "@/stores/SettingStore.js";
import BedrockCheck from "@/settings/BedrockCheck.vue";
import RawData from "@/settings/RawData.vue";
import LoginRecords from "@/settings/LoginRecords.vue";
import LicenceCheck from "@/settings/LicenceCheck.vue";
const store = useUserStore();

const showRaw = ref(false);
const showLoginRecords = ref(false);

const users = ref([]);
const seletedApiKey = ref(null);

let getUserAbortController = null;

const handleClose = () => {
  showRaw.value = false;
  showLoginRecords.value = false;
}

onMounted(async () => {
  if (store.get) {
    users.value = store.get;
  }

  getUserData();
});

const getUserData = async () => {

  if (getUserAbortController) {
    getUserAbortController.abort();
  }

  getUserAbortController = new AbortController();

  try {

    const response = await axios.get('/go/users', {
      headers: { 'Content-Type': 'application/json' },
      signal: getUserAbortController.signal
    });

    users.value = response.data.users;
    store.save(response.data.users)

  } catch (error) {
    if (error.name === 'AbortError' || error.code === 'ERR_CANCELED') {
      //
    } else {
      console.error('Error fetching data:', error);
    }
  }
};

</script>

<style scoped>
.portfolio-screen {
  text-align: left;
  font-size: 14px;
}

.portfolio-header {
  font-weight: 700;
  margin-bottom: 19px;
  font-size: 10pt;
  margin-top: 7px;
}

.horizontal-container {
  display: flex;
  gap: 1rem;
  margin-top: 10px;
}
</style>