<template>
  <div>
    <div class="page">
      <div class="grid-container">
        <OptimisationScore :dataArray="costingData"/>
      </div>

      <div style="width: 2px; height: 155px; background-color: #2f353d; margin-top: 10px; margin-left: 35px; margin-right: 10px;"/>
      <RecommendationSummary :dataArray="costingData" :support="support" :discount="discount" :overhead="overhead" :free_egress="freeEgress" :s3_cost="s3_cost" />
      <div style="width: 2px; height: 155px; background-color: #2f353d; margin-top: 10px; margin-left: 15px; margin-right: 35px;"/>
      <CostSavingsPie :dataArray="costingData" :support="support" :discount="discount" :overhead="overhead" :free_egress="freeEgress" :s3_cost="s3_cost" />
      <CostSavingsFigures :dataArray="costingData" :support="support" :discount="discount" :overhead="overhead" :free_egress="freeEgress" :s3_cost="s3_cost" />

      <transition name="fade">
        <div v-if="loading" style="margin-top: 142px; margin-left: -445px;" class="lds-dual-ring"></div>
      </transition>
    </div>

    <Tabs style="margin-top: 17px; margin-left: -3px; margin-bottom: -2px; position: relative !important;" tabs-width="1000px"
      :tabs="['Strategy', 'Regions', 'Summary', 'EC2', 'RDS', 'Retired', 'Licences', 'Costs']"
      :activeTab="activeTab"
      @change-tab="changeTab"
    />

    <div v-if="activeTab === 0" class="additional-components-container">
      <PerformanceSettings :portfolio="selectedPortfName" @updatePortfolio="fetchCostingData" @startLoadIcon="startLoadIcon"/>
      <OtherStrategy :portfolio="selectedPortfName" @updatePortfolio="fetchCostingData" @startLoadIcon="startLoadIcon" />
      <Ec2Strategy :portfolio="selectedPortfName" @updatePortfolio="fetchCostingData" @startLoadIcon="startLoadIcon" @stopLoadIcon="stopLoadIcon" />
      <RdsStrategy :portfolio="selectedPortfName" @updatePortfolio="fetchCostingData" @startLoadIcon="startLoadIcon" @stopLoadIcon="stopLoadIcon" />
      <ExtraSettings :portfolio="selectedPortfName" @updatePortfolio="fetchCostingData" @startLoadIcon="startLoadIcon" @stopLoadIcon="stopLoadIcon" />
    </div>

    <div v-else-if="activeTab === 1" class="costing-table-container">
       <RegionView :portfolio="selectedPortfName"/>
    </div>

    <div v-else-if="activeTab === 2" class="costing-table-container">
      <CostingTable page="all" :costings="costingData" @updatePortfolio="fetchCostingData" @startLoadIcon="startLoadIcon"/>
    </div>

    <div v-else-if="activeTab === 3" class="costing-table-container">
      <CostingTable page="ec2" :costings="ec2Data" @updatePortfolio="fetchCostingData" @startLoadIcon="startLoadIcon"/>
    </div>

    <div v-else-if="activeTab === 4" class="costing-table-container">
      <CostingTable page="rds" :costings="rdsData" @updatePortfolio="fetchCostingData" @startLoadIcon="startLoadIcon"/>
    </div>

    <div v-else-if="activeTab === 5" class="costing-table-container">
      <CostingTable page="all" :costings="retireData" @updatePortfolio="fetchCostingData" @startLoadIcon="startLoadIcon"/>
    </div>

    <div v-else-if="activeTab === 6" class="costing-table-container">
      <WindowsTable :costings="costingData"/>
      <SQLServerTable :costings="costingData"/>
      <OracleTable :costings="costingData"/>
      <Db2Table :costings="costingData"/>
      <RhelTable :costings="costingData"/>
      <SuseTable :costings="costingData"/>
      <NoneTable :costings="costingData"/>
      <div style="margin-bottom: 50px;"></div>
    </div>

    <div v-else-if="activeTab === 7" class="costing-table-container">
      <CostSummary :support="support" :discount="discount" :overhead="overhead" :free_egress="freeEgress" :costings="costingData" :s3_cost="s3_cost" :s3_tibs="s3_tibs" />
      <FiveYrSummary :portfolio="selectedPortfName" style="margin-bottom: 80px;" :support="support" :costings="costingData" :free_egress="freeEgress" :discount="discount" :overhead="overhead" :s3_cost="s3_cost" />
    </div>

  </div>
</template>

<script setup>
import {onBeforeMount, onBeforeUnmount, onMounted, ref} from 'vue';
import axios from 'axios';
import {useCostingStore} from '@/stores/CostStore';
import {usePortfolioStore} from '@/stores/DataStore';
import OptimisationScore from "@/costing/components/OptimisationScore.vue";
import RecommendationSummary from "@/costing/components/RecommendationSummary.vue";
import CostSavingsFigures from "@/costing/components/CostSavingsFigures.vue";
import CostSavingsPie from "@/costing/components/CostSavingsPie.vue";
import CostingTable from "@/costing/CostingTable.vue";
import Tabs from "@/components/discovery/TabComponent.vue";
import RdsStrategy from "@/costing/strategies/RdsStrategy.vue";
import PerformanceSettings from "@/costing/strategies/PerformanceSettings.vue";
import Ec2Strategy from "@/costing/strategies/Ec2Strategy.vue";
import OtherStrategy from "@/costing/strategies/OtherStrategy.vue";
import CostSummary from "@/costing/CostSummary.vue";
import FiveYrSummary from "@/costing/FiveYrSummary.vue";
import WindowsTable from "@/costing/licensing/WindowsTable.vue";
import SQLServerTable from "@/costing/licensing/SQLServerTable.vue";
import RhelTable from "@/costing/licensing/RhelTable.vue";
import SuseTable from "@/costing/licensing/SuseTable.vue";
import OracleTable from "@/costing/licensing/OracleTable.vue";
import Db2Table from "@/costing/licensing/Db2Table.vue";
import NoneTable from "@/costing/licensing/NoneTable.vue";
import ExtraSettings from "@/costing/strategies/ExtraSettings.vue";
import RegionView from "@/costing/components/RegionView.vue";

const costingStore = useCostingStore();
const portfolioStore = usePortfolioStore();

const activeTab = ref(0);
const selectedPortfName = ref("");

const costingData = ref([]);
const freeEgress = ref(0.0);
const ec2Data = ref([]);
const rdsData = ref([]);
const retireData = ref([]);
const discount = ref(0);
const overhead = ref(0);
const support = ref("");
const s3_tibs = ref(0);
const s3_cost = ref(0.0);

const loading = ref(false);

const changeTab = (tabIndex) => {
  activeTab.value = tabIndex;
  localStorage.setItem('activeTab', tabIndex); // Persist to localStorage
  costingStore.setSelectedTab(tabIndex);
};

let controller = null;

function startLoadIcon() {
  if (controller) {
    controller.abort();
  }

  loading.value = true;
}

// only used if child component exited early
function stopLoadIcon() {
  console.log("cancel load icon early");
  loading.value = false;
}

let first_load = true;

async function fetchCostingData() {
  if (controller) {
    controller.abort();
  }

  controller = new AbortController();
  const { signal } = controller;

  // don't show loading icon on mount
  if (!first_load) {
    loading.value = true;
  }

  if (first_load) {
    const portfolio = portfolioStore.getCurPortfolio;
    const costing = await costingStore.getCostingData(portfolio);

    if (costing && costing.length > 0) {
      loading.value = false;
    }
  }

  try {
    const response = await axios.get(`/go/costing`, {
      headers: { 'Content-Type': 'application/json' },
      signal,
    });

    if (response.data?.array) {
      costingData.value = response.data.array;

      // Save data and free egress to the store
      await costingStore.setCostingData(
        response.data.portfolio,
        response.data.array,
        response.data.free_egress,
        response.data.discount,
        response.data.support,
        response.data.overhead,
        response.data.s3_cost,
        response.data.s3_tibs,
      );

      freeEgress.value = response.data.free_egress_usd;
      discount.value = response.data.discount;
      support.value = response.data.support;
      overhead.value = response.data.overhead;
      s3_cost.value = response.data.s3_cost;
      s3_tibs.value = response.data.s3_tibs;

      ec2Data.value = response.data.array.filter(item => (!item.retire && item.target === "ec2"));
      rdsData.value = response.data.array.filter(item => (!item.retire && (item.target === "rds" || item.target === "rdsm")));
      retireData.value = response.data.array.filter(item => item.retire);
    }

    loading.value = false;

  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled', error.message);
    } else {
      console.error('Request failed', error);
      loading.value = false;
    }
  } finally {
    controller = null;
  }

  first_load = false;
}

onBeforeMount(async () => {
  const savedTab = localStorage.getItem('activeTab'); // Retrieve from localStorage
  if (savedTab !== null) {
    activeTab.value = parseInt(savedTab, 10);
  } else {
    activeTab.value = costingStore.getSelectedTab;
  }

  const portfolio = portfolioStore.getCurPortfolio;
  if (portfolio) {
    selectedPortfName.value = portfolio;

    const costing = await costingStore.getCostingData(portfolio);
    if (costing) {
      costingData.value = costing.data;

      ec2Data.value = costing.data.filter(item => (!item.retire && item.target === "ec2"));
      rdsData.value = costing.data.filter(item => (!item.retire && (item.target === "rds" || item.target === "rdsm")));
      retireData.value = costing.data.filter(item => item.retire);

      freeEgress.value = costing.free_egress_usd;
      discount.value = costing.discount;
      support.value = costing.support;
      overhead.value = costing.overhead;
      s3_cost.value = costing.s3_cost;
      s3_tibs.value = costing.s3_tibs;
    }
  }
});

onMounted(() => {
  // Attach a keydown event listener
  window.addEventListener('keydown', handleKeydown);
  fetchCostingData();
});

onBeforeUnmount(() => {
  // Remove the event listener
  window.removeEventListener('keydown', handleKeydown);
});

const handleKeydown = (event) => {
  if (event.key === 'ArrowRight') {
    // Move to the next tab
    if (activeTab.value < 6) {
      activeTab.value += 1;
      changeTab(activeTab.value);
    }
  } else if (event.key === 'ArrowLeft') {
    // Move to the previous tab
    if (activeTab.value > 0) {
      activeTab.value -= 1;
      changeTab(activeTab.value);
    }
  }
};

</script>

<style scoped>
.page {
  display: flex;
  align-items: flex-start;
  height: 165px;
  max-height: 165px;
  overflow: hidden;
  width: 1195px;
  border-radius: 3px;
  border: 1px solid #2f353d;
  padding-bottom: 9px;
  margin-top: 5px;
  background-color: #0d1117;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, auto);
  margin-right: 10px;
  margin-left: 60px;
}

.costing-table-container {
  margin-top: 5px;
  text-align: left;
}

.additional-components-container {
  display: flex;
  flex-direction: row;
  text-align: left;
}
</style>
