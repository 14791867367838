<template>
  <div style="font-weight: 600; margin-top: 18px; margin-bottom: 8px;">Five-Year Cost Projection</div>
  <div>
    <table>
      <thead>
        <tr>
          <th style="width: 100px;">Category</th>
          <th style="width: 150px;"> </th>
          <th style="width: 110px;">1st Year</th>
          <th style="width: 110px;">2nd Year</th>
          <th style="width: 110px;">3rd Year</th>
          <th style="width: 110px;">4th Year</th>
          <th style="width: 110px;">5th Year</th>
        </tr>
      </thead>
      <tbody>

        <tr class="black-row"><td/><td/><td/><td/><td/><td/><td/></tr>

        <tr>
          <td :style="{ color: ec2Price === 0 ? 'grey' : null }">EC2</td>
          <td :style="{ color: ec2Price === 0 ? 'grey' : null }">Compute</td>
          <td :style="{ color: getEC2(1) === 0 ? 'grey' : null }">{{ formatPrice(yearData[0].ec2) }}</td>
          <td :style="{ color: getEC2(2) === 0 ? 'grey' : null }">{{ formatPrice(yearData[1].ec2) }}</td>
          <td :style="{ color: getEC2(3) === 0 ? 'grey' : null }">{{ formatPrice(yearData[2].ec2) }}</td>
          <td :style="{ color: getEC2(4) === 0 ? 'grey' : null }">{{ formatPrice(yearData[3].ec2) }}</td>
          <td :style="{ color: getEC2(5) === 0 ? 'grey' : null }">{{ formatPrice(yearData[4].ec2) }}</td>
        </tr>
        <tr>
          <td :style="{ color: ec2Price === 0 ? 'grey' : null }"></td>
          <td :style="{ color: ec2Price === 0 ? 'grey' : null }">Licences</td>
          <td :style="{ color: getEC2(1) === 0 ? 'grey' : null }">{{ formatPrice(yearData[0].ec2_licences) }}</td>
          <td :style="{ color: getEC2(2) === 0 ? 'grey' : null }">{{ formatPrice(yearData[1].ec2_licences) }}</td>
          <td :style="{ color: getEC2(3) === 0 ? 'grey' : null }">{{ formatPrice(yearData[2].ec2_licences) }}</td>
          <td :style="{ color: getEC2(4) === 0 ? 'grey' : null }">{{ formatPrice(yearData[3].ec2_licences) }}</td>
          <td :style="{ color: getEC2(5) === 0 ? 'grey' : null }">{{ formatPrice(yearData[4].ec2_licences) }}</td>
        </tr>

        <tr>
          <td :style="{ color: ec2StoragePrice === 0 ? 'grey' : null }"></td>
          <td :style="{ color: ec2StoragePrice === 0 ? 'grey' : null }">Storage</td>
          <td :style="{ color: ec2StoragePrice === 0 ? 'grey' : null }">{{ formatPrice(yearData[0].ec2_storage) }}</td>
          <td :style="{ color: ec2StoragePrice === 0 ? 'grey' : null }">{{ formatPrice(yearData[1].ec2_storage) }}</td>
          <td :style="{ color: ec2StoragePrice === 0 ? 'grey' : null }">{{ formatPrice(yearData[2].ec2_storage) }}</td>
          <td :style="{ color: ec2StoragePrice === 0 ? 'grey' : null }">{{ formatPrice(yearData[3].ec2_storage) }}</td>
          <td :style="{ color: ec2StoragePrice === 0 ? 'grey' : null }">{{ formatPrice(yearData[4].ec2_storage) }}</td>
        </tr>
        <tr>
          <td :style="{ color: backupPrice === 0 ? 'grey' : null }"></td>
          <td :style="{ color: backupPrice === 0 ? 'grey' : null }">Backups</td>
          <td :style="{ color: backupPrice === 0 ? 'grey' : null }">{{ formatPrice(yearData[0].ec2_backup) }}</td>
          <td :style="{ color: backupPrice === 0 ? 'grey' : null }">{{ formatPrice(yearData[1].ec2_backup) }}</td>
          <td :style="{ color: backupPrice === 0 ? 'grey' : null }">{{ formatPrice(yearData[2].ec2_backup) }}</td>
          <td :style="{ color: backupPrice === 0 ? 'grey' : null }">{{ formatPrice(yearData[3].ec2_backup) }}</td>
          <td :style="{ color: backupPrice === 0 ? 'grey' : null }">{{ formatPrice(yearData[4].ec2_backup) }}</td>
        </tr>
        <tr>
          <td :style="{ color: yearData[0].ec2_io === 0 ? 'grey' : null }"></td>
          <td :style="{ color: yearData[0].ec2_io === 0 ? 'grey' : null }">IOPs/MBs</td>
          <td :style="{ color: yearData[0].ec2_io === 0 ? 'grey' : null }">{{ formatPrice(yearData[0].ec2_io) }}</td>
          <td :style="{ color: yearData[0].ec2_io === 0 ? 'grey' : null }">{{ formatPrice(yearData[1].ec2_io) }}</td>
          <td :style="{ color: yearData[0].ec2_io === 0 ? 'grey' : null }">{{ formatPrice(yearData[2].ec2_io) }}</td>
          <td :style="{ color: yearData[0].ec2_io === 0 ? 'grey' : null }">{{ formatPrice(yearData[3].ec2_io) }}</td>
          <td :style="{ color: yearData[0].ec2_io === 0 ? 'grey' : null }">{{ formatPrice(yearData[4].ec2_io) }}</td>
        </tr>

        <tr class="black-row"><td/><td/><td/><td/><td/><td/><td/></tr>

        <tr>
          <td :style="{ color: yearData[0].rds === 0 ? 'grey' : null }">RDS</td>
          <td :style="{ color: yearData[0].rds === 0 ? 'grey' : null }">Compute</td>
          <td :style="{ color: yearData[0].rds === 0 ? 'grey' : null }">{{ formatPrice(yearData[0].rds) }}</td>
          <td :style="{ color: yearData[0].rds === 0 ? 'grey' : null }">{{ formatPrice(yearData[1].rds) }}</td>
          <td :style="{ color: yearData[0].rds === 0 ? 'grey' : null }">{{ formatPrice(yearData[2].rds) }}</td>
          <td :style="{ color: yearData[0].rds === 0 ? 'grey' : null }">{{ formatPrice(yearData[3].rds) }}</td>
          <td :style="{ color: yearData[0].rds === 0 ? 'grey' : null }">{{ formatPrice(yearData[4].rds) }}</td>
        </tr>
        <tr>
          <td :style="{ color: yearData[0].rds_licences === 0 ? 'grey' : null }"></td>
          <td :style="{ color: yearData[0].rds_licences === 0 ? 'grey' : null }">Licences</td>
          <td :style="{ color: yearData[0].rds_licences === 0 ? 'grey' : null }">{{ formatPrice(yearData[0].rds_licences) }}</td>
          <td :style="{ color: yearData[0].rds_licences === 0 ? 'grey' : null }">{{ formatPrice(yearData[1].rds_licences) }}</td>
          <td :style="{ color: yearData[0].rds_licences === 0 ? 'grey' : null }">{{ formatPrice(yearData[2].rds_licences) }}</td>
          <td :style="{ color: yearData[0].rds_licences === 0 ? 'grey' : null }">{{ formatPrice(yearData[3].rds_licences) }}</td>
          <td :style="{ color: yearData[0].rds_licences === 0 ? 'grey' : null }">{{ formatPrice(yearData[4].rds_licences) }}</td>
        </tr>
        <tr>
          <td :style="{ color: yearData[0].rds_storage === 0 ? 'grey' : null }"></td>
          <td :style="{ color: yearData[0].rds_storage === 0 ? 'grey' : null }">Storage / Backups</td>
          <td :style="{ color: yearData[0].rds_storage === 0 ? 'grey' : null }">{{ formatPrice(yearData[0].rds_storage) }}</td>
          <td :style="{ color: yearData[0].rds_storage === 0 ? 'grey' : null }">{{ formatPrice(yearData[1].rds_storage) }}</td>
          <td :style="{ color: yearData[0].rds_storage === 0 ? 'grey' : null }">{{ formatPrice(yearData[2].rds_storage) }}</td>
          <td :style="{ color: yearData[0].rds_storage === 0 ? 'grey' : null }">{{ formatPrice(yearData[3].rds_storage) }}</td>
          <td :style="{ color: yearData[0].rds_storage === 0 ? 'grey' : null }">{{ formatPrice(yearData[4].rds_storage) }}</td>
        </tr>
        <tr>
          <td :style="{ color: yearData[0].rds_io === 0 ? 'grey' : null }"></td>
          <td :style="{ color: yearData[0].rds_io === 0 ? 'grey' : null }">IOPs/MBs</td>
          <td :style="{ color: yearData[0].rds_io === 0 ? 'grey' : null }">{{ formatPrice(yearData[0].rds_io) }}</td>
          <td :style="{ color: yearData[0].rds_io === 0 ? 'grey' : null }">{{ formatPrice(yearData[1].rds_io) }}</td>
          <td :style="{ color: yearData[0].rds_io === 0 ? 'grey' : null }">{{ formatPrice(yearData[2].rds_io) }}</td>
          <td :style="{ color: yearData[0].rds_io === 0 ? 'grey' : null }">{{ formatPrice(yearData[3].rds_io) }}</td>
          <td :style="{ color: yearData[0].rds_io === 0 ? 'grey' : null }">{{ formatPrice(yearData[4].rds_io) }}</td>
        </tr>

        <tr class="black-row"><td/><td/><td/><td/><td/><td/><td/></tr>

        <tr>
          <td :style="{ color: overhead === 0 ? 'grey' : null }">VPC</td>
          <td :style="{ color: overhead === 0 ? 'grey' : null }">Overhead</td>
          <td :style="{ color: overhead === 0 ? 'grey' : null }">{{ formatPrice(yearData[0].overheadValue ) }}</td>
          <td :style="{ color: overhead === 0 ? 'grey' : null }">{{ formatPrice(yearData[1].overheadValue ) }}</td>
          <td :style="{ color: overhead === 0 ? 'grey' : null }">{{ formatPrice(yearData[2].overheadValue ) }}</td>
          <td :style="{ color: overhead === 0 ? 'grey' : null }">{{ formatPrice(yearData[3].overheadValue ) }}</td>
          <td :style="{ color: overhead === 0 ? 'grey' : null }">{{ formatPrice(yearData[4].overheadValue ) }}</td>
        </tr>
        <tr>
          <td :style="{ color: yearData[0].s3_cost === 0 ? 'grey' : null }">S3</td>
          <td :style="{ color: yearData[0].s3_cost === 0 ? 'grey' : null }">Storage</td>
          <td :style="{ color: yearData[0].s3_cost === 0 ? 'grey' : null }">{{ formatPrice(yearData[0].s3_cost) }}</td>
          <td :style="{ color: yearData[0].s3_cost === 0 ? 'grey' : null }">{{ formatPrice(yearData[1].s3_cost) }}</td>
          <td :style="{ color: yearData[0].s3_cost === 0 ? 'grey' : null }">{{ formatPrice(yearData[2].s3_cost) }}</td>
          <td :style="{ color: yearData[0].s3_cost === 0 ? 'grey' : null }">{{ formatPrice(yearData[3].s3_cost) }}</td>
          <td :style="{ color: yearData[0].s3_cost === 0 ? 'grey' : null }">{{ formatPrice(yearData[4].s3_cost) }}</td>
        </tr>
        <tr>
          <td :style="{ color: yearData[0].egress === 0 ? 'grey' : null }">Network</td>
          <td :style="{ color: yearData[0].egress === 0 ? 'grey' : null }">Egress</td>
          <td :style="{ color: yearData[0].egress === 0 ? 'grey' : null }">{{ formatPrice(yearData[0].egress ) }}</td>
          <td :style="{ color: yearData[0].egress === 0 ? 'grey' : null }">{{ formatPrice(yearData[1].egress ) }}</td>
          <td :style="{ color: yearData[0].egress === 0 ? 'grey' : null }">{{ formatPrice(yearData[2].egress ) }}</td>
          <td :style="{ color: yearData[0].egress === 0 ? 'grey' : null }">{{ formatPrice(yearData[3].egress ) }}</td>
          <td :style="{ color: yearData[0].egress === 0 ? 'grey' : null }">{{ formatPrice(yearData[4].egress ) }}</td>
        </tr>
        <tr>
          <td :style="{ color: yearData[0].discountValue === 0 ? 'grey' : null }">Discount</td>
          <td :style="{ color: yearData[0].discountValue === 0 ? 'grey' : null }">EDP</td>
          <td :style="{ color: yearData[0].discountValue === 0 ? 'grey' : 'rgb(91, 196, 89)' }">{{ formatPrice(-yearData[0].discountValue) }}</td>
          <td :style="{ color: yearData[0].discountValue === 0 ? 'grey' : 'rgb(91, 196, 89)' }">{{ formatPrice(-yearData[1].discountValue) }}</td>
          <td :style="{ color: yearData[0].discountValue === 0 ? 'grey' : 'rgb(91, 196, 89)' }">{{ formatPrice(-yearData[2].discountValue) }}</td>
          <td :style="{ color: yearData[0].discountValue === 0 ? 'grey' : 'rgb(91, 196, 89)' }">{{ formatPrice(-yearData[3].discountValue) }}</td>
          <td :style="{ color: yearData[0].discountValue === 0 ? 'grey' : 'rgb(91, 196, 89)' }">{{ formatPrice(-yearData[4].discountValue) }}</td>
        </tr>
        <tr>
          <td :style="{ color: yearData[0].supportFee === 0 ? 'grey' : null }">Support</td>
          <td :style="{ color: yearData[0].supportFee === 0 ? 'grey' : null }">{{ support === 'none'?"None":support === 'business'?'Business':'Enterprise' }}</td>
          <td :style="{ color: yearData[0].supportFee === 0 ? 'grey' : 'orange' }">{{ formatPrice(yearData[0].supportFee) }}</td>
          <td :style="{ color: yearData[0].supportFee === 0 ? 'grey' : 'orange' }">{{ formatPrice(yearData[1].supportFee) }}</td>
          <td :style="{ color: yearData[0].supportFee === 0 ? 'grey' : 'orange' }">{{ formatPrice(yearData[2].supportFee) }}</td>
          <td :style="{ color: yearData[0].supportFee === 0 ? 'grey' : 'orange' }">{{ formatPrice(yearData[3].supportFee) }}</td>
          <td :style="{ color: yearData[0].supportFee === 0 ? 'grey' : 'orange' }">{{ formatPrice(yearData[4].supportFee) }}</td>
        </tr>

        <tr class="black-row"><td/><td/><td/><td/><td/><td/><td/></tr>
          <tr>
            <th style="text-align: left !important;">Total</th>
            <th> </th>
            <th>{{ formatPrice(yearData[0].total) }}</th>
            <th>{{ formatPrice(yearData[1].total) }}</th>
            <th>{{ formatPrice(yearData[2].total) }}</th>
            <th>{{ formatPrice(yearData[3].total) }}</th>
            <th>{{ formatPrice(yearData[4].total) }}</th>
          </tr>

          <tr>
            <td style="text-align: left !important;">Cumulative Total</td>
            <td> </td>
            <td>{{ formatPrice(yearData[0].total) }}</td>
            <td>{{ formatPrice(yearData[0].total.plus(yearData[1].total)) }}</td>
            <td>{{ formatPrice(yearData[0].total.plus(yearData[1].total).plus(yearData[2].total)) }}</td>
            <td>{{ formatPrice(yearData[0].total.plus(yearData[1].total).plus(yearData[2].total).plus(yearData[3].total)) }}</td>
            <td>{{ formatPrice(yearData[0].total.plus(yearData[1].total).plus(yearData[2].total).plus(yearData[3].total).plus(yearData[4].total)) }}</td>
          </tr>
        <tr class="black-row"><td/><td/><td/><td/><td/><td/><td/></tr>

        <tr>
          <th style="text-align: left !important;">NPV *</th>
          <th style="text-align: left !important;"> </th>
          <th>{{ formatPrice(yearData[0].npv) }}</th>
          <th>{{ formatPrice(yearData[1].npv) }}</th>
          <th>{{ formatPrice(yearData[2].npv) }}</th>
          <th>{{ formatPrice(yearData[3].npv) }}</th>
          <th>{{ formatPrice(yearData[4].npv) }}</th>
        </tr>

        <tr>
          <td style="text-align: left !important;">Cumulative NPV</td>
          <td> </td>
          <td>{{ formatPrice(yearData[0].npv) }}</td>
          <td>{{ formatPrice(yearData[0].npv.plus(yearData[1].npv)) }}</td>
          <td>{{ formatPrice(yearData[0].npv.plus(yearData[1].npv).plus(yearData[2].npv)) }}</td>
          <td>{{ formatPrice(yearData[0].npv.plus(yearData[1].npv).plus(yearData[2].npv).plus(yearData[3].npv)) }}</td>
          <td>{{ formatPrice(yearData[0].npv.plus(yearData[1].npv).plus(yearData[2].npv).plus(yearData[3].npv).plus(yearData[4].npv)) }}</td>
        </tr>

      </tbody>
    </table>
  </div>

  <div style="margin-top: 10px; color: grey; margin-bottom: 200px;">
    * Net Present Value represents future cost in current dollars, using a discount rate or inflation rate of
    <input type="text" v-model="discountRateInput" class="npv-input" />
    %
  </div>
</template>

<script setup>
import Decimal from "decimal.js";
import { defineProps, watch, ref } from 'vue';
import { calculateSupportFeesYearDecimal } from './CostShared';

const discountRateInput = ref("2.5");

// Props
const props = defineProps({
  costings: { type: Array, required: true },
  support: { type: String, required: true },
  discount: { type: Number, required: true },
  overhead: { type: Number, required: true },
  free_egress: { type: Number, required: true },
  s3_cost: { type: Number, required: true, default: 0 },
});

const calculateNPV = (cashFlow, year, discountRate) => {
  const factor = new Decimal(1).plus(discountRate).pow(new Decimal(year).minus(0.5));
  return new Decimal(cashFlow).div(factor);
};

const formatPrice = (value) => {
  return Number(value).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  //return Number(value).toLocaleString("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 });
};

// make sure a is converted to decimal, b can be passed as float or decimal
const add = (a, b) => {
  if (a === undefined || b === undefined) return 0;
  return new Decimal(a).plus(b);
};

// make sure a is converted to decimal, b can be passed as float or decimal
const sub = (a, b) => {
  if (a === undefined || b === undefined) return 0;
  return new Decimal(a).minus(b);
};

// make sure a is converted to decimal, b can be passed as float or decimal
const mult = (a, b) => {
  if (a === undefined || b === undefined) return 0;
  return new Decimal(a).times(b);
};

const getRDS = (year) => {
  let rds = new Decimal(0);

  props.costings.forEach(item => {
    if (!item.retire && item.target.startsWith("rds")) {
      let saz = sub(item.pricing.rds_price_ri, sub(item.pricing.rds_price, item.pricing.rds_db_price));
      let maz = sub(item.pricing.rds_price_ri_mz, sub(item.pricing.rds_price_mz, item.pricing.rds_db_price_mz));
      let price = (item.target === "rds") ? saz : maz

      if (item.pricing.strategy === "3auri") {
        if (year === 1 || year === 4) {
          rds = add(rds, mult(3, price));
        }
      } else {
        rds = add(rds, price);
      }
    }
  });

  return mult(12, rds);
}

const getRDSOS = (year) => {
  let rds_os = new Decimal(0);

  props.costings.forEach(item => {
    if (!item.retire && item.target.startsWith("rds")) {
      let saz = sub(item.pricing.rds_price, item.pricing.rds_db_price);
      let maz = sub(item.pricing.rds_price_mz, item.pricing.rds_db_price_mz);
      let price = (item.target === "rds") ? saz : maz

      if (item.pricing.strategy === "3auri") {
        if (year===1 || year === 4) {
          rds_os = add(rds_os, mult(3, price));
        }
      } else {
          if (item.target === "rds") {
            rds_os = add(rds_os, sub(item.pricing.rds_price, item.pricing.rds_db_price));
          } else {
            rds_os = add(rds_os, sub(item.pricing.rds_price_mz, item.pricing.rds_db_price_mz)); // maz
          }
      }
    }
  });

  return mult(12, rds_os);
}

const getEC2 = (year) => {
  let ec2 = new Decimal(0);

  props.costings.forEach(item => {
    if (!item.retire && item.target === "ec2") {
      if (item.pricing.strategy === "3ausp") {
        // for all up front all is paid in 1 & 4
        if (year===1 || year === 4) {
          ec2 = add(ec2, mult(3, sub(item.pricing.price_nusp, sub(item.pricing.price_instance, item.pricing.price_os))));
        }
        // else none this year
      } else {
        ec2 = add(ec2, sub(item.pricing.price_nusp, sub(item.pricing.price_instance, item.pricing.price_os)));
      }
    }
  });

  return mult(12, ec2);
}

const getEC2Storage = () => {
  let ec2_storage = new Decimal(0);

  props.costings.forEach(item => {
    if (!item.retire && item.target === "ec2") {
      ec2_storage = add(ec2_storage, item.pricing.price_disk);
    }
  });

  return mult(12, ec2_storage);
}

const getEC2backup = () => {
  let backup = new Decimal(0);
  props.costings.forEach(item => {
    if (!item.retire && item.target === "ec2") {
      backup = add(backup, item.pricing.price_backup);
    }
  });
  return mult(12, backup);
}

const getEC2io = () => {
  let io = new Decimal(0);
  props.costings.forEach(item => {
    if (!item.retire && item.target === "ec2") {
      io = add(io, item.pricing.price_iops);
      io = add(io, item.pricing.price_throughput);
    }
  });
  return mult(12, io);
}

const getRDSio = () => {
  let io = new Decimal(0);
  props.costings.forEach(item => {
    if (!item.retire && item.target.startsWith("rds")) {
      io = add(io, mult(item.pricing.rds_iops_price, item.target === "rdsm" ? 2 : 1));
      io = add(io, mult(item.pricing.rds_thrpt_price, item.target === "rdsm" ? 2 : 1));
    }
  });
  return mult(12, io);
}


const getRDSStorage = () => {
  let rds_storage = new Decimal(0);
  props.costings.forEach(item => {
    if (!item.retire && item.target.startsWith("rds")) {
      rds_storage = add(rds_storage, mult(item.pricing.rds_disk_price, item.target === "rdsm" ? 2 : 1));
    }
  });
  return mult(12, rds_storage);
}

const getEC2os = (year) => {
  let ec2_os = new Decimal(0);

  props.costings.forEach(item => {
    if (!item.retire && item.target === "ec2") {
      if (item.pricing.strategy === "3ausp") {
        // for all up front all is paid in 1 & 4
        if (year===1 || year === 4) {
          ec2_os = add(ec2_os, mult(3, sub(item.pricing.price_instance, item.pricing.price_os)));
        }
        // else none this year
      } else {
        ec2_os = add(ec2_os, sub(item.pricing.price_instance, item.pricing.price_os));
      }
    }
  });

  return mult(12, ec2_os);
}

const getEgress = () => {
  let egress = 0;
  props.costings.forEach(item => {
    if (!item.retire) {
      egress = add(egress, item.pricing.egress_price);
    }
  });
  return mult(12, egress);
}

// Reactive variables
const yearData = ref([1, 2, 3, 4, 5].map(() => ({
  ec2: new Decimal(0),
  ec2_storage: new Decimal(0),
  ec2_licences: new Decimal(0),
  ec2_backup: new Decimal(0),
  ec2_io: new Decimal(0),
  rds: new Decimal(0),
  rds_storage: new Decimal(0),
  rds_licences: new Decimal(0),
  rds_io: new Decimal(0),
  egress: new Decimal(0),
  total: new Decimal(0),
  discountValue: new Decimal(0),
  overheadValue: new Decimal(0),
  supportFee: new Decimal(0),
  npv: new Decimal(0),
  s3_cost: new Decimal(0),
})));

// Main calculation logic
const calculateYearlyData = () => {
  const discountRate = new Decimal(discountRateInput.value).div(100);

  yearData.value = [1, 2, 3, 4, 5].map((year) => {

    // yearly costs
    const ec2 = getEC2(year);
    const ec2_storage = getEC2Storage()
    const ec2_licence = getEC2os(year);
    const ec2_backup = getEC2backup();
    const ec2_io = getEC2io();
    const rds = getRDS(year);
    const rds_storage = getRDSStorage();
    const rds_licence = getRDSOS(year);
    const rds_io = getRDSio();
    const egress = getEgress();
    const s3 = new Decimal(props.s3_cost).times(12);

    const yearly_total = new Decimal(ec2)
        .plus(ec2_storage)
        .plus(ec2_licence)
        .plus(ec2_backup)
        .plus(ec2_io)
        .plus(rds)
        .plus(rds_storage)
        .plus(rds_licence)
        .plus(rds_io);

    // vpc overhead  (add s3 and egress after this)
    const overheadValue = yearly_total.times(props.overhead / 100);
    const totalWithOverhead = yearly_total.plus(egress).plus(s3).plus(overheadValue);

    // enterprise discount
    const discountValue = totalWithOverhead.times(props.discount / 100);
    const totalAfterDiscount = totalWithOverhead.minus(discountValue);

    // support fees
    const supportFee = calculateSupportFeesYearDecimal(props.support, totalAfterDiscount);
    const finalTotal = totalAfterDiscount.plus(supportFee);

    // npv
    const npv = calculateNPV(finalTotal, year, discountRate);

    // return all as decimal, not number
    return {
      ec2: ec2,
      ec2_storage: ec2_storage,
      ec2_licences: ec2_licence,
      ec2_backup: ec2_backup,
      ec2_io: ec2_io,
      rds: rds,
      rds_storage: rds_storage,
      rds_licences: rds_licence,
      rds_io: rds_io,
      egress: egress,
      total: finalTotal,
      discountValue: discountValue,
      overheadValue: overheadValue,
      supportFee: supportFee,
      npv: npv,
      s3_cost: s3,
    };
  });
};

// recalc numbers on change on discount rate
watch(discountRateInput, calculateYearlyData);

// watch for changes in props or user input
watch(
  () => [props.costings, props.discount, props.overhead, props.support],
  calculateYearlyData,
  { immediate: true }
);

</script>

<style scoped>
table {
  border-collapse: collapse;
  border-radius: 9px !important;
  border: 1px solid #2f353d;
}
th, td {
  border-bottom: 1px solid #2f353d;
  padding: 3px 7px 3px 11px;
  text-align: left;
}
th:nth-child(1),
td:nth-child(1) {
  text-align: left;
}

th:nth-child(3), th:nth-child(4), th:nth-child(5), th:nth-child(6), th:nth-child(7),
td:nth-child(3), td:nth-child(4), td:nth-child(5), td:nth-child(6), td:nth-child(7) {
  text-align: right;
}

.black-row td {
  background-color: #000;
}
.black-row {
  height: 5px;
  border-left: none;
  border-right: none;
}

.large-black-row td {
  background-color: #000;
}
.large-black-row {
  height: 20px;
  border-left: none;
  border-right: none;
}

.npv-input {
  margin-left: 5px;
  margin-right: 5px;
  width: 40px;
  text-align: center;
  border-radius: 2px;
  background-color: #1D2532;
  color: #D9D9D9;
  border: 1px solid #2F353D;
}
.npv-input:focus {
  outline: none;
  box-shadow: none;
}
</style>