import './index.css';
import { createApp } from 'vue';
import App from './App.vue';
import { makeRoutes } from './sidebar';
import { createPinia } from 'pinia';
import VNetworkGraph from "v-network-graph"
import HighchartsVue from 'highcharts-vue'
import "v-network-graph/lib/style.css"
import Highcharts from 'highcharts'

const app = createApp(App);
const pinia = createPinia();

import stockInit from 'highcharts/modules/stock'

// needed to support stock charts in highchart
stockInit(Highcharts)

// disable all animation in high-charts
Highcharts.setOptions({
	plotOptions: {
		series: {
			animation: false
		}
	}
});

const router = makeRoutes();

app
    .use(router)
    .use(pinia)
    .use(HighchartsVue)
    .use(VNetworkGraph)
    .mount('#app');