// stores/cancelFetchStore.js
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useCancelFetchStore = defineStore('cancelFetch', () => {
  const cancelFetch = ref(false);

  const toggleCancelFetch = (value) => {
    cancelFetch.value = value;
  };

  return {
    cancelFetch,
    toggleCancelFetch,
  };
});