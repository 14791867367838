<script setup>

</script>

<template>
<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 64 64" xml:space="preserve">
<path d="M32.5,2.8c-16.6,0-30,13.4-30,30s13.4,30,30,30s30-13.4,30-30S49,2.8,32.5,2.8z M51,33L21,51V15"/>
</svg>

</template>

<style scoped>

</style>