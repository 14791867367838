<template>
  <div v-if="show" class="modal-overlay">
    <div class="modal-content">
      <div class="header">Complete Collection</div>
      <div class="modal-body">

        <p class="warning-message" :style="paused ? { color: 'rgb(217 217 217)' } : {}">
          This action will <b>cease</b> all data collection for this portfolio, and all agents will be removed. This is not reversible. Please re-enter the Portfolio ID to confirm.
        </p>

        <div class="modal-label">Portfolio ID</div>
        <input
          v-model="enteredPortfolioId"
          id="deletePortfolioId"
          type="text"
          class="modal-input"
          autocomplete="off"
          ref="deletePortfolioIdInput"
          :placeholder="props.portfolioId.includes('.') ? props.portfolioId.split('.')[0] : props.portfolioId"
        />

        <div class="modal-label">Complete Collection</div>
        <input
          v-model="enteredComplete"
          id="deleteConfirm"
          type="text"
          class="modal-input"
          autocomplete="off"
          placeholder="complete"
        />

      </div>
      <div class="modal-actions">
        <div class="error-message">{{ errorMessage }}</div>
        <button @click="submitForm" class="modal-button delete-button">Complete</button>
        <button @click="closeModal" class="modal-button modal-close">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue';

const emit = defineEmits(['close', 'stop']);

const props = defineProps({
  portfolioId: {
    type: String,
    required: true
  }
});

const show = ref(true);
const errorMessage = ref('');
const enteredPortfolioId = ref('');
const enteredComplete = ref('');

// declare ref for portfolio ID input
const deletePortfolioIdInput = ref(null);

const validateForm = () => {
  enteredPortfolioId.value = enteredPortfolioId.value.trim();

  if (!enteredPortfolioId.value) {
    errorMessage.value = 'Portfolio ID is required.';
    return false;
  }

  if (enteredComplete.value !== 'complete') {
    errorMessage.value = 'You must type complete to confirm.';
    return false;
  }

  // handle cases where the portfolioId might or might not have a suffix
  const strippedPortfolioId = props.portfolioId.includes('.')
    ? props.portfolioId.split('.')[0]
    : props.portfolioId;

  if (enteredPortfolioId.value !== strippedPortfolioId) {
    errorMessage.value = 'Portfolio ID does not match.';
    return false;
  }

  errorMessage.value = '';
  return true;
};

const submitForm = () => {
  if (validateForm()) {
    emit('stop', props.portfolioId)
    closeModal();
  }
};

const closeModal = () => {
  show.value = false;
  emit('close');
};
</script>

<style scoped>
.header {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 15px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00000085;
  display: flex;
  justify-content: center;
  align-items: flex-start; /* align to the top */
  z-index: 100;
  padding-top: calc(100vh / 4);
}

.modal-content {
  background-color: #06080A;
  padding: 15px 20px 15px;
  border: 1px solid #2f353d;
  border-radius: 4px;
  width: 400px;
  max-width: 90vw;
  box-shadow: 0 0 58px #ffffff08;
}

.modal-input:focus {
  outline: none;
  box-shadow: none;
  border-color: #2f353d;
}

.modal-input {
  width: 358px;
  background-color: #0D1117;
  border: 1px solid #2f353d;
  color: #d9d9d9;
  font-size: 12px;
  padding: 6px 30px 7px 10px;
  border-radius: 4px;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 15px;
}

.modal-button {
  padding: 6px 12px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.modal-close {
  background-color: #1d232d;
  color: #fff;
  border: 1px solid #2f353d;
  margin-left: 13px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.modal-label {
  display: block;
  margin-bottom: 4px;
  margin-top: 10px;
  font-size: 12px;
  color: #d4d4d4;
}

.error-message {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff4d4f;
  font-size: 12px;
  font-weight: 600;
  margin-right: 20px;
  padding-right: 20px;
  width: 250px;
  height: 30px;
}

.warning-message {
  color: #ff9900;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 15px;
  text-align: center;
  width: 334px;
  margin-left: 37px;
}

.delete-button {
  color: #fff;
}
</style>