<template>
  <div class="counter-container">
    <div class="header">{{ headerText }}</div>
    <CountUp :startVal="oldRecordCount" :endVal="recordCount" :options="countUpOptions" />
  </div>
</template>

<script setup>
import {ref, onMounted, onUnmounted, toRefs, watch, computed} from 'vue';
import axios from 'axios';
import CountUp from 'vue-countup-v3';
import {useLiveCounterStore} from "@/stores/DataStore";

const counterStore = useLiveCounterStore();

const props = defineProps({
  headerText: {
    type: String,
    default: 'Network (kb/s)' // default header text if not provided
  },
  pollSec: {
    type: Number,
    required: true,
    default: 3 // default to 3000ms if not provided
  },
  path: {
    type: String,
    required: true
  },
  dp: {
    type: Number,
    default: 2 // default to 2 decimal places if not provided
  },
  selectedPortfolio: {
    type: String,
    required: true
  },
});

const {pollSec, path, dp, headerText} = toRefs(props);

const recordCount = ref(0.0);
const oldRecordCount = ref(0.0);

const countUpOptions = computed(() => ({
  duration: pollSec.value,
  useEasing: false,
  decimalPlaces: dp.value,
}));

let first_val;
let cancelTokenSource;

let intervalId;

onMounted(async () => {
  // nothing provided on mount, instead wait for watch props
});

onUnmounted(() => {
  clearInterval(intervalId);
  if (cancelTokenSource) {
    cancelTokenSource.cancel('Component unmounted.');
  }
});

watch(() => props.selectedPortfolio, async (newPortfolio) => {

  // error which may not exist anymore -> object was passed instead of string
  if (typeof props.selectedPortfolio != 'string' || props.selectedPortfolio.trim() === '') {
    console.log("Invalid portfolio: not a string or is empty");
    return;
  }

  getNewValue(newPortfolio);
});

const getNewValue = async (newPortfolio) => {

  clearInterval(intervalId);

  // cancel any current request
  if (cancelTokenSource) {
    cancelTokenSource.cancel('Operation canceled due to new request.');
  }

  // set new request to reset values
  first_val = true;

  let old_data = await counterStore.getCache(newPortfolio + "." + props.path);

  // if there is existing data, set it to that now before the server call returns
  if (old_data !== null) {
    recordCount.value = old_data;
    oldRecordCount.value = old_data;
  }

  // fetch new record count
  fetchRecordCount();

  // create new interval from now
  intervalId = setInterval(fetchRecordCount, pollSec.value * 1000);

};

const fetchRecordCount = async () => {

  // cancel the previous request if it exists
  if (cancelTokenSource) {
    cancelTokenSource.cancel('Operation canceled due to new request.');
  }

  cancelTokenSource = axios.CancelToken.source();

  if (first_val) {
    // cancel any current animation
    oldRecordCount.value = recordCount.value;
  }

  try {
    const baseURL = `/go/${path.value}/${props.selectedPortfolio}`;

    const response = await axios.get(baseURL, {
      headers: { 'Content-Type': 'application/json' },
      cancelToken: cancelTokenSource.token
    });

    const any_cached_data = await counterStore.getCache(props.selectedPortfolio + "." + props.path);

    // if first val & no cached data -> go direct to new item
    if (first_val && !any_cached_data) {
      // set both to the same to avoid animation
      recordCount.value = response.data.count;
      oldRecordCount.value = response.data.count;
    } else {

      // otherwise animate to new
      oldRecordCount.value = recordCount.value;
      recordCount.value = response.data.count;
    }

    first_val = false;

    if (props.selectedPortfolio) {
      counterStore.setCache(props.selectedPortfolio + "." + props.path, response.data.count);
    }

  } catch (error) {
    if (!axios.isCancel(error)) {
      console.error('Error fetching record count:', error);
    }
  }
};
</script>

<style scoped>
.counter-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;
  width: 135px;
}

.countup-wrap {
  align-self: flex-start;
}

.header {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  align-self: flex-start;
}
</style>
