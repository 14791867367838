<template>
  <div></div>
  <div class="cpu-totals">
    <div style="font-weight: 700; margin-left: 0px; margin-bottom: -5px; font-size: 13px; color: #d3d3d3;">Indicative Cost Savings</div>

    <div class="chart-container">
      <div id="cost-savings-chart" class="chart"></div>
    </div>

    <p v-if="costPercentageDecrease > 0" class="efficiency-label"><span style="font-size:13px;">▼</span> {{ costPercentageDecrease.toFixed() }}%</p>
  </div>
</template>

<script setup>
import { computed, watch, toRefs, onMounted, nextTick } from 'vue';
import Highcharts from 'highcharts';
import Decimal from 'decimal.js';
import { calculateSupportFees } from '../CostShared';

const props = defineProps({
  dataArray: {
    type: Array,
    required: true
  },
  free_egress: {
    type: Number,
    required: true
  },
  support: {
    type: String,
    required: true
  },
  discount: {
    type: Number,
    required: true
  },
  overhead: {
    type: Number,
    required: true
  },
  s3_cost: {
    type: Number,
    required: true,
    default: 0
  }
});

const { dataArray } = toRefs(props);

// method to compute extras: egress, discount, and support fees
function computeExtras(baseCost, egress, overhead, discount, support) {
  const totalBase = new Decimal(baseCost).plus(egress).toNumber();
  const overheadCost = new Decimal(totalBase).times(overhead / 100).toNumber();
  const totalWithOverhead = new Decimal(totalBase).plus(overheadCost).toNumber();
  const discountAmount = new Decimal(totalWithOverhead).times(discount / 100).toNumber();
  const totalAfterDiscount = new Decimal(totalWithOverhead).minus(discountAmount).toNumber();
  const supportFee = calculateSupportFees(support, totalAfterDiscount);
  return new Decimal(totalAfterDiscount).plus(supportFee).toNumber()
}

// compute the totals for current costs
const currentCost = computed(() => {
  const baseCost = dataArray.value.reduce((sum, item) => {
    return sum + (item?.pricing?.target_orig_price || 0);
  }, 0);

  if (dataArray.value && dataArray.value.length > 0) {
    let egress = dataArray.value.reduce((sum, item) => {
      const egressPrice = item?.pricing?.egress_price || 0;
      return sum + egressPrice;
    }, 0);

    // subtract free egress if applicable
    if (props.free_egress) {
      egress = Math.max(0, egress - props.free_egress);
    }

    return computeExtras(baseCost, egress, props.overhead, props.discount, props.support);
  }

  return baseCost;
});

// compute the totals for recommended costs
const recommendedCost = computed(() => {
  const baseCost = dataArray.value.reduce((sum, item) => {
    return !item.retire ? sum + (item?.pricing?.target_price || 0) : sum;
  }, 0);

  if (dataArray.value && dataArray.value.length > 0) {

    // calculate egress
    let egress = dataArray.value.reduce((sum, item) => {
      return !item.retire ? sum + (item?.pricing?.egress_price || 0) : sum;
    }, 0);

    // subtract free egress if applicable
    if (props.free_egress) {
      egress = Math.max(0, egress - props.free_egress);
    }

    return computeExtras(baseCost, egress, props.overhead, props.discount, props.support);
  }

  return baseCost;
});

const costPercentageDecrease = computed(() => {
  const decrease = ((currentCost.value - recommendedCost.value) / currentCost.value) * 100;
  return isNaN(decrease) ? 0 : decrease;
});

const createChart = (efficiency) => {
  Highcharts.chart('cost-savings-chart', {
    chart: {
      type: 'pie',
      backgroundColor: "rgba(0, 0, 0, 0)",
      plotBackgroundColor: "rgba(0, 0, 0, 0)",
      plotShadow: false,
      height: '100%', // Set to full height
    },
    title: {
      text: null
    },
    accessibility: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: false // Disable data labels
        },
        enableMouseTracking: true,
        startAngle: 0,
        center: ['50%', '50%'], // Centered properly
        size: '100%', // Adjust the size to fit the chart
        innerSize: '75%', // Still a semi-circle
        borderColor: "rgba(0, 0, 0, 0)",
        borderWidth: 0,
      }
    },
    tooltip: {
      enabled: false // Disables tooltips
    },
    series: [{
      name: 'Efficiency',
      colorByPoint: true,
      data: [{
        name: 'Efficiency',
        y: efficiency, // Use the calculated efficiency score
        color: '#50b152', // Blue
        borderWidth: 0.5, // Add a gap between this slice and the next
        borderColor: '#3a3a3a' // Border color
      }, {
        name: 'Remaining',
        y: 100 - efficiency, // Remaining part of 100%
        color: '#808080', // Orange
        borderWidth: 1, // Add a gap between this slice and the next
        borderColor: '#3a3a3a' // Border color
      }]
    }]
  });
};

// On mount, create the chart with the efficiency score
onMounted(() => {
  nextTick(() => {
    if (costPercentageDecrease.value > 0) {
      createChart(costPercentageDecrease.value);
    }
  });
});

// Watch for changes in the efficiency score and update the chart accordingly
watch(costPercentageDecrease, (newEfficiency) => {
  if (newEfficiency > 0) {
    createChart(newEfficiency);
  }
});

</script>

<style scoped>
.cpu-totals {
  font-size: 14px;
  margin-top: 10px;
  text-align: left;
  margin-bottom: 30px;
}

.chart-container {
  display: flex;
  align-items: center;
}

.chart {
  width: 160px;
  height: 160px;
  position: relative;
  margin-left: -10px;
}

.efficiency-item {
  display: flex;
  justify-content: space-between;
  width: 80px;
  margin-bottom: 10px;
}

.efficiency-label {
  width: 160px;
  height: 20px;
  font-size: 19px;
  text-align: center;
  margin-top: -98px !important;
  margin-left: -10px;
  margin-right: 0;
  font-weight: 600;
}

.label {
  font-weight: bold;
  color: #fff;
}

.score {
  color: #fff;
}
</style>
