<script setup>

</script>

<template>
<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path class="st0" d="M3,3h9v9H3V3z M2,3c0-0.6,0.4-1,1-1h9c0.6,0,1,0.4,1,1v9c0,0.6-0.4,1-1,1H3c-0.6,0-1-0.4-1-1V3z"
      fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>
</template>

<style scoped>

</style>