<template>
  <div v-if="isLoaded" id="app" :class="{ dark: isDark }">
    <div class="content">
      <transition :name="transitionName" mode="out-in">
        <SideBar v-if="!isLoginPage" />
      </transition>
      <main :class="{ 'no-margin-left': isLoginPage }">
        <transition :name="transitionName" mode="out-in">
          <router-view />
        </transition>
      </main>
    </div>
    <footer>
    </footer>
  </div>
</template>

<script setup>
import { watch, ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import SideBar from '@/sidebar/SideBar.vue';
import { useDarkModeStore, usePortfolioStore, useServerDataStore } from "@/stores/DataStore";
import {useUserStore} from "@/stores/SettingStore";
const route = useRoute();
const isLoaded = ref(false);
const previousRoute = ref('');

const showSidebar = computed(() => {
  const hiddenRoutes = ['/login', '/system']; // hide sidebar for login + sysmonitor
  return !hiddenRoutes.includes(route.path);
});

const store = useDarkModeStore();
const isDark = computed(() => store.darkmode);
const isLoginPage = computed(() => route.path === '/login');
const transitionName = computed(() => ['/'].includes(route.path) ? 'fade' : '');

watch(() => route.path, (newPath, oldPath) => {
  previousRoute.value = oldPath;
});

// load all stores from indexDB on initial mount
onMounted(async () => {
  await usePortfolioStore().load();
  await useServerDataStore().load();
  await useUserStore().load();
  isLoaded.value = true;
});

</script>

<style>
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #f2f3f3;
  transition: background-color 0.1s, width 0.1s, color 0.1s;
}

#app.dark {
  background: #06080a;
  color: #d9d9d9;
}

.content {
  display: flex;
  flex: 1;
}

main {
  flex: 1;
  margin-left: 231px;
  margin-top: 16px;
  transition: opacity 0.1s;
}

.no-margin-left {
  margin-left: 0 !important;
  background-color: #06080A;
  margin-top: 0px;
}

.expanded {
  margin-left: 230px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
