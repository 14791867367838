<template>
  <div class="recommendation-summary">
    <div style="font-weight: 700; text-align: left; margin-top: 10px; font-size: 13px; color: #d3d3d3;">
      Recommendation Summary
    </div>

    <div class="fixed-width">
      <div v-if="currentCPU" class="request-section">
      <div class="request-item">
        <div class="label">Provisioned</div>
        <div class="value">${{ Math.round(currentCost * 12).toLocaleString() }}<span style="color: #b3b3b3; font-size: 14px; margin-left: 3px;">/yr</span>
        </div>
        <div class="cores">
          <span class="number">{{ Math.round(currentCPU).toLocaleString() }}</span>
          <span class="suffix">CPU</span>
        </div>
        <div class="cores">
          <span class="number">{{ Math.round(currentMem).toLocaleString() }}</span>
          <span class="suffix">GiB Mem</span>
        </div>
        <div class="storage">
          <span class="number">{{ Math.round(currentStorage).toLocaleString() }}</span>
          <span class="suffix">{{ currentStorageTiB }} Disk</span>
        </div>
      </div>
      <div class="arrow">→</div>
      <div class="request-item">
        <div class="label">Recommended</div>
        <div class="value">${{ Math.round(recommendedCost * 12).toLocaleString() }}<span style="color: #b3b3b3; font-size: 14px; margin-left: 3px;">/yr</span>
        </div>
        <div class="cores">
          <span class="number">{{ Math.round(recommendedCPU).toLocaleString() }}</span>
          <span class="suffix">CPU</span>
        </div>
        <div class="cores">
          <span class="number">{{ Math.round(recommendedMem).toLocaleString()}}</span>
          <span class="suffix">GiB Mem</span>
        </div>
        <div class="storage">
          <span class="number">{{ Math.round(recommendedStorage).toLocaleString() }}</span>
          <span class="suffix">{{ recommendedStorageTiB }} Disk</span>
        </div>
      </div>
    </div>
      </div>
  </div>
</template>

<script setup>
import { computed, toRefs } from 'vue';
import Decimal from 'decimal.js';
import { calculateSupportFees } from '../CostShared';

const props = defineProps({
  dataArray: {
    type: Array,
    required: true
  },
  free_egress: {
    type: Number,
    required: true,
    default: 0
  },
  support: {
    type: String,
    required: true
  },
  discount: {
    type: Number,
    required: true
  },
  overhead: {
    type: Number,
    required: true
  },
  s3_cost: {
    type: Number,
    required: true,
    default: 0
  }
});

const { dataArray } = toRefs(props);

// method to compute extras: egress, discount, and support fees
function computeExtras(baseCost, egress, overhead, discount, support) {
  const totalBase = new Decimal(baseCost).toNumber();
  const overheadCost = new Decimal(totalBase).times(overhead / 100).toNumber();
  const totalWithOverhead = new Decimal(totalBase).plus(overheadCost).plus(egress).plus(props.s3_cost).toNumber();
  const discountAmount = new Decimal(totalWithOverhead).times(discount / 100).toNumber();
  const totalAfterDiscount = new Decimal(totalWithOverhead).minus(discountAmount).toNumber();
  const supportFee = calculateSupportFees(support, totalAfterDiscount);
  return new Decimal(totalAfterDiscount).plus(supportFee).toNumber()
}

// compute the totals for current costs
const currentCost = computed(() => {
  const baseCost = dataArray.value.reduce((sum, item) => {
    return sum + (item?.pricing?.target_orig_price || 0);
  }, 0);

  if (dataArray.value && dataArray.value.length > 0) {
    let egress = dataArray.value.reduce((sum, item) => {
      const egressPrice = item?.pricing?.egress_price || 0;
      return sum + egressPrice;
    }, 0);

    // subtract free egress if applicable
    if (props.free_egress) {
      egress = Math.max(0, egress - props.free_egress);
    }

    return computeExtras(baseCost, egress, props.overhead, props.discount, props.support);
  }

  return baseCost;
});

// compute the totals for recommended costs
const recommendedCost = computed(() => {
  const baseCost = dataArray.value.reduce((sum, item) => {
    return !item.retire ? sum + (item?.pricing?.target_price || 0) : sum;
  }, 0);

  if (dataArray.value && dataArray.value.length > 0) {

    // calculate egress
    let egress = dataArray.value.reduce((sum, item) => {
      return !item.retire ? sum + (item?.pricing?.egress_price || 0) : sum;
    }, 0);

    // subtract free egress if applicable
    if (props.free_egress) {
      egress = Math.max(0, egress - props.free_egress);
    }

    return computeExtras(baseCost, egress, props.overhead, props.discount, props.support);
  }

  return baseCost;
});

const currentCPU = computed(() => {
  return dataArray.value.reduce((sum, item) => sum + (item?.pricing?.cpu || 0), 0);
});

const currentMem = computed(() => {
  return dataArray.value.reduce((sum, item) => sum + (item?.pricing?.mem || 0), 0);
});

const recommendedCPU = computed(() => {
  return dataArray.value.reduce((sum, item) => {
    return item.retire === false ? sum + (item?.pricing?.new_cpu || 0) : sum;
  }, 0);
});

const recommendedMem = computed(() => {
  return dataArray.value.reduce((sum, item) => {
    return item.retire === false ? sum + (item?.pricing?.new_mem || 0) : sum;
  }, 0);
});

const recommendedStorage = computed(() => {
  const newDisk = dataArray.value.reduce((sum, item) => {
    return item.retire === false ? sum + (item?.pricing?.new_disk || 0) : sum;
  }, 0);
  return newDisk > 1024 ? (newDisk / 1024).toFixed(1) : newDisk.toFixed(0);
});

const recommendedStorageTiB = computed(() => {
  const newDisk = dataArray.value.reduce((sum, item) => {
    return item.retire === false ? sum + (item?.pricing?.new_disk || 0) : sum;
  }, 0);
  return newDisk > 1024 ? ' TiB' : ' GiB';
});

const currentStorage = computed(() => {
  const totalDisk = dataArray.value.reduce((sum, item) => sum + (item?.pricing?.total_disk || 0), 0);
  return totalDisk > 1024 ? (totalDisk / 1024).toFixed(1) : totalDisk.toFixed(0);
});

const currentStorageTiB = computed(() => {
  const totalDisk = dataArray.value.reduce((sum, item) => sum + (item?.pricing?.total_disk || 0), 0);
  return totalDisk > 1024 ? ' TiB' : ' GiB';
});

</script>

<style scoped>
.recommendation-summary {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
}

h3 {
  margin: 0;

  font-weight: 700;
}

.fixed-width {
  margin-top: 11px;
  width: 323px;
}

.request-section {
  display: flex;
  align-items: center;
}

.request-item {
  width: 107px;
  text-align: left;
  padding-left: 24px;
}

.label {
  font-size: 13px;
  color: #bbbbbb;
}

.value {
  font-weight: 600;
  color: #a4d1fe;
  font-size: 18px;
  margin: 6px 0 9px;
}

.cores,
.storage {
  display: flex;
  align-items: baseline;
  margin-bottom: 2px;
  font-size: 13px;
}

.number {
  display: inline-block;
  text-align: left;
  font-size: 13px;
}

.suffix {
  color: grey;
  margin-left: 5px;
  font-size: 12px;
}

.arrow {
  font-size: 30px;
  color: #bdbdbd;
  margin-top: 13px;
  padding: 0 20px 0 15px;
}
</style>