<template>
 <div class="outer-container" :style="{ height: topViewHeight }">
      <div style="display: flex; align-items: center; margin-bottom: 15px; vertical-align: middle;">

        <div class="portfolio-header">Portfolios</div>
        
        <label class="top-button-style" style="margin-left: 987px; display: flex; align-items: center; width: 105px;" @click="openNewPortfolioModal">
          <Pencil2Icon style="width: 20px; margin-right: 8px;"/>
          New Portfolio
        </label>

      </div>

      <div class="table-container">
        <table class="portfolio-table">
          <tr>
            <th class="header-cell" style="width: 23px"></th>
            <th class="header-cell" style="width: 130px">Portfolio ID</th>
            <th class="header-cell" style="width: 30px"></th>
            <th class="header-cell" style="width: 150px">Customer</th>
            <th class="header-cell" style="width: 230px">Description</th>
            <th class="header-cell" style="width: 150px">Client Key</th>
            <th class="header-cell" style="width: 130px">Region</th>
            <th class="header-cell" style="width: 70px">Servers</th>
            <th class="header-cell" style="width: 90px">Created</th>
            <th class="header-cell" style="width: 50px">Edit</th>
          </tr>
          <tr v-for="portfolio in paginatedPortfolios" @click="selectPortfolio(portfolio.api_key)" :class="{'selected-row': localSelectedPortfolioKey === portfolio.api_key}" :key="portfolio.api_key">
            <td>
            <div class="radio-container" @click="localSelectedPortfolioKey = portfolio.api_key">
              <RadioOnIcon v-if="localSelectedPortfolioKey === portfolio.api_key" />
              <RadioOffIcon v-else />
            </div>
            </td>
            <td>{{ getPortfolioDisplay(portfolio.portfolio) }}</td>
            <td>
              <CheckPlayIcon
                  v-if="portfolio.agent_status !== 'paused'"
                  style="width: 13px; margin-bottom: -1.9px; fill: rgb(67, 160, 71);"
              />
              <CheckPause
                v-if="portfolio.agent_status === 'paused'"
                style="width: 13px; margin-bottom: -1.9px; fill: rgb(193 193 193)"
              />
            </td>
            <td>{{ portfolio?.customer }}</td>
            <td>{{ portfolio?.description }}</td>
            <td>{{ portfolio.preassess ? "" : portfolio.api_key }}</td>
            <td>{{ portfolio.aws_region }}</td>
            <td>{{ portfolio?.nodes?.toLocaleString() }}</td>
            <td>{{ formatDate(portfolio.created) || 'N/A' }}</td>
            <td>
              <div class="dropdown-container">
                <button @click.stop="toggleDropdown(portfolio.api_key, $event)" class="dropdown-button">
                  &#8942; <!-- Three vertical dots icon -->
                </button>
                <Teleport to="body">
                  <ul v-if="activeDropdown === portfolio.api_key" class="dropdown" :style="dropdownStyles">
                    <li @click="confirmPausePortfolio(portfolio, portfolio.agent_status === 'paused')">{{ portfolio.agent_status === 'paused' ? 'Resume' : 'Pause' }}</li>
                    <li @click="confirmStopPortfolio(portfolio)">Complete</li>
                    <li @click="editPortfolio(portfolio)">Edit</li>
                    <li @click="confirmDeletePortfolio(portfolio)">Delete</li>
                  </ul>
                </Teleport>
              </div>
            </td>
          </tr>
        </table>
      </div>

      <!-- Pagination Controls -->
      <div class="pagination-controls">
        <a href="#" @click.prevent="prevPage" :class="{ disabled: currentPage === 1 }" style="width: 90px;">Previous</a>
        <span style="margin-left: 15px; margin-right: 15px;">Page {{ currentPage }} of {{ totalPages }}</span>
        <a href="#" @click.prevent="nextPage" :class="{ disabled: currentPage === totalPages }" style="width: 90px;">Next</a>
      </div>

      <!-- <button class="portfolio-button" @click="openNewPortfolioModal">Add Portfolio</button> -->

      <!-- NewPortfolio Modal -->
      <NewPortfolio
        v-if="showModal"
        :isEditing="modalData.isEditing"
        :initialData="modalData.initialData"
        @close="showModal = false"
        @submit="handleAddPortfolio"
      />

      <!-- PausePortfolio Modal -->
      <PausePortfolio
        v-if="showPauseModal"
        :portfolioId="pauseData.portfolioId"
        :paused="pauseData.paused"
        @close="showPauseModal = false"
        @pause="handlePausePortfolio"
      />

      <!-- StopPortfolio Modal -->
      <StopPortfolio
        v-if="showStopModal"
        :portfolioId="stopData.portfolioId"
        @close="showStopModal = false"
        @stop="handleStopPortfolio"
      />

      <!-- DeletePortfolio Modal -->
      <DeletePortfolio
        v-if="showDeleteModal"
        :portfolioId="deleteData.portfolioId"
        @close="showDeleteModal = false"
        @delete="handleDeletePortfolio"
      />
    </div>
</template>

<script setup>
import { ref, watch, onMounted, onUnmounted, computed } from 'vue';
import axios from 'axios';
import { useDiscoveryStore } from "@/stores/DataStore";
import NewPortfolio from "@/components/portfolio/NewPortfolio.vue";
import DeletePortfolio from "@/components/portfolio/DeletePortfolio.vue";
import RadioOnIcon from "@/components/icons/RadioOnIcon.vue";
import RadioOffIcon from "@/components/icons/RadioOffIcon.vue";
import PausePortfolio from "@/components/portfolio/PausePortfolio.vue";
import Pencil2Icon from "@/components/icons/Pencil2Icon.vue";
import StopPortfolio from "@/components/portfolio/StopPortfolio.vue";
import CheckPause from "@/components/icons/CheckPause.vue";
import CheckPlayIcon from "@/components/icons/CheckPlayIcon.vue";

const emit = defineEmits(['portfolioSwitched', 'newPortfolio', 'agentStatus']);

const props = defineProps({
  portfolios: {
    type: Array,
    default: () => []
  },
  selectedPortfolio: {
    type: String,
    default: null
  }
});

const pageSize = ref(8);
const currentPage = ref(1);

const totalPages = computed(() => Math.ceil(localPortfolios.value.length / pageSize.value));

const paginatedPortfolios = computed(() => {
  const start = (currentPage.value - 1) * pageSize.value;
  return localPortfolios.value.slice(start, start + pageSize.value);
});

const prevPage = () => {
  if (currentPage.value > 1) currentPage.value--;
};

const nextPage = () => {
  if (currentPage.value < totalPages.value) currentPage.value++;
};

const showModal = ref(false); // toggle NewPortfolio modal visibility
const showPauseModal = ref(false);
const showStopModal = ref(false);
const showDeleteModal = ref(false); // toggle DeletePortfolio modal visibility
const activeDropdown = ref(null); // track which portfolio dropdown is active
const dropdownStyles = ref({}); // dynamic styles for dropdown positioning

const modalData = ref({
  isEditing: false,
  initialData: { portfolioId: '', customer: '', description: '', api_key: '' }
});

const deleteData = ref({
  portfolioId: ''
});

const pauseData = ref({
  portfolioId: '',
  paused: false
});

const stopData = ref({
  portfolioId: '',
  paused: false
});

const openNewPortfolioModal = () => {
  modalData.value.isEditing = false;
  modalData.value.initialData = { portfolioId: '', customer: '', description: '', region: '' };
  showModal.value = true;
};

const confirmPausePortfolio = (portfolio, paused) => {
  selectPortfolio(portfolio.api_key)
  pauseData.value.portfolioId = portfolio.portfolio;
  pauseData.value.paused = paused;
  showPauseModal.value = true;
};

const confirmStopPortfolio = (portfolio) => {
  selectPortfolio(portfolio.api_key)
  stopData.value.portfolioId = portfolio.portfolio;
  showStopModal.value = true;
};

const confirmDeletePortfolio = (portfolio) => {
  selectPortfolio(portfolio.api_key)
  deleteData.value.portfolioId = portfolio.portfolio;
  showDeleteModal.value = true;
};

const handleAddPortfolio = async (formData) => {
  if (modalData.value.isEditing) {
    // edit portfolio
    const index = localPortfolios.value.findIndex(p => p.api_key === formData.api_key);
    if (index !== -1) {
      localPortfolios.value[index].customer = formData.customer;
      localPortfolios.value[index].description = formData.description;
    }

    try {
      await axios.post('/go/updateportfolio', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
    } catch (error) {
      console.error('Error updating portfolio:', error);
    }

  } else {
    // add portfolio
    localPortfolios.value.unshift({
      portfolio: formData.portfolioId, customer: formData.customer,
      description: formData.description, region: formData.region,
      aws_region: formData.aws_region,
      api_key: "", cpu_perc: 95, cpu_buff: 20, nodes: 0, created: new Date(),
      preassess: formData.type==="preassess",
    });
    localSelectedPortfolioKey.value = ""; // make selected

    try {
      const response = await axios.post('/go/newportfolio', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      localPortfolios.value[0].api_key = response.data.api_key;
      localSelectedPortfolioKey.value = response.data.api_key;
    } catch (error) {
      console.error('Error adding portfolio:', error);
    }
  }

  emit('newPortfolio');

  showModal.value = false;
  modalData.value.isEditing = false;
};

const handlePausePortfolio = async (portfolioId, paused) => {
  try {
    const formData = new URLSearchParams();
    formData.append('portfolioId', portfolioId);

    if (paused) {
      await axios.post('/go/resumeportfolio', formData);
    } else {
      await axios.post('/go/pauseportfolio', formData);
    }

    const portfolio = localPortfolios.value.find(p => p.portfolio === portfolioId);
    if (portfolio) {
      portfolio.agent_status = paused ? "active" : "paused";
    }

    // update page and status icon
    emit('agentStatus', !paused);
    emit('newPortfolio');
  } catch (error) {
    console.error('Error deleting portfolio:', error);
  }
};

const handleStopPortfolio = async (portfolioId) => {
  try {
    const formData = new URLSearchParams();
    formData.append('portfolioId', portfolioId);

    await axios.post('/go/stopportfolio', formData);

    const portfolio = localPortfolios.value.find(p => p.portfolio === portfolioId);
    if (portfolio) {
      portfolio.agent_status = "complete";
    }

    // update page and status icon
    emit('newPortfolio');
  } catch (error) {
    console.error('Error deleting portfolio:', error);
  }
};

const handleDeletePortfolio = async (portfolioId) => {
  try {
    const formData = new URLSearchParams();
    formData.append('portfolioId', portfolioId);
    await axios.post('/go/deleteportfolio', formData);

    // get the api key from localPortfolios where p.portfolio == portfolioId
    let api_key = "";
    const portfolio = localPortfolios.value.find(p => p.portfolio === portfolioId);
    if (portfolio) {
      api_key = portfolio.api_key;
    }

    // remove portfolio from localPortfolios after successful deletion
    localPortfolios.value = localPortfolios.value.filter(p => p.portfolio !== portfolioId);

    // check if the deleted portfolio is the currently selected one
    if (localSelectedPortfolioKey.value === api_key) {

      // select the first portfolio in the list if there are any portfolios left
      if (localPortfolios.value.length > 0) {
        localSelectedPortfolioKey.value = localPortfolios.value[0].api_key;
        switchPortfolio();
      } else {
        localSelectedPortfolioKey.value = null; // no portfolios left
      }
    }

  } catch (error) {
    console.error('Error deleting portfolio:', error);
  }

  showDeleteModal.value = false;

  // triggers portfolio screen redownload
  emit('newPortfolio');
};

const toggleDropdown = (apiKey, event) => {

  // change portfolio
  selectPortfolio(apiKey)

  // hide dropdown if shown
  if (activeDropdown.value === apiKey) {
    activeDropdown.value = null;
    return;
  }

  const buttonRect = event.target.getBoundingClientRect();

  dropdownStyles.value = {
    position: 'absolute',
    top: `${buttonRect.bottom}px`, // position it below the button
    left: `${buttonRect.left - 60}px`,  // align it with the button
    zIndex: 1000,
  };

  activeDropdown.value = apiKey;
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
  window.addEventListener('keydown', handleKeyNavigation);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
  window.removeEventListener('keydown', handleKeyNavigation);
});

// key up/down support
const handleKeyNavigation = (event) => {
  if (!localPortfolios.value.length) return;

  const currentIndex = localPortfolios.value.findIndex(
    (portfolio) => portfolio.api_key === localSelectedPortfolioKey.value
  );

  let newIndex = currentIndex;

  if (event.key === 'ArrowDown') {
    if (currentIndex < localPortfolios.value.length - 1) {
      newIndex = currentIndex + 1;
    } else if (currentPage.value < totalPages.value) {
      nextPage();
      return;
    }
  } else if (event.key === 'ArrowUp') {
    if (currentIndex > 0) {
      newIndex = currentIndex - 1;
    } else if (currentPage.value > 1) {
      prevPage();
      return;
    }
  } else {
    return; // Exit if it's not ArrowUp or ArrowDown
  }

  const newPortfolio = localPortfolios.value[newIndex];
  if (newPortfolio) {
    selectPortfolio(newPortfolio.api_key);
    event.preventDefault();
  }
};

const store = useDiscoveryStore();

const localPortfolios = ref([...props.portfolios]);
const localSelectedPortfolioKey = ref(props.selectedPortfolio);

watch(() => props.portfolios, (newVal) => {
  localPortfolios.value = [...newVal];
}, { deep: true });

watch(() => props.selectedPortfolio, (newVal) => {
  if (newVal !== undefined) {
    localSelectedPortfolioKey.value = newVal;
  }
});

const editPortfolio = (portfolio) => {
  selectPortfolio(portfolio.api_key)

  modalData.value.isEditing = true;
  modalData.value.initialData = {
    portfolioId: portfolio.portfolio,
    customer: portfolio.customer,
    description: portfolio.description,
    api_key: portfolio.api_key,
    region: portfolio.region
  };
  showModal.value = true;
};

// select portfolio and switch
const selectPortfolio = (apiKey) => {

  // hide dropdown
  activeDropdown.value = null;

  if (localSelectedPortfolioKey.value === apiKey) return;

  localSelectedPortfolioKey.value = apiKey;
  store.setSelectedUuid(null, null);
  switchPortfolio();
};

// switch portfolio
const switchPortfolio = async () => {
  if (!localSelectedPortfolioKey.value) return;

  emit('prePortfolioSwitched', localSelectedPortfolioKey.value);

  // send actual portfolioid
  const selectedPortfolio = localPortfolios.value.find(
    (portfolio) => portfolio.api_key === localSelectedPortfolioKey.value
  );

  if (!selectedPortfolio) {
    console.error("Selected portfolio not found");
    return;
  }

  try {
    await axios.post('/go/setportfolio', {
      portfolio: selectedPortfolio.portfolio
    }, {
      headers: { 'Content-Type': 'application/json' }
    });

    emit('portfolioSwitched');

  } catch (error) {
    console.error("Error switching portfolio:", error);
  }
};

// format date utility
const formatDate = (dateString) => {
  if (!dateString) return null;
  const date = new Date(dateString);
  return date.toLocaleDateString(navigator.language, {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit'
  });
};

// het display name for portfolio
const getPortfolioDisplay = (portfolioName) => {
  if (!portfolioName) return '';
  const index = portfolioName.indexOf('.');
  return index === -1 ? portfolioName : portfolioName.slice(0, index);
};

// handle click outside to close dropdown
onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});

const handleClickOutside = (event) => {
  event.stopPropagation(); // stop the click event from propagating
  event.stopImmediatePropagation();

  const dropdowns = document.querySelectorAll('.dropdown-container');
  let isClickInside = false;

  dropdowns.forEach(dropdown => {
    if (dropdown.contains(event.target)) {
      isClickInside = true;
    }
  });

  if (!isClickInside) {
    activeDropdown.value = null;
  }
};
</script>

<style scoped>

.header-cell {
  padding: 2px 2px 4px 10px;
}

.portfolio-radio {
  vertical-align: middle;
}

.portfolio-table {
  margin-bottom: 0;
  width: 100%;
}

.portfolio-button {
  margin: 15px 0;
  background: #d05c00;
  color: #fff;
  border-radius: 4px 4px 4px 4px;
  cursor: pointer;
  padding: 2px 10px;
  border: 1px solid #2f353d;
}

.dropdown-container {
  position: relative;
}

.dropdown {
  background-color: #1d2532;
  box-shadow: 0 0 20px #000000;
  border: 1px solid #4a556d;
  border-radius: 3px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100px;
  z-index: 1000;
  position: absolute; /* Ensure the dropdown is absolutely positioned */
}

.dropdown li {
  padding: 8px 13px;
  cursor: pointer;
  color: #cccccc;
}

.dropdown li:hover {
  background-color: #384459;
}

.dropdown-button {
  margin: 0;
  background: none;
  border: none;
}

.radio-container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 13px;
  height: 13px;
  margin-top: 2px;
}

.pagination-controls {
  margin-top: 15px;
  margin-bottom: 15px;
}

.top-button-style {
  margin-left: 30px;
  background-color: #1d2532;
  border: 1px solid #2f353d;
  color: #d9d9d9;
  font-size: 12px;
  padding: 5px 9px 6px 9px;
  border-radius: 4px 4px 4px 4px;
  outline: none;
  width: 108px;
  cursor: pointer;
}

.top-button-style:hover {
  background-color: #273646;
  border: 1px solid #273646;
}

.portfolio-header {
  font-weight: 700;
  margin-bottom: 6.5px;
  font-size: 10pt;
  margin-top: 6px;
}
</style>