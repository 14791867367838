<template>
  <div class="modal-overlay">
    <div class="modal-content">
      <div class="header">Onpremise Costs</div>
      <div class="modal-body">

        <div class="modal-label">Generation Type</div>
        <select
          v-model="generationType"
          id="region"
          class="modal-input"
          style="width: 400px;">

          <option value="standard">Customer Provided</option>
          <!-- <option value="autogen">AI Cost Estimate [beta]</option> -->
        </select>

        <div v-if="generationType !== 'autogen'" style="margin-top: 10px; color: #c8c8c9;">

          <div class="modal-label" style="">Amortized Yearly Cost (USD)</div>

          <input
            v-model="onPremCost"
            type="text"
            class="modal-input"
            :disabled="disabled"
            :class="{ 'disabled-input': disabled }"
            placeholder=""
          />

        </div>

        <div v-if="generationType === 'autogen'" style="margin-top: 20px; color: #c8c8c9;">

          <div class="modal-label" style="margin-bottom:10px;"><b>AI Cost Generation</b></div>
          CloudHound can generate estimates for on-premise colocation hosting of the portfolio source servers in your country of choice.
          Please note this is a rough estimate based on regional pricing and may not fully align with current costs.
          <br><br>
          If possible, getting current costs from the customer should always be used first.
          <br><br>
          To generate a on-premise cost estimate, click the checkbox, then 'generate'. Generation takes approximately 1 minute to complete.

          <div class="modal-label" style="margin-top: 20px; font-weight: bold;">Data Center Location</div>
          <CountryDropdown v-model="onpremCountry"/>

          <div style="margin-top: 15px;">
            <label style="display: flex; align-items: center; gap: 5px; cursor: pointer;">
              <input type="checkbox" v-model="agreed" style="margin: 0;" />
              I understand this is an estimate only and may not reflect actual costs.
            </label>
          </div>

          <button :disabled="!agreed" @click="generateOnprem" class="modal-button">
            Generate
          </button>

          <span v-if="isGenerating" class="loading-spinner"/>
        </div>

      </div>
      <div class="modal-actions">
        <div class="error-message">{{ errorMessage }}</div>
        <button @click="submitForm" class="modal-button">Save</button>
        <button @click="closeModal" class="modal-button modal-close">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits, onUnmounted, onMounted, watch } from 'vue';
import axios from 'axios';
import CountryDropdown from "@/export/CountryDropdown.vue";

const emit = defineEmits(['close', 'submit']);

const agreed = ref(false)
const isGenerating = ref(false)

// modal visibility and error handling
const errorMessage = ref('');

const generationType = ref('standard');
const onpremCountry = ref(''); // replaces formData.onprem_country
const onPremCost = ref(0);

const props = defineProps({
  onprem: Number,
  useaigen: Boolean,
});

// when changed, reformat with $ and commas
watch(onPremCost, (newVal) => {
  let numericValue = newVal.replace(/\D+/g, '');
  if (numericValue === '') {
    onPremCost.value = '';
    return;
  }
  numericValue = parseInt(numericValue, 10).toLocaleString();
  onPremCost.value = `$${numericValue}`;
});

const generateOnprem = async () => {

  // already generating
  if (isGenerating.value) {
    return
  }

  // start generating
  isGenerating.value = true;

  try {
    await axios.post('/go/generate', {
      onprem_country: onpremCountry.value
    });
    console.log('Send Generate');
    startPolling();
  } catch (error) {
    console.error('Error starting generation:', error);
    alert('Failed to start the generation process. Please try again.');
    isGenerating.value = false;
  }
};

let generatingInterval = null;

const startPolling = () => {
  if (generatingInterval) return; // avoid duplicate intervals

  generatingInterval = setInterval(async () => {
    try {
      const response = await axios.get('/go/generating');

      if (!response.data.status) {
        clearInterval(generatingInterval);
        generatingInterval = null;
        isGenerating.value = false;

        //latestTimestamp.value = response.data.timestamp
        //latestTotal.value = response.data.total
      }

    } catch (error) {
      console.error('Error checking generation status:', error);
      clearInterval(generatingInterval);
      generatingInterval = null;
      isGenerating.value = false;
    }
  }, 1000);
};

const validateForm = () => {

  if (generationType.value === 'autogen') {
    if (!onpremCountry.value) {
      errorMessage.value = 'A country must be entered.';
      return false;
    }

    if (!agreed.value) {
      errorMessage.value = 'Please check the box';
      return false;
    }
  } else {
    if (onPremCost.value === 0) {
      errorMessage.value = 'Please enter a cost';
      return false;
    }
  }

  return true;
};

const submitForm = async () => {
  if (validateForm()) {

    // upload regardless of using autogen or not, but send a flag to tell server which to use (autogen)
    try {
      const numericCost = Number(String(onPremCost.value).replace(/\D+/g, ''));

      await axios.post('/go/onprem', {
        cost: numericCost,
        autogen: generationType.value === 'autogen'
      });
      console.log('Send On Prem');
    } catch (error) {
      console.error('Error starting generation:', error);
      alert('Failed to start the generation process. Please try again.');
      isGenerating.value = false;
    }

    closeModal();
  }
};

onMounted(() => {
  if (props.onprem === 0) {
    onPremCost.value = "";
  } else {
    onPremCost.value = `$${props.onprem.toLocaleString()}`;
  }

  if (props.useaigen) {
    generationType.value = "autogen"
  }
});

onUnmounted(() => {
  if (generatingInterval) {
    clearInterval(generatingInterval);
    generatingInterval = null;
  }
});

const closeModal = () => {
  emit('close');
};
</script>

<style scoped>
.header {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 15px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00000085;
  display: flex;
  justify-content: center;
  align-items: flex-start; /* align to the top */
  z-index: 100;
  padding-top: calc(100vh / 7);
  text-align: left;
}

.modal-content {
  background-color: #06080A;
  padding: 15px 20px 15px;
  border: 1px solid #2f353d;
  border-radius: 4px;
  width: 400px;
  max-width: 90vw;
  box-shadow: 0 0 58px #ffffff08;
}

.modal-input:focus {
  outline: none;
  box-shadow: none;
  border-color: #2f353d;
}

.modal-input {
  width: 358px;
  background-color: #0D1117;
  border: 1px solid #2f353d;
  color: #d9d9d9;
  font-size: 12px;
  padding: 6px 30px 7px 10px;
  border-radius: 4px 4px 4px 4px;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.modal-button {
  padding: 6px 12px;
  margin-top: 20px;
  margin-bottom: 0px;
}

.modal-close {
  background-color: #1d232d;
  color: #fff;
  border: 1px solid #2f353d;
  margin-left: 13px;
}

.modal-label {
  display: block;
  margin-bottom: 4px;
  margin-top: 10px;
  font-size: 12px;
  color: #d4d4d4;
}

.error-message {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff4d4f;
  font-size: 12px;
  font-weight: 600;
  margin-right: 0px;
  padding-right: 20px;
  width: 250px;
  height: 30px;
  margin-top: 15px;
  text-align: center;
}

.loading-spinner {
  display: inline-block;
  width: 12px;
  height: 12px;
  border: 1.5px solid #ffffff;
  border-top: 1.5px solid transparent;
  border-radius: 50%;
  animation: spin 0.6s linear infinite;
  margin-left: 5px; /* Reduce the space to keep it closer to the button */
  position: relative; /* Remove absolute positioning */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.modal-input {
  width: 358px;
  background-color: #0D1117;
  border: 1px solid #2f353d;
  color: #d9d9d9;
  font-size: 12px;
  padding: 6px 30px 7px 10px;
  border-radius: 4px 4px 4px 4px;
}

.modal-input:focus {
  outline: none;
  box-shadow: none;
  border-color: #2f353d;
}

</style>