<template>
  <div class="strategy">
    <div class="strategy-label">RDS Strategy</div>

    <div style="margin-bottom: 22px;">Reserved Instances</div>

    <div class="radio-buttons">
      <label style="margin-top: -2px; padding: 0;">
        <input type="radio" value="" v-model="yearly"/>
      </label>

      <label :class="{ active: yearly === '1' }">
        <input type="radio" value="1" v-model="yearly" @change="onRadioChange" /> 1yr
      </label>
      <label :class="{ active: yearly === '3' }">
        <input type="radio" value="3" v-model="yearly" @change="onRadioChange" /> 3yr
      </label>
    </div>

    <div>Payment term</div>

    <div class="radio-buttons">
      <label style="margin-top: -2px; padding: 0;">
        <input type="radio" value="" v-model="upfront"/>
      </label>

      <label :class="{'active': upfront === 'nu', 'disabled': isNoUpfrontDisabled}">
        <input type="radio" value="nu" v-model="upfront" @change="onRadioChange" :disabled="isNoUpfrontDisabled" /> No Upfront
      </label>
      <label :class="{ active: upfront === 'pu' }">
        <input type="radio" value="pu" v-model="upfront" @change="onRadioChange" /> Partial Upfront
      </label>
      <label :class="{ active: upfront === 'au' }">
        <input type="radio" value="au" v-model="upfront" @change="onRadioChange" /> All Upfront
      </label>
    </div>

    <div>Use On-Demand</div>

    <div class="radio-buttons">
      <label style="margin-top: -2px; padding: 0;">
        <input type="radio" value="0" v-model="ondemand"/>
      </label>

      <label :class="{ active: ondemand === '1' }">
        <input type="radio" value="1" v-model="ondemand" @change="onOnDemand" /> No Reserved Instances
      </label>
    </div>


  </div>
</template>

<script setup>
import { ref, computed, watch, onBeforeUnmount } from 'vue';
import { usePortfolioStore } from '@/stores/DataStore';
import axios from 'axios';

const emit = defineEmits(['updatePortfolio','postUpdatePortfolio', 'stopLoadIcon' ]);
const props = defineProps({
  portfolio: {
    type: String,
    required: true
  }
});

const portfolioStore = usePortfolioStore();
const portfolios = computed(() => portfolioStore.get);

const yearly = ref('1');  // Default to 1-year
const upfront = ref('nu');  // Default to No Upfront
const ondemand = ref('0');  // Default to none
const selectedStrategy = ref('ri_1nu');  // Default strategy

const filteredPortfolio = ref(null);

// Disable "No Upfront" if 3 years is selected
const isNoUpfrontDisabled = computed(() => yearly.value === '3');

// Watch yearly changes to adjust the upfront value accordingly
watch(yearly, (newYearly) => {
  if (newYearly === '3' && upfront.value === 'nu') {
    upfront.value = 'pu';  // Switch to Partial Upfront if No Upfront was selected
  } else if (newYearly === '1' && upfront.value === 'pu') {
    upfront.value = 'nu';  // Restore to No Upfront if All Upfront wasn't selected
  }
});

const previousState = {
  yearly: yearly.value,
  upfront: upfront.value,
};

// Watch apikey changes
watch(() => props.portfolio, (newportfolio) => {
  filteredPortfolio.value = portfolios.value?.find(p => p.portfolio === newportfolio);
  if (filteredPortfolio.value) {
    selectedStrategy.value = filteredPortfolio.value.rds_strategy;

    if (selectedStrategy.value === "ondemand") {
      ondemand.value = '1'
      yearly.value = '';
      upfront.value = '';
    } else {
      ondemand.value = ''
      yearly.value = selectedStrategy.value.charAt(3); // 1 or 3
      upfront.value = selectedStrategy.value.slice(4, 6); // nu/pu/au
    }

    // update previousState with the initial values
    previousState.yearly = yearly.value;
    previousState.upfront = upfront.value;
  }

}, { immediate: true });

// go to on demand and deselect the reserved instance
async function onOnDemand() {
  yearly.value = '';
  upfront.value = '';
  selectedStrategy.value = `ondemand`;
  sendStrategy();
}

// triggered only on user button use
async function onRadioChange() {

  // if switching back to a previously selected option (ie: back to all upfront) pick the other prev year/term selected
  if (yearly.value === previousState.yearly && upfront.value === '') {
    upfront.value = previousState.upfront;
  } else if (upfront.value === previousState.upfront && yearly.value === '') {
    yearly.value = previousState.yearly;
  }

  ondemand.value = '0'
  if (yearly.value === '')    { yearly.value = '1'; }
  if (upfront.value === '')   { upfront.value = 'nu'; }

  selectedStrategy.value = `ri_${yearly.value}${upfront.value}`;
  sendStrategy();

  previousState.yearly = yearly.value;
  previousState.upfront = upfront.value;
}

let controller = null;

async function sendStrategy() {
  let rds_strategy = selectedStrategy.value;

  if (!filteredPortfolio.value) {
    return;
  }

  emit('startLoadIcon');

  filteredPortfolio.value.rds_strategy = rds_strategy;

  portfolioStore.updatePortfolio(props.portfolio, filteredPortfolio.value);

  if (controller) {
    controller.abort(); // Cancel any ongoing request
  }

  controller = new AbortController();
  const { signal } = controller;

  try {
    await axios.post(
      `/go/portfolio/rds`,
      { rds_strategy },
      {
        headers: { 'Content-Type': 'application/json' },
        signal,
      }
    );
  } catch (error) {
    if (error.name === 'CanceledError') {
      console.log("Request canceled");
    } else {
      console.error("Error updating RDS strategy:", error);
    }
  } finally {
    controller = null; // Reset the controller
  }

  emit('updatePortfolio');
}

onBeforeUnmount(() => {
  if (controller) {
    emit('stopLoadIcon');
    controller.abort();
  }
});

</script>

<style scoped>
.radio-buttons {
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  border-color: #2f353d;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  background-color: #0d1117;
  padding: .25rem;
  width: 205px;
  margin-bottom: 14px;
  box-shadow: 0 0 0 1px #0000000f;
}

.radio-buttons label {
  display: flex;
  padding-left: 10px;
  align-items: center;
  background-color: #0d1117;
  color:  #e4e4e4;
  padding-top: 4px;
  padding-bottom: 5px;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid transparent;
}

.radio-buttons label.active {
  background-color: #384459 !important;
  border: 1px solid transparent;
  color: #e4e4e4;
}

.radio-buttons input[type="radio"] {
  display: none;
}

.strategy-label {
  margin-top: 10px;
  margin-bottom: 21px;
  font-weight: 500;
}

.strategy {
  width: 210px;
  margin-left: 20px;
}

.disabled {
  color: #646464 !important;
  pointer-events: none;
}
</style>