<template>
  <div class="counter-container">
    <div class="header">Records </div>
    <CountUp v-if="has_loaded" :startVal="oldRecordCount" :endVal="recordCount" :options="countUpOptions" />
    <span class="loader2" :style="{'--loader-speed-before': loaderSpeedBefore, '--loader-speed-after': loaderSpeedAfter,}"></span>
  </div>
</template>

<script setup>
import {ref, onMounted, onUnmounted, computed } from 'vue';
import axios from 'axios';
import CountUp from 'vue-countup-v3';

import { useRouter } from 'vue-router';
const router = useRouter();

const recordCount = ref(0.0);
const oldRecordCount = ref(0.0);

const cur_interval = ref(1)
const duration = ref(1)

const countUpOptions = computed(() => ({
  duration: duration.value,
  useEasing: false,
  decimalPlaces: 0,
}));

let has_loaded = ref(false)

let first_val = true;
let cancelTokenSource;
let intervalId;

onMounted(async () => {
  if (cancelTokenSource) {
    cancelTokenSource.cancel('Operation canceled due to new request.');
  }

  fetchRecordCount();
});

onUnmounted(() => {
  clearInterval(intervalId);
  if (cancelTokenSource) {
    cancelTokenSource.cancel('Component unmounted.');
  }
});

const fetchRecordCount = async () => {

  // cancel the previous request if it exists
  if (cancelTokenSource) {
    cancelTokenSource.cancel('Operation canceled due to new request.');
  }

  cancelTokenSource = axios.CancelToken.source();

  if (first_val) { // cancel any current animation
    oldRecordCount.value = recordCount.value;
  }

  // clear interval
  clearInterval(intervalId);

  try {

    const startTime = performance.now();

    const response = await axios.get(`/go/metrics/allrecords`, {
      headers: {'Content-Type': 'application/json'},
      cancelToken: cancelTokenSource.token
    })

    const endTime = performance.now();
    const latency = (endTime - startTime) / 1000; // Fetch latency in seconds

    if (first_val) { // if first val -> go direct to new item
      recordCount.value = response.data.count;
      oldRecordCount.value = response.data.count;
    } else { // otherwise animate to new
      oldRecordCount.value = recordCount.value;
      recordCount.value = response.data.count;

      // now change the duration to the next one up to 10 seconds
      if (cur_interval.value === 1) {
        cur_interval.value = 5;
      } else if (cur_interval.value === 5) {
        cur_interval.value = 10
      }
    }

    // duration between now and the next tick
    duration.value = cur_interval.value + latency // add time for latency

    first_val = false;
    has_loaded.value = true;

  } catch (error) {
    if (error.response && error.response.status === 401) {
      clearInterval(intervalId);
      router.push('/login'); // if unauthorized -> to login page
      return
    }

    if (!axios.isCancel(error)) {
      console.error('Error fetching record count:', error);
    }
  }

  // retrigger
  intervalId = setInterval(fetchRecordCount, cur_interval.value * 1000);
};

const loaderSpeedAfter = computed(() => {
  const diff = Math.abs(recordCount.value - oldRecordCount.value);
  const minSpeedAfter = duration.value * 0.16; // Fastest speed as a fraction of duration
  const maxSpeedAfter = duration.value * 10; // Slowest speed as a fraction of duration

  if (diff === 0) return maxSpeedAfter.toFixed(2) + 's';

  const scaledDuration = minSpeedAfter + ((maxSpeedAfter - minSpeedAfter) / (1 + diff));
  return scaledDuration.toFixed(2) + 's';
});

const loaderSpeedBefore = computed(() => {
  const afterSpeed = parseFloat(loaderSpeedAfter.value);
  return (afterSpeed * 0.75).toFixed(2) + 's';
});
</script>

<style scoped>
.counter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  margin: auto auto 6px;
}

.header {
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
  margin-left: 15px;
}

.countup-wrap {
  width: 85px;
  margin-left: 8px;
  text-align: left;
}

.loader2 {
  width: 16px;
  height: 16px;
  position: relative;
  display: inline-block;
  --base-color: #0d1117;
  margin-right: 30px;
  top: 0.5px;
}

.loader2::before {
  content: '';
  left: 0;
  top: 0;
  position: absolute;
  width: 14.4px;
  height: 14.4px;
  border-radius: 50%;
  background-color: #d9d9d9;
  background-image: radial-gradient(circle 3.2px at 7.2px 7.2px, var(--base-color) 100%, transparent 0),
                    radial-gradient(circle 1.6px at 7.2px 0px, var(--base-color) 100%, transparent 0),
                    radial-gradient(circle 1.6px at 0px 7.2px, var(--base-color) 100%, transparent 0),
                    radial-gradient(circle 1.6px at 14.4px 7.2px, var(--base-color) 100%, transparent 0),
                    radial-gradient(circle 1.6px at 7.2px 14.4px, var(--base-color) 100%, transparent 0),
                    radial-gradient(circle 1.6px at 12px 1.8px, var(--base-color) 100%, transparent 0),
                    radial-gradient(circle 1.6px at 12px 1.8px, var(--base-color) 100%, transparent 0),
                    radial-gradient(circle 1.6px at 12px 12px, var(--base-color) 100%, transparent 0),
                    radial-gradient(circle 1.6px at 1.8px 12px, var(--base-color) 100%, transparent 0),
                    radial-gradient(circle 1.6px at 1.8px 1.8px, var(--base-color) 100%, transparent 0);
  background-repeat: no-repeat;
  box-sizing: border-box;
  animation: rotationBack var(--loader-speed-before, 100s) linear infinite;
}
.loader2::after {
  content: '';
  left: 14px;
  top: 6px;
  position: absolute;
  width: 9.6px;
  height: 9.6px;
  border-radius: 50%;
  background-color: #d9d9d9;
  background-image: radial-gradient(circle 2px at 4.8px 4.8px, var(--base-color) 100%, transparent 0),
                    radial-gradient(circle 1px at 4.8px 0px, var(--base-color) 100%, transparent 0),
                    radial-gradient(circle 1px at 0px 4.8px, var(--base-color) 100%, transparent 0),
                    radial-gradient(circle 1px at 9.6px 4.8px, var(--base-color) 100%, transparent 0),
                    radial-gradient(circle 1px at 4.8px 9.6px, var(--base-color) 100%, transparent 0),
                    radial-gradient(circle 1px at 8px 1.2px, var(--base-color) 100%, transparent 0),
                    radial-gradient(circle 1px at 8px 1.2px, var(--base-color) 100%, transparent 0),
                    radial-gradient(circle 1px at 8px 8px, var(--base-color) 100%, transparent 0),
                    radial-gradient(circle 1px at 1.2px 8px, var(--base-color) 100%, transparent 0),
                    radial-gradient(circle 1px at 1.2px 1.2px, var(--base-color) 100%, transparent 0);
  background-repeat: no-repeat;
  box-sizing: border-box;
  animation: rotationBack var(--loader-speed-after, 100s) linear infinite reverse;
}
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

</style>
