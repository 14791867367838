<template>
  <div class="header" style="cursor: default !important;">Current Processes</div>

  <!-- Main process table -->
  <ProcessTable
    :processes="mainProcesses"
    type="main"
    :uuid="props.uuid"
    @update:hidden="updateHidden"
    @update:toggle="updateToggle"
  />

  <!-- Hidden Processes Header and Table -->
  <div v-if="Object.keys(hiddenProcesses).length > 0">
    <div class="header" @click="toggleHiddenList" style="cursor: pointer;">
      Hidden Processes ({{ Object.keys(hiddenProcesses).length }})
    </div>
    <ProcessTable
      v-if="showHidden"
      :processes="hiddenProcesses"
      type="hidden"
      :uuid="props.uuid"
      @update:hidden="updateHidden"
      @update:toggle="updateToggle"
    />
  </div>

  <!-- System Processes -->
  <div v-if="Object.keys(systemProcesses).length > 0">
    <div class="header2" @click="toggleSystemList" style="cursor: pointer;">
      System Processes
    </div>
    <ProcessTable
      v-if="showSystem"
      :processes="systemProcesses"
      type="system"
      :uuid="props.uuid"
      @update:toggle="updateToggle"
    />
  </div>
</template>

<script setup>
import { defineProps, reactive, ref, watch } from 'vue';
import ProcessTable from './ProcessTable.vue';

const props = defineProps({
  processInfo: {
    type: Array,
    default: () => [],
  },
  uuid: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['update:hidden']);

const showHidden = ref(false);
const showSystem = ref(false);
const mainProcesses = reactive({});
const hiddenProcesses = reactive({});
const systemProcesses = reactive({});

watch(
  () => props.processInfo,
  (newProcessInfo) => {
    groupProcesses(newProcessInfo);
  },
  { immediate: true, deep: true }
);

// Toggles the visibility of the hidden processes table
function toggleHiddenList() {
  showHidden.value = !showHidden.value;
}

function toggleSystemList() {
  showSystem.value = !showSystem.value;
}

// groups processes into main and hidden based on their attributes
function groupProcesses(processInfo) {

  // clear previous processes
  Object.keys(mainProcesses).forEach((key) => delete mainProcesses[key]);
  Object.keys(hiddenProcesses).forEach((key) => delete hiddenProcesses[key]);
  Object.keys(systemProcesses).forEach((key) => delete systemProcesses[key]);

  // Process grouping logic
  const groups = processInfo.reduce((acc, item) => {
    const groupName = `${item.app_group}${item.version ? ` ${item.version}` : ''}`;

    if (!acc[groupName]) {
      acc[groupName] = {
        name: groupName,
        expanded: false,
        processes: [],
        cpu_percent: 0,
        memory_percent: 0,
        hidden: item.hidden,
        system: item.system,
        server_tag: false,
        listening_on: item.listening_on || [],
      };
    }

    acc[groupName].processes.push(item);
    acc[groupName].cpu_percent += item.cpu_percent || 0;
    acc[groupName].memory_percent += item.memory_percent || 0;

    // add new listening_on items without duplicates
    if (item.listening_on) {
      item.listening_on.forEach((address) => {
        if (!acc[groupName].listening_on.includes(address)) {
          acc[groupName].listening_on.push(address);
        }
      });
    }

    if (item.server_tag) {
      acc[groupName].server_tag = true;
    }

    return acc;
  }, {});

  // sort on CPU, highest to the top
  Object.values(groups).forEach((group) => {
    group.processes.sort((a, b) => {
      // sort by main_process first
      if (b.main_process !== a.main_process) {
        return b.main_process ? 1 : -1;
      }
      // then sort by cpu_percent
      return (b.cpu_percent || 0) - (a.cpu_percent || 0);
    });
  });

  // organize groups into main, hidden, or system
  Object.values(groups).forEach((group) => {
    if (group.system) {
      systemProcesses[group.name] = group;
    } else if (group.hidden) {
      hiddenProcesses[group.name] = group;
    } else {
      mainProcesses[group.name] = group;
    }
  });
}

function updateHidden(name, hide) {
  if (hide) {
    // move from mainProcesses to hiddenProcesses
    const group = mainProcesses[name];
    if (group) {
      group.hidden = true;
      delete mainProcesses[name];
      hiddenProcesses[name] = group;
    }
  } else {
    // move from hiddenProcesses to mainProcesses
    const group = hiddenProcesses[name];
    if (group) {
      group.hidden = false;
      delete hiddenProcesses[name];
      mainProcesses[name] = group;
    }
  }

  emit('update:hidden', name, hide);
}

function updateToggle(groupName, type) {
  let group = mainProcesses[groupName]
  if (type==='hidden') group = hiddenProcesses[groupName]
  if (type==='system') group = systemProcesses[groupName]

  if (group) group.expanded = !group.expanded;
}
</script>

<style scoped>
.header {
  font-weight: 600;
  margin-bottom: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 200px;
}

.header2 {
  font-weight: 600;
  margin-bottom: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #646464;
  width: 200px;
  margin-top: 13px;
}

.icon-chevron-right,
.icon-chevron-down {
  width: 12px;
  height: 12px;
  vertical-align: middle;
  margin-left: -14px;
  margin-right: -1px;
  margin-top: -1.5px;
}
</style>