<script setup>
import {ref, computed, onMounted, onUnmounted, watch} from 'vue';

const props = defineProps({
  ageInSeconds: {
    type: Number,
    required: true,
  }
});

const justUpdated = ref(null);
const timer = ref(Date.now()); // Reactive variable to trigger reactivity

const interpolateColor = (startColor, endColor, factor) => {
  const result = startColor.slice();
  for (let i = 0; i < 3; i++) {
    result[i] = Math.round(result[i] + factor * (endColor[i] - startColor[i]));
  }
  return `rgb(${result[0]}, ${result[1]}, ${result[2]})`;
};

const color = computed(() => {
  const timeSinceUpdate = justUpdated.value ? (Date.now() - justUpdated.value) / 1000.0 : Infinity;
  timer.value;

  if (timeSinceUpdate < 2) {
    const factor = timeSinceUpdate / 2;
    return interpolateColor([105, 224, 111], [67, 160, 71], factor);
  } else if (props.ageInSeconds < 120) {
    return '#43a047';
  } else {
    return '#a3a3a3';
  }
});

const tooltip = computed(() => formatDate(props.ageInSeconds));

let intervalId;

onMounted(() => {
  if (!justUpdated.value) {
    justUpdated.value = Date.now() - 60000;
  }
  intervalId = setInterval(() => {
    timer.value = Date.now();
  }, 5);
});

onUnmounted(() => {
  clearInterval(intervalId);
});

watch(
    () => props.ageInSeconds,
    (newValue, oldValue) => {
      if (newValue < oldValue) {
        justUpdated.value = Date.now(); // Update the last update time
      }
    }
);

const formatDate = (secondsAgo) => {
  if (!secondsAgo) {
    return `0s ago`;
  } else if (secondsAgo < 100) {
    return `${secondsAgo}s ago`;
  } else if (secondsAgo < 3600) {
    const minutes = Math.floor(secondsAgo / 60);
    return `${minutes}m ago`;
  } else if (secondsAgo < 86400) {
    const hours = Math.floor(secondsAgo / 3600);
    return `${hours}h ago`;
  } else {
    const days = Math.floor(secondsAgo / 86400);
    return `${days}d ago`;
  }
};
</script>

<template>
  <div :title="tooltip" style="width: 14px; height: 14px; padding-top: 2px;">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
      <path d="M32,2C15.431,2,2,15.432,2,32c0,16.568,13.432,30,30,30c16.568,0,30-13.432,30-30C62,15.432,48.568,2,32,2z M25.025,50
            l-0.02-0.02L24.988,50L11,35.6l7.029-7.164l6.977,7.184l21-21.619L53,21.199L25.025,50z" :fill="color"/>
    </svg>
  </div>
</template>
