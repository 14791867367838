<template>
  <div class="recommendation-summary">
    <div style="font-weight: 700; text-align: left; margin-top: 10px; font-size: 13px; opacity: 0;">Recommendation Impact</div>

    <div class="request-section">
      <div class="request-item">
        <div class="label">Cost savings <span style="color: dimgrey">*</span></div>

        <div class="value">
          <div class="fixed-width">
            ${{ Math.round((currentCost - recommendedCost) * 12).toLocaleString() }}<span style="color: #b3b3b3; margin-left: 3px; font-size: 14px;">/yr</span>
          </div>

          <span v-if="costPercentageDecrease >= 0" class="triangle">▼</span>
          <span v-else class="triangle">▲</span>
          <span class="big-percentage">{{ costPercentageDecrease.toFixed() }}%</span>
        </div>

        <div class="cores">
          <div class="fixed-width">
            <span class="number">{{ cpuPercentageDecrease < 0 ? "+" : "" }}{{ Math.round(recommendedCPU - currentCPU).toLocaleString() }}</span>
            <span class="suffix">CPU</span>
          </div>
          <span v-if="cpuPercentageDecrease >= 0" class="triangle">▼</span>
          <span v-else class="triangle">▲</span>
          <span class="percentage">{{ Math.abs(cpuPercentageDecrease).toFixed() }}%</span>
        </div>
        <div class="cores">
          <div class="fixed-width">
            <span class="number">{{ memPercentageDecrease < 0 ? "+" : "" }}{{ Math.round(recommendedMem - currentMem).toLocaleString() }}</span>
            <span class="suffix">Mem</span>
          </div>
          <span v-if="memPercentageDecrease >= 0" class="triangle">▼</span>
          <span v-else class="triangle">▲</span>
          <span class="percentage">{{ Math.abs(memPercentageDecrease).toFixed() }}%</span>
        </div>

        <div class="storage">
          <div class="fixed-width">
            <span class="number">-{{ Math.round(diffStorage).toLocaleString() }}</span>
            <span class="suffix">{{ diffStorageTiB }} Disk</span>
          </div>
          <span class="triangle">▼</span>
          <span class="percentage">{{ Math.abs(storagePercentageDecrease).toFixed() }}%</span>
        </div>
      </div>
    </div>

    <div style="font-size: 10px; color: #626262; text-align: right; margin-top: 8px;">* EC2/RDS Only</div>
  </div>
</template>


<script setup>
import { computed, toRefs } from 'vue';
import Decimal from 'decimal.js';

const props = defineProps({
  dataArray: {
    type: Array,
    required: true
  },
  free_egress: {
    type: Number,
    required: true
  },
  support: {
    type: String,
    required: true
  },
  discount: {
    type: Number,
    required: true
  },
  overhead: {
    type: Number,
    required: true
  },
  s3_cost: {
    type: Number,
    required: true,
    default: 0
  }
});

const { dataArray } = toRefs(props);

// method to compute extras: egress, discount, and support fees
function computeExtras(baseCost, egress, overhead, discount, support) {
  const totalBase = new Decimal(baseCost).toNumber();
  //const overheadCost = new Decimal(totalBase).times(overhead / 100).toNumber();
  //const totalWithOverhead = new Decimal(totalBase).plus(overheadCost).toNumber();
  const discountAmount = new Decimal(totalBase).times(discount / 100).toNumber();
  const totalAfterDiscount = new Decimal(totalBase).minus(discountAmount).toNumber();
  return new Decimal(totalAfterDiscount).toNumber()
}

// compute the totals for current costs
const currentCost = computed(() => {
  const baseCost = dataArray.value.reduce((sum, item) => {
    return sum + (item?.pricing?.target_orig_price || 0);
  }, 0);

  if (dataArray.value && dataArray.value.length > 0) {
    let egress = dataArray.value.reduce((sum, item) => {
      const egressPrice = item?.pricing?.egress_price || 0;
      return sum + egressPrice;
    }, 0);

    // subtract free egress if applicable
    if (props.free_egress) {
      egress = Math.max(0, egress - props.free_egress);
    }

    return computeExtras(baseCost, egress, props.overhead, props.discount, props.support);
  }

  return baseCost;
});

// compute the totals for recommended costs
const recommendedCost = computed(() => {
  const baseCost = dataArray.value.reduce((sum, item) => {
    return !item.retire ? sum + (item?.pricing?.target_price || 0) : sum;
  }, 0);

  if (dataArray.value && dataArray.value.length > 0) {

    // calculate egress
    let egress = dataArray.value.reduce((sum, item) => {
      return !item.retire ? sum + (item?.pricing?.egress_price || 0) : sum;
    }, 0);

    // subtract free egress if applicable
    if (props.free_egress) {
      egress = Math.max(0, egress - props.free_egress);
    }

    return computeExtras(baseCost, egress, props.overhead, props.discount, props.support);
  }

  return baseCost;
});


const currentCPU = computed(() => {
  return dataArray.value.reduce((sum, item) => sum + (item?.pricing?.cpu || 0), 0);
});

const currentMem = computed(() => {
  return dataArray.value.reduce((sum, item) => sum + (item?.pricing?.mem || 0), 0);
});


const recommendedCPU = computed(() => {
  return dataArray.value.reduce((sum, item) => {
    return !item.retire ? sum + (item?.pricing?.new_cpu || 0) : sum;
  }, 0);
});

const recommendedMem = computed(() => {
  return dataArray.value.reduce((sum, item) => {
    return !item.retire ? sum + (item?.pricing?.new_mem || 0) : sum;
  }, 0);
});

// percentage decrease calculations
const cpuPercentageDecrease = computed(() => {
  const decrease = ((currentCPU.value - recommendedCPU.value) / currentCPU.value) * 100;
  return isNaN(decrease) ? 0 : Number(decrease);
});

const memPercentageDecrease = computed(() => {
  const decrease = ((currentMem.value - recommendedMem.value) / currentMem.value) * 100;
  return isNaN(decrease) ? 0 : Number(decrease);
});

const costPercentageDecrease = computed(() => {
  const decrease = ((currentCost.value - recommendedCost.value) / currentCost.value) * 100;
  return isNaN(decrease) ? 0 : Number(decrease);
});

const totalDisk = computed(() => {
  return dataArray.value.reduce((sum, item) => sum + (item?.pricing?.total_disk || 0), 0);
});

const newDisk = computed(() => {
  return dataArray.value.reduce((sum, item) => {
    return !item.retire ? sum + (item?.pricing?.new_disk || 0) : sum;
  }, 0);
});

const diffStorage = computed(() => {
  const diffDisk = totalDisk.value - newDisk.value;
  return diffDisk > 1024 ? (diffDisk / 1024) : diffDisk;
});

const diffStorageTiB = computed(() => {
  const diffDisk = totalDisk.value - newDisk.value;
  return diffDisk > 1024 ? ' TiB' : ' GiB';
});

const storagePercentageDecrease = computed(() => {
  const decrease = ((totalDisk.value - newDisk.value) / totalDisk.value) * 100;
  return isNaN(decrease) ? 0 : decrease;
});

</script>

<style scoped>
.recommendation-summary {
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  width: 253px;
}

h3 {
  margin: 0;
  font-weight: 700;
}

.request-section {
  display: flex;
  align-items: center;
  margin-top: 11px;
}

.request-item {
  flex: 1;
  text-align: left;
  padding-left: 15px;
}

.label {
  font-size: 13px;
  color: #bbbbbb;
}

.value {
  font-weight: 600;
  color: #5bc459;
  font-size: 18px;
  margin: 5px 0 6px;
}

.cores,
.storage {
  margin-bottom: -5px;
}

.number {
  display: inline-block;
  text-align: left;
  font-size: 13px;
}

.suffix {
  color: grey;
  margin-left: 5px;
  font-size: 12px;
  display: inline-block;
}

.percentage {
  color: #5bc459;
  margin-left: 5px;
  font-weight: 600;
  font-size: 13px;
}

.triangle {
  color: #5bc459;
  font-size: 10px;
}

.big-percentage {
  color: #5bc459;
  margin-left: 5px;
  font-weight: 600;
  font-size: 16px;
}

.big-triangle {
  color: #5bc459;
  font-size: 13px;
}

.fixed-width {
  width: 120px;
  display: inline-block;
  text-align: left;
  font-size: 18px;
}

</style>