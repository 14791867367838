<template>
  <div class="agent-info">
    <div class="agent-field agent-pad-right">
      <label for="linux-agent" class="agent-header">Unified Unix agent (Linux x86, x64, ARM, AIX, Solaris, FreeBSD):</label>
      <div class="input-group">
        <input id="linux-agent" type="text" class="agentCommand" :value="unixCommand" readonly>

        <button v-if="apikey" @click="copyToClipboard(unixCommand)" class="copy-btn">
          <span class="button-text">Copy</span>
        </button>

      </div>
    </div>
    <div class="agent-field">
      <label for="windows-agent" class="agent-header">Unified Windows agent (Win x86, x64, ARM):</label>
      <div class="input-group">
        <input id="windows-agent" type="text" class="agentCommand" :value="winCommand" readonly>

        <button v-if="apikey" @click="copyToClipboard(winCommand)" class="copy-btn">
          <span class="button-text">Copy</span>
        </button>

      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue';

const props = defineProps({
  apikey: {
    type: String,
    default: null,
    validator: value => typeof value === 'string' || value === null
  }
});

const winCommand = computed(() => {
  if (!props.apikey){return ''}

  return `iwr https://go.cloudhound.io/?${props.apikey || 'YOUR_API_KEY'} -UseBasicParsing | iex`;
});

const unixCommand = computed(() => {
  if (!props.apikey){return ''}
  return `sudo sh -c "$(curl -s https://go.cloudhound.io?${props.apikey || 'YOUR_API_KEY'})"`
});

const copyToClipboard = async (command) => {
  try {
    await navigator.clipboard.writeText(command);
    console.log("clicked " + command)
  } catch (err) {
    console.error('Failed to copy: ', err);
  }
};
</script>

<style scoped>
.agent-info {
  display: flex;
  flex-direction: row;
  padding-right: 150px;
}

.agent-field {
  flex: 1;
  margin-bottom: 10px;
}

.agent-pad-right {
  padding-right: 20px;
}

.input-group {
  position: relative;
  display: flex;
  align-items: center;
}

.agent-header {
    font-weight: 700;
    margin-bottom: 20px;
}

.agentCommand {
  font-size: 12px;
  font-weight: 500;
  background-color: #fff;
  border: 1px solid #d3d3d3;
  margin-top: 12px;
  padding: 6px 30px 7px 10px;
  border-radius: 3px;
  width: 100%;
}

.dark .agentCommand {
  background-color: #0d1117;
  border: 1px solid #2f353d;
  color: #d9d7d4;
}

.copy-btn {
  position: absolute;
  right: 6px;
  top: 6px;
  /* text-align: left; */
  width: 70px;
  padding: 1px 3px 3px 3px;
  border-radius: 4px 4px 4px 4px;
  cursor: pointer;
  height: 23px;
  margin-top: 10px;
  box-sizing: border-box;

  background: #d05c00;
  color: #fff;
  border: 1px solid #2f353d;
}

.agentCommand:focus {
  outline: none;
}
</style>