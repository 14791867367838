<template>
  <div class="outer-container" :style="{ height: topViewHeight }">
    <div style="margin-bottom: 30px;">
      <!-- <div class="header">Cost Summary</div> -->
      <div class="monitoring-table-container">
      <table class="monitoring-table" ref="monitoringTable">
       <tr>
          <th class="header-cell" style="cursor: pointer; width: 115px; padding-left: 8px; user-select: none;" @click="sortTable('host')">
            Host <span v-if="sortKey === 'host'">{{ sortOrder === 'asc' ? '↑' : '↓' }}</span>
          </th>
          <th class="header-cell" style="cursor: pointer; width: 125px; user-select: none;" @click="sortTable('label')">
            Label <span v-if="sortKey === 'label'">{{ sortOrder === 'asc' ? '↑' : '↓' }}</span>
          </th>
          <th class="value-center" style="cursor: pointer; user-select: none; width: 25px;" @click="sortTable('target')">
            Target <span v-if="sortKey === 'target'">{{ sortOrder === 'asc' ? '↑' : '↓' }}</span>
          </th>
          <th class="value-center" style="cursor: pointer; user-select: none; width: 50px;" @click="sortTable('osdb')">
            {{ getHeader() }} <span v-if="sortKey === 'osdb'">{{ sortOrder === 'asc' ? '↑' : '↓' }}</span>
          </th>
          <th class="value-center" style="cursor: pointer; user-select: none; width: 60px;" @click="sortTable('instance')">
            Instance <span v-if="sortKey === 'instance'">{{ sortOrder === 'asc' ? '↑' : '↓' }}</span>
          </th>
          <th class="value-center" style="cursor: pointer; user-select: none; width: 40px;" @click="sortTable('disk')">
            Disk <span v-if="sortKey === 'disk'">{{ sortOrder === 'asc' ? '↑' : '↓' }}</span>
          </th>
          <th class="value-center" style="cursor: pointer; user-select: none; width: 60px;" @click="sortTable('strategy')">
            Strategy <span v-if="sortKey === 'strategy'">{{ sortOrder === 'asc' ? '↑' : '↓' }}</span>
          </th>
          <th class="value-center" style="cursor: pointer; user-select: none; width: 45px;">
            Uptime
          </th>
          <th class="pricing-right" style="cursor: pointer; user-select: none; width: 46px;" @click="sortTable('compute')">
            Compute ($) <span v-if="sortKey === 'compute'">{{ sortOrder === 'asc' ? '↑' : '↓' }}</span>
          </th>
          <th class="pricing-right" style="cursor: pointer; user-select: none; width: 43px;" @click="sortTable('storage')">
            Storage ($) <span v-if="sortKey === 'storage'">{{ sortOrder === 'asc' ? '↑' : '↓' }}</span>
          </th>
          <th class="pricing-right" style="cursor: pointer; user-select: none; width: 43px;" @click="sortTable('total')">
            Total ($) <span v-if="sortKey === 'total'">{{ sortOrder === 'asc' ? '↑' : '↓' }}</span>
          </th>
        </tr>
        <tr v-for="(item, index) in paginatedMonitoring" :key="item.uuid">
          <td
            style="width: 210px; padding-left: 8px;"
            :style="{ opacity: item.retire ? 0.5 : 1 }"
          >
            <a
              :href="`/discovery?host=${item.host}`"
              class="hover-underline"
            >
              {{ item.host }}
            </a>
          </td>
          <td :style="{ opacity: item.retire ? 0.5 : 1 }">{{ item.label }}</td>
          <td class="value-center" :style="{ opacity: item.retire ? 0.5 : 1 }">{{ formatTarget(item) }}</td>
          <td class="value-center" :style="{ opacity: item.retire ? 0.5 : 1 }">{{ getOSDB(item) }}</td>
          <td class="value-center" :style="{ opacity: item.retire ? 0.5 : 1 }">{{ getInstance(item) }}</td>
          <td class="value-center" :style="{ opacity: item.retire ? 0.5 : 1 }">{{ getDiskFormatted(item) }} <span class="darker-white">{{ getDiskSuffix(item) }}</span></td>
          <td class="value-center" style="padding-left: 18px;" :style="{ color: (getSavingsPlan(item) === 'On Demand' || item.pricing.base_strategy) ? 'grey' : '' }">
            {{ getSavingsPlan(item) }}
            <ChevronDownIcon :class="{ 'chevron-opaque': !showSavingsPlanOptions(item) }" class="chevron" @click="() => toggleSavingsMenu(index)"/>
            <div v-if="savingsMenu === index" class="menu-popup">
              <ul>
                <li v-for="(option, optionIndex) in getSavingsPlanOptions(item)" :key="optionIndex" @click="selectSavingsOption(item, option)">
                  {{ option }}
                </li>
              </ul>
            </div>
          </td>
          <td class="value-center" style="padding-left: 18px;" :style="{ color: (item.pricing.uptime_strategy === 100 && !item.retire) ? 'grey' : '' }">
            <div v-if="item.target === 'ec2'" :style="{ opacity: item.retire ? 0.5 : 1 }">
            {{ item.pricing.uptime_strategy }}%
            <ChevronDownIcon :class="{ 'chevron-opaque': !showSavingsPlanOptions(item) }" class="chevron" @click="() => toggleUptimeMenu(index)"/>
            <div v-if="uptimeMenu === index" class="menu-popup">
              <ul>
                <li v-for="(option, optionIndex) in getUptimeOptions()" :key="optionIndex" @click="selectUptimeOption(item, option)">
                  {{ option }}
                </li>
              </ul>
            </div>
            </div>
          </td>
          <td class="pricing-right" :style="{ opacity: item.retire ? 0.5 : 1 }">
            {{ getInstancePrice(item).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
          </td>
          <td class="pricing-right" :style="{ opacity: item.retire ? 0.5 : 1 }">
            {{ getStoragePrice(item).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
          </td>
          <td class="pricing-right" :style="{ opacity: item.retire ? 0.5 : 1 }">
            {{ getTotalPrice(item).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
          </td>
        </tr>
      </table>
    </div>
    <div class="pagination-controls" v-if="totalPages > 0">
      <a href="#" @click.prevent="prevPage" :class="{ disabled: currentPage === 1 }">Previous</a>
      <span style="margin-left: 15px; margin-right: 15px;">Page {{ currentPage }} of {{ totalPages }}</span>
      <a href="#" @click.prevent="nextPage" :class="{ disabled: currentPage === totalPages }">Next</a>
    </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, onMounted, onUnmounted } from 'vue';
import ChevronDownIcon from "@/components/icons/ChevronDownIcon.vue";
import axios from 'axios';
const monitoringTable = ref(null);

// data from parent
const props = defineProps({
  costings: {
    type: Array,
    required: true
  },
  page: {
    type: String,
    required: true
  }
});

const sortKey = ref('');
const sortOrder = ref('asc'); // 'asc' or 'desc'

const sortTable = (key) => {
  if (sortKey.value === key) {

    // if clicking the same twice -> cancel
    let opposite = 'desc';
    if (sortOrder.value === opposite) {
      sortKey.value = '';
      sortOrder.value = 'asc';
      return;
    }

    sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc';
  } else {
    sortKey.value = key;
    sortOrder.value = 'asc';

    // descending should be the initial sort key for compute/storage (asc not useful)
    //if (sortKey.value==="compute" || sortKey.value==="storage" || sortKey.value==="total" || sortKey.value==="disk") {
    //  sortOrder.value = 'desc';
    //}
  }
};

const savingsMenu = ref(null);

const toggleSavingsMenu = (index) => {
  uptimeMenu.value = null;
  savingsMenu.value = savingsMenu.value === index ? null : index;
};

const uptimeMenu = ref(null);

const toggleUptimeMenu = (index) => {
  savingsMenu.value = null;
  uptimeMenu.value = uptimeMenu.value === index ? null : index;
};

const emit = defineEmits(['updatePortfolio','postUpdatePortfolio']);

const strategyMap = {
  "csp_1nu": "1y NU CSP",
  "csp_1pu": "1y PU CSP",
  "csp_1au": "1y AU CSP",
  "csp_3nu": "3y NU CSP",
  "csp_3pu": "3y PU CSP",
  "csp_3au": "3y AU CSP",

  "ec2_1nu": "1y NU ISP",
  "ec2_1pu": "1y PU ISP",
  "ec2_1au": "1y AU ISP",
  "ec2_3nu": "3y NU ISP",
  "ec2_3pu": "3y PU ISP",
  "ec2_3au": "3y AU ISP",

  "ri_1nu": "1y NU RI",
  "ri_1pu": "1y PU RI",
  "ri_1au": "1y AU RI",
  "ri_3pu": "3y PU RI",
  "ri_3au": "3y AU RI",

  "ondemand": "On Demand"
};

const getOSDB = (item) => {
  if (item.target === "ec2") return item.os;
  if (item.pricing.rds_database === "MariaDB") return "mariadb";
  if (item.pricing.rds_database === "MySQL") return "mysql";
  if (item.pricing.rds_database === "PostgreSQL") return "postgres";
  if (item.pricing.rds_database === "Db2") return "ibm db2";
  if (item.pricing.rds_database === "SQL Server") return "sql server";
  if (item.pricing.rds_database === "Oracle") return "oracle";
  return item.pricing.rds_database;
}

const showSavingsPlanOptions = (item) => {
  const options = getSavingsPlanOptions(item);
  return options && options.length > 0; // Ensure options exist and are non-empty
};

const getUptimeOptions = () => {
  let options = [];
  options.push("100%");
  options.push("75%");
  options.push("24%");
  return options;
};

const getSavingsPlanOptions = (item) => {

  const rds_discounts = Array.isArray(item.pricing?.rds_discounts) ? item.pricing.rds_discounts : [];

  let options;
  if (item.target.startsWith('rds')) {
    options = [
        'ri_1nu', 'ri_1pu', 'ri_1au', 'ri_3pu', 'ri_3au'
    ]
      .filter(key => rds_discounts.includes(key) && strategyMap[key])
      .map(key => strategyMap[key]);
  } else {
    options = [
      'csp_1nu', 'csp_1pu', 'csp_1au', 'csp_3nu', 'csp_3pu', 'csp_3au',
      'ec2_1nu', 'ec2_1pu', 'ec2_1au', 'ec2_3nu', 'ec2_3pu', 'ec2_3au'
    ]
      .map(key => strategyMap[key]);
  }

  // disable On Demand if there's an underlying base strategy
  let none_enabled = true;
  if (item.orig_ec2_strat !== "ondemand" && item.target === "ec2") {
    none_enabled = false;
  }
  if (item.orig_rds_strat !== "ondemand" && item.target.startsWith("rds")) {
    none_enabled = false;
  }

  if (item.pricing && item.pricing.strategy && item.pricing.strategy !== 'ondemand' && strategyMap[item.pricing.strategy.toLowerCase()]) {

    // do not remove current pricing strategy if EC2 with uptime <100 (so we can reset)
    if (item.target === 'rds' || item.pricing.uptime_strategy === 100) {
      // removes current pricing strategy
      options = options.filter(option => option !== strategyMap[item.pricing.strategy.toLowerCase()]);
    }

    if (none_enabled) {
      options.unshift('On Demand'); // add 'On Demand' at the start of the array
    }
  }

  return options;
};

const getHeader = () => {
  if (props.page === "ec2") return "OS";
  if (props.page === "rds") return "DB";
  return "OS / DB";
}

const selectSavingsOption = async (item, option) => {
  console.log(`Option ${option} selected for item ${item}`);

  const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
  };

  let selectedKey = getKeyByValue(strategyMap, option);
  console.log(`Key for the selected option: ${selectedKey}`);

  // update strategy
  item.pricing.strategy = selectedKey;

  item.pricing.base_strategy = false;

  if (item.target === "ec2" && item.orig_ec2_strat === item.pricing.strategy) {
    item.pricing.base_strategy = true;
  }
  if (item.target.startsWith("rds") && item.orig_rds_strat === item.pricing.strategy) {
    item.pricing.base_strategy = true;
  }

  // if back to base strategy, send a empty string to clear the override
  if (item.pricing.base_strategy) {
    selectedKey = "";
  }

  savingsMenu.value = null; // close menu

  emit('startLoadIcon');

  try {
    await axios.post(`/go/portfolio/indstrat`,
      { uuid: item.uuid, strat: selectedKey },
      {
        headers: { 'Content-Type': 'application/json' }
      }
    );
  } catch (error) {
    console.error("Error updating RDS strategy:", error);
  }

  emit('updatePortfolio');
};

const selectUptimeOption = async (item, option) => {
  console.log(`Uptime ${option} selected for item ${item}`);
  let uptime = parseInt( option.replace("%", ""), 10);

  item.pricing.uptime = uptime;
  uptimeMenu.value = null; // close menu4

  emit('startLoadIcon');

  try {
    await axios.post(`/go/portfolio/uptime`,
      { uuid: item.uuid, uptime: uptime },
      {
        headers: { 'Content-Type': 'application/json' }
      }
    );
  } catch (error) {
    console.error("Error updating RDS strategy:", error);
  }

  emit('updatePortfolio');
};

const handleOutsideClick = (event) => {
  if (!event.target.closest('.menu-popup') && !event.target.closest('.chevron')) {
    savingsMenu.value = null;
    uptimeMenu.value = null;
  }
};

onMounted(() => {
  document.addEventListener('click', handleOutsideClick);
});

onUnmounted(() => {
  document.removeEventListener('click', handleOutsideClick);
});

const currentPage = ref(1);
const pageSize = ref(400);

const totalPages = computed(() => {
  return props.costings ? Math.ceil(props.costings.length / pageSize.value) : 1;
});

const getSavingsPlan = (item) => {
  if (item.pricing.uptime_strategy < 100 && item.target === "ec2") {
    return "On Demand"
  }

  let result = strategyMap[item.pricing.strategy];
  return result === undefined ? "Unknown" : result;
}

const getDiskGiB = (item) => {
  switch(item.target.toLowerCase()) {
    case 'ec2':
      return item.pricing.new_disk;
    case 'rds':
      return item.pricing.rds_disk;
    case 'rdsm':
      return item.pricing.rds_disk;
  }
  return 0;
}


const getDiskFormatted = (item) => {
  let disk_size = getDiskGiB(item);
  if (disk_size >= 1024) {
    disk_size = (disk_size / 1024).toFixed(1)
  }
  return disk_size
}

const getDiskSuffix = (item) => {
  if (getDiskGiB(item) >= 1024) return "TiB";
  return "GiB";
}

const getInstance = (item) => {

  switch(item.target.toLowerCase()) {
    case 'ec2':
      return item.pricing.instance;
    case 'rds':
      return item.pricing.rds_instance;
    case 'rdsm':
      return item.pricing.rds_instance;
    default:
      return 0;
  }
}

const getTotalPrice = (item) => {
  if (item.retire) {
    return 0;
  }

  switch(item.target.toLowerCase()) {
    case 'ec2':
      return item.pricing.price_total;
    case 'rds':
      return item.pricing.rds_price_total;
    case 'rdsm':
      return item.pricing.rds_maz_price_total;
    default:
      return 0;
  }
}

/**
const getBackupPrice = (item) => {
  switch(item.target.toLowerCase()) {
    case 'ec2':
      return Math.round(item.pricing.price_backup);
    case 'rds':
      return "";
    case 'rdsm':
      return "";
    default:
      return 0;
  }
}
 **/

const getStoragePrice = (item) => {
  const p = item.pricing || {};
  switch (item.target?.toLowerCase()) {
    case 'ec2':
      return (
        (p.price_disk ?? 0) +
        (p.price_iops ?? 0) * 2 +
        (p.price_throughput ?? 0) +
        (p.price_backup ?? 0)
      );
    case 'rds':
      return (
        (p.rds_disk_price ?? 0) +
        (p.rds_iops_price ?? 0) +
        (p.rds_thrpt_price ?? 0)
      );
    case 'rdsm':
      return (
        ((p.rds_disk_price ?? 0) +
         (p.rds_iops_price ?? 0) +
         (p.rds_thrpt_price ?? 0)) * 2
      );
    default:
      return 0;
  }
};

const getInstancePrice = (item) => {
  switch(item.target.toLowerCase()) {
    case 'ec2':
      return item.pricing.price_nusp;
    case 'rds':
      return item.pricing.rds_price_ri;
    case 'rdsm':
      return item.pricing.rds_price_ri_mz;
    default:
      return 0;
  }
}

const formatTarget = (item) => {

  if (item.retire) {
    return "Retire"
  }

  switch(item.target) {
    case 'ec2':
      return 'EC2';
    case 'rds':
      return 'RDS';
    case 'rdsm':
      return 'RDS x2';
    default:
      return item.target;
  }
};

const paginatedMonitoring = computed(() => {
  if (!props.costings) {
    return [];
  }

  const start = (currentPage.value - 1) * pageSize.value;
  const end = start + pageSize.value;

  let sortedData = [...props.costings];
  if (sortKey.value) {
    sortedData.sort((a, b) => {
      let valueA = a[sortKey.value];
      let valueB = b[sortKey.value];

      if (sortKey.value === "compute") {
        valueA =  getInstancePrice(a);
        valueB =  getInstancePrice(b);
      }

      if (sortKey.value === "storage") {
        valueA =  getStoragePrice(a);
        valueB =  getStoragePrice(b);
      }

      if (sortKey.value === "total") {
        valueA =  getTotalPrice(a);
        valueB =  getTotalPrice(b);
      }

      if (sortKey.value === "osdb") {
        valueA = getOSDB(a);
        valueB = getOSDB(b);
      }

      if (sortKey.value === "disk") {
        valueA = getDiskGiB(a);
        valueB = getDiskGiB(b);
      }

      if (sortKey.value === "instance") {
        valueA = (a.target==="ec2") ? a.pricing.instance : a.pricing.rds_instance;
        valueB = (b.target==="ec2") ? b.pricing.instance : b.pricing.rds_instance;
      }

      // Handle numeric sorting
      if (typeof valueA === 'number' && typeof valueB === 'number') {
        return sortOrder.value === 'asc' ? valueA - valueB : valueB - valueA;
      }

      // Handle string sorting
      valueA = valueA ? valueA.toString().toLowerCase() : '';
      valueB = valueB ? valueB.toString().toLowerCase() : '';

      // empty strings should always go last
      if (valueA === '' && valueB !== '') return 1;
      if (valueB === '' && valueA !== '') return -1;

      if (valueA < valueB) return sortOrder.value === 'asc' ? -1 : 1;
      if (valueA > valueB) return sortOrder.value === 'asc' ? 1 : -1;
      return 0;
    });
  }

  return sortedData.slice(start, end);
});
const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
};
</script>

<style scoped>
.outer-container {
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: rgb(165,165,165) transparent;
  -ms-overflow-style: none; /* Edge, Internet Explorer */
}

/* Style the scrollbar for Webkit browsers (Chrome, Safari) */
.outer-container::-webkit-scrollbar {
  width: 6px;
}

.outer-container::-webkit-scrollbar-track {
  background: transparent;
}

.outer-container::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.header {
  font-weight: 700;
  margin-bottom: 14px;
  font-size: 10pt;
  margin-top: 10px;
  text-align: left;
}

.monitoring-table-container {
  width: 1195px;
  border: 1px solid #2f353d;
  border-radius: 2px;
  overflow: hidden;
}

.monitoring-table {
  border-collapse: collapse;
  width: 100%;
}

.monitoring-table th, .monitoring-table td {
  padding: 2px 5px 3px 5px;

  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-cell > * {
  margin: auto;
}

.pagination-controls {
  margin-top: 15px;
}

.value-center {
  text-align: center !important;
}

.pricing-right {
  text-align: right !important;
  padding-right: 5px !important;
}

.darker-white {
  color: rgba(255, 255, 255, 0.4);
}

.chevron {
  width: 10px;
  margin-bottom: -3.5px;
  cursor: pointer;
  padding-left: 3px;
  padding-right: 3px;
}

.chevron-opaque {
  color: #000;
  cursor: default;
}

.menu-popup {
  position: absolute;
  background-color: #11161e;
  border: 1px solid #2f353d;
  border-radius: 3px;
  z-index: 100;
  width: 70px;
  box-shadow: 0 8px 16px 0 #0003;
  margin-top: 6px;
  margin-left: -5px;
  color: #cccccc;
}

.menu-popup ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-popup li {
  padding: 4px 5px;
  cursor: pointer;
}

.menu-popup li:hover {
  background-color: #1d2532;
}

.hover-underline {
  padding-top: 4px;
  padding-bottom: 4px;
  text-decoration: none;
}

.hover-underline:hover {
  text-decoration: underline;
}
</style>
