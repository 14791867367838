import { defineStore } from 'pinia';

import localForage from "localforage";

const chartDB = localForage.createInstance({name: 'CloudHound', storeName: 'charts'});

export const useChartCacheStore = defineStore('chartData', {
  state: () => ({
    cache: {},
  }),
  actions: {
    async setCache(uuid, data) {
      if (!uuid || !this.isValidData(data)) {
        console.warn('Invalid data format or uuid, not saving to cache:', uuid, data);
        return;
      }

      const sanitizedData = JSON.parse(JSON.stringify(data));
      this.cache[uuid] = sanitizedData; // memcache
      await chartDB.setItem(uuid, sanitizedData); // db
    },
    async isDataStale(uuid) {

      // if not in cache, check if it's in indexedDB first
      // if not, return stale, otherwise load into mem from indexedDB
      if (!this.cache[uuid]) {
        this.cache[uuid] = await chartDB.getItem(uuid)
        if (!this.cache[uuid]) {
          return true
        }
      }

      const ten_minutes = 10 * 60 * 1000;

      // return if stale
      return (Date.now() - this.cache[uuid].timestamp) > ten_minutes;
    },
    async getCache(uuid) {

      // check if data exists in memory cache
      if (this.cache[uuid]) {
        return this.cache[uuid];
      }

      // if not in memory, check IndexedDB
      const cachedData = await chartDB.getItem(uuid);

      if (cachedData) {
        this.cache[uuid] = cachedData;
        return cachedData;
      }

      return null;
    },
    isValidData(data) {
      // check if data is an object and not null or an array
      if (typeof data !== 'object' || data === null || Array.isArray(data)) {
        return false;
      }
      return true;
    },
  }
});
