<template>
  <div style="display: flex; flex-direction: column;">
    <div style="display: flex; gap: 1rem;">

      <div style="display: flex; flex-direction: column;">
        <div class="header">CPU Utilisation</div>
        <div style="border: 1px #2F353D solid; margin-right: 10px; width: 500px; height: 215px; border-radius: 5px; overflow: hidden; background: #192634;">
          <div v-if="cpuChartOptions && Object.keys(cpuChartOptions).length > 0">
            <highcharts :constructor-type="'stockChart'" :options="cpuChartOptions" ref="cpuChartRef"></highcharts>
          </div>
        </div>
      </div>

      <div style="margin-left: 5px; display: flex; flex-direction: column;">
        <div class="header">Mem Utilisation</div>
        <div style="border: 1px #2F353D solid; margin-right: 10px; width: 500px; height: 215px; border-radius: 5px; overflow: hidden; background: #192634;">
          <div v-if="memChartOptions && Object.keys(memChartOptions).length > 0">
            <highcharts :constructor-type="'stockChart'" :options="memChartOptions" ref="memChartRef"></highcharts>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';
import HighchartsVue from 'highcharts-vue';
import axios from 'axios';
import { getCommonChartOptions } from '@/components/util-chart/chartOptions';
import { useChartCacheStore } from '@/stores/ChartStore';

export default {
  name: 'CpuAndMemWidget',
  components: {
    highcharts: HighchartsVue.component,
  },
  props: {
    uuid: {
      type: String,
      required: true,
    },
    pricing: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const cpuChartOptions = ref(null);
    const memChartOptions = ref(null);
    const cpuChartRef = ref(null);
    const memChartRef = ref(null);
    const currentUUID = ref(props.uuid);
    const controller = ref(null); // For aborting the request

    const chartCacheStore = useChartCacheStore();

    const updateCharts = (data) => {

      const newCpuChartOptions = {
        ...getCommonChartOptions(props, data.cpu, props.pricing.cpu_peak, props.pricing.cpu_buff),
        series: [{
          name: 'd7d7d7',
          color: '#d7d7d7',
          lineWidth: 1.0,
          showInNavigator: false,
          data: data.cpu.data,
          pointStart: data.cpu.pointStart,
          pointInterval: data.cpu.pointInterval,
          tooltip: {
            valueDecimals: 1,
            valueSuffix: '%',
          },
        }],
      };

      const newMemChartOptions = {
        ...getCommonChartOptions(props, data.mem, props.pricing.mem_peak, props.pricing.mem_buff),
        series: [{
          name: 'MEM',
          color: '#d7d7d7',
          lineWidth: 1.0,
          showInNavigator: false,
          data: data.mem.data,
          pointStart: data.mem.pointStart,
          pointInterval: data.mem.pointInterval,
          tooltip: {
            valueDecimals: 1,
            valueSuffix: '%',
          },
        }],
      };

      if (cpuChartRef.value && cpuChartRef.value.chart) {
        cpuChartRef.value.chart.update(newCpuChartOptions, true, false, false);
      } else {
        cpuChartOptions.value = newCpuChartOptions;
      }

      if (memChartRef.value && memChartRef.value.chart) {
        memChartRef.value.chart.update(newMemChartOptions, true, false, false);
      } else {
        memChartOptions.value = newMemChartOptions;
      }
    };

    const fetchData = async () => {

      if (controller.value) {
        controller.value.abort(); // abort previous request if exists
      }

      const fetchUUID = props.uuid; // Capture the UUID at the time of the fetch

      controller.value = new AbortController();
      const signal = controller.value.signal;

      try {
        const response = await axios.get(`/go/util/${fetchUUID}`, {
          headers: { 'Content-Type': 'application/json' },
          signal,
        });

        // Check if the UUID has changed since the fetch started
        if (fetchUUID === currentUUID.value && response.data) {
          updateCharts(response.data); // update charts with fresh data

          await chartCacheStore.setCache(fetchUUID, response.data); // update the cache
        }

      } catch (error) {
        if (axios.isCancel(error)) {
          // console.log('Request canceled:', error.message);
        } else {
          console.error('Error fetching data:', error);
        }
      }
    };

    const loadCharts = async () => {
      const currentFetchUUID = props.uuid; // Capture the UUID at the time of loading

      // Load from cache if available
      const cachedData = await chartCacheStore.getCache(currentFetchUUID);

      // if cached load chart
      if (cachedData) {
        // console.log("Cached version available")
        updateCharts(cachedData);
      }

      // if not cached or data is stale -> fetch
      if (!cachedData || (await chartCacheStore.isDataStale(currentFetchUUID))) {

        if (!cachedData) {
          console.log("No cache, requires fetch")
        } else {
          console.log("Data stale, refetch")
        }

        fetchData();
      }
    };

    onMounted(() => {
      currentUUID.value = props.uuid; // Initialize the current UUID
      loadCharts();
    });

    watch(() => props.uuid, (newUUID) => {
      currentUUID.value = newUUID; // Update the current UUID
      loadCharts();
    });

    onBeforeUnmount(() => {
      if (controller.value) {
        controller.value.abort(); // Abort any ongoing request when the component is unmounted
      }
    });

    return {
      cpuChartOptions,
      memChartOptions,
      cpuChartRef,
      memChartRef,
    };
  },
};
</script>

<style scoped>
.header {
  margin-top: 0;
  margin-left: 0px;
  margin-bottom: 13px;
  font-weight: 600;
  width: 100%;
}

.highcharts-range-input {
  font-size: 12px;
}
</style>